<nz-layout class="app-layout">
  <nz-layout>
    <nz-header>
      <div class="app-header">
        <div class="logos">
          <a class="logo" [routerLink]="['/']">
            <img src="/assets/img/logo_mini.png" alt="Logo Lavéo"/>
          </a>
          <div class="logo" *ngIf="logo">
            <img [src]="logo" alt="Votre Logo"/>
          </div>
        </div>
        <ul class="menu-horizontal" nz-menu nzMode="horizontal" nzTheme="dark">
          <li nz-menu-item [nzSelected]="router.url === '/'">
            <a [routerLink]="['/']" >
              <i nz-icon nzType="home"></i>
              Accueil
            </a>
          </li>
          <li nz-submenu [routerLink]="['/', 'prestations']">
            <span title>
              <i nz-icon nzType="unordered-list"></i>
              Prestations
            </span>
            <ul>
              <li *ngIf="canCreateService" nz-menu-item>
                <a [routerLink]="['/', 'prestations', 'new']">
                  <i nz-icon nzType="plus"></i>
                  <span>Créer une prestation</span>
                </a>
              </li>
              <li nz-menu-item>
                <a [routerLink]="['/', 'prestations', 'calendrier']" queryParamsHandling="merge">
                  <i nz-icon nzType="calendar"></i>
                  <span>Calendrier</span>
                </a>
              </li>
              <li nz-menu-divider></li>
              <li nz-menu-item>
                <a [routerLink]="['/', 'prestations']" [queryParams]="{status: 'ASKED'}">
                  <i nz-icon nzType="unordered-list"></i>
                  <span>Prestations demandées</span>
                </a>
              </li>
              <li nz-menu-item>
                <a [routerLink]="['/', 'prestations']" [queryParams]="{status: 'PROPOSED'}">
                  <i nz-icon nzType="unordered-list"></i>
                  <span>Prestations proposées</span>
                </a>
              </li>
              <li nz-menu-item>
                <a [routerLink]="['/', 'prestations']" [queryParams]="{status: 'PLANNED'}">
                  <i nz-icon nzType="unordered-list"></i>
                  <span>Prestations planifiées</span>
                </a>
              </li>
              <li nz-menu-item>
                <a [routerLink]="['/', 'prestations']" [queryParams]="{status: 'PERFORMED'}">
                  <i nz-icon nzType="unordered-list"></i>
                  <span>Prestations réalisées</span>
                </a>
              </li>
            </ul>
          </li>
          <li nz-submenu [routerLink]="['/', 'vehicles']" *ngIf="userCanReadVehicles">
            <span title>
              <i nz-icon nzType="car"></i>
              Véhicules
            </span>
            <ul>
              <li *ngIf="canCreateVehicle" nz-menu-item>
                <a [routerLink]="['/', 'vehicles', 'new']">
                  <i nz-icon nzType="plus"></i>
                  <span>Créer un véhicule</span>
                </a>
              </li>
            </ul>
          </li>
          <li nz-submenu [routerLink]="['/', 'reporting']" *ngIf="userCanSeeReporting">
            <span title>
              <i nz-icon nzType="database"></i>
              Rapports
              <i nz-icon nzType="down"></i>
            </span>
            <ul>
              <li nz-menu-item>
                <a [routerLink]="['/', 'reporting', 'vehicle']">
                  <i nz-icon nzType="car"></i>
                  <span>Véhicules</span>
                </a>
              </li>
              <li nz-menu-item>
                <a [routerLink]="['/', 'reporting', 'service-status']">
                  <i nz-icon nzType="unordered-list"></i>
                  <span>Statut des prestations</span>
                </a>
              </li>
              <li nz-menu-item>
                <a [routerLink]="['/', 'reporting', 'service-specifications']">
                  <i nz-icon nzType="ordered-list"></i>
                  <span>Caractéristiques des prestations</span>
                </a>
              </li>
              <li nz-menu-item>
                <a [routerLink]="['/', 'reporting', 'vehicle-monitoring']">
                  <i nz-icon nzType="car"></i>
                  <span>Monitoring Véhicules</span>
                </a>
              </li>
              <li nz-menu-item>
                <a [routerLink]="['/', 'reporting', 'fleet']">
                  <i nz-icon nzType="shop"></i>
                  <span>Monitoring Flotte</span>
                </a>
              </li>
              <li nz-menu-item>
                <a [routerLink]="['/', 'reporting', 'rse']">
                  <i nz-icon nzType="line-chart"></i>
                  <span>Bilan RSE</span>
                </a>
              </li>
            </ul>
          </li>
          <li nz-submenu [routerLink]="['/', 'admin']" *ngIf="(menuRights?.length ? menuRights.length : 0) > 1; else oneAdmin">
            <span title>
              <ng-container *ngIf="currentRole?.type === 'SUPER_ADMIN'; else nonAdmin">
                <i nz-icon nzType="setting"></i>
                Administration
              </ng-container>
              <ng-template #nonAdmin>
                <i nz-icon nzType="setting"></i>
                Mes infos
              </ng-template>
              <i nz-icon nzType="down"></i>
            </span>
            <ul>
              <li nz-menu-item *ngFor="let right of menuRights">
                <a [routerLink]="['/', 'admin', (right === 'customerSites' ? 'customer-sites' : right)]">
                  <i nz-icon [nzType]="right | rightIcon"></i>
                  <span>{{ right | rightName:currentRole?.type }}</span>
                </a>
              </li>
            </ul>
          </li>
          <ng-template #oneAdmin>
            <li nz-menu-item *ngFor="let right of menuRights">
              <a [routerLink]="['/', 'admin', (right === 'customerSites' ? 'customer-sites' : right)]">
                <i nz-icon [nzType]="right | rightIcon"></i>
                <span>{{ right | rightName:currentRole?.type }}</span>
              </a>
            </li>
          </ng-template>
        </ul>
        <button class="env" nz-button nzGhost disabled *ngIf="environmentString">{{ environmentString }}</button>
        <div class="profil">
          <a class="icon link" href="mailto:mylaveo@laveo.fr?subject={{ bugSubject }}&body={{ bugBody }}">
            <i nz-icon nzType="bug"></i>
          </a>
          <a class="icon link" [routerLink]="['/', 'prestations']" [queryParams]="{ f: 't' }">
            <i nz-icon nzType="search"></i>
          </a>
          <a class="user-menu" nz-dropdown [nzDropdownMenu]="userMenu" [nzOverlayClassName]="'user-menu'">
            <div nz-row nzGutter="16">
              <div nz-col>
                <nz-avatar nzIcon="user"></nz-avatar>
              </div>
              <div nz-col nzFlex="auto">
                <span>Mon compte</span>
              </div>
              <div nz-col>
                <i nz-icon nzType="down"></i>
              </div>
            </div>
          </a>
          <nz-dropdown-menu #userMenu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-group nzTitle="Mes rôles">
                <ul>
                  <li nz-menu-item *ngIf="this.userRolesAreLoading else rolesList">Chargement…</li>
                  <ng-template #rolesList>
                    <li nz-menu-item *ngFor="let role of this.userRoles" (click)="this.setRole(role.id)">
                      <i nz-icon nzType="check" *ngIf="role.id === currentRole?.id"></i>
                      {{ role.actor.name }} - {{ role.type | roleName }}
                    </li>
                  </ng-template>
                </ul>
              </li>
              <li nz-menu-divider></li>
              <li nz-menu-item>
                <a [routerLink]="['/', 'account']">
                  <i nz-icon nzType="user"></i>
                  Mon compte
                </a>
              </li>
              <li nz-menu-item>
                <a [routerLink]="['/', 'logout']">
                  <i nz-icon nzType="logout"></i>
                  Se déconnecter
                </a>
              </li>
            </ul>
          </nz-dropdown-menu>
        </div>
        <button class="menu-vertical" nz-button nzType="primary" (click)="toggleMobileMenu()"><i nz-icon nzType="menu" nzTheme="outline"></i></button>
        <nz-drawer [nzClosable]="false" [nzVisible]="isMobileMenuVisible" nzPlacement="right" nzTitle="Menu" (nzOnClose)="toggleMobileMenu()">
          <ng-container *nzDrawerContent>
            <ul nz-menu nzMode="inline" nzTheme="dark">
              <li nz-menu-item [nzSelected]="router.url === '/'">
                <a [routerLink]="['/']">
                  <i nz-icon nzType="home"></i>
                  Accueil
                </a>
              </li>
              <li nz-menu-item>
                <a [routerLink]="['/', 'prestations']">
                  <i nz-icon nzType="unordered-list"></i>
                  Prestations
                </a>
              </li>
              <li nz-menu-item *ngIf="userCanReadVehicles">
                <a [routerLink]="['/', 'vehicles']">
                  <i nz-icon nzType="car"></i>
                  Véhicules
                </a>
              </li>
              <li nz-submenu [routerLink]="['/', 'reporting']" *ngIf="userCanSeeReporting">
                <span title>
                  <i nz-icon nzType="database"></i>
                  Rapports
                </span>
                <ul>
                  <li nz-menu-item>
                    <a [routerLink]="['/', 'reporting', 'vehicle']">
                      <i nz-icon nzType="car"></i>
                      <span>Véhicules</span>
                    </a>
                  </li>
                  <li nz-menu-item>
                    <a [routerLink]="['/', 'reporting', 'service-status']">
                      <i nz-icon nzType="unordered-list"></i>
                      <span>Statut des Prestations</span>
                    </a>
                  </li>
                  <li nz-menu-item>
                    <a [routerLink]="['/', 'reporting', 'service-specifications']">
                      <i nz-icon nzType="ordered-list"></i>
                      <span>Caractéristiques des Prestations</span>
                    </a>
                  </li>
                  <li nz-menu-item>
                    <a [routerLink]="['/', 'reporting', 'vehicle-monitoring']">
                      <i nz-icon nzType="car"></i>
                      <span>Monitoring Véhicules</span>
                    </a>
                  </li>
                  <li nz-menu-item>
                    <a [routerLink]="['/', 'reporting', 'fleet']">
                      <i nz-icon nzType="shop"></i>
                      <span>Monitoring Flotte</span>
                    </a>
                  </li>
                  <li nz-menu-item>
                    <a [routerLink]="['/', 'reporting', 'rse']">
                      <i nz-icon nzType="line-chart"></i>
                      <span>Bilan RSE</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li nz-submenu [routerLink]="['/', 'admin']" *ngIf="(menuRights?.length ? menuRights.length : 0) > 1; else oneAdmin">
                <span title>
                  <ng-container *ngIf="currentRole?.type === 'SUPER_ADMIN'; else nonAdmin">
                    <i nz-icon nzType="setting"></i>
                    Administration
                  </ng-container>
                  <ng-template #nonAdmin>
                    <i nz-icon nzType="setting"></i>
                    Mes infos
                  </ng-template>
                </span>
                <ul>
                  <li nz-menu-item *ngFor="let right of menuRights">
                    <a [routerLink]="['/', 'admin', (right === 'customerSites' ? 'customer-sites' : right)]">
                      <i nz-icon [nzType]="right | rightIcon"></i>
                      <span>{{ right | rightName:currentRole?.type }}</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li nz-submenu>
                <span title>
                  <i nz-icon nzType="user"></i>
                  Mon compte
                </span>
                <ul>
                  <li nz-menu-item *ngIf="this.userRolesAreLoading else rolesListMenu">Chargement…</li>
                  <ng-template #rolesListMenu>
                    <li nz-menu-item *ngFor="let role of this.userRoles" (click)="this.setRole(role.id)">
                      <i nz-icon nzType="check" *ngIf="role.id === currentRole?.id"></i>
                      {{ role.actor.name }} - {{ role.type | roleName }}
                    </li>
                  </ng-template>
                  <li nz-menu-item>
                    <a [routerLink]="['/', 'account']">
                      <i nz-icon nzType="user"></i>
                      Mon compte
                    </a>
                  </li>
                  <li nz-menu-item>
                    <a [routerLink]="['/', 'logout']">
                      <i nz-icon nzType="logout"></i>
                      Se déconnecter
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </ng-container>
        </nz-drawer>
      </div>
    </nz-header>
    <nz-content>
      <router-outlet></router-outlet>
    </nz-content>
  </nz-layout>
</nz-layout>
