import { FilePondOptions } from 'filepond/dist/filepond.esm';

const baseFileUploadOptions: FilePondOptions = {
  maxTotalFileSize: null,
  labelMaxFileSizeExceeded: 'Le fichier est trop gros',
  labelMaxFileSize: 'Taille max. : {filesize}',
  labelFileTypeNotAllowed: 'Type de fichier non autorisé',
  fileValidateTypeLabelExpectedTypes: 'Attendu {allButLastType} ou {lastType}',
};

/** Configuration pour l'upload d'image (logo) */
export const imageFileUploadOptions: FilePondOptions = {
  ...baseFileUploadOptions,
  maxFileSize: '2MB',
  acceptedFileTypes: ['image/*'],
  imageResizeTargetWidth: 300,
  imageResizeTargetHeight: 64,
  imageResizeMode: 'contain',
  imageResizeUpscale: false,
  imageTransformOutputMimeType: 'image/png',
};

/** Configuration pour l'upload de PDF */
export const pdfFileUploadOptions: FilePondOptions = {
  ...baseFileUploadOptions,
  maxFileSize: '5MB',
  acceptedFileTypes: ['application/pdf'],
  instantUpload: false,
};
