import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { CustomerSiteBillingPerson } from 'src/app/shared/models/entities/customer-site-billing-person';

@Component({
  selector: 'laveo-select-customer-site-billing-person',
  templateUrl: './select-customer-site-billing-person.component.html',
  styleUrls: ['./select-customer-site-billing-person.component.scss']
})
export class SelectCustomerSiteBillingPersonComponent {
  @Input() control: AbstractControl | null;
  @Input() typeSize: 'default' | 'small' | 'large' = 'large';

  public get customerSiteBillingPersonValues(): CustomerSiteBillingPerson[] {
    return Object.values(CustomerSiteBillingPerson);
  }

  get formControl(): UntypedFormControl {
    return this.control as UntypedFormControl;
  }
}
