<nz-card [nzBordered]="false" [nzTitle]="cardTitle">
  <ng-template #cardTitle>
    <div class="header">
      <laveo-card-header
        title="Véhicules"
        [description]="description"
        [buttonTitle]="this.userCanAdd ? 'Ajouter un véhicule' : undefined"
        [buttonIcon]="this.userCanAdd ? 'plus' : undefined"
        [backButtonEnabled]="false"
        (buttonClick)="add()"
        [isLoading]="isLoading">
      </laveo-card-header>
      <nz-upload [(nzFileList)]="importFileList" (nzFileListChange)="handleChange($event)" [nzCustomRequest]="import" [nzAccept]="'.xlsx,.xls'" *ngIf="this.userCanAdd && this.userIsCustomerSite">
        <button
          nz-button
          nzType="primary"
          nzSize="large"
        ><i nz-icon nzType="upload"></i>Importer des véhicule (.xlsx)</button>
      </nz-upload>
      <a
        nz-button
        nzType="primary"
        nzSize="large"
        download
        [href]="excelPath"
        target="_blank"
        class="ml-8"
        *ngIf="this.userCanAdd && this.userIsCustomerSite"><i nz-icon nzType="download"></i>Télécharger le modèle d'import</a>
      <nz-modal
        [(nzVisible)]="importErrorModalVisible"
        (nzOnOk)="closeImportErrorModal()"
        (nzOnCancel)="closeImportErrorModal()"
        nzTitle="Erreur d'import pour {{ importErrors?.length }} véhicules">
        <ng-container *nzModalContent>
          <ul>
            <li *ngFor="let error of importErrors!"><b>{{ error.object.licensePlate }}</b> : {{ error.message }}</li>
          </ul>
        </ng-container>
      </nz-modal>
    </div>
  </ng-template>
  <div nz-row [nzGutter]="[16, 16]">
    <form nz-form [formGroup]="form" nzLayout="vertical">
      <div nz-row [nzGutter]="8" [nzAlign]="'middle'">
        <div nz-col *ngIf="checked.length > 0 && (userCanDelete || userCanEdit)">
          <button nz-button nzType="default" nzSize="large" nz-dropdown [nzDropdownMenu]="actionMenu">
            {{ checked.length }} {{ checked.length > 1 ? 'véhicules séléctionnés' : 'véhicule séléctionné' }}
            <i nz-icon nzType="down"></i>
          </button>
          <nz-dropdown-menu #actionMenu="nzDropdownMenu">
            <ul nz-menu nzSelectable>
              <!-- <li nz-menu-item (click)="copyChecked()">
                <i nz-icon nzType="copy"></i>
                Dupliquer
              </li> -->
              <li nz-menu-item (click)="updateChecked()" *ngIf="userCanEdit">
                <i nz-icon nzType="delete"></i>
                Modifier
              </li>
              <li nz-menu-item nzDanger (click)="deleteChecked()" *ngIf="userCanDelete">
                <i nz-icon nzType="delete"></i>
                Désactiver
              </li>
              <li nz-menu-divider></li>
              <li nz-menu-item (click)="checked.splice(0, checked.length)">
                <i nz-icon nzType="minus-square"></i>
                Tout déséléctionner
              </li>
            </ul>
          </nz-dropdown-menu>
        </div>
        <div nz-col nzFlex="1 1 auto">
          <nz-input-group [nzPrefix]="prefixIconSearch" nzSize="large">
            <ng-template #prefixIconSearch>
              <i nz-icon nzType="search"></i>
            </ng-template>
            <input type="text" nz-input placeholder="Rechercher un véhicule" formControlName="search" />
          </nz-input-group>
        </div>
        <div nz-col nzFlex="0 0 auto">
          <button nz-button [nzType]="filtersAreVisible ? 'primary' : 'default'" (click)="toggleFilter()" nzSize="large">
            <i nz-icon nzType="filter"></i>
          </button>
        </div>
      </div>

      <div *ngIf="filtersAreVisible">
        <div nz-row nzGutter="8" class="mt-8">
          <div nz-col [nzSm]="4" [nzMd]="2" [nzXl]="1">
            <form [formGroup]="form">
              <nz-form-item>
                <nz-form-label nzFor="active">Actifs</nz-form-label>
                <nz-form-control class="text-center mh-40">
                  <label nz-checkbox id="active" name="active" formControlName="active"></label>
                </nz-form-control>
              </nz-form-item>
            </form>
          </div>
          <div nz-col [nzSm]="20" [nzMd]="10" [nzXl]="7">
            <laveo-select-vehicle-brand
              [control]="form.get('brands')"
              [typeSelection]="'multiple'"
              [typeSize]="'large'"
              [canCreateBrand]="false"
              [showModels]="true">
            </laveo-select-vehicle-brand>
          </div>
          <div nz-col [nzSm]="24" [nzMd]="12" [nzXl]="8">
            <laveo-select-vehicle-category
              [control]="form.get('categories')"
              [typeSelection]="'multiple'"
              [typeSize]="'large'">
            </laveo-select-vehicle-category>
          </div>
          <div nz-col [nzSm]="24" [nzMd]="12" [nzXl]="8" *ngIf="userCanReadCustomerSites || userCanReadCustomer">
            <laveo-select-actor
              [control]="form.get('customers')"
              [typeEntity]="filterClientTypeEntity"
              [typeSelection]="'multiple'"
              [typeSize]="'large'"
              [placeholder]="userCanReadCustomer ? 'Clients et sites client' : 'Sites client'">
            </laveo-select-actor>
          </div>
        </div>
      </div>
    </form>
    <nz-table
      nzShowSizeChanger
      [nzData]="vehicles ? vehicles.data : []"
      [nzFrontPagination]="false"
      [nzLoading]="isLoading"
      [nzTotal]="vehicles ? vehicles.metadata.totalResults : 0"
      [nzPageSize]="limit"
      [nzPageIndex]="vehicles ? vehicles.metadata.currentPage : 0"
      [nzNoResult]="emptyTemplate"
      (nzQueryParams)="setPage($event)"
      *ngIf="!error; else errorTemplate">
      <thead>
        <tr>
          <th nzWidth="60px" *ngIf="userCanEdit || userCanDelete"></th>
          <th nzColumnKey="active" nzWidth="60px" [nzSortFn]="true">Actif</th>
          <th nzColumnKey="brand" [nzSortFn]="true">Marque</th>
          <th nzColumnKey="model" [nzSortFn]="true">Modèle</th>
          <th nzColumnKey="immat">Catégorie</th>
          <th nzColumnKey="categorie">Immatriculation</th>
          <th nzColumnKey="customer" [nzSortFn]="true" *ngIf="userCanReadCustomer">Client</th>
          <th nzColumnKey="customerSite" [nzSortFn]="true" *ngIf="userCanReadCustomerSites">Site Client</th>
          <th nzColumnKey="actions" class="actions">Actions</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let vehicle of vehicles?.data">
          <tr>
            <td [nzChecked]="checked.includes(vehicle)" (nzCheckedChange)="setChecked($event, vehicle)" *ngIf="userCanEdit || userCanDelete"></td>
            <td><label nz-checkbox nzDisabled [(ngModel)]="vehicle.active"></label></td>
            <td>{{ vehicle.model.brand.name }}</td>
            <td>{{ vehicle.model.name }}</td>
            <td>
              <button nz-button>
                <i nz-icon [nzType]="vehicle.model.category | vehicleCategoryIcon"></i>
                {{ vehicle.model.category | vehicleCategoryName }}
              </button>
            </td>
            <td>
              <laveo-license-plate [licensePlate]="vehicle.licensePlate" [country]="vehicle.customerSite.country"></laveo-license-plate>
            </td>
            <td *ngIf="userCanReadCustomer"><button nz-button (click)="goToCustomer(vehicle)">{{ vehicle.customerSite.customer.name }}<i nz-icon nzType="right-square" nzTheme="outline"></i></button></td>
            <td *ngIf="userCanReadCustomerSites"><button nz-button (click)="goToCustomerSite(vehicle)">{{ vehicle.customerSite.name }}<i nz-icon nzType="right-square" nzTheme="outline"></i></button></td>
            <td class="actions">
              <button nz-button class="mr-8" [routerLink]="['/', 'vehicles', vehicle.id]">
                <i nz-icon nzType="eye"></i>
                Détails
              </button>
              <button nz-button class="mr-8" (click)="edit(vehicle)" *ngIf="userCanEdit">Modifier</button>
              <button nz-button nzType="primary" (click)="delete(vehicle)" *ngIf="userCanDelete">
                <i nz-icon nzType="delete"></i>
              </button>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>
    <ng-template #errorTemplate>
      <nz-result
        nzTitle="Erreur de chargement"
        nzStatus="error"
        nzSubTitle="Une erreur est survenue lors du chargement de la liste des véhicules">
        <div nz-result-extra>
          <button nz-button nzType="primary" (click)="loadData()">
            Réessayer
            <i nz-icon nzType="reload"></i>
          </button>
        </div>
      </nz-result>
    </ng-template>
    <ng-template #emptyTemplate>
      <nz-empty
        nzNotFoundImage="simple"
        [nzNotFoundContent]="this.userCanAdd ? 'Aucun véhicule trouvé. Commencez par en ajouter un en utilisant le bouton ci-dessous.' : 'Aucun véhicule trouvé'"
        [nzNotFoundFooter]="emptyFooter">
        <ng-template #emptyFooter>
          <button nz-button nzType="primary" (click)="add()" *ngIf="this.userCanAdd">
            <i nz-icon nzType="plus"></i>
            Ajouter un véhicule
          </button>
        </ng-template>
      </nz-empty>
    </ng-template>
  </div>
</nz-card>

<ng-template #updateForm let-params>
  <div [innerHtml]="params.content" class="mb-8"></div>
  <div nz-row>
    <laveo-admin-vehicle-modal-update [vehicles]="params.vehicles" [actionEmitter]="params.emitter" (updating)="updateModalUpdating($event)"></laveo-admin-vehicle-modal-update>
  </div>
</ng-template>
