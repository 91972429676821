import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ServicesFiltersRequest } from 'src/app/shared/models/api/services/services-filters-requests.interface';
import { PagedRequest } from 'src/app/shared/models/api/shared/paged/paged-request';
import { UserRight } from 'src/app/shared/models/entities/user-right';
import { UserService } from 'src/app/shared/services/api/user.service';
import { Service, ServiceStatus, ServiceType } from '../../../shared/models/entities/service';
import { environment } from '../../../../environments/environment';
import { UserRoleType } from '../../../shared/models/entities/user-role';
import { ListServicesComponent } from 'src/app/shared/views/list-services/list-services.component';
import { addMonths, startOfToday } from 'date-fns';
import { Title } from '@angular/platform-browser';
import { isSameDay } from 'date-fns';

@Component({
  selector: 'laveo-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit, OnDestroy {
  @ViewChild('listServices') private listServices: ListServicesComponent;

  checked: Service[] = [];
  filters: PagedRequest<ServicesFiltersRequest>;
  queryStringFilters = new BehaviorSubject<PagedRequest<ServicesFiltersRequest> | null>(null);
  userCanAdd = false;
  shouldAutofocusSearch = false;

  bilanRseUrl: string | null = null;

  private subscriptions: Subscription[] = [];

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly userService: UserService,
    private readonly titleService: Title
  ) {}

  ngOnInit(): void {
    this.setTitle();
    this.loadRole();
    this.setSearchIfExist();
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  setFilters(filters: PagedRequest<ServicesFiltersRequest>): void {
    const queryParameters = {
      s: filters.search ?? null,
      status: (filters.filters?.status?.length ?? 0) > 0 ? filters.filters?.status?.join(',') : null,
      types: (filters.filters?.types?.length ?? 0) > 0 ? filters.filters?.types?.join(',') : null,
      customers: (filters.filters?.customers?.length ?? 0) > 0 ? filters.filters?.customers?.join(',') : null,
      customerSites: (filters.filters?.customerSites?.length ?? 0) > 0 ? filters.filters?.customerSites?.join(',') : null,
      structures: (filters.filters?.structures?.length ?? 0) > 0 ? filters.filters?.structures?.join(',') : null,
      preparers: (filters.filters?.preparers?.length ?? 0) > 0 ? filters.filters?.preparers?.join(',') : null,
      minDate: filters.filters?.minDate ? +filters.filters.minDate : null,
      maxDate: filters.filters?.maxDate ? +filters.filters.maxDate : null,
      page: filters.page
    };

    if (filters.filters) {
      filters.filters.minDate = filters.filters.minDate ?? startOfToday();
      filters.filters.maxDate = filters.filters.maxDate ?? addMonths(startOfToday(), 6);
    }

    // Reset page to one if filters changed
    if (
      this.filters !== undefined &&
      (
        this.filters?.search !== filters.search ||
        (this.filters?.filters?.status ?? []).length !== (filters.filters?.status ?? []).length ||
        (this.filters?.filters?.types ?? []).length !== (filters.filters?.types ?? []).length ||
        (this.filters?.filters?.customers ?? []).length !== (filters.filters?.customers ?? []).length ||
        (this.filters?.filters?.customerSites ?? []).length !== (filters.filters?.customerSites ?? []).length ||
        (this.filters?.filters?.structures ?? []).length !== (filters.filters?.structures ?? []).length ||
        (this.filters?.filters?.preparers ?? []).length !== (filters.filters?.preparers ?? []).length ||
        (this.filters.filters?.minDate && filters.filters?.minDate ? !isSameDay(this.filters?.filters?.minDate, filters.filters?.minDate) : false) ||
        (this.filters.filters?.maxDate && filters.filters?.maxDate ? !isSameDay(this.filters?.filters?.maxDate, filters.filters?.maxDate) : false)
      )
    ) {
      queryParameters.page = 1;
      filters.page = 1;
    }

    this.filters = filters;

    for (const [key, value] of Object.entries(queryParameters)) {
      if (value === null) {
        delete queryParameters[key];
      }
    }

    void this.router.navigate([], { queryParams: queryParameters });
  }

  setPage(page: number) {
    void this.router.navigate([], {queryParams: { page }, queryParamsHandling: 'merge' });
  }

  addService(): void {
    void this.router.navigate(['/', 'prestations', 'new']);
  }

  reloadData(): void {
    this.listServices?.loadData();
  }

  private setSearchIfExist(): void {
    const querySubscription = this.route.queryParamMap.subscribe(parameters => {
      const search = parameters.get('s');
      const focus = parameters.get('f');
      const status = parameters.get('status');
      const types = parameters.get('types');
      const customers = parameters.get('customers');
      const customerSites = parameters.get('customerSites');
      const structures = parameters.get('structures');
      const preparers = parameters.get('preparers');
      const minDate = parameters.get('minDate');
      const maxDate = parameters.get('maxDate');
      const page = parameters.get('page');

      if (focus) {
        this.shouldAutofocusSearch = true;
        void this.router.navigate([], {queryParams: { f: null }, queryParamsHandling: 'merge' });
      }

      const newFilters = new PagedRequest<ServicesFiltersRequest>({
        page: page ? +page : 1,
        limit: 10,
        search: search ?? undefined,
        filters: {
          status: status ? (status.split(',') as ServiceStatus[]) : [],
          types: types ? (types.split(',') as ServiceType[]) : [],
          customers: customers ? (customers.split(',')) : [],
          customerSites: customerSites ? (customerSites.split(',')) : [],
          structures: structures ? (structures.split(',')) : [],
          preparers: preparers ? (preparers.split(',')) : [],
          minDate: minDate ? new Date(+minDate) : undefined,
          maxDate: maxDate ? new Date(+maxDate) : undefined,
        },
        sortProperty: 'date',
        sortType: 'ASC'
      });
      this.queryStringFilters.next(newFilters);
    });
    this.subscriptions.push(querySubscription);
  }

  private loadRole(): void {
    const roleSubscription = this.userService.currentRole.subscribe(role => {
      this.userCanAdd = role.rights.services.includes(UserRight.create);

      if (role.type === UserRoleType.customerSite || role.type === UserRoleType.customerSiteRead) {
        this.bilanRseUrl = environment.api.url + '/bilan_rse/' + role.actor.id + '.pdf';
      }
    });

    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }
  }

  private setTitle() {
    this.titleService.setTitle('Lavéo - Prestations');
  }
}
