import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/shared/models/entities/user';
import { UserRoleType } from 'src/app/shared/models/entities/user-role';

@Component({
  selector: 'laveo-account-detail',
  templateUrl: './account-detail.component.html',
  styleUrls: ['./account-detail.component.scss']
})
export class AccountDetailComponent implements OnInit {
  @Input() user?: User;
  userIsAdmin = false;

  ngOnInit(): void {
    const roleAdmin = this.user?.roles.find(r => r.type === UserRoleType.admin);
    if (roleAdmin) {
      this.userIsAdmin = true;
    }
  }

}
