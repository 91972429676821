<nz-form-item>
  <nz-form-label *ngIf="showLabel" nzFor="category">Rôle</nz-form-label>
  <nz-form-control [nzErrorTip]="error">
    <nz-select [formControl]="formControl" nzMode="default" nzPlaceHolder="Rôle" nzAllowClear [nzSize]="typeSize" [nzDisabled]="disabled" class="w-100-p">
      <nz-option *ngFor="let value of values" [nzValue]="value" [nzLabel]="value | roleName" [nzDisabled]="value === 'SUPER_ADMIN'" [nzHide]="value === 'SUPER_ADMIN'"></nz-option>
    </nz-select>
  </nz-form-control>
  <ng-template #error let-control>
    <ng-container *ngIf="control?.hasError('required')">
      Le rôle est requis
    </ng-container>
  </ng-template>
</nz-form-item>
