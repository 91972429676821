import { Type } from 'typeserializer';
import { Entity } from './entity';

export class Actor extends Entity {
  name: string;
  active: boolean;
}

export class ActorArray {
  @Type([Actor])
  children: Actor[];
}
