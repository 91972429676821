<nz-card>
    <div class="content">
      <nz-list nzItemLayout="vertical" [nzLoading]="isLoading" *ngIf="!error; else errorTemplate">
        <ng-container *ngFor="let groupedPrestation of prestations?.data">
          <nz-list-item>
            <div class="prestations-header">
              {{ groupedPrestation.title }} ({{ groupedPrestation.prestations.length }})
            </div>
          </nz-list-item>
          <nz-list-item *ngFor="let prestation of groupedPrestation.prestations">
            <laveo-row-service [service]="prestation" [canEdit]="userCanEdit" [showClient]="!this.isCustomerSite" [showStructure]="!this.isPreparer" [checked]="checked"></laveo-row-service>
          </nz-list-item>
        </ng-container>
        <nz-list-empty [nzNoResult]="emptyTemplate" *ngIf="(prestations?.data?.length ? prestations.data.length : 0) === 0"></nz-list-empty>
      </nz-list>
    </div>
    <div class="pagination" *ngIf="(prestations?.data?.length ? prestations.data.length : 0) > 0">
      <nz-divider class="p-0 m-0"></nz-divider>
      <div nz-row nzAlign="middle" nzJustify="end" class="p-16">
        <span nz-typography nzType="secondary">Prestations {{ (prestations.metadata.currentPage * prestations.metadata.currentResult) + 1 - prestations.metadata.currentResult }} à {{ prestations.metadata.currentPage * prestations.metadata.currentResult }} sur {{ prestations.metadata.totalResults }}</span>
        <nz-pagination
          class="ml-16"
          *ngIf="prestations.metadata.totalPages > 1"
          [nzPageIndex]="filters.page"
          [nzPageSize]="pageSize"
          [nzTotal]="prestations.metadata.totalResults"
          (nzPageIndexChange)="setPage($event)">
        </nz-pagination>
      </div>
    </div>
</nz-card>
<ng-template #emptyTemplate>
  <nz-empty
    nzNotFoundImage="simple"
    [nzNotFoundContent]="userCanAdd ? 'Aucune prestation trouvée. Essayez une autre date ou ajoutez une nouvelle prestation.' : 'Aucune prestation trouvée. Essayez une autre date.'"
    [nzNotFoundFooter]="emptyFooter">
    <ng-template #emptyFooter>
      <button nz-button nzType="primary" [routerLink]="['/', 'prestations', 'new']" *ngIf="userCanAdd">
        <i nz-icon nzType="plus"></i>
        Ajouter une prestation
      </button>
    </ng-template>
  </nz-empty>
</ng-template>
<ng-template #errorTemplate>
  <nz-result nzTitle="Erreur de chargement" nzStatus="error"
    nzSubTitle="Une erreur est survenue lors du chargement de la liste des prestation">
    <div nz-result-extra>
      <button nz-button nzType="primary" (click)="loadData()">
        Réessayer
        <i nz-icon nzType="reload"></i>
      </button>
    </div>
  </nz-result>
</ng-template>
