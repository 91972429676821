<form nz-form [formGroup]="form" nzLayout="vertical" (keydown.enter)="$event.preventDefault()">
  <div nz-row  [nzGutter]="[24, 0]">
    <div nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-label nzFor="name">Nom</nz-form-label>
        <nz-form-control>
          <input formControlName="name" nz-input placeholder="Nom" nzSize="large"/>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzXs]="24">
      <nz-form-item>
        <nz-form-label nzFor="mail">Mail</nz-form-label>
        <nz-form-control [nzErrorTip]="loginError" [nzWarningTip]="loginWarning" [nzValidateStatus]="usernameValidationStatus ? usernameValidationStatus : 'validating'">
          <input formControlName="mailValidation" nz-input placeholder="Mail" nzSize="large"/>
          <ng-template #loginError let-control>
            <ng-container *ngIf="control?.hasError('email')">
              Email invalide
            </ng-container>
          </ng-template>
          <ng-template #loginWarning>
            <ng-container *ngIf="emailErrors?.suggestion">
              <span>Vouliez-vous dire : </span>
              <span (click)="this.form.get('mailValidation')?.setValue(emailErrors?.suggestion.full)" [style.textDecoration]="'underline'" [style.cursor]="'pointer'">
                {{ emailErrors?.suggestion.domain }}
              </span>
              <span> ?</span>
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <div class="validation-errors" *ngIf="this.mailChanged">
        <p>
          Attention, si vous modifiez votre email, vous devrez le valider avant de pouvoir vous connecter avec celui-ci.<br>
          Votre ancien mail restera actif tant que le nouveau n'aura pas était validé.
        </p>
      </div>
    </div>

    <div nz-col [nzXs]="24" [nzSm]="12">
      <nz-form-item>
        <nz-form-label nzFor="password" nzRequired>Nouveau mot de passe</nz-form-label>
        <nz-form-control [nzErrorTip]="passwordError">
          <nz-input-group [nzSuffix]="suffixTemplate" nzSize="large">
            <input
              nz-input
              id="password"
              [type]="passwordVisible ? 'text' : 'password'"
              placeholder="••••••••••"
              passwordrules="required: lower; required: upper; required: digit; required: special; minlength: 8;"
              nzSize="large"
              formControlName="plainPassword" />
            <ng-template #suffixTemplate>
              <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="passwordVisible = !passwordVisible"></i>
            </ng-template>
          </nz-input-group>
          <ng-template #passwordError let-control>
            <span *ngIf="control.hasError('required')">Le nouveau mot de passe est requis</span>
            <span *ngIf="control.hasError('minlength')">Le nouveau mot de passe doit contenir au minimum 8 caractères</span>
            <div *ngIf="!control.hasError('required')" class="validation-errors">
              <i nz-icon [nzType]="control.hasError('digitCharacterRule') ? 'warning' : 'check'"></i><span> Chiffre</span>
              <i nz-icon [nzType]="control.hasError('lowercaseCharacterRule') ? 'warning' : 'check'"></i><span> Minuscule</span>
              <i nz-icon [nzType]="control.hasError('uppercaseCharacterRule') ? 'warning' : 'check'"></i><span> Majuscule</span>
              <i nz-icon [nzType]="control.hasError('specialCharacterRule') ? 'warning' : 'check'"></i><span> Caractère Spécial</span>
            </div>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzXs]="24" [nzSm]="12">
      <nz-form-item>
        <nz-form-label nzFor="confirmPassword" nzRequired>Confirmez le mot de passe</nz-form-label>
        <nz-form-control [nzErrorTip]="confirmPasswordError">
          <nz-input-group [nzSuffix]="confirmSuffixTemplate" nzSize="large">
            <input
              nz-input
              id="confirmPassword"
              [type]="confirmPasswordVisible ? 'text' : 'password'"
              placeholder="••••••••••"
              nzSize="large"
              formControlName="confirmPassword"/>
            <ng-template #confirmSuffixTemplate>
              <i nz-icon [nzType]="confirmPasswordVisible ? 'eye-invisible' : 'eye'" (click)="confirmPasswordVisible = !confirmPasswordVisible"></i>
            </ng-template>
          </nz-input-group>
          <ng-template #confirmPasswordError let-control>
            <span *ngIf="form.hasError('mismatchedPasswords')">Les mots de passe doivent être identique</span>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div nz-col [nzXs]="24">
    <laveo-inputs-roles
      *ngIf="isAdmin"
      [controlArray]="rolesForm">
    </laveo-inputs-roles>
  </div>
</form>
