import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { RoutesConfig } from './configs/routes.config';
import { AdminGuard } from './shared/guards/admin.guard';

@NgModule({
  imports: [
    RouterModule.forRoot(RoutesConfig),
  ],
  exports: [RouterModule],
  providers: [
    AuthGuard,
    AdminGuard,
  ],
})
export class AppRoutingModule {}
