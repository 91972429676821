import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumber } from 'awesome-phonenumber';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
  transform(value?: string): string | undefined {
    if (!value) {
      return;
    }

    try {
      const phoneNumber = parsePhoneNumber(value, { regionCode: 'FR' });
      if (phoneNumber.possible) {
        return phoneNumber.number?.national;
      }
    } catch {
      // Do nothing
    }

    return value;
  }
}
