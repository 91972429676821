import { Customer } from '../../entities/customer';
import { CustomerSite } from '../../entities/customer-site';
import { Structure } from '../../entities/structure';
import { Type } from 'typeserializer';

export class ReportingRse {
  @Type(Customer)
  customer?: Customer;
  @Type(CustomerSite)
  customerSite?: CustomerSite;
  @Type(Structure)
  structure?: Structure;
  actor: Customer | CustomerSite | Structure;
  nbServices: number;
  waterSaving: number;
  waterConsumptionPerHousehold: number;
  insertion: number;
  etp: number;
  co2EmissionsSavings: number;
  internationalSolidarityComponent: number;
}
