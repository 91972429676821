import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { MainSharedModule } from '../shared/main-shared.module';
import { AccountDetailComponent } from './account-detail/account-detail.component';
import { AccountFormComponent } from './account-form/account-form.component';
import { AccountComponent } from './account.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { ReactiveFormsModule } from '@angular/forms';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { InputsRolesModule } from 'src/app/shared/views/inputs/inputs-roles/inputs-roles.module';

@NgModule({
  declarations: [
    AccountComponent,
    AccountDetailComponent,
    AccountFormComponent
  ],
  imports: [
    CommonModule,
    MainSharedModule,
    SharedModule,
    ReactiveFormsModule,
    NzLayoutModule,
    NzFormModule,
    NzInputModule,
    NzButtonModule,
    NzRadioModule,
    NzToolTipModule,
    InputsRolesModule
  ]
})
export class AccountModule {}
