import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo, gql, MutationResult } from 'apollo-angular';
import { PagedRequest } from '../../models/api/shared/paged/paged-request';
import { PagedResponse } from '../../models/api/shared/paged/paged-response.interface';
import { map } from 'rxjs/operators';
import { VehicleRequest } from '../../models/api/services/vehicle-requests.interface';
import { Vehicle, VehicleArray } from 'src/app/shared/models/entities/vehicle';
import { TypeSerializerUtils } from '../../utils/type-serializer.util';
import { VehiclesFiltersRequest } from '../../models/api/services/vehicles-filters-requests.interface';
import { BatchProcessVehicle } from '../../models/entities/batch-process-vehicle';

@Injectable({
  providedIn: 'root'
})
export class VehiclesService {
  constructor(
    private readonly apollo: Apollo
  ) {}

  addVehicle(data: VehicleRequest): Observable<MutationResult<Vehicle>> {
    const query = gql`
      mutation addVehicle($data: VehicleRequest) {
        vehicles {
          add (data: $data) {
            id
            active
            licensePlate
            frequency
            attribution
            collaborator
            comment
            group
            model {
              id
              name
              category
              brand {
                id
                name
                logo
              }
            }
            customerSite {
              id
              name
              country
              customer {
                id
                name
              }
            }
          }
        }
      }
    `;

    return this.apollo.mutate<{ vehicles: { add: Vehicle } }>({
      mutation: query,
      variables: {
        data: {
          licensePlate: data.licensePlate,
          modelId: data.model?.id,
          customerSiteId: data.customerSite?.id,
          frequency: data.frequency,
          active: data.active,
          attribution: data.attribution,
          collaborator: data.collaborator,
          comment: data.comment,
          group: data.group
        }
      }
    }).pipe(map(result => ({

      data: TypeSerializerUtils.deserialize(result.data?.vehicles?.add, Vehicle),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }

  updateVehicle(data: VehicleRequest): Observable<MutationResult<Vehicle>> {
    const query = gql`
      mutation updateVehicle($id: Guid!, $data: VehicleRequest) {
        vehicles {
          update (id: $id, data: $data) {
            id
            active
            licensePlate
            frequency
            attribution
            collaborator
            comment
            group
            model {
              id
              name
              category
              brand {
                id
                name
                logo
              }
            }
            customerSite {
              id
              name
              country
              customer {
                id
                name
              }
            }
          }
        }
      }
    `;

    return this.apollo.mutate<{ vehicles: { update: Vehicle } }>({
      mutation: query,
      variables: {
        id: data.id,
        data: {
          licensePlate: data.licensePlate,
          modelId: data.model?.id,
          customerSiteId: data.customerSite?.id,
          frequency: data.frequency,
          active: data.active,
          attribution: data.attribution,
          collaborator: data.collaborator,
          comment: data.comment,
          group: data.group
        }
      }
    }).pipe(map(result => ({

      data: TypeSerializerUtils.deserialize(result.data?.vehicles?.update, Vehicle),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }

  updateVehicles(ids: string[], data: Partial<VehicleRequest>): Observable<MutationResult<Vehicle[]>> {
    const query = `
      mutation updateVehicles(` + ids.map((_, index) => '$id' + index + ': Guid!').join(', ') + `, $data: VehicleRequest) {
        vehicles {\n` + ids.map((_, index) => 'id' + index + ': update (id: $id' + index + ', data: $data) { id }').join('\n') + `\n}
      }
    `;

    const variables: any = ids.reduce((response, item, index) => {
      response['id' + index] = item;
      return response;
    }, {});

    variables.data = data;

    return this.apollo.mutate<Vehicle[]>({
      mutation: gql(query),
      variables
    });
  }

  deleteVehicle(...ids: string[]): Observable<MutationResult<void>> {
    const query = `
      mutation deleteVehicle(` + ids.map((_, index) => '$id' + index + ': Guid!').join(', ') + `) {
        vehicles {\n` + ids.map((_, index) => 'id' + index + ': delete (id: $id' + index + ')').join('\n') + `\n}
      }
    `;

    const variables = ids.reduce((response, item, index) => {
      response['id' + index] = item;
      return response;
    }, {});

    return this.apollo.mutate<void>({
      mutation: gql(query),
      variables
    });
  }

  deleteOneVehicleHard(id: string): Observable<MutationResult<void>> {
    const mutation = gql`
      mutation deleteVehicle($id: Guid!, $hard: Boolean) {
        vehicles {
          delete (id: $id, hard: $hard)
        }
      }
    `;

    const variables = {
      id,
      hard: true
    };

    return this.apollo.mutate<void>({
      mutation,
      variables
    });
  }

  vehicle(id: string): Observable<ApolloQueryResult<Vehicle>> {
    const query = gql`
      query vehicle($id: Guid!) {
        vehicles {
          vehicle(id: $id){
            id
            active
            licensePlate
            frequency
            attribution
            collaborator
            comment
            group
            model {
              id
              name
              category
              brand {
                id
                name
                logo
              }
            }
            customerSite {
              id
              name
              country
              workflowType
              customer {
                id
                name
              }
            }
          }
        }
      }
    `;

    return this.apollo.query<{ vehicles: { vehicle: Vehicle } }>({
      query,
      variables: { id }
    }).pipe(map(result => ({
      data: TypeSerializerUtils.deserialize(result.data?.vehicles?.vehicle, Vehicle),
      error: result.error,
      errors: result.errors,
      partial: result.partial,
      loading: result.loading,
      networkStatus: result.networkStatus
    })));
  }

  allVehicles(parameters: PagedRequest<VehiclesFiltersRequest>): Observable<ApolloQueryResult<PagedResponse<Vehicle>>> {
    const query = gql`
      query vehicles($offset: Int, $limit: Int, $filters: VehiclesFiltersRequest, $sortProperty: String, $sortType: ESortType, $search: String) {
        vehicles {
          vehicles(offset: $offset, limit: $limit, filters: $filters sortProperty: $sortProperty, sortType: $sortType, search: $search) {
            data {
              id
              active
              licensePlate
              frequency
              attribution
              collaborator
              comment
              group
              model {
                id
                name
                category
                brand {
                  id
                  name
                  logo
                }
              }
              customerSite {
                id
                name
                country
                customer {
                  id
                  name
                }
                workflowType
                type
                finishing
                firstIntervention
                vehicleVeryDirty
                upholsteryStainRemoval
                carpetStainRemoval
                conveying
                sticking
                desticking
                contactZoneDisinfection
                interiorDisinfection
                frequencyServiceAffectedVehicle
                frequencyServiceMutualizedVehicle
              }
            }
            metadata {
              currentPage
              currentResult
              totalPages
              totalResults
              hasMore
            }
          }
        }
      }
    `;
    return this.apollo.query<{ vehicles: { vehicles: PagedResponse<Vehicle> } }>({
      query,
      variables: {
        offset: parameters.offset,
        limit: parameters.limit,
        sortProperty: parameters.sortProperty,
        sortType: parameters.sortType,
        search: parameters.search,
        filters: parameters.filters
      }
    }).pipe(map(result => {
      const raw = result.data?.vehicles?.vehicles;
      const response: PagedResponse<Vehicle> = {
        data: TypeSerializerUtils.deserializeArr(raw.data, VehicleArray),
        metadata: raw.metadata
      };
      return {
        data: response,
        error: result.error,
        errors: result.errors,
        partial: result.partial,
        loading: result.loading,
        networkStatus: result.networkStatus
      };
    }));
  }

  importVehicles(customerSiteId: string, file: File): Observable<MutationResult<BatchProcessVehicle>> {
    const mutation = gql`
      mutation importToCustomerSiteFromExcel($customerSiteId: Guid!, $file: Upload!) {
        vehicles {
          importToCustomerSiteFromExcel(customerSiteId: $customerSiteId, file: $file) {
            processOk {
              id
            }
            processFailed {
              message
              object {
                id
                licensePlate
              }
            }
          }
        }
      }
    `;

    return this.apollo.mutate <{ vehicles: { importToCustomerSiteFromExcel: BatchProcessVehicle } }>({
      mutation,
      variables: {
        customerSiteId,
        file
      },
      context: {
        useMultipart: true
      }
    }).pipe(map(result => ({

      data: TypeSerializerUtils.deserialize(result.data?.vehicles?.importToCustomerSiteFromExcel, BatchProcessVehicle),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }
}
