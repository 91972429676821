import { Pipe, PipeTransform } from '@angular/core';
import { ServiceStatus } from '../models/entities/service';

@Pipe({
  name: 'serviceStatusName'
})
export class ServiceStatusNamePipe implements PipeTransform {
  transform(status?: ServiceStatus, short = false): string | undefined {
    if (!status) {
      return;
    }

    switch (status) {
      case ServiceStatus.created: {
        return 'Créé';
      }
      case ServiceStatus.asked: {
        return 'Demandé';
      }
      case ServiceStatus.proposed: {
        return 'Proposé';
      }
      case ServiceStatus.planned: {
        return 'Planifié';
      }
      case ServiceStatus.performed: {
        return 'Réalisé';
      }
      case ServiceStatus.vehicleNotPresent: {
        return short ? 'Non présent' : 'Non présenté';
      }
      case ServiceStatus.canceled_by_customer_site: {
        return short ? 'Annulé' : 'Annulé par le client';
      }
      case ServiceStatus.canceled_by_structure: {
        return short ? 'Annulé' : 'Annulé par la structure';
      }
      default: {
        return;
      }
    }
  }
}
