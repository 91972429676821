import { Component, EventEmitter, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NzTableQueryParams, NzTableSortOrder } from 'ng-zorro-antd/table';
import { of, Subscription, throwError } from 'rxjs';
import { debounceTime, delay, switchMap } from 'rxjs/operators';
import { PagedRequest } from 'src/app/shared/models/api/shared/paged/paged-request';
import { PagedResponse } from 'src/app/shared/models/api/shared/paged/paged-response.interface';
import { Vehicle } from 'src/app/shared/models/entities/vehicle';
import { VehiclesService } from 'src/app/shared/services/api/vehicles.service';
import { ModalButtonOptions, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UserService } from 'src/app/shared/services/api/user.service';
import { UserRight } from 'src/app/shared/models/entities/user-right';
import { VehiclesFiltersRequest } from 'src/app/shared/models/api/services/vehicles-filters-requests.interface';
import { Customer } from 'src/app/shared/models/entities/customer';
import { CustomerSite } from 'src/app/shared/models/entities/customer-site';
import { VehicleBrand } from 'src/app/shared/models/entities/vehicle-brand';
import { VehicleModel } from 'src/app/shared/models/entities/vehicle-model';
import { NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { UserRoleType } from 'src/app/shared/models/entities/user-role';
import { BatchProcessFailed } from 'src/app/shared/models/entities/batch-process-vehicle';
import { ApolloError } from '@apollo/client/core';
import { environment } from '../../../../../environments/environment';
import { Title } from '@angular/platform-browser';
import { ApiErrorMessageUtil } from '../../../../shared/utils/api-error-message.util';

@Component({
  selector: 'laveo-admin-vehicles',
  templateUrl: './admin-vehicles.component.html',
  styleUrls: ['./admin-vehicles.component.scss']
})
export class AdminVehiclesComponent implements OnInit, OnDestroy {
  @ViewChild('updateForm') private updateFormContent: TemplateRef<any>;

  isLoading = true;
  actionLoading = false;
  vehicles?: PagedResponse<Vehicle>;
  currentPage = 1;
  limit = 10;

  form: UntypedFormGroup;
  checked: Vehicle[] = [];
  error?: Error;

  filtersAreVisible = false;
  filterClientTypeEntity: (typeof Customer | typeof CustomerSite)[] = [];

  userCanAdd = false;
  userCanEdit = false;
  userCanDelete = false;

  userCanReadCustomer = false;
  userCanReadCustomerSites = false;

  userIsCustomerSite = false;

  importFileList: NzUploadFile[] = [];
  importErrorModalVisible = false;
  importErrors?: BatchProcessFailed[];

  excelPath = environment.api.url + '/excel/laveo-importation-vehicules.xlsx';

  private updateModal?: NzModalRef;
  private updateButtonsLoading = false;

  private currentRoleId: string;
  private sort: { key: string; value: NzTableSortOrder }[] = [{ key: 'customer', value: 'ascend' }];
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly vehiclesServices: VehiclesService,
    private readonly userService: UserService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly message: NzMessageService,
    private readonly titleService: Title,
    private modal: NzModalService
  ) {}

  get description(): string {
    const desc = 'Gérez ici vos véhicules. ';
    const numberOfVehicles = this.vehicles?.metadata?.totalResults ?? 0;

    if (numberOfVehicles > 1) {
      return desc + 'Il y a ' + numberOfVehicles + ' véhicules.';
    }

    if (numberOfVehicles > 0) {
      return desc + 'Il y a ' + numberOfVehicles + ' véhicule.';
    }

    return 'Aucun véhicule trouvé. Commencez par ajouter des véhicules en utilisant le bouton "Ajouter un véhicule".';
  }

  ngOnInit(): void {
    this.setTitle();
    this.loadRole();
    this.setForm();
    this.setSearchIfExist();
    this.loadData();
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  loadData(): void {
    let sortProperty: string | undefined;
    let sortType: 'ASC' | 'DESC' | undefined;

    const currentSort =  this.sort.find(s => s.value);
    if (currentSort) {
      sortProperty = currentSort.key;
      sortType = currentSort.value === 'ascend' ? 'ASC' : 'DESC';
    }

    const search: string = this.form.get('search')?.value;

    const parameters = new PagedRequest<VehiclesFiltersRequest>({
      page: this.currentPage,
      limit: this.limit,
      sortProperty,
      sortType,
      filters: {
        active: this.form.get('active')?.value,
        categories: this.form.get('categories')?.value,
        customers: this.form.get('customers')?.value
          .filter((c: Customer | CustomerSite) => c.__typename === 'Customer')
          .map((c: Customer) => c.id),
        customerSites: this.form.get('customers')?.value
          .filter((c: Customer | CustomerSite) => c.__typename === 'CustomerSite')
          .map((c: CustomerSite) => c.id),
        brands: this.form.get('brands')?.value
          .filter((b: VehicleBrand | VehicleModel) => b.__typename === 'VehicleBrand')
          .map((b: VehicleBrand) => b.id),
        models: this.form.get('brands')?.value
          .filter((m: VehicleBrand | VehicleModel) => m.__typename === 'VehicleModel')
          .map((m: VehicleModel) => m.id),
      },
      search: search?.toLowerCase()?.trim()
    });

    this.isLoading = true;
    this.error = undefined;

    const usersSubscription = this.vehiclesServices.allVehicles(parameters).subscribe({
      next: data => {
        this.vehicles = data.data;
        this.isLoading = data.loading;
      },
      error: error => {
        this.isLoading = false;
        console.error(error);
        this.error = error;
      }
    });

    this.subscriptions.push(usersSubscription);
  }

  setPage(event: NzTableQueryParams): void {
    const indexSame = !event.pageIndex || this.currentPage === event.pageIndex;
    const limitSame = this.limit === event.pageSize;
    let sortSame = true;
    for (const sortObject of event.sort) {
      const originalSort = this.sort.find(sortElement => sortElement.key === sortObject.key);
      if (originalSort?.value !== sortObject.value) {
        sortSame = false;
        break;
      }
    }

    if (indexSame && limitSame && sortSame) {
      return;
    }

    this.currentPage = event.pageIndex;
    this.limit = event.pageSize;
    this.sort = event.sort;
    this.loadData();
  }

  setChecked(check: boolean, vehicle: Vehicle): void {
    if (check && !this.checked.includes(vehicle)) {
      this.checked.push(vehicle);
    } else if (!check && this.checked.includes(vehicle)) {
      this.checked = this.checked.filter(c => c !== vehicle);
    }
  }

  // Routing

  goToAdmin(): void {
    void this.router.navigate(['/admin']);
  }

  goToCustomer(fromVehicle: Vehicle): void {
    void this.router.navigate(['/', 'admin', 'customers', fromVehicle.customerSite.customer.id]);
  }

  goToCustomerSite(fromVehicle: Vehicle): void {
    void this.router.navigate(['/', 'admin', 'customer-sites', fromVehicle.customerSite.id]);
  }

  // Actions

  toggleFilter(): void {
    this.filtersAreVisible = !this.filtersAreVisible;
  }

  add(): void {
    void this.router.navigate(['/', 'vehicles', 'new']);
  }

  edit(vehicle: Vehicle): void {
    void this.router.navigate(['/', 'vehicles', vehicle.id], { fragment: 'edit' });
  }

  delete(vehicle: Vehicle): void {
    this.modal.confirm({
      nzTitle: 'Désactivation',
      nzContent: `Êtes-vous sûr de vouloir désactiver le véhicule <b>${vehicle.licensePlate} (${vehicle?.model?.brand?.name} - ${vehicle?.model?.name})</b>?`,
      nzOkText: 'Désactiver',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzCancelText: 'Annuler',
      nzOnOk: () => {
        this.vehiclesServices.deleteVehicle(vehicle.id).subscribe({
          next: () => {
            this.loadData();
          },
          error: error => {
            console.error(error);
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
          }
        });
      }
    });
  }

  // Checked actions

  updateChecked(): void {
    const content = `Êtes-vous sûr de vouloir modifier <b>${this.checked.length > 1 ? `les ${this.checked.length} véhicules sélectionnés` : 'le véhicule sélectionné'}</b> ?`;
    const emitter = new EventEmitter<void>();

    const updateButtons: ModalButtonOptions[] = [
      {
        label: 'Annuler',
        onClick: () => this.updateModal?.destroy()
      },
      {
        label: 'Envoyer',
        type: 'primary',
        loading: () => this.updateButtonsLoading,
        onClick: () => emitter.emit()
      }
    ];

    this.updateModal = this.modal.create({
      nzTitle: 'Modification multiple de véhicules',
      nzContent: this.updateFormContent,
      nzData: {
        vehicles: this.checked,
        content,
        emitter
      },
      nzFooter: updateButtons
    });
  }

  deleteChecked(): void {
    this.modal.confirm({
      nzTitle: 'Désactivation multiple',
      nzContent: 'Êtes-vous sûr de vouloir désactiver les <b> ' + this.checked.length + ' véhicules sélectionnés</b>?',
      nzOkText: 'Désactiver',
      nzOkType: 'primary',
      nzCancelText: 'Annuler',
      nzOnOk: () => {
        this.actionLoading = true;
        this.vehiclesServices.deleteVehicle(...this.checked.map(vehicle => vehicle.id)).subscribe({
          next: () => {
            this.actionLoading = false;
            this.loadData();
            this.checked.splice(0, this.checked.length);
          },
          error: error => {
            console.error(error);
            this.actionLoading = false;
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
          }
        });
      }
    });
  }

  updateModalUpdating(event: { loading: boolean; error?: Error }): void {
    if (!this.updateModal) {
      return;
    }

    if (event.loading) {
      this.updateButtonsLoading = true;
      this.actionLoading = true;
    } else if (event.error) {
      this.updateButtonsLoading = false;
      this.actionLoading = false;
    } else {
      this.updateButtonsLoading = false;
      this.actionLoading = false;
      this.updateModal.destroy();
      this.loadData();
      this.checked.splice(0, this.checked.length);
    }
  }

  // Import

  import = (item: NzUploadXHRArgs): Subscription => item.postFile as File ? this.vehiclesServices.importVehicles(this.currentRoleId, item.postFile as File).subscribe({
    next: (response) => {
      if (response.data?.processOk?.length ?? 0 > 0) {
        this.message.success(`Import de ${response.data?.processOk?.length} véhicules réussi`);
      } else {
        this.message.warning(`Aucun véhicule importé. Vérifiez votre fichier`);
      }

      if (response.data?.processFailed?.length ?? 0 > 0) {
        this.importErrorModalVisible = true;
        this.importErrors = response.data?.processFailed;
      }

      if (item.onSuccess) {
        item.onSuccess(null, item.file, null);
        this.importFileList = [];
        this.loadData();
      }
    },
    error: (error: ApolloError) => {
      console.error(error);
      this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
      if (item.onError) {
        item.onError(error, item.file);
      }
    }
  }) : of('').pipe(
        delay(500),
        switchMap(() => throwError(() => new Error('Erreur lors de l\'envoi. Réessayer')))
      ).subscribe({
        error: (error) => {
          if (item.onError) {
            item.onError(error, item.file);
          }
        }
      });

  handleChange(fileList: NzUploadFile[]): void {
    if (fileList?.length > 0) {
      let newFileList: NzUploadFile[] = [...fileList];
      newFileList = fileList.slice(-1);
      this.importFileList = newFileList;
    }
  }

  closeImportErrorModal(): void {
    this.importErrorModalVisible = false;
  }

  // Private

  private setForm(): void {
    this.form = this.formBuilder.group({
      active: this.formBuilder.control(false),
      search: this.formBuilder.control(null),
      brands: this.formBuilder.control([]),
      categories: this.formBuilder.control([]),
      customers: this.formBuilder.control([]),
    });

    const searchSubscription = this.form.valueChanges.pipe(debounceTime(500)).subscribe(formValues => {
      this.currentPage = 1;
      let queryParameters: Params = { s: null };
      if (formValues.search && formValues.search !== '') {
        queryParameters = { s: formValues.search };
      }
      queryParameters.active = formValues.active ?? null;
      void this.router.navigate([], { queryParams: queryParameters, queryParamsHandling: 'merge' });
      this.loadData();
    });
    this.subscriptions.push(searchSubscription);
  }

  private setSearchIfExist(): void {
    const querySubscription = this.route.queryParamMap.subscribe(parameters => {
      const search = parameters.get('s');
      if (search) {
        this.form.get('search')?.setValue(search);
      }
      const active = parameters.get('active');
      if (active) {
        const activeBoolean = Boolean(JSON.parse(active));
        this.form.get('active')?.setValue(activeBoolean ?? null);
      } else {
        this.form.get('active')?.setValue(null);
      }
      this.loadData();
    });
    this.subscriptions.push(querySubscription);
  }

  private loadRole(): void {
    const roleSubscription = this.userService.currentRole.subscribe(role => {
      this.currentRoleId = role.actor.id;
      this.userIsCustomerSite = role.type === UserRoleType.customerSite;
      this.userCanAdd = role.rights.vehicles.includes(UserRight.create);
      this.userCanEdit = role.rights.vehicles.includes(UserRight.update);
      this.userCanDelete = role.rights.vehicles.includes(UserRight.delete);
      this.userCanReadCustomer = role.rights.customers.includes(UserRight.read);
      this.userCanReadCustomerSites = role.rights.customerSites.includes(UserRight.read);
      if (this.userCanReadCustomer) {
        this.filterClientTypeEntity.push(Customer);
      }
      if (this.userCanReadCustomerSites) {
        this.filterClientTypeEntity.push(CustomerSite);
      }
    });

    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }
  }

  private setTitle(): void {
    this.titleService.setTitle('Lavéo - Véhicules');
  }
}
