<form nz-form [formGroup]="form" nzLayout="vertical" (keydown.enter)="$event.preventDefault()">
  <div nz-row  [nzGutter]="[24, 0]">
    <div nz-col nzSpan="24">
      <div nz-row [nzGutter]="[24, 0]">
        <div nz-col nzFlex="0 0 auto" *ngIf="user">
          <nz-form-item>
            <nz-form-label nzFor="active">Statut</nz-form-label>
            <nz-form-control>
              <nz-radio-group formControlName="active" nzButtonStyle="solid" nzSize="large">
                <label nz-radio-button [nzValue]="true">Activé</label>
                <label nz-radio-button [nzValue]="false">Désactivé</label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzFlex="1">
          <nz-form-item>
            <nz-form-label nzFor="name">Nom</nz-form-label>
            <nz-form-control [nzErrorTip]="nameError">
              <input formControlName="name" nz-input placeholder="Nom" nzSize="large"/>
            </nz-form-control>
          </nz-form-item>
          <ng-template #nameError let-control>
            <ng-container *ngIf="control?.hasError('required')">
              Le nom de l'utilisateur est requis
            </ng-container>
          </ng-template>
        </div>
      </div>
    </div>

    <div nz-col [nzXs]="24">
      <nz-form-item>
        <nz-form-label nzFor="mailValidation">Mail</nz-form-label>
        <nz-form-control [nzErrorTip]="loginError" [nzWarningTip]="loginWarning" [nzValidateStatus]="usernameValidationStatus ? usernameValidationStatus : 'validating'">
          <input formControlName="mailValidation" nz-input placeholder="Mail" nzSize="large"/>
          <ng-template #loginError let-control>
            <ng-container *ngIf="control?.hasError('required')">
              L'email de l'utilisateur est requis
            </ng-container>
            <ng-container *ngIf="control?.hasError('email')">
              L'email est invalide
            </ng-container>
          </ng-template>
          <ng-template #loginWarning>
            <ng-container *ngIf="emailErrors?.suggestion">
              <span>Vouliez-vous dire : </span>
              <span (click)="this.form.get('mailValidation')?.setValue(emailErrors?.suggestion.full)" [style.textDecoration]="'underline'" [style.cursor]="'pointer'">
                {{ emailErrors?.suggestion.domain }}
              </span>
              <span> ?</span>
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzXs]="24">
      <laveo-inputs-roles
        *ngIf="!isAdmin"
        [controlArray]="rolesFormArray">
      </laveo-inputs-roles>
    </div>
  </div>
</form>
