<div nz-row [nzGutter]="[48, 24]" *ngIf="!isInModal">
  <div nz-col nzSpan="24">
    <h5 class="service-title"><i nz-icon nzType="check-square" nzTheme="outline"></i>&nbsp;Actions</h5>
  </div>
</div>

<div>
  <div nz-row [nzGutter]="[48, 0]" *ngIf="(this.availableActions?.length ? this.availableActions.length : 0) > 0; else noAction">
    <div nz-col nzSpan="24">
      <form nz-form [formGroup]="form" nzLayout="vertical">
        <div nz-row [nzGutter]="[48, 0]" *ngIf="showSendToCustomerSite || showPerform">
          <div nz-col *ngIf="showSendToCustomerSite">
            <nz-form-item>
              <nz-form-label>Date proposée</nz-form-label>
              <nz-form-control [nzErrorTip]="dateProposedError">
                <nz-date-picker
                  nzShowTime
                  nzFormat="dd/MM/yyyy 'à' HH'h'mm"
                  formControlName="dateProposed"
                  placeholder="Date proposée"
                  [nzSize]="'large'"
                  [nzDisabledDate]="disabledDatesPast"
                  [nzDisabledTime]="disabledTime"
                  [nzShowTime]="timePickerOptions">
                </nz-date-picker>
              </nz-form-control>
              <ng-template #dateProposedError let-control>
                <ng-container *ngIf="control.hasError('required')">Ce champ est requis</ng-container>
              </ng-template>
            </nz-form-item>
          </div>
          <div nz-col *ngIf="showPerform">
            <nz-form-item>
              <nz-form-label>Date de réalisation</nz-form-label>
              <nz-form-control>
                <nz-date-picker
                  nzShowTime
                  nzFormat="dd/MM/yyyy 'à' HH'h'mm"
                  formControlName="datePerformed"
                  placeholder="Date de réalisation"
                  [nzSize]="'large'"
                  [nzDisabledDate]="disabledDatesFuture"
                  [nzDisabledTime]="disabledTime"
                  [nzShowTime]="timePickerOptions">
                </nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-row [nzGutter]="[48, 0]" *ngIf="showPerform">
          <!-- <div nz-col [style.display]="'none'">
            <nz-form-item>
              <nz-form-label nzFor="hoursToInvoice">Heures facturées</nz-form-label>
              <nz-form-control [nzErrorTip]="hoursToInvoiceError">
                <nz-input-group nzSuffix="heures facturées" [nzSize]="'large'">
                  <input type="number" formControlName="hoursToInvoice" nz-input placeholder="Heures facturées"/>
                </nz-input-group>
              </nz-form-control>
              <ng-template #hoursToInvoiceError let-control>
                <ng-container *ngIf="control.hasError('required')">Ce champ est requis</ng-container>
                <ng-container *ngIf="control.hasError('min')">Doit être supérieur ou égale a 1</ng-container>
              </ng-template>
            </nz-form-item>
          </div> -->
          <div nz-col>
            <laveo-select-actor [data]="preparers" [control]="form.get('preparer')" [typeEntity]="typePreparer"></laveo-select-actor>
          </div>
        </div>
      </form>
    </div>

    <div nz-col nzSpan="24" *ngIf="!isInModal">
      <div nz-row [nzGutter]="[48, 0]">
        <div nz-col *ngFor="let action of this.availableActions">
          <button
            nz-button
            nzSize="large"
            nzType="primary"
            [nzLoading]="getLoading(action)"
            [nzDanger]="action === 'CANCEL' || action === 'VEHICLE_NOT_PRESENT'"
            [disabled]="actionLoading || cancelLoading"
            (click)="performAction(action, cancelServiceTemplate, cancelServiceFooterTemplate)">
            {{ action | serviceActionsName }}
            <i nz-icon *ngIf="action | serviceActionsIcon as actionIcon" [nzType]="actionIcon"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <ng-template #noAction>
    <div nz-row [nzGutter]="[48, 0]">
      <div nz-col>
        <span nz-typography nzType="secondary">Vous n'avez aucune action à réaliser sur cette prestation pour le moment</span>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #cancelServiceTemplate>
  <p>Êtes-vous sûr de vouloir annuler la demande de prestation ?</p>
  <form>
    <nz-form-item>
      <nz-form-label nzFor="comment" nzRequired>Commentaire</nz-form-label>
      <nz-form-control nzErrorTip="Le commentaire est requis">
        <textarea [formControl]="cancelComment" nz-input id="comment" name="comment" [nzAutosize]="true" rows="3" placeholder="Commentaire"></textarea>
      </nz-form-control>
    </nz-form-item>
  </form>
</ng-template>

<ng-template #cancelServiceFooterTemplate let-ref="modalRef">
  <button nz-button (click)="ref.destroy()">Fermer</button>
  <button nz-button nzType="primary" nzDanger (click)="closeCancelModal(ref)" [nzLoading]="cancelLoading">Annuler</button>
</ng-template>
