<nz-form-item>
  <nz-form-label nzFor="frequency">Fréquence</nz-form-label>
  <nz-form-control [nzErrorTip]="error">
    <nz-select [formControl]="formControl" nzMode="default" nzPlaceHolder="Fréquence" nzAllowClear [nzSize]="typeSize" class="w-100-p">
      <nz-option *ngFor="let frequency of vehicleFrequencyValues" [nzValue]="frequency" [nzLabel]="frequency | vehicleFrequencyName | nullCoal"></nz-option>
    </nz-select>
  </nz-form-control>
  <ng-template #error let-control>
    <ng-container *ngIf="control?.hasError('required')">
      La fréquence est requise
    </ng-container>
  </ng-template>
</nz-form-item>
