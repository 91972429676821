import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { VehicleBrandsService } from 'src/app/shared/services/api/vehicles-brand.service';
import { PagedRequest } from '../../../models/api/shared/paged/paged-request';
import { TitleCasePipe } from '@angular/common';
import { VehicleBrandWithModels } from 'src/app/shared/models/entities/vehicle-model';

@Component({
  selector: 'laveo-select-vehicle-brand',
  templateUrl: './select-vehicle-brand.component.html',
  styleUrls: ['./select-vehicle-brand.component.scss']
})
export class SelectVehicleBrandComponent implements OnInit, OnDestroy {
  @Input() control: AbstractControl | null;
  @Input() typeSelection: 'single' | 'multiple' = 'single';
  @Input() typeSize: 'default' | 'small' | 'large' = 'large';
  @Input() canCreateBrand = false;
  @Input() showModels = false;

  isBrandLoading = true;
  private privateBrandList: VehicleBrandWithModels[] = [];

  private searchParams = new BehaviorSubject<PagedRequest>(new PagedRequest({
    page: 0,
    limit: 10
  }));

  private subscriptions: Subscription[] = [];

  constructor(
    private readonly vehicleBrandsService: VehicleBrandsService
  ) {}

  public get brandList(): VehicleBrandWithModels[] {
    const current = this.control?.value;
    const temporaryList = [...this.privateBrandList];
    if (Array.isArray(current)) {
      for (const c of current) {
        if (c && !this.privateBrandList.some(brand => brand.id === c.id)) {
          temporaryList.push(c);
        }
      }
    } else {
      if (current && !this.privateBrandList.some(brand => brand.id === current.id)) {
        temporaryList.push(current);
      }
    }

    return temporaryList;
  }

  public get formControl(): UntypedFormControl {
    return this.control as UntypedFormControl;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.searchParams.pipe(
        debounceTime(500)
      ).subscribe(parameters => {
        if (!parameters) {
          return;
        }
        this.isBrandLoading = true;
        this.vehicleBrandsService.allVehicleBrands(parameters).subscribe(response => {
          if (response?.loading) {
            return;
          }

          const newData = [...response.data.data];
          if (this.canCreateBrand && (parameters.search?.length ?? 0 > 0) && newData?.[0]?.name.toLowerCase()?.trim() !== parameters.search) {
            newData.splice(0, 0, { name: (new TitleCasePipe()).transform(parameters.search), add: true} as any);
          }

          this.privateBrandList = newData;
          this.isBrandLoading = false;
        });
      })
    );
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  onSearchBrand(search: string): void {
    const parameters = new PagedRequest({
      page: 0,
      limit: 10,
      search: search?.toLowerCase()?.trim()
    });

    this.searchParams.next(parameters);
  }

  comparer(a: any, b: any): boolean {
    return a?.id === b?.id;
  }
}
