import { Component, Input } from '@angular/core';
import { VehicleModel } from '../../../../../../shared/models/entities/vehicle-model';

@Component({
  selector: 'laveo-vehicle-model-detail',
  templateUrl: './vehicle-model-detail.component.html',
  styleUrls: ['./vehicle-model-detail.component.scss']
})
export class VehicleModelDetailComponent {
  @Input() vehicleModel?: VehicleModel;
}
