import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PreparerFormComponent } from './preparer-form/preparer-form.component';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Preparer } from 'src/app/shared/models/entities/preparer';
import { PreparersService } from 'src/app/shared/services/api/preparers.service';
import { EntityWrapperComponent } from '../../../shared/entity-wrapper/entity-wrapper.component';
import { UserService } from 'src/app/shared/services/api/user.service';
import { UserRight } from 'src/app/shared/models/entities/user-right';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'laveo-preparer',
  templateUrl: './preparer.component.html',
  styleUrls: ['./preparer.component.scss']
})
export class PreparerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(EntityWrapperComponent) entityWrapperComponent: EntityWrapperComponent;
  @ViewChild(PreparerFormComponent) preparerFormComponent: PreparerFormComponent;

  isReady = false;
  preparer: Preparer;
  editEnabled = false;
  deleteEnabled = false;

  private subscriptions: Subscription[] = [];

  constructor(
    public readonly preparersService: PreparersService,
    private readonly userService: UserService,
    private readonly titleService: Title,
  ) {}

  get title(): string {
    return this.preparer?.name ?? 'Nouveau préparateur';
  }

  readonly description = '';

  ngOnInit(): void {
    this.loadRole();
  }

  ngAfterViewInit(): void {
    const entitySubscription = this.entityWrapperComponent?.entity.subscribe((preparer: Preparer) => {
      if (preparer !== undefined) {
        this.isReady = true;
      }
      this.preparer = preparer;
      this.setTitle();
    });

    if (entitySubscription) {
      this.subscriptions.push(entitySubscription);
    }
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  public form(): UntypedFormGroup {
    return this.preparerFormComponent?.form;
  }

  private loadRole(): void {
    const roleSubscription = this.userService.currentRole.subscribe(role => {
      this.editEnabled = role.rights.preparers.includes(UserRight.update);
      this.deleteEnabled = role.rights.preparers.includes(UserRight.delete);
    });

    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }
  }

  private setTitle(): void {
    this.titleService.setTitle(`Lavéo - ${this.title}`);
  }
}
