import { Environment } from './environment.interface';

export const environment: Environment = {
  name: 'prod',
  production: true,
  hmr: false,
  api: {
    url: 'https://api.mylaveo.fr',
    version: 1,
    mock: false,
    graphQL: '/graphQL'
  },
  oauth: {
    clientId: '55j4SRAjn56WpBoQW4r&Ver4dDBCkFCSYrL7WSQ*@wvTQxGa^vehjRYc6X#&qDyq',
    clientSecret: 'Tpd&jW23RPXBiWGFkGzZWq52bNUvNXB4!wR^VgMHVXu$UW7JjEVm^paJNXosogBi'
  },
  googleMapsKey: 'AIzaSyCB6a5AtKQG77EGOlzBKDSWLRi8ILgkwyI',
  sentry: 'https://190baedae6ee534efbd024aa40f3a68a@o4507684994940928.ingest.de.sentry.io/4507685008244816',
  flags: {
    enableReporting: true
  }
};
