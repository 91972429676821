import Bowser from 'bowser';
import { fromEvent, merge, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import packageJson from 'package.json';

export const isBrowserValid = (): boolean => {
  const browser = Bowser.getParser(window.navigator.userAgent);
  const browserName = browser.getBrowserName().toLowerCase();
  const validBrowsers: Bowser.Parser.checkTree = {
    'internet explorer': '>=10',
    edge: '>=80',
    chrome: '>=80',
    safari: '>=13',
    firefox: '>=74',
    opera: '>=67',
  };

  const validBrowsersName = Object.keys(validBrowsers);
  let foundBrowser = false;
  for (const validBrowserName of validBrowsersName) {
    if (browserName.includes(validBrowserName)) {
      foundBrowser = true;
      break;
    }
  }

  const satisfy = browser.satisfies(validBrowsers);
  return foundBrowser ? (satisfy ?? false) : true;
};

export const isBrowserOnline = (): Observable<boolean> => merge(
    fromEvent(window, 'offline').pipe(map(() => false)),
    fromEvent(window, 'online').pipe(map(() => true)),
    of(navigator.onLine)
  );

export const getEnvironmentName = (): string |undefined => {
  if (environment.name === 'prod') {
    return packageJson.version;
  }

  let name = environment.name;
  if (environment.api.mock) {
    name += ' + mock';
  }

  return name += ` (${ packageJson.version })`;
};
