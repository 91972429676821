import { Pipe, PipeTransform } from '@angular/core';
import { ServiceUpholsteryStainRemoval } from '../models/entities/service';

@Pipe({
  name: 'serviceUpholsteryStainRemovalName'
})
export class ServiceUpholsteryStainRemovalNamePipe implements PipeTransform {
  transform(type?: ServiceUpholsteryStainRemoval | ServiceUpholsteryStainRemoval[] | string | string[]): string | undefined {
    if (!type) {
      return;
    }

    if (Array.isArray(type)) {
      if (type.length <= 0) {
        return;
      }
      return type.map(t => this.getTypeName(t)).join(', ');
    } else {
      return this.getTypeName(type);
    }
  }

  private getTypeName(type: ServiceUpholsteryStainRemoval | string): string {
    switch (type) {
      case ServiceUpholsteryStainRemoval.driver:
      case 'Driver': {
        return 'Conducteur';
      }
      case ServiceUpholsteryStainRemoval.passenger:
      case 'Passenger': {
        return 'Passager';
      }
      case ServiceUpholsteryStainRemoval.back_left:
      case 'BackLeft': {
        return 'Arrière gauche';
      }
      case ServiceUpholsteryStainRemoval.back_right:
      case 'BackRight': {
        return 'Arrière droit';
      }
    }
    return type.toString();
  }
}
