import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { VehicleAttribution } from '../../../models/entities/vehicle-attribution';

@Component({
  selector: 'laveo-select-vehicle-attribution',
  templateUrl: './select-vehicle-attribution.component.html',
  styleUrls: ['./select-vehicle-attribution.component.scss']
})
export class SelectVehicleAttributionComponent {
  @Input() control: AbstractControl | null;
  @Input() typeSize: 'default' | 'small' | 'large' = 'large';

  public get vehicleAttributionValues(): VehicleAttribution[] {
    return Object.values(VehicleAttribution);
  }

  public get formControl(): UntypedFormControl {
    return this.control as UntypedFormControl;
  }
}
