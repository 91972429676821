<div class="tab-content">
  <form nz-form [formGroup]="checkpointsSearchForm">
    <div nz-row [nzGutter]="8" [nzAlign]="'middle'">
      <div nz-col *ngIf="checkpointsChecked.length > 0">
        <button nz-button nzType="default" nzSize="large" nz-dropdown [nzDropdownMenu]="checkpointsActionMenu">
          {{ checkpointsChecked.length }} {{ checkpointsChecked.length > 1 ? 'points de contrôles séléctionnés' : 'point de contrôle séléctionné' }}
          <i nz-icon nzType="down"></i>
        </button>
        <nz-dropdown-menu #checkpointsActionMenu="nzDropdownMenu">
          <ul nz-menu nzSelectable>
            <li nz-menu-item nzDanger (click)="deleteCheckedCheckpoints()">
              <i nz-icon nzType="delete"></i>
              Désactiver
            </li>
            <li nz-menu-divider></li>
            <li nz-menu-item (click)="checkpointsChecked.splice(0, checkpointsChecked.length)">
              <i nz-icon nzType="minus-square"></i>
              Tout déséléctionner
            </li>
          </ul>
        </nz-dropdown-menu>
      </div>
      <div nz-col nzFlex="1 1 auto">
        <nz-input-group [nzPrefix]="prefixIconSearch" nzSize="large">
          <ng-template #prefixIconSearch>
            <i nz-icon nzType="search"></i>
          </ng-template>
          <input type="text" nz-input placeholder="Rechercher un point de contrôle" formControlName="search" />
        </nz-input-group>
      </div>
      <div nz-col>
        <button type="button" nz-button nzType="primary" nzSize="large" (click)="addVehicleCheckpoint()">
          <i nz-icon nzType="plus"></i>
          Ajouter un point de contrôle
        </button>
      </div>
    </div>
  </form>
  <nz-table
    nzShowSizeChanger
    [nzData]="checkpointsData ? checkpointsData.data : []"
    [nzFrontPagination]="false"
    [nzLoading]="checkpointsIsLoading"
    [nzTotal]="checkpointsData ? checkpointsData.metadata.totalResults : 0"
    [nzPageSize]="checkpointsLimit"
    [nzPageIndex]="checkpointsData ? checkpointsData.metadata.currentPage : 0"
    [nzNoResult]="checkpointsEmptyTemplate"
    (nzQueryParams)="setCheckpointsPage($event)"
    *ngIf="!checkpointsError; else checkpointsErrorTemplate">
    <thead>
    <tr>
      <th nzWidth="60px"></th>
      <th nzColumnKey="active" nzWidth="60px" [nzSortFn]="true">Actif</th>
      <th nzColumnKey="category" [nzSortFn]="true">Catégorie</th>
      <th nzColumnKey="name" [nzSortFn]="true">Nom</th>
      <th nzColumnKey="order" nzWidth="70px" [nzSortFn]="true">Ordre</th>
      <th nzColumnKey="isOnRepport" nzWidth="200px">Dans le rapport par défaut</th>
      <th nzColumnKey="fieldType" nzWidth="70px">Type</th>
      <th nzColumnKey="required" nzWidth="70px">Requis</th>
      <th nzColumnKey="actions" class="actions">Actions</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let checkpoint of checkpointsData?.data">
      <tr>
        <td [nzChecked]="checkpointsChecked.includes(checkpoint.id)" (nzCheckedChange)="setCheckpointsChecked($event, checkpoint.id)"></td>
        <td><label nz-checkbox nzDisabled [(ngModel)]="checkpoint.active"></label></td>
        <td>{{ checkpoint.category }}</td>
        <td>{{ checkpoint.name }}</td>
        <td>{{ checkpoint.order }}</td>
        <td>{{ checkpoint.isOnRepport ? 'Oui' : 'Non' }}</td>
        <td>{{ checkpoint.fieldType | vehicleCheckpointFieldTypeName }}</td>
        <td>{{ checkpoint.required ? 'Oui' : 'Non' }}</td>
        <td class="actions">
          <button nz-button class="mr-8" (click)="editVehicleCheckpoint(checkpoint)">Modifier</button>
          <button nz-button nzType="primary" (click)="deleteVehicleCheckpoint(checkpoint)" [disabled]="!checkpoint.active">
            <i nz-icon nzType="delete"></i>
          </button>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </nz-table>
  <ng-template #checkpointsErrorTemplate>
    <nz-result nzTitle="Erreur de chargement" nzStatus="error"
               nzSubTitle="Une erreur est survenue lors du chargement de la liste des points de contrôles">
      <div nz-result-extra>
        <button nz-button nzType="primary" (click)="loadCheckpointsData()">
          Réessayer
          <i nz-icon nzType="reload"></i>
        </button>
      </div>
    </nz-result>
  </ng-template>
  <ng-template #checkpointsEmptyTemplate>
    <nz-empty nzNotFoundImage="simple"
              nzNotFoundContent="Aucun point de contrôle trouvé. Commencez par en ajouter un en utilisant le bouton ci-dessous."
              [nzNotFoundFooter]="checkpointsEmptyFooter">
      <ng-template #checkpointsEmptyFooter>
        <button nz-button nzType="primary" (click)="addVehicleCheckpoint()">
          <i nz-icon nzType="plus"></i>
          Ajouter un point de contrôle
        </button>
      </ng-template>
    </nz-empty>
  </ng-template>
</div>
