import { Component, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { EntityWrapperComponent } from '../../../shared/entity-wrapper/entity-wrapper.component';
import { VehicleCheckpointFormComponent } from './vehicle-checkpoint-form/vehicle-checkpoint-form.component';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { VehicleCheckpoint } from '../../../../../shared/models/entities/vehicle-checkpoint';
import { VehicleCheckpointsService } from '../../../../../shared/services/api/vehicles-checkpoint.service';

@Component({
  selector: 'laveo-vehicle-checkpoint',
  templateUrl: './vehicle-checkpoint.component.html',
  styleUrls: ['./vehicle-checkpoint.component.scss']
})
export class VehicleCheckpointComponent implements AfterViewInit, OnDestroy {
  @ViewChild(EntityWrapperComponent) entityWrapperComponent: EntityWrapperComponent;
  @ViewChild(VehicleCheckpointFormComponent) vehicleCheckpointFormComponent: VehicleCheckpointFormComponent;

  isReady = false;
  vehicleCheckpoint: VehicleCheckpoint;

  private subscriptions: Subscription[] = [];

  constructor(public readonly vehicleCheckpointsService: VehicleCheckpointsService) {}

  get title(): string {
    const vechileCheckpoint = this.vehicleCheckpoint;
    if (vechileCheckpoint) {
      return vechileCheckpoint.name;
    }

    if (vechileCheckpoint === null) {
      return 'Nouveau point de controle';
    }

    return '';
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(this.entityWrapperComponent?.entity
      .subscribe(entity => {
        this.vehicleCheckpoint = entity as VehicleCheckpoint;
        if (this.vehicleCheckpoint !== undefined) {
          this.isReady = true;
        }
      }));
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  public form(): UntypedFormGroup {
    return this.vehicleCheckpointFormComponent?.form;
  }
}
