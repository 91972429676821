import { Type } from 'typeserializer';
import { Entity } from './entity';
import { UserRole } from './user-role';

export class User extends Entity {
  @Type([UserRole])
  roles: UserRole[];
  id: string;
  active: boolean;
  name: string;
  mail: string;
  mailValidation?: string;
  password: string;
  activatedByUser: boolean;
}

export class UserArray {
  @Type([User])
  children: User[];
}
