import { AfterViewInit, Component, ViewChild, OnDestroy, OnInit } from '@angular/core';
import { VehicleFormComponent } from './vehicle-form/vehicle-form.component';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { EntityWrapperComponent } from '../../../shared/entity-wrapper/entity-wrapper.component';
import { Vehicle } from 'src/app/shared/models/entities/vehicle';
import { VehiclesService } from 'src/app/shared/services/api/vehicles.service';
import { UserService } from 'src/app/shared/services/api/user.service';
import { UserRight } from 'src/app/shared/models/entities/user-right';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'laveo-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.scss']
})
export class VehicleComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(EntityWrapperComponent) entityWrapperComponent: EntityWrapperComponent;
  @ViewChild(VehicleFormComponent) vehicleFormComponent: VehicleFormComponent;

  isReady = false;
  vehicle: Vehicle;
  editEnabled = false;
  deleteEnabled = false;

  private subscriptions: Subscription[] = [];

  constructor(
    public readonly vehiclesService: VehiclesService,
    private readonly userService: UserService,
    private readonly titleService: Title,
  ) {}

  get title(): string {
    const vehicle = this.vehicle;
    if (vehicle) {
      return vehicle.model.brand.name + ' - ' + vehicle.model.name;
    }

    if (vehicle === null) {
      return 'Nouveau véhicule';
    }

    return '';
  }

  get description(): string {
    const vehicle = this.vehicle;
    return vehicle?.licensePlate ?? '';
  }

  ngOnInit(): void {
    this.loadRole();
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(this.entityWrapperComponent?.entity
      .subscribe(entity => {
        this.vehicle = entity as Vehicle;
        if (this.vehicle !== undefined) {
          this.isReady = true;
        }
        this.setTitle();
      }));
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  public form(): UntypedFormGroup {
    return this.vehicleFormComponent?.form;
  }

  private loadRole(): void {
    const roleSubscription = this.userService.currentRole.subscribe(role => {
      this.editEnabled = role.rights.vehicles.includes(UserRight.update);
      this.deleteEnabled = role.rights.vehicles.includes(UserRight.delete);
    });

    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }
  }

  private setTitle(): void {
    this.titleService.setTitle(`Lavéo - ${this.title}`);
  }
}
