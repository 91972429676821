import { Component, Input, OnInit } from '@angular/core';
import { CustomerSite } from 'src/app/shared/models/entities/customer-site';
import { environment } from '../../../../../../../environments/environment';
import { Utils } from '../../../../../../shared/utils/utils';
import { VehicleCheckpoint } from '../../../../../../shared/models/entities/vehicle-checkpoint';

@Component({
  selector: 'laveo-customer-site-detail',
  templateUrl: './customer-site-detail.component.html',
  styleUrls: ['./customer-site-detail.component.scss']
})
export class CustomerSiteDetailComponent implements OnInit {
  @Input() customerSite?: CustomerSite;

  bilanRseUrl = environment.api.url + '/bilan_rse/';
  vehicleCheckpointGroupedByCategory: {
    key: string;
    value: VehicleCheckpoint[];
  }[] = [];

  ngOnInit(): void {
    if (this.customerSite?.vehicleCheckpoints) {
      const grouped = Utils.groupBy(this.customerSite?.vehicleCheckpoints, d => d.category);
      this.vehicleCheckpointGroupedByCategory = Object.keys(grouped).map(key => ({
        key,
        value: grouped[key]
      }));
    }
  }
}
