import { Component, ViewChild, OnDestroy, AfterViewInit, OnInit } from '@angular/core';
import { UserFormComponent } from './user-form/user-form.component';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { EntityWrapperComponent } from '../../../shared/entity-wrapper/entity-wrapper.component';
import { User } from 'src/app/shared/models/entities/user';
import { UserService } from 'src/app/shared/services/api/user.service';
import { Title } from '@angular/platform-browser';
import { UserRight } from 'src/app/shared/models/entities/user-right';

@Component({
  selector: 'laveo-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(EntityWrapperComponent) entityWrapperComponent: EntityWrapperComponent;
  @ViewChild(UserFormComponent) userFormComponent: UserFormComponent;

  isReady = false;
  editEnabled = false;
  deleteEnabled = false;
  user: User;

  private subscriptions: Subscription[] = [];

  constructor(
    public readonly userService: UserService,
    private readonly titleService: Title,
  ) {}

  get title(): string {
    const user = this.user;
    if (user) {
      return user.name;
    }

    if (user === null) {
      return 'Nouvel utilisateur';
    }

    return '';
  }

  get description(): string {
    const user = this.user;
    return user?.mail ?? '';
  }

  ngOnInit(): void {
    this.loadRole();
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(this.entityWrapperComponent?.entity
      .subscribe(entity => {
        this.user = entity as User;
        if (this.user !== undefined) {
          this.isReady = true;
        }
        this.setTitle();
      }));
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  public form(): UntypedFormGroup {
    return this.userFormComponent?.form;
  }

  private loadRole(): void {
    const roleSubscription = this.userService.currentRole.subscribe(role => {
      this.editEnabled = role.rights.users.includes(UserRight.update);
      this.deleteEnabled = role.rights.users.includes(UserRight.delete);
    });

    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }
  }

  private setTitle(): void {
    this.titleService.setTitle(`Lavéo - ${this.title}`);
  }
}
