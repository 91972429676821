import { Customer } from '../../entities/customer';
import { CustomerSite } from '../../entities/customer-site';
import { Structure } from '../../entities/structure';
import { Type } from 'typeserializer';
import { Vehicle } from '../../entities/vehicle';
import { Service } from 'src/app/shared/models/entities/service';

export class ReportingVehicleMonitoring {
  @Type(Customer)
  customer?: Customer;
  @Type(CustomerSite)
  customerSite?: CustomerSite;
  @Type(Structure)
  structure?: Structure;
  @Type(Vehicle)
  vehicle?: Vehicle;
  @Type(Service)
  lastService?: Service;
  @Type(Service)
  upcomingService1?: Service;
  @Type(Service)
  upcomingService2?: Service;
  @Type(Service)
  upcomingService3?: Service;
  actor: Customer | CustomerSite | Structure;
  interiorFrequencyReal: number;
  interiorFrequencyContractual: number;
  exteriorFrequencyReal: number;
  exteriorFrequencyContractual: number;
  fullFrequencyReal: number;
  fullFrequencyContractual: number;
  vehicleStateEnabledFrequencyReal: number;
  allFrequencyReal: number;
}
