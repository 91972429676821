import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo, gql, MutationResult } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PagedRequest } from '../../models/api/shared/paged/paged-request';
import { PagedResponse } from '../../models/api/shared/paged/paged-response.interface';
import { CustomerRequest } from '../../models/api/services/customer-requests.interface';
import { TypeSerializerUtils } from '../../utils/type-serializer.util';
import { Customer, CustomerArray } from '../../models/entities/customer';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {
  constructor(
    private readonly apollo: Apollo
  ) {}

  addCustomer(data: CustomerRequest): Observable<MutationResult<Customer>> {
    const mutation = gql`
      mutation addCustomer($data: CustomerRequest) {
        actors {
          customers {
            add(data: $data) {
              id
              active
              name
              address
              city
              postalCode
              country
              logo {
                id
                url
              }
              customerSites {
                metadata {
                  totalResults
                }
              }
              contacts {
                id
                name
                sendMail
                mail
                phoneNumber
                comment
              }
            }
          }
        }
      }
    `;

    return this.apollo.mutate<{ actors: { customers: { add: Customer } } }>({
      mutation,
      variables: {
        data: {
          name: data.name,
          address: data.address,
          city: data.city,
          postalCode: data.postalCode,
          country: data.country,
          latitude: data.latitude,
          longitude: data.longitude,
          contacts : data.contacts,
          logo: data.logo
        }
      },
      context: {
        useMultipart: true
      }
    }).pipe(map(result => ({

      data: TypeSerializerUtils.deserialize(result.data?.actors?.customers?.add, Customer),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }

  updateCustomer(data: CustomerRequest): Observable<MutationResult<Customer>> {
    const mutation = gql`
      mutation updateCustomer($id: Guid!, $data: CustomerRequest) {
        actors {
          customers {
            update(id: $id, data: $data) {
              id
              active
              name
              address
              city
              postalCode
              country
              logo {
                id
                url
              }
              customerSites {
                metadata {
                  totalResults
                }
              }
              contacts {
                id
                name
                sendMail
                mail
                phoneNumber
                comment
              }
            }
          }
        }
      }
    `;

    return this.apollo.mutate<{ actors: { customers: { update: Customer } } }>({
      mutation,
      variables: {
        id: data.id,
        data: {
          active: data.active,
          name: data.name,
          address: data.address,
          city: data.city,
          postalCode: data.postalCode,
          country: data.country,
          latitude: data.latitude,
          longitude: data.longitude,
          contacts : data.contacts,
          logo: data.logo
        }
      },
      context: {
        useMultipart: true
      }
    }).pipe(map(result => ({

      data: TypeSerializerUtils.deserialize(result.data?.actors?.customers?.update, Customer),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }

  deleteCustomer(...ids: string[]): Observable<MutationResult<void>> {
    const query = `
      mutation deleteCustomer(` + ids.map((_, index) => '$id' + index + ': Guid!').join(', ') + `) {
        actors {
          customers {\n` + ids.map((_, index) => 'id' + index + ': delete (id: $id' + index + ')').join('\n') + `\n}
        }
      }
    `;

    const variables = ids.reduce((response, item, index) => {
      response['id' + index] = item;
      return response;
    }, {});

    return this.apollo.mutate<void>({
      mutation: gql(query),
      variables
    });
  }

  deleteOneCustomerHard(id: string): Observable<MutationResult<void>> {
    const mutation = gql`
      mutation deleteCustomerActor($id: Guid!, $hard: Boolean) {
        actors {
          customers {
            delete(id: $id, hard: $hard)
          }
        }
      }
    `;

    const variables = {
      id,
      hard: true
    };

    return this.apollo.mutate<void>({
      mutation,
      variables
    });
  }

  customer(id: string): Observable<ApolloQueryResult<Customer>> {
    const query = gql`
      query customer($id: Guid!){
        actors {
          customer(id: $id) {
            id
            active
            name
            address
            city
            country
            postalCode
            logo {
              id
              url
            }
            customerSites {
              metadata {
                totalResults
              }
            }
            contacts {
              id
              name
              sendMail
              mail
              phoneNumber
              comment
            }
          }
        }
      }
    `;
    return this.apollo.query<{ actors: { customer: Customer } }>({
      query,
      variables: { id }
    }).pipe(map(result => ({
      data: TypeSerializerUtils.deserialize(result.data?.actors?.customer, Customer),
      error: result.error,
      errors: result.errors,
      partial: result.partial,
      loading: result.loading,
      networkStatus: result.networkStatus
    })));
  }

  allCustomers(parameters: PagedRequest): Observable<ApolloQueryResult<PagedResponse<Customer>>> {
    const query = gql`
      query customers($offset: Int, $limit: Int, $sortProperty: String, $sortType: ESortType, $search: String) {
        actors {
          customers(offset: $offset, limit: $limit, sortProperty: $sortProperty, sortType: $sortType, search: $search) {
            data {
              id
              active
              name
              address
              city
              postalCode
              country
              logo {
                id
                url
              }
              customerSites {
                metadata {
                  totalResults
                }
              }
            }
            metadata {
              currentPage
              currentResult
              totalPages
              totalResults
              hasMore
            }
          }
        }
      }
    `;
    return this.apollo.query<{ actors: { customers: PagedResponse<Customer> } }>({
      query,
      variables: {
        offset: parameters.offset,
        limit: parameters.limit,
        sortProperty: parameters.sortProperty,
        sortType: parameters.sortType,
        search: parameters.search
      }
    }).pipe(map(result => {
      const raw = result.data?.actors?.customers;
      const response: PagedResponse<Customer> = {
        data: TypeSerializerUtils.deserializeArr(raw.data, CustomerArray),
        metadata: raw.metadata
      };
      return {
        data: response,
        error: result.error,
        errors: result.errors,
        partial: result.partial,
        loading: result.loading,
        networkStatus: result.networkStatus
      };
    }));
  }
}
