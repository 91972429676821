import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { Vehicle } from 'src/app/shared/models/entities/vehicle';
import { VehicleFrequency } from 'src/app/shared/models/entities/vehicle-frequency';
import { CustomerSite } from '../../../../../../shared/models/entities/customer-site';
import { UserService } from '../../../../../../shared/services/api/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'laveo-vehicle-form',
  templateUrl: './vehicle-form.component.html',
  styleUrls: ['./vehicle-form.component.scss']
})
export class VehicleFormComponent implements OnInit, OnDestroy {
  @Input() vehicle: Vehicle;
  form: UntypedFormGroup;
  customerSiteTypeEntity = [CustomerSite];
  licensePlateChanged = false;

  private subscriptions: Subscription[] = [];
  private oldLicenseValue = '';

  constructor(
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.setForm();
    this.loadRole();
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  private setForm(): void {
    this.form = new UntypedFormGroup({
      id: new UntypedFormControl(this.vehicle?.id, []),
      active: new UntypedFormControl(this.vehicle?.active ?? true, [Validators.required]),
      licensePlate: new UntypedFormControl(this.vehicle?.licensePlate, [Validators.required]),
      customerSite: new UntypedFormControl(this.vehicle?.customerSite, [Validators.required]),
      model: new UntypedFormControl(this.vehicle?.model, [Validators.required]),
      frequency: new UntypedFormControl(this.vehicle?.frequency, [Validators.required]),
      attribution: new UntypedFormControl(this.vehicle?.attribution, []),
      group: new UntypedFormControl(this.vehicle?.group, []),
      collaborator: new UntypedFormControl(this.vehicle?.collaborator, []),
      comment: new UntypedFormControl(this.vehicle?.comment, [Validators.maxLength(500)]),
    });

    this.updateAttributionEnable();
    const frequencySubscription = this.form.get('frequency')?.valueChanges.subscribe(() => {
      this.updateAttributionEnable();
    });

    const licenseSubscription = this.form.get('licensePlate')?.valueChanges.subscribe((value: string) => {
      const newValue = value?.toUpperCase();
      if (this.oldLicenseValue !== newValue) { // Valentin : Je ne sais pas à quoi sert ce code ?!
        this.oldLicenseValue = newValue;
        this.form.get('licensePlate')?.setValue(this.oldLicenseValue);
        this.form.get('licensePlate')?.updateValueAndValidity();
      }

      this.licensePlateChanged = this.form.get('id')?.value && this.vehicle?.licensePlate?.toUpperCase() !== newValue;
    });

    if (frequencySubscription && licenseSubscription) {
      this.subscriptions.push(frequencySubscription, licenseSubscription);
    }
  }

  private updateAttributionEnable(): void {
    const f = this.form.get('frequency')?.value;
    if (f === VehicleFrequency.recurrent) {
      this.form.get('attribution')?.enable();
    } else {
      this.form.get('attribution')?.disable();
      this.form.get('attribution')?.setValue(null);
    }
  }

  private loadRole(): void {
    const roleSubscription = this.userService.currentRole.subscribe(role => {
      if (role.actor?.__typename === 'CustomerSite') {
        const control = this.form.get('customerSite');
        control?.setValue(role.actor);
        control?.disable();
      }
    });

    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }
  }
}
