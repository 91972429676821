<div nz-row [nzGutter]="[48, 24]">
  <div nz-col nzSpan="24" *ngIf="customer?.logo?.urlWithDomain">
    <img [src]="customer?.logo?.urlWithDomain" alt="Logo {{ customer?.name }}" class="logo">
  </div>

  <div nz-col nzSpan="24">
    <div nz-row [nzGutter]="[48, 0]">
      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Actif</span>
          <span class="detail-content" nz-typography>
            <i nz-icon [nzType]="customer?.active ? 'check-square' : 'close-square'" nzTheme="outline"></i>
            {{ customer?.active ? 'Activé' : 'Désactivé' }}
          </span>
        </div>
      </div>

      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Nom</span>
          <span class="detail-content" nz-typography>{{ customer?.name }}</span>
        </div>
      </div>
    </div>
  </div>

  <div nz-col nzSpan="24">
    <div class="detail">
      <span class="detail-title" nz-typography nzType="secondary">Adresse</span>
      <span class="detail-content" nz-typography>{{ customer?.fullAddress | nullCoal }}</span>
    </div>
  </div>

  <div nz-col nzSpan="24">
    <div class="detail">
      <span class="detail-title" nz-typography nzType="secondary">Site client</span>
      <span class="detail-content" nz-typography>
        <button
          nz-button
          nzSize="large"
          [routerLink]="['/', 'admin', 'customer-sites']"
          [queryParams]="{ s: customer?.name }"
          [disabled]="customer?.customerSitesMetaData?.totalResults === 0">
          {{ customer ? customerSites(customer) : null }}<i nz-icon nzType="right-square"></i>
        </button>
      </span>
    </div>
  </div>

  <div nz-col nzSpan="24">
    <laveo-list-contacts [contacts]="customer ? customer.contacts : []"></laveo-list-contacts>
  </div>
</div>
