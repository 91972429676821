import { Pipe, PipeTransform } from '@angular/core';
import { ServiceActions } from '../models/entities/service-actions';

@Pipe({
  name: 'serviceActionsName'
})
export class ServiceActionsNamePipe implements PipeTransform {
  transform(action: ServiceActions, short = false): string | null {
    switch (action) {
      case ServiceActions.cancel: {
        return 'Annuler';
      }
      case ServiceActions.confirm: {
        return 'Confirmer';
      }
      case ServiceActions.perform: {
        return 'Réaliser';
      }
      case ServiceActions.vehicle_not_present: {
        return short ? 'Non présenté' : 'Véhicule non présenté';
      }
      case ServiceActions.send_to_customer_site: {
        return short ? 'Transmettre' : 'Transmettre au site client';
      }
      case ServiceActions.send_to_structure: {
        return short ? 'Transmettre' : 'Transmettre à la structure';
      }
      case ServiceActions.update: {
        return 'Modifier';
      }
      case ServiceActions.upload_vehicle_state: {
        return 'Envoyer un état des lieux';
      }
      default: {
        return null;
      }
    }
  }
}
