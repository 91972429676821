import { Component, Input, OnInit, OnDestroy, TemplateRef } from '@angular/core';
import { PagedRequest } from '../../../models/api/shared/paged/paged-request';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Vehicle } from 'src/app/shared/models/entities/vehicle';
import { CustomerSite } from 'src/app/shared/models/entities/customer-site';
import { VehiclesService } from 'src/app/shared/services/api/vehicles.service';

@Component({
  selector: 'laveo-select-vehicle',
  templateUrl: './select-vehicle.component.html',
  styleUrls: ['./select-vehicle.component.scss']
})
export class SelectVehicleComponent implements OnInit, OnDestroy {
  @Input() control: AbstractControl | null;
  @Input() typeSize: 'default' | 'small' | 'large' = 'large';
  @Input() typeSelection: 'single' | 'multiple' = 'single';
  @Input() customerSite = new BehaviorSubject<CustomerSite | null>(null);
  @Input() warning: TemplateRef<any>;
  @Input() hasWarning = false;

  isLoading = true;
  private listPrivate: Vehicle[] = [];
  private searchParams: BehaviorSubject<PagedRequest>;
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly vehiclesService: VehiclesService
  ) {}

  public get list(): Vehicle[] {
    const current = this.control?.value;
    if (Array.isArray(current)) {
      const temporaryList = [...this.listPrivate];
      for (const c of current) {
        if (c && !this.listPrivate.some(vehicle => vehicle.id === c.id)) {
          temporaryList.push(c);
        }
      }
      return temporaryList;
    } else {
      if (current && !this.listPrivate.some(vehicle => vehicle.id === current.id)) {
        return [...this.listPrivate, this.control?.value];
      }
    }
    return this.listPrivate;
  }

  get validationStatus(): string | UntypedFormControl | null {
    if (this.hasWarning) {
      return 'warning';
    }

    return this.control as UntypedFormControl;
  }

  get formControl(): UntypedFormControl {
    return this.control as UntypedFormControl;
  }

  ngOnInit(): void {
    this.searchParams = new BehaviorSubject<PagedRequest>(new PagedRequest({
      page: 0,
      limit: 10,
    }));
    this.loadData();
    this.setCustomerSubscription();
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  onSearch(search: string): void {
    const parameters = new PagedRequest({
      page: 0,
      limit: 10,
      search: search?.toLowerCase()?.trim(),
    });

    parameters.filters = this.customerSite.value ? { customerSites: [this.customerSite.value.id] } : undefined;
    this.searchParams.next(parameters);
  }

  comparer(a: any, b: any): boolean {
    return a?.id === b?.id;
  }

  private loadData(): void {
    const searchSubscription = this.searchParams.pipe(debounceTime(500)).subscribe(parameters => {
      if (!parameters) {
        return;
      }
      this.isLoading = true;
      const vehicleSubscription = this.vehiclesService.allVehicles(parameters).subscribe(response => {
        if (response.loading) {
          return;
        }

        this.listPrivate = response.data.data;
        this.isLoading = false;
        if (vehicleSubscription) {
          vehicleSubscription.unsubscribe();
        }
      });
      this.subscriptions.push(vehicleSubscription);
    });

    if (searchSubscription) {
      this.subscriptions.push(searchSubscription);
    }
  }

  private setCustomerSubscription(): void {
    const customerSubscription = this.customerSite?.subscribe(customerSite => {
      const searchParameters = this.searchParams.value;
      searchParameters.filters = customerSite ? { customerSites: [customerSite.id] } : undefined;
      this.searchParams.next(searchParameters);
    });

    if (customerSubscription) {
      this.subscriptions.push(customerSubscription);
    }
  }
}
