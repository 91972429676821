import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import packageJson from 'package.json';
import { browserTracingIntegration, init } from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import 'reflect-metadata';

init({
  dsn: environment.sentry,
  environment: environment.name,
  autoSessionTracking: true,
  attachStacktrace: true,
  release: `laveo-front@${packageJson.version}`,
  enabled: !environment.hmr,
  integrations: [
    browserTracingIntegration({
      tracingOrigins: ['localhost', environment.api.url],
    })
  ],
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.5
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(error => console.error(error));
