<nz-card [nzBordered]="false" [nzTitle]="cardTitle">
  <ng-template #cardTitle>
      <laveo-card-header title="Rapports"></laveo-card-header>
  </ng-template>
  <div nz-row [nzGutter]="[16, 16]">
    <div nz-col [nzXs]="24" [nzMd]="12" [nzLg]="6">
      <div class="reporting" [routerLink]="['/', 'reporting', 'vehicle']">
        <i nz-icon nzType="car"></i>
        <p>Véhicules</p>
      </div>
    </div>
    <div nz-col [nzXs]="24" [nzMd]="12" [nzLg]="6">
      <div class="reporting" [routerLink]="['/', 'reporting', 'service-status']">
        <i nz-icon nzType="unordered-list"></i>
        <p>Statut des prestations</p>
      </div>
    </div>
    <div nz-col [nzXs]="24" [nzMd]="12" [nzLg]="6">
      <div class="reporting" [routerLink]="['/', 'reporting', 'service-specifications']">
        <i nz-icon nzType="ordered-list"></i>
        <p>Caractéristiques des prestations</p>
      </div>
    </div>
    <div nz-col [nzXs]="24" [nzMd]="12" [nzLg]="6">
      <div class="reporting" [routerLink]="['/', 'reporting', 'vehicle-monitoring']">
        <i nz-icon nzType="car"></i>
        <p>Monitoring Véhicules</p>
      </div>
    </div>
    <div nz-col [nzXs]="24" [nzMd]="12" [nzLg]="6">
      <div class="reporting" [routerLink]="['/', 'reporting', 'fleet']">
        <i nz-icon nzType="shop"></i>
        <p>Monitoring Flotte</p>
      </div>
    </div>
    <div nz-col [nzXs]="24" [nzMd]="12" [nzLg]="6">
      <div class="reporting" [routerLink]="['/', 'reporting', 'rse']">
        <i nz-icon nzType="line-chart"></i>
        <p>Bilan RSE</p>
      </div>
    </div>
  </div>
</nz-card>
