import { Type } from 'typeserializer';
import { Entity } from './entity';

export class Contact extends Entity {
  name: string;
  mail: string;
  phoneNumber: string;
  comment: string;
  sendMail?: boolean;
}

export class ContactArray {
  @Type([Contact])
  children: Contact[];
}
