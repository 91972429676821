import { Type } from 'typeserializer';
import { Entity } from './entity';
import { VehicleCheckpointFieldType } from './vehicle-checkpoint-field-type';

export class VehicleCheckpoint extends Entity {
  name: string;
  active: boolean;
  category: string;
  order: number;
  fieldType: VehicleCheckpointFieldType;
  isOnRepport: boolean;
  required: boolean;
  values: string[];
}

export class VehicleCheckpointArray {
  @Type([VehicleCheckpoint])
  children: VehicleCheckpoint[];
}
