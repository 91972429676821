import { Component, Inject, LOCALE_ID, ViewChild, OnDestroy, AfterViewInit, OnInit } from '@angular/core';
import { Service } from '../../../shared/models/entities/service';
import { ServiceActions } from 'src/app/shared/models/entities/service-actions';
import { formatDate } from '@angular/common';
import { EntityWrapperComponent } from '../shared/entity-wrapper/entity-wrapper.component';
import { ServiceFormComponent } from './service-form/service-form.component';
import { UntypedFormGroup } from '@angular/forms';
import { ServicesService } from '../../../shared/services/api/services.service';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { UserService } from 'src/app/shared/services/api/user.service';
import { UserRight } from 'src/app/shared/models/entities/user-right';

@Component({
  selector: 'laveo-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServiceComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(EntityWrapperComponent) entityWrapperComponent: EntityWrapperComponent;
  @ViewChild(ServiceFormComponent) serviceFormComponent: ServiceFormComponent;
  isReady = false;
  service: Service;
  editEnabled = false;
  deleteEnabled = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private readonly titleService: Title,
    private readonly userService: UserService,
    public readonly servicesService: ServicesService,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  get title(): string {
    const service = this.service;
    if (service) {
      return service.vehicle.licensePlate + ' - ' + (formatDate(service.date, 'short', this.locale));
    }
    return 'Nouvelle prestation';
  }

  get description(): string {
    const service = this.service;
    return service?.reference ?? '';
  }

  ngOnInit(): void {
    this.loadRole();
  }

  ngAfterViewInit(): void {
    const entitySubscription = this.entityWrapperComponent?.entity.subscribe((service: Service) => {
      if (service !== undefined) {
        this.isReady = true;
      }
      this.editEnabled = service === null || !!service?.actions?.includes(ServiceActions.update);

      this.service = service;
      this.setTitle();
    });

    if (entitySubscription) {
      this.subscriptions.push();
    }
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  public form(): UntypedFormGroup {
    return this.serviceFormComponent?.form;
  }

  private loadRole(): void {
    const roleSubscription = this.userService.currentRole.subscribe(role => {
      this.deleteEnabled = role.rights.services.includes(UserRight.delete);
    });

    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }
  }

  private setTitle() {
    this.titleService.setTitle(`Lavéo - ${this.title}`);
  }
}
