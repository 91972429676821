import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { VehicleCheckpoint } from '../../../../../../shared/models/entities/vehicle-checkpoint';
import { VehicleCheckpointFieldType } from '../../../../../../shared/models/entities/vehicle-checkpoint-field-type';

@Component({
  selector: 'laveo-vehicle-checkpoint-form',
  templateUrl: './vehicle-checkpoint-form.component.html',
  styleUrls: ['./vehicle-checkpoint-form.component.scss']
})
export class VehicleCheckpointFormComponent implements OnInit {
  @Input() vehicleCheckpoint: VehicleCheckpoint;

  form: UntypedFormGroup;


  public get vehicleCheckpointFieldTypeValues(): VehicleCheckpointFieldType[] {
    return Object.values(VehicleCheckpointFieldType);
  }

  ngOnInit(): void {
    this.setForm();
  }

  private setForm(): void {
    this.form = new UntypedFormGroup({
      id: new UntypedFormControl(this.vehicleCheckpoint?.id, []),
      name: new UntypedFormControl(this.vehicleCheckpoint?.name, [Validators.required]),
      active: new UntypedFormControl(this.vehicleCheckpoint?.active),
      category: new UntypedFormControl(this.vehicleCheckpoint?.category, [Validators.required]),
      order: new UntypedFormControl(this.vehicleCheckpoint?.order, [Validators.required]),
      fieldData: new UntypedFormGroup({
        fieldType: new UntypedFormControl(this.vehicleCheckpoint?.fieldType, [Validators.required]),
        values: new UntypedFormControl(this.vehicleCheckpoint?.values),
        isOnRepport: new UntypedFormControl(this.vehicleCheckpoint?.isOnRepport),
        required: new UntypedFormControl(this.vehicleCheckpoint?.required),
      }),
    });

    const fieldTypeForm = this.form.get('fieldData')?.get('fieldType');
    if (fieldTypeForm) {
      this.changeStateValueField(fieldTypeForm.value);

      fieldTypeForm.valueChanges.subscribe(value => {
        this.changeStateValueField(value);
      });
    }
  }

  private changeStateValueField(fieldType: string) {
    const valuesForm = this.form.get('fieldData')?.get('values');

    if (fieldType === VehicleCheckpointFieldType.selectType) {
      valuesForm?.enable();
    } else {
      valuesForm?.disable();
    }
  }
}
