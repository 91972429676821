<div nz-row [nzGutter]="[48, 24]">
  <div nz-col nzSpan="24">
    <div nz-row [nzGutter]="[48, 0]">
      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Actif</span>
          <span class="detail-content" nz-typography>
            <i nz-icon [nzType]="preparer?.active ? 'check-square' : 'close-square'" nzTheme="outline"></i>
            {{ preparer?.active ? 'Activé' : 'Désactivé' }}
          </span>
        </div>
      </div>

      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Nom</span>
          <span class="detail-content" nz-typography>{{ preparer?.name }}</span>
        </div>
      </div>
    </div>
  </div>

  <div nz-col nzSpan="24">
    <div class="detail">
      <span class="detail-title" nz-typography nzType="secondary">Structure</span>
      <span class="detail-content" nz-typography>
        <button
          nz-button
          nzSize="large"
          [routerLink]="['/', 'admin', 'structures']"
          [queryParams]="{ s: preparer?.structure?.name }">
          {{ preparer?.structure?.name }}<i nz-icon nzType="right-square"></i>
        </button>
      </span>
    </div>
  </div>

  <div nz-col nzSpan="24">
    <div class="detail">
      <span class="detail-title" nz-typography nzType="secondary">Téléphone</span>
      <span class="detail-content" nz-typography>{{ preparer ? (preparer.contacts[0].phoneNumber | phone | nullCoal) : '-' }}</span>
    </div>
  </div>

  <div nz-col nzSpan="24">
    <div class="detail">
      <span class="detail-title" nz-typography nzType="secondary">Mail</span>
      <span class="detail-content" nz-typography>{{ preparer ? (preparer.contacts[0].mail | nullCoal) : '-' }}</span>
    </div>
  </div>
</div>
