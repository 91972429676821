<form nz-form [formGroup]="form" nzLayout="vertical" (keydown.enter)="$event.preventDefault()">
  <div nz-row [nzGutter]="[24, 0]">
    <div nz-col nzSpan="24">
      <div nz-row [nzGutter]="24">
        <div nz-col nzSpan="6">
          <nz-form-item>
            <nz-form-label>
              <ng-container *ngIf="customer?.logo; else logoTitle">Nouveau Logo</ng-container>
              <ng-template #logoTitle>Logo</ng-template>
            </nz-form-label>
            <nz-form-control>
              <file-pond [options]="fileUploadOptions" (onpreparefile)="addLogo($event)" (onremovefile)="removeLogo()"></file-pond>
            </nz-form-control>
            <span class="hint">Taille max. {{ this.fileUploadOptions.maxFileSize }}</span>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="6" *ngIf="customer?.logo">
          <p>Logo actuel</p>
          <img class="current-logo" [src]="customer?.logo?.urlWithDomain" alt="Logo" />
        </div>
      </div>
    </div>

    <div nz-col nzSpan="24">
      <div nz-row [nzGutter]="[24, 0]">
        <div nz-col nzFlex="0 0 auto" *ngIf="this.customer">
          <nz-form-item>
            <nz-form-label nzFor="active">Actif</nz-form-label>
            <nz-form-control>
              <nz-radio-group formControlName="active" nzButtonStyle="solid" [nzSize]="'large'">
                <label nz-radio-button [nzValue]="true">Activé</label>
                <label nz-radio-button [nzValue]="false">Désactivé</label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzFlex="1">
          <nz-form-item>
            <nz-form-label nzFor="name">Nom</nz-form-label>
            <nz-form-control [nzErrorTip]="nameError">
              <input formControlName="name" nz-input [nzSize]="'large'" placeholder="Nom"/>
            </nz-form-control>
            <ng-template #nameError let-control>
              <ng-container *ngIf="control.hasError('required')">Le nom du client est requis</ng-container>
            </ng-template>
          </nz-form-item>
        </div>
      </div>
    </div>
    <div nz-col [nzXs]="24" *ngIf="googleMapsUtil.loaded | async">
      <nz-form-item>
        <nz-form-label nzFor="fullAddress">Adresse</nz-form-label>
        <nz-form-control>
          <input
            formControlName="fullAddress"
            nz-input
            nzSize="large"
            placeholder="Adresse"
            ngx-gp-autocomplete
            (onAddressChange)="onFullAddressChange($event)"
          />
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzXs]="24">
      <laveo-inputs-contacts [controlArray]="contactFormArray"></laveo-inputs-contacts>
    </div>

    <div nz-col [nzXs]="24" class="mt-16">
      <div class="detail">
        <span class="detail-content" nz-typography nzType="secondary">Sites clients</span>
        <span class="detail-title" nz-typography>Pour ajouter/enlever des sites client à ce client, utilisez l'administration des sites clients.</span>
        <span class="detail-title" *ngIf="(customer ? customer.customerSitesMetaData.totalResults : 0) > 0">
          <button nz-button nzSize="large" [routerLink]="['/', 'admin', 'customer-sites']" [queryParams]="{ s: customer?.name }">
            {{ customer ? customerSites(customer) : null }}<i nz-icon nzType="right-square"></i>
          </button>
        </span>
      </div>
    </div>
  </div>
</form>
