import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { NzIconService } from 'ng-zorro-antd/icon';
import { NzMessageRef, NzMessageService } from 'ng-zorro-antd/message';
import { Subscription } from 'rxjs';
import { isBrowserOnline, isBrowserValid } from './shared/services/helper.service';

@Component({
  selector: 'laveo-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private messageRef: NzMessageRef;
  private browserOnlineSubscription: Subscription;

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId,
    private readonly message: NzMessageService,
    private readonly iconService: NzIconService
  ) {}

  ngOnInit(): void {
    this.checkIfBrowserIsValid();
    this.checkIfBrowserIsOnline();
    this.setCustomIconsPath();
  }

  ngOnDestroy(): void {
    this.browserOnlineSubscription?.unsubscribe();
  }

  private checkIfBrowserIsOnline(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.browserOnlineSubscription = isBrowserOnline().subscribe((isOnline) => {
        if (isOnline && this.messageRef?.messageId) {
          this.message.remove(this.messageRef?.messageId);
        } else if (!isOnline) {
          this.messageRef = this.message.warning('Votre navigateur est hors-connexion', { nzDuration: 0 });
        }
      });
    }
  }

  private checkIfBrowserIsValid(): void {
    if (isPlatformBrowser(this.platformId) && !isBrowserValid()) {
      this.message.warning('Votre navigateur est incompatible avec ce site. Veuillez le mettre à jour', { nzDuration: 0 });
    }
  }

  private setCustomIconsPath(): void {
    this.iconService.changeAssetsSource('/assets/img/icons/');
  }
}
