import { Type } from 'typeserializer';
import { Entity } from './entity';
import { Vehicle } from './vehicle';

export class BatchProcessFailed extends Entity {
  message: string;
  object: Vehicle;
}

export class BatchProcessVehicle extends Entity {
  /** Liste des erreurs d'import */
  @Type([BatchProcessFailed])
  processFailed: BatchProcessFailed[];

  /** Liste des véhicules ajoutés */
  @Type([Vehicle])
  processOk: Vehicle[];
}
