import { Pipe, PipeTransform } from '@angular/core';
import { ServiceType } from '../models/entities/service';

@Pipe({
  name: 'serviceTypeName'
})
export class ServiceTypeNamePipe implements PipeTransform {
  transform(type?: ServiceType | ServiceType[] | string | string[]): string | undefined {
    if (!type) {
      return;
    }

    if (Array.isArray(type)) {
      if (type.length <= 0) {
        return;
      }
      return type.map(t => this.getTypeName(t)).join(', ');
    } else {
      return this.getTypeName(type);
    }
  }

  private getTypeName(type: ServiceType | string): string {
    switch (type) {
      case ServiceType.interior:
      case 'Interior': {
        return 'Intérieur';
      }
      case ServiceType.exterior:
      case 'Exterior': {
        return 'Extérieur';
      }
      case ServiceType.full:
      case 'Full': {
        return 'Complet';
      }
      case ServiceType.no: {
        return 'Pas de lavage';
      }
    }
    return type.toString();
  }
}
