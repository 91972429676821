<nz-card [nzBordered]="false" [nzTitle]="cardTitle">
    <ng-template #cardTitle>
        <laveo-card-header [title]="isAdmin ? 'Administration' : 'Mes informations'"></laveo-card-header>
    </ng-template>
    <div nz-row [nzGutter]="[16, 16]">
      <ng-container *ngIf="this.isLoading else content">
        <div nz-col [nzXs]="24" [nzMd]="12" [nzLg]="6" *ngFor="let i of [0, 1, 2, 3]">
          <div class="fake-right"></div>
        </div>
      </ng-container>
      <ng-template #content>
        <div nz-col [nzXs]="24" [nzMd]="12" [nzLg]="6" *ngFor="let right of rightsKeys">
          <div class="right" (click)="goToRight(right)">
            <i nz-icon [nzType]="right | rightIcon"></i>
            <p>{{ right | rightName:currentRole?.type }}</p>
          </div>
        </div>
      </ng-template>
    </div>
</nz-card>
