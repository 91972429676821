<div nz-row [nzGutter]="[48, 24]" *ngIf="service">
  <div nz-col [nzSpan]="16">
    <div nz-row [nzGutter]="[48, 24]">
      <div nz-col [nzSpan]="24">
        <div nz-row [nzGutter]="[48, 0]">
          <div nz-col [nzSpan]="12">
            <h5 class="service-title"><i nz-icon nzType="car" nzTheme="outline"></i>&nbsp;Véhicule</h5>
            <laveo-image-button
              [title]="service.vehicle.licensePlate | nullCoal"
              description="{{ service.vehicle.model.brand.name | nullCoal }} {{ service.vehicle.model.name | nullCoal }}"
              [avatar]="service.vehicle ? (service.vehicle.model.category | vehicleCategoryIcon) : undefined"
              [image]="service.vehicle.model.brand.logo"
              [routerLink]="userCanReadVehicle ? ['/', 'vehicles', service.vehicle.id ] : undefined"
              [enabled]="userCanReadVehicle"
              nz-tooltip
              [nzTooltipTitle]="service.vehicle.model.category | vehicleCategoryName">
            </laveo-image-button>
          </div>

          <div nz-col [nzSpan]="12">
              <h5 class="service-title"><i nz-icon nzType="pushpin" nzTheme="outline"></i>&nbsp;Client</h5>
              <laveo-image-button
                [title]="service.vehicle.customerSite.customer.name | nullCoal"
                [description]="service.vehicle.customerSite.name | nullCoal"
                [image]="service.vehicle.customerSite.logo?.urlWithDomain"
                [routerLink]="userCanReadCustomerSite ? ['/', 'admin', 'customer-sites', service.vehicle.customerSite.id] : undefined"
                [enabled]="userCanReadCustomerSite">
              </laveo-image-button>
          </div>
        </div>

        <div nz-row [nzGutter]="[48, 0]">
          <div nz-col>
            <h5 class="service-title"><i nz-icon nzType="shop" nzTheme="outline"></i>&nbsp;Structure</h5>
            <span class="button-history" *ngIf="showHistory && userCanReadPreparer && service.lastHistory && (service.lastHistory?.preparer?.id !== service.preparer?.id)">
              <laveo-image-button
                [title]="service.vehicle.customerSite.structure.name | nullCoal"
                [description]="service.lastHistory.preparer.name"
                [image]="service.vehicle.customerSite.structure.logo?.urlWithDomain"
                [enabled]="userCanReadStructure"
                *ngIf="service.vehicle?.customerSite; else noStructure">
              </laveo-image-button>
              <i nz-icon nzType="arrow-right" class="mx-8"></i>
            </span>
            <laveo-image-button
              [title]="service.vehicle.customerSite.structure.name | nullCoal"
              [description]="userCanReadPreparer && service.preparer ? service.preparer.name : undefined"
              [image]="service.vehicle.customerSite.structure.logo?.urlWithDomain"
              [routerLink]="userCanReadStructure ? ['/', 'admin', 'structures', service.vehicle.customerSite.structure.id] : undefined"
              [enabled]="userCanReadStructure"
              *ngIf="service.vehicle?.customerSite; else noStructure">
            </laveo-image-button>
            <ng-template #noStructure>Aucune structure associée</ng-template>
          </div>
        </div>
      </div>

      <div nz-col [nzSpan]="24">
        <h5 class="service-title mt-0"><i nz-icon nzType="unordered-list" nzTheme="outline"></i>&nbsp;Informations complémentaires</h5>

        <div nz-row [nzGutter]="[48, 0]">
          <div nz-col>
            <div class="detail">
              <span class="detail-title" nz-typography nzType="secondary">Type de lavage</span>
              <span *ngIf="showHistory && service.lastHistory && (service.lastHistory?.type !== service.type); else typeValue" class="detail-content" nz-typography>
                <div class="detail-history">
                  <span>{{ service.lastHistory.type | serviceTypeName | nullCoal }} &nbsp;</span>
                  <i nz-icon nzType="arrow-right"></i>
                </div>
                {{ service.type | serviceTypeName | nullCoal }}
              </span>
              <ng-template #typeValue>
                <span class="detail-content" nz-typography>{{ service.type | serviceTypeName | nullCoal }}</span>
              </ng-template>
            </div>
          </div>

          <div nz-col>
            <div class="detail">
              <span class="detail-title" nz-typography nzType="secondary">Finition</span>
              <span *ngIf="showHistory && service.lastHistory && (service.lastHistory?.finishing !== service.finishing); else finishingValue" class="detail-content" nz-typography>
                <div class="detail-history">
                  <span>{{ service.lastHistory.finishing | serviceFinishingName | nullCoal }} &nbsp;</span>
                  <i nz-icon nzType="arrow-right"></i>
                </div>
                {{ service.finishing | serviceFinishingName | nullCoal }}
              </span>
              <ng-template #finishingValue>
                <span class="detail-content" nz-typography>{{ service.finishing | serviceFinishingName | nullCoal }}</span>
              </ng-template>
            </div>
          </div>
        </div>

        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Options de lavage</span>
          <span *ngIf="showHistory && service.lastHistory && (service.lastHistory?.options !== service.options); else optionsValue" class="detail-content" nz-typography>
            <div class="detail-history">
              <span>{{ service.lastHistory.options | nullCoal }} &nbsp;</span>
              <i nz-icon nzType="arrow-right"></i>
            </div>
            {{ service.options | nullCoal }}
          </span>
          <ng-template #optionsValue>
            <span class="detail-content" nz-typography>{{ service.options | nullCoal }}</span>
          </ng-template>
        </div>

        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Commentaire</span>
          <span class="detail-content" nz-typography>{{ service.comment | nullCoal }}</span>
        </div>
      </div>

      <div nz-col [nzSpan]="24">
        <laveo-list-contacts [contacts]="service.contacts"></laveo-list-contacts>
      </div>
    </div>
  </div>

  <div nz-col [nzSpan]="8" class="sidebar">
    <div class="status">
      <div nz-row [nzGutter]="[48, 0]">
        <div nz-col [nzSpan]="12">
          <div>
            <h5 class="service-title"><i nz-icon nzType="info-circle" nzTheme="outline"></i>&nbsp;Statut</h5>
            <span *ngIf="showHistory && service.lastHistory?.status && (service.lastHistory?.status !== service.status); else statusValue" class="detail-content">
              <nz-tag nzColor="{{ service.lastHistory?.status | serviceStatusColor }}">
                {{ service.lastHistory?.status | serviceStatusName }}
                <i nz-icon
                  nzType="lock"
                  class="pl-4"
                  *ngIf="service.lastHistory?.status === 'CANCELED_BY_STRUCTURE' || service.lastHistory?.status === 'CANCELED_BY_CUSTOMER_SITE' || service.lastHistory?.status === 'PERFORMED'"></i>
              </nz-tag>
              <i nz-icon nzType="arrow-right"></i>&nbsp;
              <ng-container *ngTemplateOutlet="statusValue"></ng-container>
            </span>
            <ng-template #statusValue>
              <nz-tag nzColor="{{ service.status | serviceStatusColor }}">
                {{ service.status | serviceStatusName }}
                <i nz-icon
                  nzType="lock"
                  class="pl-4"
                  *ngIf="service.status === 'CANCELED_BY_STRUCTURE' || service.status === 'CANCELED_BY_CUSTOMER_SITE' || service.status === 'PERFORMED'"></i>
              </nz-tag>
            </ng-template>

            <button
              *ngIf="(service.status === 'PERFORMED' || service.status === 'VEHICLE_NOT_PRESENT') && userCanSeeInvoiced && contractIsTripartite"
              nz-button
              nz-tooltip
              nzSize="default"
              [nzTooltipTitle]="service.invoiced ? 'Marquer comme non facturé' : 'Marquer comme facturé'"
              class="mt-8"
              [style.color]="service.invoiced ? '#52c41a' : '#fa8c16'"
              (click)="toggleInvoiced()"
              [disabled]="!userIsAdmin">
              {{ service.invoiced ? 'Facturé' : 'Non facturé' }}
              <i nz-icon nzType="euro" [style.color]="service.invoiced ? '#52c41a' : '#fa8c16'"></i>
            </button>
          </div>
        </div>

        <div nz-col [nzSpan]="12">
          <div>
            <h5 class="service-title"><i nz-icon nzType="arrow-right" nzTheme="outline"></i>&nbsp;Workflow</h5>
            <span class="detail-content" nz-typography nzType="secondary">{{ service.workflowType | customerSiteWorkflowTypeName }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="dates" nz-typography nzType="secondary">
      <h5 class="service-title"><i nz-icon nzType="calendar" nzTheme="outline"></i>&nbsp;Dates</h5>
      <div class="date-container">
        <span class="date-title">Demandée</span>
        <span class="date-content" *ngIf="showHistory && service.lastHistory?.dateAsked && (service.lastHistory?.dateAsked?.getTime() !== service.dateAsked?.getTime()); else dateAskedValue">
          <span class="detail-history">
            {{ service.lastHistory?.dateAsked | dfnsFormat:(service.lastHistory?.dateAsked?.getHours() === 0 ? 'dd/MM/yy' : 'dd/MM/yy HH\'h\'mm') }}
            <i nz-icon nzType="arrow-right"></i>
          </span>
          {{ service.dateAsked | dfnsFormat:(service.dateAsked?.getHours() === 0 ? 'dd/MM/yy' : 'dd/MM/yy HH\'h\'mm') }}
        </span>
        <ng-template #dateAskedValue>
          <span class="date-content" *ngIf="service.dateAsked; else emptyDate">
            {{ service.dateAsked | dfnsFormat:(service.dateAsked.getHours() === 0 ? 'eeee dd MMM yyy' : 'eeee dd MMM yyy \'à\' HH\'h\'mm') }}
          </span>
        </ng-template>
      </div>
      <div class="date-container">
        <span class="date-title">Proposée</span>
        <span class="date-content" *ngIf="showHistory && service.lastHistory?.dateProposed && (service.lastHistory?.dateProposed?.getTime() !== service.dateProposed?.getTime()); else dateProposedValue">
          <span class="detail-history">
            {{ service.lastHistory?.dateProposed | dfnsFormat:(service.lastHistory?.dateProposed?.getHours() === 0 ? 'dd/MM/yy' : 'dd/MM/yy HH\'h\'mm') }}
            <i nz-icon nzType="arrow-right"></i>
          </span>
          {{ service.dateProposed | dfnsFormat:(service.dateProposed?.getHours() === 0 ? 'dd/MM/yy' : 'dd/MM/yy HH\'h\'mm') }}
        </span>
        <ng-template #dateProposedValue>
          <span class="date-content" *ngIf="service.dateProposed; else emptyDate">
            {{ service.dateProposed | dfnsFormat:(service.dateProposed.getHours() === 0 ? 'eeee dd MMM yyy' : 'eeee dd MMM yyy \'à\' HH\'h\'mm') }}
          </span>
        </ng-template>
      </div>
      <div class="date-container">
        <span class="date-title">Planifiée</span>
        <span class="date-content" *ngIf="showHistory && service.lastHistory?.datePlanned && (service.lastHistory?.datePlanned?.getTime() !== service.datePlanned?.getTime()); else datePlannedValue">
          <span class="detail-history">
            {{ service.lastHistory?.datePlanned | dfnsFormat:(service.lastHistory?.datePlanned?.getHours() === 0 ? 'dd/MM/yy' : 'dd/MM/yy HH\'h\'mm') }}
            <i nz-icon nzType="arrow-right"></i>
          </span>
          {{ service.datePlanned | dfnsFormat:(service.datePlanned?.getHours() === 0 ? 'dd/MM/yy' : 'dd/MM/yy HH\'h\'mm') }}
        </span>
        <ng-template #datePlannedValue>
          <span class="date-content" *ngIf="service.datePlanned; else emptyDate">
            {{ service.datePlanned | dfnsFormat:(service.datePlanned.getHours() === 0 ? 'eeee dd MMM yyy' : 'eeee dd MMM yyy \'à\' HH\'h\'mm') }}
          </span>
        </ng-template>
      </div>
      <div class="date-container">
        <span class="date-title">Réalisée</span>
        <span class="date-content" *ngIf="showHistory && service.lastHistory?.datePerformed && (service.lastHistory?.datePerformed?.getTime() !== service.datePerformed?.getTime()); else datePerformedValue">
          <span class="detail-history">
            {{ service.lastHistory?.datePerformed | dfnsFormat:(service.lastHistory?.datePerformed?.getHours() === 0 ? 'dd/MM/yy' : 'dd/MM/yy HH\'h\'mm') }}
            <i nz-icon nzType="arrow-right"></i>
          </span>
          {{ service.datePerformed | dfnsFormat:(service.datePerformed?.getHours() === 0 ? 'dd/MM/yy' : 'dd/MM/yy HH\'h\'mm') }}
        </span>
        <ng-template #datePerformedValue>
          <span class="date-content" *ngIf="service.datePerformed; else emptyDate">
            {{ service.datePerformed | dfnsFormat:(service.datePerformed.getHours() === 0 ? 'eeee dd MMM yyy' : 'eeee dd MMM yyy \'à\' HH\'h\'mm') }}
          </span>
        </ng-template>
      </div>
      <div class="date-container" *ngIf="service.dateCanceled">
        <span class="date-title">Annulée</span>
        <span class="date-content" *ngIf="showHistory && service.lastHistory?.dateCanceled && (service.lastHistory?.dateCanceled?.getTime() !== service.dateCanceled?.getTime()); else dateCanceledValue">
          <span class="detail-history">
            {{ service.lastHistory?.dateCanceled | dfnsFormat:(service.lastHistory?.dateCanceled?.getHours() === 0 ? 'dd/MM/yy' : 'dd/MM/yy HH\'h\'mm') }}
            <i nz-icon nzType="arrow-right"></i>
          </span>
          {{ service.dateCanceled | dfnsFormat:(service.dateCanceled.getHours() === 0 ? 'dd/MM/yy' : 'dd/MM/yy HH\'h\'mm') }}
        </span>
        <ng-template #dateCanceledValue>
          <span class="date-content" *ngIf="service.dateCanceled; else emptyDate">
            {{ service.dateCanceled | dfnsFormat:(service.dateCanceled.getHours() === 0 ? 'eeee dd MMM yyy' : 'eeee dd MMM yyy \'à\' HH\'h\'mm') }}
          </span>
        </ng-template>
      </div>

      <ng-template #emptyDate>
        <span class="date-content">-</span>
      </ng-template>
    </div>

    <div class="vehicleState">
      <h5 class="service-title"><i nz-icon nzType="file-done" nzTheme="outline"></i>&nbsp;État des lieux</h5>
      <div *ngIf="service.vehicleStatePdf">
        <div nz-row nzGutter="8">
          <div nz-col nzFlex="200px">
            <a
              nz-button
              nzSize="large"
              nzType="primary"
              [href]="service.vehicleStatePdf.urlWithDomain"
              target="_blank">
              <i nz-icon nzType="eye" nzTheme="outline"></i>
              Voir l'état des lieux
            </a>
          </div>
          <div nz-col nzFlex="auto">
            <nz-input-group nzSearch [nzAddOnAfter]="copyIconButton" nzSize="large">
              <input type="text" nz-input placeholder="Lien de l'état des lieux" [value]="service.vehicleStatePdfShortLink" (focus)="selectVehicleStatePdf($event)" />
            </nz-input-group>
            <ng-template #copyIconButton>
              <button nz-button nzType="primary" nzSize="large" (click)="copyVehicleStatePdf()" nz-tooltip nzTooltipTitle="Copier le lien">
                <i nz-icon nzType="copy"></i>
              </button>
              <button nz-button nzDanger nzSize="large" (click)="removeVehicleStatePdf()" nz-tooltip nzTooltipTitle="Supprimer le rapport" *ngIf="userIsAdmin">
                <i nz-icon nzType="delete"></i>
              </button>
            </ng-template>
          </div>
        </div>
      </div>
      <a
        nz-button
        nzSize="large"
        nzType="primary"
        class="mr-8"
        *ngIf="!service.vehicleStatePdf"
        target="_blank"
        disabled>
        <i nz-icon nzType="eye-invisible" nzTheme="outline"></i>
        Aucun état des lieux enregistré
      </a>
      <div class="mt-8" *ngIf="hasActionUploadVehicleState">
        <p>
          <ng-container *ngIf="service.vehicleStatePdf; else addEtat">Remplacer l'état des lieux</ng-container>
          <ng-template #addEtat>Ajouter un état des lieux</ng-template>
        </p>
        <file-pond [options]="fileUploadOptions"></file-pond>
        <span class="hint">PDF Uniquement. Taille max. {{ this.fileUploadOptions.maxFileSize }}
          <a *ngIf="!service?.vehicleStatePdf" target="_blank" [href]="apiUrl + '/service/' + service.id + '.pdf'"> | Voir la génération dynamique</a>
        </span>
      </div>
    </div>

    <div class="actions" *ngIf="showActions">
      <laveo-service-workflow [services]="[service]" [wrapper]="wrapper"></laveo-service-workflow>
    </div>

    <div class="history" *ngIf="service.status === 'PERFORMED'">
      <h5 class="service-title"><i nz-icon nzType="history" nzTheme="outline"></i>&nbsp;Historique</h5>
      <div *ngIf="service.lastHistory; else noLastHistory">
        <p nz-typography nzType="secondary">Dernière modif. {{ service.lastHistory.createdDate | dfnsFormat:(service.lastHistory.createdDate.getHours() === 0 ? 'eeee dd MMM yyy' : 'eeee dd MMM yyy \'à\' HH\'h\'mm') }}</p>
        <nz-radio-group nzButtonStyle="solid" [(ngModel)]="showHistory" nzSize="large">
          <label nz-radio-button [nzValue]="value === 'Afficher'" *ngFor="let value of ['Afficher', 'Masquer']">{{ value }}</label>
        </nz-radio-group>
      </div>
      <ng-template #noLastHistory>
        <p nz-typography nzType="secondary">Aucune modification enregistrée</p>
      </ng-template>
    </div>
  </div>
</div>
