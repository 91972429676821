<div nz-row [nzGutter]="[48, 24]">
  <div nz-col nzSpan="24">
    <div nz-row [nzGutter]="[48, 0]">
      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Actif</span>
          <span class="detail-content" nz-typography>
            <i nz-icon [nzType]="vehicle?.active ? 'check-square' : 'close-square'" nzTheme="outline"></i>
            {{ vehicle?.active ? 'Activé' : 'Désactivé' }}
          </span>
        </div>
      </div>

      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Immatriculation</span>
          <span class="detail-content" nz-typography>{{ vehicle?.licensePlate | nullCoal }}</span>
        </div>
      </div>
    </div>
  </div>

  <div nz-col nzSpan="24">
    <div class="detail">
      <span class="detail-title" nz-typography nzType="secondary">Site client</span>
      <span class="detail-content" nz-typography>{{ vehicle?.customerSite?.name | nullCoal }}</span>
    </div>
  </div>

  <div nz-col nzSpan="24">
    <div nz-row [nzGutter]="[48, 0]">
      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Marque</span>
          <span class="detail-content" nz-typography>{{ vehicle?.model?.brand?.name | nullCoal }}</span>
        </div>
      </div>

      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Marque/Modèle</span>
          <span class="detail-content" nz-typography>{{ vehicle?.model?.name | nullCoal }}</span>
        </div>
      </div>

      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Catégorie</span>
          <span class="detail-content"
            nz-typography>{{ (vehicle?.model?.category | vehicleCategoryName) | nullCoal }}</span>
        </div>
      </div>
    </div>
  </div>

  <div nz-col nzSpan="24">
    <div nz-row [nzGutter]="[48, 0]">
      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Fréquence</span>
          <span class="detail-content" nz-typography>{{ vehicle?.frequency | vehicleFrequencyName | nullCoal }}</span>
        </div>
      </div>

      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Attribution</span>
          <span class="detail-content"
            nz-typography>{{ vehicle?.attribution | vehicleAttributionName | nullCoal }}</span>
        </div>
      </div>
    </div>
  </div>

  <div nz-col nzSpan="24">
    <div nz-row [nzGutter]="[48, 0]">
      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Groupe</span>
          <span class="detail-content" nz-typography>{{ vehicle?.group | nullCoal }}</span>
        </div>
      </div>

      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Collaborateur</span>
          <span class="detail-content" nz-typography>{{ vehicle?.collaborator | nullCoal }}</span>
        </div>
      </div>
    </div>
  </div>

  <div nz-col nzSpan="24">
    <div class="detail">
      <span class="detail-title" nz-typography nzType="secondary">Commentaire</span>
      <span class="detail-content" nz-typography>{{ vehicle?.comment | nullCoal }}</span>
    </div>
  </div>
</div>