import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Route, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { EmailValidators } from 'ngx-validators';
import { Subscription } from 'rxjs';
import { ROUTES_CONFIG } from 'src/app/configs/tokens.config';
import { emailOptionsConfig } from 'src/app/configs/validators.config';
import { AuthService } from 'src/app/shared/services/auth.service';
import { getEnvironmentName } from 'src/app/shared/services/helper.service';
import { GoogleMapsUtil } from 'src/app/shared/utils/google-maps.util';
import { ApiErrorMessageUtil } from '../../shared/utils/api-error-message.util';

@Component({
  selector: 'laveo-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  passwordVisible = false;
  environmentString?: string;
  isLoading = false;
  emailErrors: ValidationErrors | null;

  mapsOptions: google.maps.MapOptions;

  // FIXME: À corriger avec la nouvelle version de @angular/google-maps
  // eslint-disable-next-line deprecation/deprecation
  mapsMarkerOptions: google.maps.MarkerOptions = { draggable: false, icon: '/assets/img/marker.png' };
  mapsMarkerPositions: google.maps.LatLngLiteral[] = [];

  private subscriptions: Subscription[] = [];

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly message: NzMessageService,
    private readonly httpClient: HttpClient,
    private readonly titleService: Title,
    public readonly googleMapsUtil: GoogleMapsUtil,
    @Inject(ROUTES_CONFIG) public readonly routes: Record<string, Route>
  ) {}

  get usernameValidationStatus(): string | AbstractControl | null {
    if (this.emailErrors?.suggestion) {
      return 'warning';
    }

    return this.form.get('username');
  }

  ngOnInit(): void {
    this.googleMapsUtil.load();
    this.setGoogleMapsOptions();
    this.setForm();
    this.setEnv();
    this.setTitle();
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  login(): void {
    for (const key of Object.keys(this.form.controls)) {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
    }

    if (this.form.invalid) {
      return;
    }

    this.isLoading = true;
    const username = (this.form.get('username')?.value as string)?.toLowerCase()?.trim();
    const password = (this.form.get('password')?.value as string);

    const loginSubscription = this.authService.login(username, password).subscribe({
      next: () => {
        this.isLoading = false;
        void this.router.navigate([this.routes.main.path]);
      },
      error: (error) => {
        this.isLoading = false;
        console.error(error);
        this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
      }
    });

    this.subscriptions.push(loginSubscription);
  }

  private setForm(): void {
    this.form = this.formBuilder.group({
      username: this.formBuilder.control(null, [Validators.required, Validators.email]),
      password: this.formBuilder.control(null, [Validators.required])
    });

    const usernameSubscription = this.form.get('username')?.valueChanges.subscribe(() => {
      const validate = EmailValidators.suggest(emailOptionsConfig);
      const username = this.form.get('username');
      if (username) {
        this.emailErrors = validate(username);
      }
    });

    if (usernameSubscription) {
      this.subscriptions.push(usernameSubscription);
    }
  }

  private setEnv(): void {
    this.environmentString = getEnvironmentName();
  }

  private setGoogleMapsOptions(): void {
    this.mapsOptions = {
      center: { lat: 46.227_638, lng: 2.213_749 },
      zoom: 6,
      disableDefaultUI: true,
      styles: GoogleMapsUtil.styles
    };

    this.httpClient.get('/assets/files/maps-markers.json').subscribe({
      next: (response: { pos: number[] }[]) => {
        for (const marker of response) {
          this.mapsMarkerPositions.push({ lat: marker.pos[0], lng: marker.pos[1] });
        }
      },
      error: error => {
        console.error(error);
      }
    });
  }

  private setTitle() {
    this.titleService.setTitle('Lavéo - Connexion');
  }
}
