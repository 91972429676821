import { Injectable, Inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { ROUTES_CONFIG } from '../../configs/tokens.config';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ApiErrorMessageUtil } from '../utils/api-error-message.util';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })

export class AuthGuard  {
  constructor(
    protected readonly  router: Router,
    private readonly authService: AuthService,
    private readonly message: NzMessageService,
    @Inject(ROUTES_CONFIG) private readonly routes: Record<string, Route>
  ) {}

  canActivate(next: ActivatedRouteSnapshot, _: RouterStateSnapshot): boolean | Observable<boolean> {
    const isAuthenticated = this.authService.isAuthenticated;
    const unauthenticatedRoutes = new Set([
      this.routes.login.path,
      this.routes.forgotPassword.path,
      this.routes.newAccount.path,
      this.routes.confirmMail.path
    ]);

    if (!isAuthenticated) {
      if (unauthenticatedRoutes.has(next.routeConfig?.path) || next.routeConfig?.path === this.routes.shortlink.path) {
        return true;
      } else {
        void this.router.navigate([this.routes.login.path]);
        return false;
      }
    }

    return new Observable<boolean>((observer) => {
      this.authService.checkToken().subscribe({
        next: () => {
          if (unauthenticatedRoutes.has(next.routeConfig?.path)) {
            void this.router.navigate([this.routes.main.path]);
            observer.next(false);
          } else {
            observer.next(true);
          }
        },
        error: (error) => {
          if (error instanceof HttpErrorResponse && (error.status === 403 || error.status === 401)) {
            if (isAuthenticated) {
              this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
              this.authService.logout();
            }

            if (next.routeConfig?.path === this.routes.login.path) {
              observer.next(true);
            } else {
              void this.router.navigate([this.routes.login.path]);
              observer.next(false);
            }
          }

          observer.next(false);
        }
      });
    });
  }
}
