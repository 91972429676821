import { Type } from 'typeserializer';
import { Customer } from '../../entities/customer';
import { CustomerSite } from '../../entities/customer-site';
import { Structure } from '../../entities/structure';

export class ReportingServiceStatus {
  @Type(Customer)
  customer?: Customer;
  @Type(CustomerSite)
  customerSite?: CustomerSite;
  @Type(Structure)
  structure?: Structure;
  actor?: Customer | CustomerSite | Structure;
  total: number;
  totalAsked: number;
  totalProposed: number;
  totalPlanned: number;
  totalPerformed: number;
  totalVehicleNotPresent: number;
  totalCanceledByCustomerSite: number;
  totalCanceledByStructure: number;
}
