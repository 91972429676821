<laveo-entity-wrapper
  [title]="title"
  [description]="description"
  [entityService]="userService"
  [getFunction]="userService.user"
  [addFunction]="userService.addUser"
  [updateFunction]="userService.updateUser"
  [wrappedComponent]="this"
  [getFormFunction]="form"
  [editEnabled]="editEnabled"
  [deleteEnabled]="deleteEnabled">
  <laveo-user-detail *ngIf="isReady" detail [user]="user"></laveo-user-detail>
  <laveo-user-form *ngIf="isReady" form [user]="user"></laveo-user-form>
</laveo-entity-wrapper>
