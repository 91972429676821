import { Pipe, PipeTransform } from '@angular/core';
import { VehicleCheckpointFieldType } from '../models/entities/vehicle-checkpoint-field-type';

@Pipe({
  name: 'vehicleCheckpointFieldTypeName'
})
export class VehicleCheckpointFieldTypeNamePipe implements PipeTransform {
  transform(type?: VehicleCheckpointFieldType): string | undefined {
    if (!type) {
      return;
    }

    switch (type) {
      case VehicleCheckpointFieldType.fileType: {
        return 'Fichier';
      }
      case VehicleCheckpointFieldType.selectType: {
        return 'Liste';
      }
      case VehicleCheckpointFieldType.textType: {
        return 'Texte';
      }
      case VehicleCheckpointFieldType.numberType: {
        return 'Numérique';
      }
      default: {
        return;
      }
    }
  }
}
