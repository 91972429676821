<div class="tab-content">
  <form nz-form [formGroup]="brandsSearchForm">
    <div nz-row [nzGutter]="8" [nzAlign]="'middle'">
      <div nz-col *ngIf="brandsChecked.length > 0">
        <button nz-button nzType="default" nzSize="large" nz-dropdown [nzDropdownMenu]="brandsActionMenu" [disabled]="brandsActionIsLoading">
          <i *ngIf="brandsActionIsLoading" nz-icon nzType="loading" class="loading-icon"></i>
          {{ brandsChecked.length }} {{ brandsChecked.length > 1 ? 'modèles séléctionnés' : 'modèle séléctionné' }}
          <i nz-icon nzType="down"></i>
        </button>
        <nz-dropdown-menu #brandsActionMenu="nzDropdownMenu">
          <ul nz-menu nzSelectable>
            <li nz-menu-item nzDanger (click)="deleteCheckedVehicleModel()">
              <i nz-icon nzType="delete"></i>
              Désactiver
            </li>
            <li nz-menu-divider></li>
            <li nz-menu-item (click)="brandsChecked.splice(0, brandsChecked.length)">
              <i nz-icon nzType="minus-square"></i>
              Tout déséléctionner
            </li>
          </ul>
        </nz-dropdown-menu>
      </div>
      <div nz-col nzFlex="1 1 auto">
        <nz-input-group [nzPrefix]="prefixIconSearch" nzSize="large">
          <ng-template #prefixIconSearch>
            <i nz-icon nzType="search"></i>
          </ng-template>
          <input type="text" nz-input placeholder="Rechercher un modèle de véhicule" formControlName="search" />
        </nz-input-group>
      </div>
      <div nz-col>
        <button type="button" nz-button nzType="primary" nzSize="large" (click)="addVehicleBrand()">
          <i nz-icon nzType="plus"></i>
          Ajouter une marque
        </button>
      </div>
    </div>
  </form>
  <nz-table
    nzShowSizeChanger
    [nzData]="brandsData ? brandsData.data : []"
    [nzFrontPagination]="false"
    [nzLoading]="brandsIsLoading"
    [nzTotal]="brandsData ? brandsData.metadata.totalResults : 0"
    [nzPageSize]="brandsLimit"
    [nzPageIndex]="brandsData ? brandsData.metadata.currentPage : 0"
    [nzNoResult]="brandsEmptyTemplate"
    (nzQueryParams)="setBrandsPage($event)"
    *ngIf="!brandsError; else brandsErrorTemplate">
    <thead>
    <tr>
      <th nzWidth="60px"></th>
      <th nzColumnKey="active" nzWidth="60px" [nzSortFn]="true">Actif</th>
      <th nzColumnKey="name" [nzSortFn]="true">Modèle</th>
      <th nzColumnKey="actions" class="actions">Actions</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let brand of brandsData?.data">
      <tr>
        <td [nzChecked]="brandsChecked.includes(brand.id)" (nzCheckedChange)="setBrandsChecked($event, brand.id)"></td>
        <td><label nz-checkbox nzDisabled [(ngModel)]="brand.active"></label></td>
        <td>{{ brand.name }}</td>
        <td class="actions">
          <button nz-button class="mr-8" (click)="editVehicleBrand(brand)">Modifier</button>
          <button nz-button nzType="primary" (click)="deleteVehicleBrand(brand)" [disabled]="!brand.active">
            <i nz-icon nzType="delete"></i>
          </button>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </nz-table>
  <ng-template #brandsErrorTemplate>
    <nz-result nzTitle="Erreur de chargement" nzStatus="error"
               nzSubTitle="Une erreur est survenue lors du chargement de la liste des modèles de véhicule">
      <div nz-result-extra>
        <button nz-button nzType="primary" (click)="loadBrandsData()">
          Réessayer
          <i nz-icon nzType="reload"></i>
        </button>
      </div>
    </nz-result>
  </ng-template>
  <ng-template #brandsEmptyTemplate>
    <nz-empty nzNotFoundImage="simple"
              nzNotFoundContent="Aucune marque de véhicule trouvée. Commencez par en ajouter un en utilisant le bouton ci-dessous."
              [nzNotFoundFooter]="brandsEmptyFooter">
      <ng-template #brandsEmptyFooter>
        <button nz-button nzType="primary" (click)="addVehicleBrand()">
          <i nz-icon nzType="plus"></i>
          Ajouter une marque de véhicule
        </button>
      </ng-template>
    </nz-empty>
  </ng-template>
</div>
