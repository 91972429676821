import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subscription } from 'rxjs';
import { PagedResponse } from '../../models/api/shared/paged/paged-response.interface';
import { PagedRequest } from '../../models/api/shared/paged/paged-request';
import { format } from 'date-fns';
import { ServicesFiltersRequest } from '../../models/api/services/services-filters-requests.interface';
import { Service } from '../../models/entities/service';
import { ServicesService } from '../../services/api/services.service';
import { ServicesStats } from '../../models/interfaces/services/services-stats.interface';
import { UserService } from '../../services/api/user.service';
import { UserRight } from '../../models/entities/user-right';
import { UserRoleType } from '../../models/entities/user-role';
import { fr } from 'date-fns/locale';
import { ApiErrorMessageUtil } from '../../utils/api-error-message.util';

@Component({
  selector: 'laveo-list-services',
  templateUrl: './list-services.component.html',
  styleUrls: ['./list-services.component.scss']
})
export class ListServicesComponent implements OnInit, OnDestroy, OnChanges {
  @Input() filters: PagedRequest<ServicesFiltersRequest>;
  @Input() checked: Service[] = [];
  @Output() stats = new EventEmitter<ServicesStats>();
  @Output() pageChanged = new EventEmitter<number>();

  pageSize = 10;
  isLoading = true;
  error?: Error;
  prestations: PagedResponse<GroupedPrestation>;
  prestationsSubscription?: Subscription;
  subscriptions: Subscription[] = [];

  userCanAdd = false;
  userCanEdit = false;

  isCustomerSite = false;
  isPreparer = false;

  constructor(
    private readonly userService: UserService,
    private readonly servicesService: ServicesService,
    private readonly message: NzMessageService
  ) {}

  ngOnInit(): void {
    this.loadRole();
    this.loadData();
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.filters?.isFirstChange()) {
      this.loadData();
    }
  }

  loadData(): void {
    this.prestationsSubscription?.unsubscribe();
    this.prestationsSubscription = undefined;

    const parameters = new PagedRequest<ServicesFiltersRequest>({
      page: this.filters.page,
      limit: this.pageSize,
      filters: this.filters?.filters,
      sortProperty: this.filters?.sortProperty,
      sortType: this.filters?.sortType,
      search: this.filters?.search
    });

    this.isLoading = true;
    this.error = undefined;

    this.prestationsSubscription = this.servicesService.services(parameters).subscribe({
      next: result => {
        const data: GroupedPrestation[] = [];
        result.data?.data.forEach(prestation => {
          const title = format(prestation.date, 'EEEE dd/MM/yyyy', { locale: fr });
          const index = data.findIndex(d => d.title === title);
          if (index === -1) {
            data.push({
              date: prestation.date,
              title,
              prestations: [prestation]
            });
          } else {
            data[index].prestations.push(prestation);
          }
        });

        this.prestations = {
          metadata: result.data?.metadata,
          data
        };

        this.stats.emit({
          total: result.data?.metadata?.totalResults,
          planned: 0 + (result.data?.extraMetadata?.totalPlanned ?? 0),
          inProgress: 0 +
            (result.data?.extraMetadata?.totalAsked ?? 0) +
            (result.data?.extraMetadata?.totalProposed ?? 0),
          done: 0 + (result.data?.extraMetadata?.totalPerformed ?? 0),
        });

        this.isLoading = result.loading;
        this.prestationsSubscription?.unsubscribe();
        this.prestationsSubscription = undefined;
      },
      error: error => {
        console.error(error);
        this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
        this.error = error;
        this.isLoading = false;
        this.prestationsSubscription?.unsubscribe();
        this.prestationsSubscription = undefined;
      }
    });
  }

  setPage(page: number): void {
    this.filters.page = page;
    this.pageChanged.emit(page);
    this.loadData();
    this.scrollUp();
  }

  private scrollUp(): void {
    const list = document.querySelector('nz-list');
    if (list) {
      list.scrollTop = 0;
    }
  }

  private loadRole(): void {
    const roleSubscription = this.userService.currentRole.subscribe(role => {
      this.userCanAdd = role.rights.services.includes(UserRight.create);
      this.userCanEdit = role.rights.services.includes(UserRight.update);
      this.isPreparer = role.type === UserRoleType.preparer;
      this.isCustomerSite = role.type === UserRoleType.customerSite;
    });

    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }
  }
}

interface GroupedPrestation {
  date: Date;
  title: string;
  prestations: Service[];
}
