import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PagedRequest } from '../../models/api/shared/paged/paged-request';
import { PagedResponse } from '../../models/api/shared/paged/paged-response.interface';
import { TypeSerializerUtils } from '../../utils/type-serializer.util';
import { ReportingVehicle } from '../../models/api/reportings/reporting-vehicle';
import { ReportingVehicleFiltersRequest } from '../../models/api/services/reporting-vehicle-filters-requests.interface';
import { UserRoleType } from '../../models/entities/user-role';
import { ReportingServiceStatusFiltersRequest } from '../../models/api/services/reporting-service-status-filters-requests.interface';
import { ReportingServiceStatus } from '../../models/api/reportings/reporting-service-status';
import { ReportingServiceSpecificationFiltersRequest } from '../../models/api/services/reporting-service-specification-filters-requests.interface';
import { ReportingServiceSpecification } from '../../models/api/reportings/reporting-service-specification';
import { ReportingVehicleMonitoringFiltersRequest } from '../../models/api/services/reporting-vehicle-monitoring-filters-requests.interface';
import { ReportingVehicleMonitoring } from '../../models/api/reportings/reporting-vehicle-monitoring';
import { ReportingVehicleFleet } from '../../models/api/reportings/reporting-vehicle-fleet';
import { ReportingVehicleFleetFiltersRequest } from '../../models/api/services/reporting-vehicle-fleet-filters-requests.interface';
import { ReportingRseFiltersRequest } from '../../models/api/services/reporting-rse-filters-requests.interface copy';
import { ReportingRse } from '../../models/api/reportings/reporting-rse';
import { Customer } from '../../models/entities/customer';
import { CustomerSite } from '../../models/entities/customer-site';
import { Structure } from '../../models/entities/structure';
import { Service } from '../../models/entities/service';

@Injectable({
  providedIn: 'root'
})
export class ReportingsService {
  constructor(
    private readonly apollo: Apollo
  ) {}

  reportingVehicles(parameters: PagedRequest<ReportingVehicleFiltersRequest>): Observable<ApolloQueryResult<PagedResponse<ReportingVehicle>>> {
    const query = gql(`
      query reportingVehicleStats($offset: Int, $limit: Int, $type: EGroupByActor) {
        reportings {
          vehicleStats(offset: $offset, limit: $limit, type: $type) {
            data {
              nb
              nbActive
              nbCatA
              nbCatB
              nbCatC
              nbCatD
              nbCatVU
              nbCatPorteur
              nbCatPL
              nbCatHG
              nbCatMoto
              nbOnDemand
              nbMutualized
              nbAffected
              actor {
                ${this.getActorSubquery(parameters)}
              }
            }
            metadata {
              currentPage
              currentResult
              totalResults
              totalPages
              hasMore
            }
            extraMetadata {
              total {
                nb
              }
            }
          }
        }
      }
    `);

    return this.apollo.query<{ reportings: { vehicleStats: PagedResponse<ReportingVehicle> } }>({
      query,
      variables: {
        offset: parameters.offset,
        limit: parameters.limit,
        type: parameters.filters?.type
      }
    }).pipe(map(result => {
      const data: ReportingVehicle[] = TypeSerializerUtils.deserializeArr(result.data.reportings.vehicleStats.data, ReportingVehicle);
      data.forEach(r => {
        switch (r.actor?.__typename) {
          case 'Customer': {
            r.customer = TypeSerializerUtils.deserialize(r.actor, Customer);
            break;
          }
          case 'CustomerSite': {
            r.customerSite = TypeSerializerUtils.deserialize(r.actor, CustomerSite);
            r.customer = TypeSerializerUtils.deserialize((r.actor as any).customer, Customer);
            break;
          }
          case 'Structure': {
            r.structure = TypeSerializerUtils.deserialize(r.actor, Structure);
            break;
          }
        }
      });
      const response: PagedResponse<ReportingVehicle> = {
        data,
        metadata: result.data.reportings.vehicleStats.metadata,
        extraMetadata: result.data.reportings.vehicleStats.extraMetadata
      };
      return {
        data: response,
        error: result.error,
        errors: result.errors,
        partial: result.partial,
        loading: result.loading,
        networkStatus: result.networkStatus
      };
    }));
  }

  reportingServiceStatus(parameters: PagedRequest<ReportingServiceStatusFiltersRequest>): Observable<ApolloQueryResult<PagedResponse<ReportingServiceStatus>>> {
    const query = gql(`
      query reportingServiceStatus($offset: Int, $limit: Int, $type: EGroupByActor, $minDate: DateTime, $maxDate: DateTime) {
        reportings {
          serviceStatus(offset: $offset, limit: $limit, type: $type, minDate: $minDate, maxDate: $maxDate) {
            data {
              total
              totalAsked
              totalProposed
              totalPlanned
              totalPerformed
              totalVehicleNotPresent
              totalCanceledByStructure
              totalCanceledByCustomerSite
              actor {
                ${this.getActorSubquery(parameters)}
              }
            }
            metadata {
              currentPage
              currentResult
              totalPages
              totalResults
              hasMore
            }
            extraMetadata {
              total {
                total
              }
            }
          }
        }
      }
    `);

    return this.apollo.query<{ reportings: { serviceStatus: PagedResponse<ReportingServiceStatus> } }>({
      query,
      variables: {
        offset: parameters.offset,
        limit: parameters.limit,
        type: parameters.filters?.type,
        minDate: parameters.filters?.minDate,
        maxDate: parameters.filters?.maxDate
      }
    }).pipe(map(result => {
      const data: ReportingServiceStatus[] = TypeSerializerUtils.deserializeArr(result.data.reportings.serviceStatus.data, ReportingServiceStatus);
      data.forEach(r => {
        switch (r.actor?.__typename) {
          case 'Customer': {
            r.customer = TypeSerializerUtils.deserialize(r.actor, Customer);
            break;
          }
          case 'CustomerSite': {
            r.customerSite = TypeSerializerUtils.deserialize(r.actor, CustomerSite);
            r.customer = TypeSerializerUtils.deserialize((r.actor as any).customer, Customer);
            break;
          }
          case 'Structure': {
            r.structure = TypeSerializerUtils.deserialize(r.actor, Structure);
            break;
          }
        }
      });
      const response: PagedResponse<ReportingServiceStatus> = {
        data,
        metadata: result.data.reportings.serviceStatus.metadata,
        extraMetadata:  result.data.reportings.serviceStatus.extraMetadata
      };
      return {
        data: response,
        error: result.error,
        errors: result.errors,
        partial: result.partial,
        loading: result.loading,
        networkStatus: result.networkStatus
      };
    }));
  }

  reportingServiceSpecifications(parameters: PagedRequest<ReportingServiceSpecificationFiltersRequest>): Observable<ApolloQueryResult<PagedResponse<ReportingServiceSpecification>>> {
    const query = gql(`
      query reportingServiceTypes($offset: Int, $limit: Int, $type: EGroupByActor, $minDate: DateTime, $maxDate: DateTime) {
        reportings {
          serviceTypes(offset: $offset, limit: $limit, type: $type, minDate: $minDate, maxDate: $maxDate) {
            data {
              nb
              type
              nbVehicleStateEnabled
              nbVehicleStateDisabled
              nbConveying
              nbNoConveying
              nbSticking
              nbNoSticking
              nbDesticking
              nbNoDesticking
              nbContactZoneDisinfection
              nbNoContactZoneDisinfection
              nbInteriorDisinfection
              nbNoInteriorDisinfection
              nbFinishingClassic
              nbFinishingEmployeeDiscount
              nbFinishingRestitution
              nbFinishingFleetManagement
              nbFirstIntervention
              nbNotFirstIntervention
              nbVehicleVeryDirty
              nbVehicleNotVeryDirty
              nbUpholsteryStainRemovalNothing
              nbUpholsteryStainRemovalDriver
              nbUpholsteryStainRemovalPassenger
              nbUpholsteryStainRemovalBackLeft
              nbUpholsteryStainRemovalBackRight
              nbUpholsteryStainRemovalAll
              nbCarpetStainRemovalNothing
              nbCarpetStainRemovalDriver
              nbCarpetStainRemovalPassenger
              nbCarpetStainRemovalBackLeft
              nbCarpetStainRemovalBackRight
              nbCarpetStainRemovalBoot
              nbCarpetStainRemovalAll
              actor {
                ${this.getActorSubquery(parameters)}
              }
            }
            metadata {
              currentPage
              currentResult
              totalResults
              totalPages
              hasMore
            }
            extraMetadata {
              total {
                nb
              }
            }
          }
        }
      }
    `);

    return this.apollo.query<{ reportings: { serviceTypes: PagedResponse<ReportingServiceSpecification> } }>({
      query,
      variables: {
        offset: parameters.offset,
        limit: parameters.limit,
        type: parameters.filters?.type,
        minDate: parameters.filters?.minDate,
        maxDate: parameters.filters?.maxDate
      }
    }).pipe(map(result => {
      const data: ReportingServiceSpecification[] = TypeSerializerUtils.deserializeArr(result.data.reportings.serviceTypes.data, ReportingServiceSpecification);
      data.forEach(r => {
        switch (r.actor?.__typename) {
          case 'Customer': {
            r.customer = TypeSerializerUtils.deserialize(r.actor, Customer);
            break;
          }
          case 'CustomerSite': {
            r.customerSite = TypeSerializerUtils.deserialize(r.actor, CustomerSite);
            r.customer = TypeSerializerUtils.deserialize((r.actor as any).customer, Customer);
            break;
          }
          case 'Structure': {
            r.structure = TypeSerializerUtils.deserialize(r.actor, Structure);
            break;
          }
        }
      });
      const response: PagedResponse<ReportingServiceSpecification> = {
        data,
        metadata: result.data.reportings.serviceTypes.metadata,
        extraMetadata: result.data.reportings.serviceTypes.extraMetadata
      };
      return {
        data: response,
        error: result.error,
        errors: result.errors,
        partial: result.partial,
        loading: result.loading,
        networkStatus: result.networkStatus
      };
    }));
  }

  reportingVehicleMonitorings(parameters: PagedRequest<ReportingVehicleMonitoringFiltersRequest>): Observable<ApolloQueryResult<PagedResponse<ReportingVehicleMonitoring>>> {
    const query = gql(`
    query reportingVehicleMonitoring($offset: Int, $limit: Int, $type: EGroupByActor, $minDate: DateTime, $maxDate: DateTime){
      reportings {
        vehicleMonitoring(offset: $offset, limit: $limit, type: $type, minDate: $minDate, maxDate: $maxDate) {
          data {
            fleet
            interiorFrequencyReal
            interiorFrequencyContractual
            exteriorFrequencyReal
            exteriorFrequencyContractual
            fullFrequencyReal
            fullFrequencyContractual
            vehicleStateEnabledFrequencyReal
            allFrequencyReal
            vehicle {
              id
              licensePlate
              frequency
              attribution
            }
            lastService {
              id
              date
            }
            upcomingService1 {
              id
              date
            }
            upcomingService2 {
              id
              date
            }
            upcomingService3 {
              id
              date
            }
            actor {
              ${this.getActorSubquery(parameters)}
            }
          }
          metadata {
            currentPage
            currentResult
            totalResults
            totalPages
            hasMore
          }
        }
      }
    }`);

    return this.apollo.query<{ reportings: { vehicleMonitoring: PagedResponse<ReportingVehicleMonitoring> } }>({
      query,
      variables: {
        offset: parameters.offset,
        limit: parameters.limit,
        type: parameters.filters?.type,
        minDate: parameters.filters?.minDate,
        maxDate: parameters.filters?.maxDate
      }
    }).pipe(map(result => {
      const data: ReportingVehicleMonitoring[] = TypeSerializerUtils.deserializeArr(result.data.reportings.vehicleMonitoring.data, ReportingVehicleMonitoring);
      data.forEach(r => {
        switch (r.actor?.__typename) {
          case 'Customer': {
            r.customer = TypeSerializerUtils.deserialize(r.actor, Customer);
            break;
          }
          case 'CustomerSite': {
            r.customerSite = TypeSerializerUtils.deserialize(r.actor, CustomerSite);
            r.customer = TypeSerializerUtils.deserialize((r.actor as any).customer, Customer);
            break;
          }
          case 'Structure': {
            r.structure = TypeSerializerUtils.deserialize(r.actor, Structure);
            break;
          }
        }
        r.lastService = TypeSerializerUtils.deserialize(r.lastService, Service);
        if (r.upcomingService1) {
          r.upcomingService1 = TypeSerializerUtils.deserialize(r.upcomingService1, Service);
        }
        if (r.upcomingService2) {
          r.upcomingService2 = TypeSerializerUtils.deserialize(r.upcomingService2, Service);
        }
        if (r.upcomingService3) {
          r.upcomingService3 = TypeSerializerUtils.deserialize(r.upcomingService3, Service);
        }
      });
      const response: PagedResponse<ReportingVehicleMonitoring> = {
        data,
        metadata: result.data.reportings.vehicleMonitoring.metadata
      };
      return {
        data: response,
        error: result.error,
        errors: result.errors,
        partial: result.partial,
        loading: result.loading,
        networkStatus: result.networkStatus
      };
    }));
  }

  reportingVehicleFleets(parameters: PagedRequest<ReportingVehicleFleetFiltersRequest>): Observable<ApolloQueryResult<PagedResponse<ReportingVehicleFleet>>> {
    const query = gql(`
      query reportingFleetMonitoring($offset: Int, $limit: Int, $type: EGroupByActor, $minDate: DateTime, $maxDate: DateTime){
        reportings {
          fleetMonitoring(offset: $offset, limit: $limit, type: $type, minDate: $minDate, maxDate: $maxDate) {
            data {
              fleet
              interiorFrequencyReal
              interiorFrequencyContractual
              exteriorFrequencyReal
              exteriorFrequencyContractual
              fullFrequencyReal
              fullFrequencyContractual
              vehicleStateEnabledFrequencyReal
              allFrequencyReal
              actor {
                ${this.getActorSubquery(parameters)}
              }
            }
            metadata {
              currentPage
              currentResult
              totalResults
              totalPages
              hasMore
            }
          }
        }
      }
    `);

    return this.apollo.query<{ reportings: { fleetMonitoring: PagedResponse<ReportingVehicleFleet> } }>({
      query,
      variables: {
        offset: parameters.offset,
        limit: parameters.limit,
        type: parameters.filters?.type,
        minDate: parameters.filters?.minDate,
        maxDate: parameters.filters?.maxDate
      }
    }).pipe(map(result => {
      const data: ReportingVehicleFleet[] = TypeSerializerUtils.deserializeArr(result.data.reportings.fleetMonitoring.data, ReportingVehicleFleet);
      data.forEach(r => {
        switch (r.actor?.__typename) {
          case 'Customer': {
            r.customer = TypeSerializerUtils.deserialize(r.actor, Customer);
            break;
          }
          case 'CustomerSite': {
            r.customerSite = TypeSerializerUtils.deserialize(r.actor, CustomerSite);
            r.customer = TypeSerializerUtils.deserialize((r.actor as any).customer, Customer);
            break;
          }
          case 'Structure': {
            r.structure = TypeSerializerUtils.deserialize(r.actor, Structure);
            break;
          }
        }
      });
      const response: PagedResponse<ReportingVehicleFleet> = {
        data,
        metadata: result.data.reportings.fleetMonitoring.metadata
      };
      return {
        data: response,
        error: result.error,
        errors: result.errors,
        partial: result.partial,
        loading: result.loading,
        networkStatus: result.networkStatus
      };
    }));
  }

  reportingRses(parameters: PagedRequest<ReportingRseFiltersRequest>): Observable<ApolloQueryResult<PagedResponse<ReportingRse>>> {
    const query = gql(`
      query reportingRse($offset: Int, $limit: Int, $type: EGroupByActor, $minDate: DateTime, $maxDate: DateTime){
        reportings {
          rse(offset: $offset, limit: $limit, type: $type, minDate: $minDate, maxDate: $maxDate) {
            data {
              nbServices
              waterSaving
              waterConsumptionPerHousehold
              insertion
              etp
              co2EmissionsSavings
              internationalSolidarityComponent
              actor {
                ${this.getActorSubquery(parameters)}
              }
            }
            metadata {
              currentPage
              currentResult
              totalResults
              totalPages
              hasMore
            }
            extraMetadata {
              total {
                nbServices
                waterSaving
              }
            }
          }
        }
      }
    `);

    return this.apollo.query<{ reportings: { rse: PagedResponse<ReportingRse> } }>({
      query,
      variables: {
        offset: parameters.offset,
        limit: parameters.limit,
        type: parameters.filters?.type,
        minDate: parameters.filters?.minDate,
        maxDate: parameters.filters?.maxDate
      }
    }).pipe(map(result => {
      const data: ReportingRse[] = TypeSerializerUtils.deserializeArr(result.data.reportings.rse.data, ReportingRse);
      data.forEach(r => {
        switch (r.actor?.__typename) {
          case 'Customer': {
            r.customer = TypeSerializerUtils.deserialize(r.actor, Customer);
            break;
          }
          case 'CustomerSite': {
            r.customerSite = TypeSerializerUtils.deserialize(r.actor, CustomerSite);
            r.customer = TypeSerializerUtils.deserialize((r.actor as any).customer, Customer);
            break;
          }
          case 'Structure': {
            r.structure = TypeSerializerUtils.deserialize(r.actor, Structure);
            break;
          }
        }
      });
      const response: PagedResponse<ReportingRse> = {
        data,
        metadata: result.data.reportings.rse.metadata,
        extraMetadata: result.data.reportings.rse.extraMetadata
      };
      return {
        data: response,
        error: result.error,
        errors: result.errors,
        partial: result.partial,
        loading: result.loading,
        networkStatus: result.networkStatus
      };
    }));
  }

  // Excel export

  reportingVehiclesExcel(parameters: PagedRequest<ReportingVehicleFiltersRequest>): Observable<ApolloQueryResult<string>> {
    const query = gql`
      query reportingVehicleStatsExcel($offset: Int, $limit: Int, $type: EGroupByActor) {
        reportings {
          vehicleStats(offset: $offset, limit: $limit, type: $type) {
            extraMetadata {
              excelUrl
            }
          }
        }
      }
    `;

    return this.apollo.query<{ reportings: { vehicleStats: { extraMetadata: { excelUrl: string } } } }>({
      query,
      variables: {
        offset: parameters.offset,
        limit: parameters.limit,
        type: parameters.filters?.type
      }
    }).pipe(map(result => ({
      data: result.data.reportings.vehicleStats.extraMetadata.excelUrl,
      error: result.error,
      errors: result.errors,
      partial: result.partial,
      loading: result.loading,
      networkStatus: result.networkStatus
    })));
  }

  reportingServiceStatusExcel(parameters: PagedRequest<ReportingServiceStatusFiltersRequest>): Observable<ApolloQueryResult<string>> {
    const query = gql`
      query reportingServiceStatusExcel($offset: Int, $limit: Int, $type: EGroupByActor, $minDate: DateTime, $maxDate: DateTime) {
        reportings {
          serviceStatus(offset: $offset, limit: $limit, type: $type, minDate: $minDate, maxDate: $maxDate) {
            extraMetadata {
              excelUrl
            }
          }
        }
      }
    `;

    return this.apollo.query<{ reportings: { serviceStatus: { extraMetadata: { excelUrl: string } } } }>({
      query,
      variables: {
        offset: parameters.offset,
        limit: parameters.limit,
        type: parameters.filters?.type,
        minDate: parameters.filters?.minDate,
        maxDate: parameters.filters?.maxDate
      }
    }).pipe(map(result => ({
      data: result.data.reportings.serviceStatus.extraMetadata.excelUrl,
      error: result.error,
      errors: result.errors,
      partial: result.partial,
      loading: result.loading,
      networkStatus: result.networkStatus
    })));
  }

  reportingServiceSpecificationsExcel(parameters: PagedRequest<ReportingServiceSpecificationFiltersRequest>): Observable<ApolloQueryResult<string>> {
    const query = gql`
      query reportingServiceTypesExcel($offset: Int, $limit: Int, $type: EGroupByActor, $minDate: DateTime, $maxDate: DateTime) {
        reportings {
          serviceTypes(offset: $offset, limit: $limit, type: $type, minDate: $minDate, maxDate: $maxDate) {
            extraMetadata {
              excelUrl
            }
          }
        }
      }
    `;

    return this.apollo.query<{ reportings: { serviceTypes: { extraMetadata: { excelUrl: string } } } }>({
      query,
      variables: {
        offset: parameters.offset,
        limit: parameters.limit,
        type: parameters.filters?.type,
        minDate: parameters.filters?.minDate,
        maxDate: parameters.filters?.maxDate
      }
    }).pipe(map(result => ({
      data: result.data.reportings.serviceTypes.extraMetadata.excelUrl,
      error: result.error,
      errors: result.errors,
      partial: result.partial,
      loading: result.loading,
      networkStatus: result.networkStatus
    })));
  }

  reportingVehicleMonitoringsExcel(parameters: PagedRequest<ReportingVehicleMonitoringFiltersRequest>): Observable<ApolloQueryResult<string>> {
    const query = gql`
      query reportingVehicleMonitoringExcel($offset: Int, $limit: Int, $type: EGroupByActor, $minDate: DateTime, $maxDate: DateTime) {
        reportings {
          vehicleMonitoring(offset: $offset, limit: $limit, type: $type, minDate: $minDate, maxDate: $maxDate) {
            extraMetadata {
              excelUrl
            }
          }
        }
      }
    `;

    return this.apollo.query<{ reportings: { vehicleMonitoring: { extraMetadata: { excelUrl: string } } } }>({
      query,
      variables: {
        offset: parameters.offset,
        limit: parameters.limit,
        type: parameters.filters?.type,
        minDate: parameters.filters?.minDate,
        maxDate: parameters.filters?.maxDate
      }
    }).pipe(map(result => ({
      data: result.data.reportings.vehicleMonitoring.extraMetadata.excelUrl,
      error: result.error,
      errors: result.errors,
      partial: result.partial,
      loading: result.loading,
      networkStatus: result.networkStatus
    })));
  }

  reportingVehicleFleetsExcel(parameters: PagedRequest<ReportingVehicleFleetFiltersRequest>): Observable<ApolloQueryResult<string>> {
    const query = gql`
      query reportingFleetMonitoringExcel($offset: Int, $limit: Int, $type: EGroupByActor, $minDate: DateTime, $maxDate: DateTime){
        reportings {
          fleetMonitoring(offset: $offset, limit: $limit, type: $type, minDate: $minDate, maxDate: $maxDate) {
            extraMetadata {
              excelUrl
            }
          }
        }
      }
    `;

    return this.apollo.query<{ reportings: { fleetMonitoring: { extraMetadata: { excelUrl: string } } } }>({
      query,
      variables: {
        offset: parameters.offset,
        limit: parameters.limit,
        type: parameters.filters?.type,
        minDate: parameters.filters?.minDate,
        maxDate: parameters.filters?.maxDate
      }
    }).pipe(map(result => ({
      data: result.data.reportings.fleetMonitoring.extraMetadata.excelUrl,
      error: result.error,
      errors: result.errors,
      partial: result.partial,
      loading: result.loading,
      networkStatus: result.networkStatus
    })));
  }

  reportingRsesExcel(parameters: PagedRequest<ReportingRseFiltersRequest>): Observable<ApolloQueryResult<string>> {
    const query = gql`
      query reportingRseExcel($offset: Int, $limit: Int, $type: EGroupByActor, $minDate: DateTime, $maxDate: DateTime){
        reportings {
          rse(offset: $offset, limit: $limit, type: $type, minDate: $minDate, maxDate: $maxDate) {
            extraMetadata {
              excelUrl
            }
          }
        }
      }
    `;

    return this.apollo.query<{ reportings: { rse: { extraMetadata: { excelUrl: string } } } }>({
      query,
      variables: {
        offset: parameters.offset,
        limit: parameters.limit,
        type: parameters.filters?.type,
        minDate: parameters.filters?.minDate,
        maxDate: parameters.filters?.maxDate
      }
    }).pipe(map(result => ({
      data: result.data.reportings.rse.extraMetadata.excelUrl,
      error: result.error,
      errors: result.errors,
      partial: result.partial,
      loading: result.loading,
      networkStatus: result.networkStatus
    })));
  }

  private getActorSubquery(parameters: PagedRequest): string {
    let actorSubQuery = `
      ... on Customer {
        id
        active
        name
      }
    `;
    switch (parameters.filters?.type ?? UserRoleType.customer) {
      case UserRoleType.structure: {
        actorSubQuery = `
          ... on Structure {
            id
            active
            name
          }
        `;
        break;
      }
      case UserRoleType.customerSite: {
        actorSubQuery = `
          ... on CustomerSite {
            id
            active
            name
            customer {
              id
              active
              name
            }
          }
        `;
        break;
      }
      default: {
        break;
      }
    }
    return actorSubQuery;
  }
}
