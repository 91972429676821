import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { ServiceUpholsteryStainRemoval } from 'src/app/shared/models/entities/service';

@Component({
  selector: 'laveo-select-service-upholstery-stain-removal',
  templateUrl: './select-service-upholstery-stain-removal.component.html',
  styleUrls: ['./select-service-upholstery-stain-removal.component.scss']
})
export class SelectServiceUpholsteryStainRemovalComponent {
  @Input() control: AbstractControl | null;
  @Input() typeSize: 'default' | 'small' | 'large' = 'large';
  @Input() typeSelection: 'single' | 'multiple' = 'single';
  @Input() values: ServiceUpholsteryStainRemoval[] = Object.values(ServiceUpholsteryStainRemoval);

  public get formControl(): UntypedFormControl {
    return this.control as UntypedFormControl;
  }
}
