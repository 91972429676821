import isPlainObject from 'is-plain-obj';
import { Body as ApolloBody } from 'apollo-angular/http/types.d';

export const ExtractFiles = {

  isExtractable(value: File | Blob): boolean {
    return (
      (typeof File !== 'undefined' && value instanceof File) || (typeof Blob !== 'undefined' && value instanceof Blob)
    );
  },

  /* eslint-disable @typescript-eslint/no-explicit-any */
  extractFiles(body: ApolloBody | ApolloBody[]): { clone: ApolloBody; files: Map<any, any> } {
    const clones = new Map();
    const files = new Map();
    const realpath = '';

    const recurse = (value: any, path: string, recursed: Set<any>) => {
      if (this.isExtractable(value)) {
        const filePaths: string[] = files.get(value);

        if (filePaths) {
          filePaths.push(path);
        } else {
          files.set(value, [path]);
        }

        // eslint-disable-next-line unicorn/no-null
        return null;
      }

      const valueIsList = Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
      const valueIsPlainObject = isPlainObject(value);

      if (valueIsList || valueIsPlainObject) {
        let clone = clones.get(value);

        const uncloned = !clone;

        if (uncloned) {
          clone = valueIsList ? [] : (value instanceof Object ? {} : Object.create(null));
          clones.set(value, clone);
        }

        if (!recursed.has(value)) {
          const pathPrefix = path ? `${path}.` : '';
          const recursedDeeper = new Set(recursed).add(value);

          if (valueIsList) {
            let index = 0;

            for (const item of value as File[]) {
              const itemClone = recurse(item, `${pathPrefix}${index++}`, recursedDeeper);

              if (uncloned) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                clone.push(itemClone);
              }
            }
          } else {
            // eslint-disable-next-line guard-for-in
            for (const key in value) {
              const propertyClone = recurse(
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                value[key],
                pathPrefix + key,
                recursedDeeper
              );

              if (uncloned) {
                clone[key] = propertyClone;
              }
            }
          }
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return clone;
      }

      return value as ApolloBody;
    };

    return {
      clone: recurse(body, realpath, new Set()),
      files
    };
  }
}
