import { deserialize } from 'typeserializer';
import { Actor, ActorArray } from '../models/entities/actor';
import { Vehicle, VehicleArray } from 'src/app/shared/models/entities/vehicle';
import { VehicleModel, VehicleModelArray } from '../models/entities/vehicle-model';
import { VehicleCheckpoint, VehicleCheckpointArray } from '../models/entities/vehicle-checkpoint';
import { VehicleBrand, VehicleBrandArray } from '../models/entities/vehicle-brand';
import { User, UserArray } from '../models/entities/user';
import { UserRole, UserRoleArray } from '../models/entities/user-role';
import { Service, ServiceArray } from '../models/entities/service';

export const TypeSerializerUtils = {
  deserialize: <T>(object: any, classType: T): any => deserialize(JSON.stringify(object), classType),

  deserializeArr: <T>(array: any[], classType: T): any[] => deserialize(JSON.stringify({ children: array }), classType).children,

  deserializeWithoutType: (object: any): any => {
    if (!object) {
      return null;
    }

    let classType: typeof Actor | typeof Service | typeof UserRole | typeof User | typeof VehicleBrand | typeof VehicleCheckpoint | typeof VehicleModel | typeof Vehicle | typeof ActorArray | typeof ServiceArray | typeof UserRoleArray | typeof UserArray | typeof VehicleBrandArray | typeof VehicleCheckpointArray | typeof VehicleModelArray | typeof VehicleArray | undefined;
    let isArray = false;

    switch (object?.__typename) {
      case 'Actor': {
        classType = Actor;
        break;
      }
      case 'Service': {
        classType = Service;
        break;
      }
      case 'UserRole': {
        classType = UserRole;
        break;
      }
      case 'User': {
        classType = User;
        break;
      }
      case 'VehicleBrand': {
        classType = VehicleBrand;
        break;
      }
      case 'VehicleCheckpoint': {
        classType = VehicleCheckpoint;
        break;
      }
      case 'VehicleModel': {
        classType = VehicleModel;
        break;
      }
      case 'Vehicle': {
        classType = Vehicle;
        break;
      }
      case 'Actors': {
        classType = ActorArray;
        isArray = true;
        break;
      }
      case 'Services': {
        classType = ServiceArray;
        isArray = true;
        break;
      }
      case 'UserRoles': {
        classType = UserRoleArray;
        isArray = true;
        break;
      }
      case 'Users': {
        classType = UserArray;
        isArray = true;
        break;
      }
      case 'VehicleBrands': {
        classType = VehicleBrandArray;
        isArray = true;
        break;
      }
      case 'VehicleCheckpoints': {
        classType = VehicleCheckpointArray;
        isArray = true;
        break;
      }
      case 'VehicleModels': {
        classType = VehicleModelArray;
        isArray = true;
        break;
      }
      case 'Vehicles': {
        classType = VehicleArray;
        isArray = true;
        break;
      }
    }

    if (classType) {
      return isArray ? TypeSerializerUtils.deserializeArr(object.data, classType) : TypeSerializerUtils.deserialize(object, classType);
    }

    return object;
  }
};
