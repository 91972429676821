import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzTableQueryParams, NzTableSortOrder } from 'ng-zorro-antd/table';
import { Subscription } from 'rxjs';
import { debounceTime, skip } from 'rxjs/operators';
import { PagedRequest } from 'src/app/shared/models/api/shared/paged/paged-request';
import { PagedResponse } from 'src/app/shared/models/api/shared/paged/paged-response.interface';
import { User } from 'src/app/shared/models/entities/user';
import { UserService } from 'src/app/shared/services/api/user.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Actor } from 'src/app/shared/models/entities/actor';
import { UserRole, UserRoleType } from 'src/app/shared/models/entities/user-role';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Title } from '@angular/platform-browser';
import { ApiErrorMessageUtil } from '../../../../shared/utils/api-error-message.util';
import { UserRight } from 'src/app/shared/models/entities/user-right';

@Component({
  selector: 'laveo-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss']
})
export class AdminUsersComponent implements OnInit, OnDestroy {
  isLoading = true;
  actionLoading = false;
  users?: PagedResponse<User>;
  currentUser: User;
  currentPage = 1;
  limit = 10;

  searchForm: UntypedFormGroup;
  checked: string[] = [];
  expanded: string[] = [];
  error?: Error;

  userCanAdd = false;
  userCanEdit = false;
  userCanDelete = false;
  isAdmin = false;
  currentRole?: UserRole;

  allRoles: UserRoleType[] = [
    UserRoleType.admin,
    UserRoleType.customer,
    UserRoleType.customerSite,
    UserRoleType.preparer,
    UserRoleType.structure
  ];
  selectedRoles: UserRoleType[] = [];

  private sort: { key: string; value: NzTableSortOrder }[] = [{ key: 'name', value: 'ascend' }];
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly userService: UserService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly message: NzMessageService,
    private readonly titleService: Title,
    private modal: NzModalService
  ) {}

  get description(): string {
    const desc = 'Gérez ici les utilisateurs de Lavéo. ';
    const numberOfUsers = this.users?.metadata?.totalResults ?? 0;

    if (numberOfUsers > 1) {
      return desc + 'Il y a ' + numberOfUsers + ' utilisateurs.';
    }

    if (numberOfUsers > 0) {
      return desc + 'Il y a' + numberOfUsers + ' utilisateur.';
    }

    return 'Aucun utilisateur trouvé. Commencez par ajouter des utilisateurs en utilisant le bouton "Ajouter un utilisateur".';
  }

  ngOnInit(): void {
    this.setTitle();
    this.loadRole();
    this.setForm();
    this.setSearchIfExist();
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  loadData(): void {
    let sortProperty: string | undefined;
    let sortType: 'ASC' | 'DESC' | undefined;

    const currentSort = this.sort.find(s => s.value);
    if (currentSort) {
      sortProperty = currentSort.key;
      sortType = currentSort.value === 'ascend' ? 'ASC' : 'DESC';
    }

    const search: string = this.searchForm.get('search')?.value;

    const parameters = new PagedRequest({
      page: this.currentPage,
      limit: this.limit,
      sortProperty,
      sortType,
      filters: {
        typesRoles: this.selectedRoles
      },
      search: search?.toLowerCase()?.trim()
    });

    this.isLoading = true;
    this.error = undefined;

    const usersSubscription = this.userService.allUsers(parameters).subscribe({
      next: data => {
        this.users = data.data;
        this.isLoading = data.loading;
      },
      error: error => {
        this.isLoading = false;
        console.error(error);
        this.error = error;
      }
    });

    const meSubscription = this.userService.currentUser().subscribe({
      next: data => {
        this.currentUser = data.data;
      },
      error: error => {
        console.error(error);
      }
    });

    this.subscriptions.push(usersSubscription, meSubscription);
  }

  goToAdmin(): void {
    void this.router.navigate(['/admin']);
  }

  setPage(event: NzTableQueryParams): void {
    const indexSame = !event.pageIndex || this.currentPage === event.pageIndex;
    const limitSame = this.limit === event.pageSize;
    let sortSame = true;
    for (const sortObject of event.sort) {
      const originalSort = this.sort.find(sortElement => sortElement.key === sortObject.key);
      if (originalSort?.value !== sortObject.value) {
        sortSame = false;
        break;
      }
    }

    if (indexSame && limitSame && sortSame) {
      return;
    }

    this.currentPage = event.pageIndex;
    this.limit = event.pageSize;
    this.sort = event.sort;
    this.loadData();
  }

  setChecked(check: boolean, id: string): void {
    if (check && !this.checked.includes(id)) {
      this.checked.push(id);
    } else if (!check && this.checked.includes(id)) {
      this.checked = this.checked.filter(c => c !== id);
    }
  }

  toggleExpanded(id: string): void {
    if (this.expanded.includes(id)) {
      this.expanded = this.expanded.filter(f => f !== id);
    } else {
      this.expanded.push(id);
    }
  }

  add(): void {
    void this.router.navigate(['/', 'admin', 'users', 'new']);
  }

  edit(user: User): void {
    void this.router.navigate(['/', 'admin', 'users', user.id], { fragment: 'edit' });
  }

  delete(user: User): void {
    this.modal.confirm({
      nzTitle: 'Désactivation',
      nzContent: 'Êtes-vous sûr de vouloir désactiver l’utilisateur <b>' + user.name + '</b>?',
      nzOkText: 'Désactiver',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzCancelText: 'Annuler',
      nzOnOk: () => {
        this.userService.deleteUser(user.id).subscribe({
          next: () => {
            this.loadData();
          },
          error: error => {
            console.error(error);
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
          }
        });
      }
    });
  }

  deleteChecked(): void {
    this.modal.confirm({
      nzTitle: 'Désactivation multiple',
      nzContent: 'Êtes-vous sûr de vouloir désactiver les <b> ' + this.checked.length + ' utilisateurs sélectionnés</b>?',
      nzOkText: 'Désactiver',
      nzOkType: 'primary',
      nzCancelText: 'Annuler',
      nzOnOk: () => {
        this.actionLoading = true;
        this.userService.deleteUser(...this.checked).subscribe({
          next: () => {
            this.actionLoading = false;
            this.loadData();
            this.checked.splice(0, this.checked.length);
          },
          error: error => {
            console.error(error);
            this.actionLoading = false;
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
          }
        });
      }
    });
  }

  goToActor(actor: Actor, type: UserRoleType): void {
    let link = '';
    switch (type){
      case UserRoleType.customer: {
        link = 'customers';
        break;
      }
      case UserRoleType.customerSite: {
        link = 'customer-sites';
        break;
      }
      case UserRoleType.structure: {
        link = 'structures';
        break;
      }
      case UserRoleType.preparer: {
        link = 'preparers';
      }
    }
    void this.router.navigate(['/', 'admin', link, actor.id]);
  }

  sendMailActivation(user: User) {
    this.actionLoading = true;
    this.userService.sendMailActivation(user.id).subscribe({
      next: () => {
        this.actionLoading = false;
        this.loadData();
        this.message.success(`Un mail de d'activation de compte a été envoyé à ${user.mail}`);
      },
      error: error => {
        console.error(error);
        this.actionLoading = false;
        this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
      }
    });
  }

  private setForm(): void {
    this.searchForm = this.formBuilder.group({
      search: this.formBuilder.control(null),
      roles: this.formBuilder.control(null)
    });

    const searchSubscription = this.searchForm.get('search')?.valueChanges.pipe(skip(1), debounceTime(500)).subscribe(search => {
      this.currentPage = 1;
      let queryParameters = { s: null };
      if (search && search !== '') {
        queryParameters = { s: search };
      }
      void this.router.navigate([], { queryParams: queryParameters, queryParamsHandling: 'merge' });
      this.loadData();
    });

    const rolesSubscription = this.searchForm.get('roles')?.valueChanges.subscribe(roles => {
      this.selectedRoles = roles;
      this.loadData();
    });

    if (searchSubscription && rolesSubscription) {
      this.subscriptions.push(searchSubscription, rolesSubscription);
    }
  }

  private setSearchIfExist(): void {
    const parameters = this.route.snapshot.queryParamMap;
    const search = parameters.get('s');
    this.searchForm.get('search')?.setValue(search);
  }

  private loadRole(): void {
    const roleSubscription = this.userService.currentRole.subscribe(role => {
      this.currentRole = role;
      this.isAdmin = role.type === UserRoleType.admin;
      this.userCanAdd = role.rights.users.includes(UserRight.create);
      this.userCanEdit = role.rights.users.includes(UserRight.update);
      this.userCanDelete = role.rights.users.includes(UserRight.delete);
    });

    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }
  }

  private setTitle(): void {
    this.titleService.setTitle('Lavéo - Gestion des utilisateurs');
  }
}
