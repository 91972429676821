import { EmailOptions } from 'ngx-validators';

export const emailOptionsConfig: EmailOptions = {
  domains: [
    'aim.com',
    'aol.com',
    'att.net',
    'bellsouth.net',
    'btinternet.com',
    'charter.net',
    'comcast.net',
    'cox.net',
    'earthlink.net',
    'fraikin.com',
    'free.fr',
    'gmail.com',
    'google.com',
    'googlemail.com',
    'groupe-curious.com',
    'icloud.com',
    'laveo.fr',
    'mac.com',
    'me.com',
    'msn.com',
    'optonline.net',
    'optusnet.com.au',
    'orange.fr',
    'qq.com',
    'rocketmail.com',
    'rogers.com',
    'sbcglobal.net',
    'sfr.fr',
    'shaw.ca',
    'sky.com',
    'sympatico.ca',
    'telus.net',
    'verizon.net',
    'web.de',
    'xtra.co.nz',
    'yahoo.com',
    'ymail.com',
  ],
  secondLevelDomains: ['yahoo', 'hotmail', 'gmail', 'live', 'outlook'],
  topLevelDomains: [
    'at',
    'be',
    'biz',
    'ca',
    'ch',
    'co.il',
    'co.jp',
    'co.nz',
    'co.uk',
    'com.au',
    'com.tw',
    'com',
    'cz',
    'de',
    'dk',
    'edu',
    'es',
    'eu',
    'fr',
    'gov',
    'gr',
    'hk',
    'hu',
    'ie',
    'in',
    'info',
    'it',
    'jp',
    'kr',
    'mil',
    'net.au',
    'net',
    'nl',
    'no',
    'org',
    'ru',
    'se',
    'sg',
    'uk',
    'us',
  ],
};
