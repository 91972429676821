<nz-form-item>
  <nz-form-label nzFor="vehicle">Immatriculation</nz-form-label>
  <nz-form-control [nzErrorTip]="error" [nzWarningTip]="warning" [nzValidateStatus]="validationStatus ? validationStatus : 'validating'">
    <nz-select
      [formControl]="formControl"
      [nzMode]="typeSelection === 'single' ? 'default' : 'multiple'"
      nzPlaceHolder="Immatriculation"
      nzAllowClear
      nzShowSearch
      nzServerSearch
      [nzLoading]="isLoading"
      (nzOnSearch)="onSearch($event)"
      [nzSize]="typeSize"
      class="w-100-p"
      [compareWith]="comparer">
      <nz-option *ngIf="isLoading; else data" nzDisabled nzCustomContent>
        <i nz-icon nzType="loading" class="loading-icon"></i> Chargement…
      </nz-option>
      <ng-template #data>
        <ng-container *ngFor="let vehicle of list">
          <nz-option [nzValue]="vehicle" [nzLabel]="vehicle.licensePlate + ' - ' + (vehicle.model.brand.name + ' - ' + vehicle.model.name + ' - ' + (vehicle.model.category | vehicleCategoryName))"  [nzDisabled]="!vehicle.active"></nz-option>
        </ng-container>
      </ng-template>
    </nz-select>
  </nz-form-control>
  <ng-template #error let-control>
    <ng-container *ngIf="control?.hasError('required')">
      Le véhicule est requis
    </ng-container>
  </ng-template>
</nz-form-item>
