import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzTableModule } from 'ng-zorro-antd/table';
import { SharedModule } from 'src/app/shared/shared.module';
import { MainSharedModule } from '../../shared/main-shared.module';
import { AdminUsersComponent } from './admin-users.component';
import { UserDetailComponent } from './user/user-detail/user-detail.component';
import { UserFormComponent } from './user/user-form/user-form.component';
import { UserComponent } from './user/user.component';
import { SelectActorModule } from '../../../../shared/views/inputs/select-actor/select-actor.module';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { InputsRolesModule } from '../../../../shared/views/inputs/inputs-roles/inputs-roles.module';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@NgModule({
  declarations: [
    AdminUsersComponent,
    UserComponent,
    UserFormComponent,
    UserDetailComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MainSharedModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzButtonModule,
    NzCardModule,
    NzGridModule,
    NzFormModule,
    NzInputModule,
    NzRadioModule,
    NzTableModule,
    NzCheckboxModule,
    NzResultModule,
    NzEmptyModule,
    NzSelectModule,
    NzDropDownModule,
    NzToolTipModule,
    SelectActorModule,
    InputsRolesModule
  ],
  exports: [
    AdminUsersComponent
  ]
})
export class AdminUsersModule {}
