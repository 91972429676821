<nz-form-item>
  <nz-form-label nzFor="category">Type de lavage</nz-form-label>
  <nz-form-control [nzErrorTip]="error">
    <nz-select [formControl]="formControl" nzMode="default" nzPlaceHolder="Type de lavage" nzAllowClear [nzSize]="typeSize" class="w-100-p">
      <nz-option *ngFor="let value of values" [nzValue]="value" [nzLabel]="value | serviceTypeName | nullCoal"></nz-option>
    </nz-select>
  </nz-form-control>
  <ng-template #error let-control>
    <ng-container *ngIf="control?.hasError('required')">
      Le type de lavage est requis
    </ng-container>
  </ng-template>
</nz-form-item>
