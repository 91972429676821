<laveo-entity-wrapper
  [title]="title"
  [entityService]="vehicleModelsService"
  [getFunction]="vehicleModelsService.vehicleModel"
  [addFunction]="vehicleModelsService.addVehicleModel"
  [updateFunction]="vehicleModelsService.updateVehicleModel"
  [wrappedComponent]="this"
  [getFormFunction]="form">
  <laveo-vehicle-model-detail *ngIf="isReady" detail [vehicleModel]="vehicleModel"></laveo-vehicle-model-detail>
  <laveo-vehicle-model-form *ngIf="isReady" form [vehicleModel]="vehicleModel"></laveo-vehicle-model-form>
</laveo-entity-wrapper>
