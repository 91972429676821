import { Type } from 'typeserializer';
import { Entity } from './entity';

export class VehicleBrand extends Entity {
  name: string;
  active: boolean;
  add?: boolean;
  logo?: string;
}

export class VehicleBrandArray {
  @Type([VehicleBrand])
  children: VehicleBrand[];
}
