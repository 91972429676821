import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { PagedRequest } from '../../../models/api/shared/paged/paged-request';
import { VehicleModel } from '../../../models/entities/vehicle-model';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { VehicleModelsService } from '../../../services/api/vehicles-model.service';

@Component({
  selector: 'laveo-select-vehicle-model',
  templateUrl: './select-vehicle-model.component.html',
  styleUrls: ['./select-vehicle-model.component.scss']
})
export class SelectVehicleModelComponent implements OnInit, OnDestroy {
  @Input() control: AbstractControl | null;
  @Input() typeSize: 'default' | 'small' | 'large' = 'large';

  isModelLoading = true;
  private privateModelList: VehicleModel[] = [];
  private searchParams = new BehaviorSubject<PagedRequest>(new PagedRequest({
    page: 0,
    limit: 10
  }));

  private subscriptions: Subscription[] = [];

  constructor(
    private readonly vehicleModelsService: VehicleModelsService
  ) {}

  public get modelList(): VehicleModel[] {
    const current = this.control?.value;
    if (Array.isArray(current)) {
      const temporaryList = [...this.privateModelList];
      for (const c of current) {
        if (c && !this.privateModelList.some(model => model.id === c.id)) {
          temporaryList.push(c);
        }
      }
      return temporaryList;
    } else {
      if (current && !this.privateModelList.some(model => model.id === current.id)) {
        return [...this.privateModelList, this.control?.value];
      }
    }
    return this.privateModelList;
  }

  public get formControl(): UntypedFormControl {
    return this.control as UntypedFormControl;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.searchParams.pipe(
        debounceTime(500)
      ).subscribe(parameters => {
        if (!parameters) {
          return;
        }
        this.isModelLoading = true;
        this.vehicleModelsService.allVehicleModels(parameters).subscribe(response => {
          if (response.loading) {
            return;
          }

          this.privateModelList = response.data.data;
          this.isModelLoading = false;
        });
      })
    );
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  onSearchModel(search: string): void {
    const parameters = new PagedRequest({
      page: 0,
      limit: 10,
      search: search?.toLowerCase()?.trim()
    });

    this.searchParams.next(parameters);
  }

  comparer(a: any, b: any): boolean {
    return a?.id === b?.id;
  }
}
