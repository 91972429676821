import { Component, OnDestroy, OnInit } from '@angular/core';
import { NzTableQueryParams, NzTableSortOrder } from 'ng-zorro-antd/table';
import { VehicleCheckpoint } from '../../../../../shared/models/entities/vehicle-checkpoint';
import { debounceTime } from 'rxjs/operators';
import { PagedRequest } from '../../../../../shared/models/api/shared/paged/paged-request';
import { PagedResponse } from '../../../../../shared/models/api/shared/paged/paged-response.interface';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { VehicleCheckpointsService } from '../../../../../shared/services/api/vehicles-checkpoint.service';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ApiErrorMessageUtil } from '../../../../../shared/utils/api-error-message.util';

@Component({
  selector: 'laveo-vehicle-checkpoint-list',
  templateUrl: './vehicle-checkpoint-list.component.html',
  styleUrls: ['./vehicle-checkpoint-list.component.scss']
})
export class VehicleCheckpointListComponent implements OnInit, OnDestroy {
  checkpointsIsLoading = true;
  checkpointsData?: PagedResponse<VehicleCheckpoint>;
  checkpointsSearchForm: UntypedFormGroup;
  checkpointsCurrentPage = 1;
  checkpointsLimit = 10;
  checkpointsChecked: string[] = [];
  checkpointsError?: Error;

  private checkpointsSort: { key: string; value: NzTableSortOrder }[] = [{ key: 'category', value: 'ascend' }];
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly router: Router,
    private readonly vehicleCheckpointsService: VehicleCheckpointsService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly modal: NzModalService,
    private readonly message: NzMessageService
  ) {}

  ngOnInit(): void {
    this.setCheckpointsForm();
    this.loadCheckpointsData();
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  loadCheckpointsData(): void {
    let sortProperty: string | undefined;
    let sortType: 'ASC' | 'DESC' | undefined;

    const currentSort = this.checkpointsSort.find(s => s.value);
    if (currentSort) {
      sortProperty = currentSort.key;
      sortType = currentSort.value === 'ascend' ? 'ASC' : 'DESC';
    }

    const search: string = this.checkpointsSearchForm.get('search')?.value;

    const parameters = new PagedRequest({
      page: this.checkpointsCurrentPage,
      limit: this.checkpointsLimit,
      sortProperty,
      sortType,
      search: search?.toLowerCase()?.trim()
    });

    this.checkpointsIsLoading = true;

    const checkpointsSubscription = this.vehicleCheckpointsService.allVehicleCheckpoints(parameters).subscribe({
      next: response => {
        this.checkpointsIsLoading = response.loading;
        this.checkpointsData = response.data;
        checkpointsSubscription?.unsubscribe();
      },
      error: error => {
        console.error(error);
        this.checkpointsError = error;
        this.checkpointsIsLoading = false;
        checkpointsSubscription?.unsubscribe();
      }
    });

    this.subscriptions.push(checkpointsSubscription);
  }

  setCheckpointsPage(event: NzTableQueryParams): void {
    const indexSame = !event.pageIndex || this.checkpointsCurrentPage === event.pageIndex;
    const limitSame = this.checkpointsLimit === event.pageSize;
    let sortSame = true;
    for (const sortObject of event.sort) {
      const originalSort = this.checkpointsSort.find(sortElement => sortElement.key === sortObject.key);
      if (originalSort?.value !== sortObject.value) {
        sortSame = false;
        break;
      }
    }

    if (indexSame && limitSame && sortSame) {
      return;
    }

    this.checkpointsCurrentPage = event.pageIndex;
    this.checkpointsLimit = event.pageSize;
    this.checkpointsSort = event.sort;
    this.loadCheckpointsData();
  }

  setCheckpointsChecked(check: boolean, id: string): void {
    if (check && !this.checkpointsChecked.includes(id)) {
      this.checkpointsChecked.push(id);
    } else if (!check && this.checkpointsChecked.includes(id)) {
      this.checkpointsChecked = this.checkpointsChecked.filter(c => c !== id);
    }
  }

  addVehicleCheckpoint(): void {
    void this.router.navigate(['/', 'admin', 'params', 'vehicle-checkpoints', 'new']);
  }

  editVehicleCheckpoint(vehicleCheckpoint: VehicleCheckpoint): void {
    void this.router.navigate(['/', 'admin', 'params', 'vehicle-checkpoints', vehicleCheckpoint.id], { fragment: 'edit' });
  }


  deleteVehicleCheckpoint(vehicleCheckpoint: VehicleCheckpoint): void {
    this.modal.confirm({
      nzTitle: 'Désactivation',
      nzContent: 'Êtes-vous sûr de vouloir désactiver le point de contrôle sélectionné ?',
      nzOkText: 'Désactiver',
      nzOkType: 'primary',
      nzCancelText: 'Annuler',
      nzOnOk: () => {
        this.vehicleCheckpointsService.deleteVehicleCheckpoint(vehicleCheckpoint.id).subscribe({
          next: () => {
            this.loadCheckpointsData();
            this.message.info('La marque à bien étée désactivée');
          },
          error: error => {
            console.error(error);
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
          }
        });
      }
    });
  }

  deleteCheckedCheckpoints(): void {
    let nzContent = 'Êtes-vous sûr de vouloir désactiver les <b> ' + this.checkpointsChecked.length + ' modèles sélectionnés</b> ?';
    if (this.checkpointsChecked.length === 1) {
      nzContent = 'Êtes-vous sûr de vouloir désactiver le point de controle sélectionné ?';
    }
    this.modal.confirm({
      nzTitle: 'Désactivation multiple',
      nzContent,
      nzOkText: 'Désactiver',
      nzOkType: 'primary',
      nzCancelText: 'Annuler',
      nzOnOk: () => {
        this.checkpointsIsLoading = true;
        this.vehicleCheckpointsService.deleteVehicleCheckpoint(...this.checkpointsChecked).subscribe({
          next: () => {
            this.checkpointsIsLoading = false;
            this.loadCheckpointsData();
            this.checkpointsChecked.splice(0, this.checkpointsChecked.length);
          },
          error: error => {
            console.error(error);
            this.checkpointsIsLoading = false;
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
          }
        });
      }
    });
  }

  private setCheckpointsForm(): void {
    this.checkpointsSearchForm = this.formBuilder.group({
      search: this.formBuilder.control(null)
    });

    const searchSubscription = this.checkpointsSearchForm.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this.checkpointsCurrentPage = 1;
      this.loadCheckpointsData();
    });
    this.subscriptions.push(searchSubscription);
  }
}
