<nz-card [nzBordered]="false" [nzTitle]="cardTitle">
  <ng-template #cardTitle>
    <laveo-card-header
        [title]="title"
        [description]="description"
        [buttonEnabled]="editEnabled && !isLoading && !isSaving"
        [backButtonEnabled]="true"
        [isLoading]="isLoading"
        [isTitleSkeleton]="isLoading"
        [isDescriptionSkeleton]="isLoading"
        (backButtonClick)="back()"
        [buttonIcon]="buttonIcon"
        [buttonTitle]="buttonTitle"
        (buttonClick)="action()">
    </laveo-card-header>
  </ng-template>
  <div nz-row [nzGutter]="[16, 16]">
    <ng-container *ngIf="this.isLoading else content">
      <div class="w-100-p h-200" nz-row nzAlign="middle" nzJustify="center">
        <nz-spin nzSimple></nz-spin>
      </div>
    </ng-container>
    <ng-template #content>
      <div *ngIf="(entity | async) !== undefined" class="w-100-p">
        <div *ngIf="!isEditing">
          <ng-content select="[detail]"></ng-content>
        </div>
        <div *ngIf="isEditing">
          <ng-content select="[form]"></ng-content>
        </div>
      </div>
    </ng-template>
  </div>

  <div *ngIf="deleteHardFunction && isEditing && (entity | async)" class="mt-16">
    <button
      nz-button
      nzType="primary"
      nzSize="small"
      nzDanger
      (click)="deleteHard()"
    ><i nz-icon nzType="delete"></i>Suppression définitive</button>
  </div>
</nz-card>
