<nz-form-item>
  <nz-form-label nzFor="category">Détachage sellerie</nz-form-label>
  <nz-form-control [nzErrorTip]="error">
    <nz-select
      [formControl]="formControl"
      [nzMode]="typeSelection === 'single' ? 'default' : 'multiple'"
      nzPlaceHolder="Détachage sellerie"
      nzAllowClear
      [nzSize]="typeSize"
      [nzShowArrow]="true"
      class="w-100-p">
      <nz-option *ngFor="let value of values" [nzValue]="value" [nzLabel]="value | serviceUpholsteryStainRemovalName | nullCoal"></nz-option>
    </nz-select>
  </nz-form-control>
  <ng-template #error let-control>
    <ng-container *ngIf="control?.hasError('required')">
      Le détachage sellerie est requise
    </ng-container>
  </ng-template>
</nz-form-item>
