import { ApolloLink, Operation } from 'apollo-link';
import { environment } from '../../environments/environment';
import { ExecutionResult } from 'apollo-link/lib/types';
import { throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from '@apollo/client/core';

export const MockLinkProvider = 'MOCK_LINK';

export const apolloMock = (httpClient: HttpClient) => new ApolloLink((operation: Operation, forward: any) => handleOperation(operation, forward, httpClient));

const handleOperation = (operation: Operation, forward: any, httpClient: HttpClient) => {
  if (environment.api.mock) {
    let path = '/assets/mock/';

    switch (operation.operationName) {
      case 'services': {
        path += 'services/services.json';
        break;
      }
      case 'service': {
        path += 'services/service.json';
        break;
      }
      case 'me': {
        path += 'users/me.json';
        break;
      }
      case 'user': {
        path += 'users/user.json';
        break;
      }
      case 'users': {
        path += 'users/users.json';
        break;
      }
      case 'customers': {
        path += 'customers/customers.json';
        break;
      }
      case 'customer': {
        path += 'customers/customer.json';
        break;
      }
      case 'customerSites': {
        path += 'customerSites/customerSites.json';
        break;
      }
      case 'customerSite': {
        path += 'customerSites/customerSite.json';
        break;
      }
      case 'preparers': {
        path += 'preparers/preparers.json';
        break;
      }
      case 'preparer': {
        path += 'preparers/preparer.json';
        break;
      }
      case 'vehicles': {
        path += 'vehicles/vehicles.json';
        break;
      }
      case 'vehicle': {
        path += 'vehicles/vehicle.json';
        break;
      }
      case 'structures': {
        path += 'structures/structures.json';
        break;
      }
      case 'structurePreparers':
      case 'structure': {
        path += 'structures/structure.json';
        break;
      }
      case 'checkpoints': {
        path += 'params/checkpoints.json';
        break;
      }
      case 'vehicleModel': {
        path += 'params/model.json';
        break;
      }
      case 'vehicleModels': {
        path += 'params/models.json';
        break;
      }
      case 'vehicleBrands': {
        path += 'vehicles/brands.json';
        break;
      }
      case 'resetPassword':
      case 'resetPasswordCheckToken':
      case 'resetPasswordChangePassword': {
        path = 'resetPassword';
        break;
      }
      case 'actors': {
        path += 'actors/actors.json';
        break;
      }
      case 'confirmMailCheckToken': {
        path = 'users';
        break;
      }
      case 'reportingVehicles': {
        path += 'reportings/reportingVehicles.json';
        break;
      }
      case 'reportingServiceStatus': {
        path += 'reportings/reportingServiceStatus.json';
        break;
      }
      case 'reportingServiceSpecifications': {
        path += 'reportings/reportingServiceSpecifications.json';
        break;
      }
      case 'reportingVehicleMonitorings': {
        path += 'reportings/reportingVehicleMonitorings.json';
        break;
      }
      case 'reportingVehicleFleets': {
        path += 'reportings/reportingVehicleFleets.json';
        break;
      }
      case 'reportingRses': {
        path += 'reportings/reportingRses.json';
        break;
      }
      default: {
        break;
      }
    }

    if (path && path !== '/assets/mock/') {
      if (path.startsWith('/assets/mock/')) {
        return httpClient.get(path).pipe(map(response => response as ExecutionResult<typeof response>));
      } else {
        const response: Record<string, any> = { data: { [path]: null } };
        return Observable.of(response as ExecutionResult<typeof response>);
      }
    }

    throw throwError(() => new Error('Unhandled mocked response'));
  } else {
    return forward(operation);
  }
};
