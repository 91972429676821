<div nz-row [nzGutter]="[48, 24]">
  <div nz-col nzSpan="24">
    <div class="detail">
      <span class="detail-title" nz-typography nzType="secondary">Nom</span>
      <span class="detail-content" nz-typography>{{ vehicleModel?.name | nullCoal }}</span>
    </div>
  </div>

  <div nz-col nzSpan="24">
    <div class="detail">
      <span class="detail-title" nz-typography nzType="secondary">Marque</span>
      <span class="detail-content" nz-typography>{{ vehicleModel?.brand?.name | nullCoal }}</span>
    </div>
  </div>

  <div nz-col nzSpan="24">
    <div class="detail">
      <span class="detail-title" nz-typography nzType="secondary">Catégorie</span>
      <span class="detail-content" nz-typography>
        <i *ngIf="vehicleModel?.category as category" nz-icon [nzType]="category | vehicleCategoryIcon"></i>
        {{ vehicleModel?.category | vehicleCategoryName | nullCoal }}
      </span>
    </div>
  </div>

  <div nz-col>
    <div class="detail">
      <span class="detail-title" nz-typography nzType="secondary">Actif</span>
      <span class="detail-content" nz-typography>
            <i nz-icon [nzType]="vehicleModel?.active ? 'check-square' : 'close-square'" nzTheme="outline"></i>
        {{ vehicleModel?.active ? 'Activé' : 'Désactivé' }}
          </span>
    </div>
  </div>
</div>
