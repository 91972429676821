import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { UserRoleType } from '../../../../shared/models/entities/user-role';
import { ServiceStatus } from '../../../../shared/models/entities/service';
import { PagedResponse } from '../../../../shared/models/api/shared/paged/paged-response.interface';
import { ReportingServiceStatus } from '../../../../shared/models/api/reportings/reporting-service-status';
import { NzTableSortOrder, NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subscription } from 'rxjs';
import { ReportingsService } from '../../../../shared/services/api/reportings.service';
import { UserService } from '../../../../shared/services/api/user.service';
import { Title } from '@angular/platform-browser';
import { ReportingServiceStatusFiltersRequest } from '../../../../shared/models/api/services/reporting-service-status-filters-requests.interface';
import { PagedRequest } from '../../../../shared/models/api/shared/paged/paged-request';
import { startOfToday, startOfMonth, startOfWeek, endOfWeek, endOfMonth, endOfToday, startOfYear, endOfYear } from 'date-fns';
import { Utils } from '../../../../shared/utils/utils';
import { ApiErrorMessageUtil } from '../../../../shared/utils/api-error-message.util';
import { NzMessageService } from 'ng-zorro-antd/message';
import { take } from 'rxjs/operators';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'laveo-reporting-service-status',
  templateUrl: './reporting-service-status.component.html',
  styleUrls: ['./reporting-service-status.component.scss']
})
export class ReportingServiceStatusComponent implements OnInit, OnDestroy {
  @ViewChild('exportForm') private exportFormContent: TemplateRef<any>;

  isLoading = true;
  actionLoading = false;
  currentPage = 1;
  limit = 10;

  form: UntypedFormGroup;
  exportForm: UntypedFormGroup;
  userCanChooseSelectedRole = false;
  selectedRole: UserRoleType.customer | UserRoleType.customerSite | UserRoleType.structure = UserRoleType.customer;
  UserRoleType = UserRoleType;
  minDate = startOfYear(new Date());
  maxDate = endOfYear(new Date());
  dateRanges: Record<string, Date[]> = {
    'Aujourd\'hui': [startOfToday(), endOfToday()],
    'Cette semaine': [startOfWeek(startOfToday(), { weekStartsOn: 1 }), endOfWeek(startOfToday(), { weekStartsOn: 1 })],
    'Ce mois': [startOfMonth(startOfToday()), endOfMonth(startOfToday())]
  };

  serviceStatuss = Object.values(ServiceStatus).filter(s => s !== ServiceStatus.created);

  error?: Error;

  data?: PagedResponse<ReportingServiceStatus>;

  private sort: { key: string; value: NzTableSortOrder }[] = [{ key: 'customer', value: 'ascend' }];
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly reportingsService: ReportingsService,
    private readonly userService: UserService,
    private readonly titleService: Title,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly message: NzMessageService,
    private readonly modal: NzModalService
  ) {}

  get description(): string | undefined {
    const total = this.data?.extraMetadata?.total?.total;
    if (total) {
      return `Nombre total de prestations : ${total}`;
    }
    return 'Chargement…';
  }

  ngOnInit(): void {
    this.setTitle();
    this.setForm();
    this.loadRole();
    this.loadData();
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  loadData(): void {
    let sortProperty: string | undefined;
    let sortType: 'ASC' | 'DESC' | undefined;

    const currentSort =  this.sort.find(s => s.value);
    if (currentSort) {
      sortProperty = currentSort.key;
      sortType = currentSort.value === 'ascend' ? 'ASC' : 'DESC';
    }

    const parameters = new PagedRequest<ReportingServiceStatusFiltersRequest>({
      page: this.currentPage,
      limit: this.limit,
      sortProperty,
      sortType,
      filters: {
        type: this.selectedRole,
        minDate: this.minDate,
        maxDate: this.maxDate
      },
    });

    this.isLoading = true;
    this.error = undefined;

    const reportingsSubscription = this.reportingsService.reportingServiceStatus(parameters).subscribe({
      next: data => {
        this.data = data.data;
        this.isLoading = data.loading;
      },
      error: error => {
        this.isLoading = false;
        console.error(error);
        this.error = error;
      }
    });

    this.subscriptions.push(reportingsSubscription);
  }

  setPage(event: NzTableQueryParams): void {
    const indexSame = !event.pageIndex || this.currentPage === event.pageIndex;
    const limitSame = this.limit === event.pageSize;
    let sortSame = true;
    for (const sortObject of event.sort) {
      const originalSort = this.sort.find(sortElement => sortElement.key === sortObject.key);
      if (originalSort?.value !== sortObject.value) {
        sortSame = false;
        break;
      }
    }

    if (indexSame && limitSame && sortSame) {
      return;
    }

    this.currentPage = event.pageIndex;
    this.limit = event.pageSize;
    this.sort = event.sort;
    this.loadData();
  }

  back(): void {
    window.history.back();
  }

  export(): void {
    this.exportForm = this.formBuilder.group({
      role: this.formBuilder.control(this.form.get('role')?.value, [Validators.required]),
      dates: this.formBuilder.control(this.form.get('dates')?.value, [Validators.required]),
      page: this.formBuilder.control('all', [Validators.required]),
      filename: this.formBuilder.control('')
    });

    const roleSubscription = this.exportForm.get('role')?.valueChanges.subscribe(role => {
      this.form.get('role')?.setValue(role);
      this.selectedRole = role;
      this.loadData();
    });

    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }

    const datesSubscription = this.exportForm.get('dates')?.valueChanges.subscribe(dates => {
      this.form.get('dates')?.setValue(dates);
      this.minDate = dates[0];
      this.maxDate = dates[1];
      this.loadData();
    });

    if (datesSubscription) {
      this.subscriptions.push(datesSubscription);
    }

    this.modal.create({
      nzTitle: 'Exporter en .xlsx',
      nzContent: this.exportFormContent,
      nzOkText: 'Exporter',
      nzCancelText: 'Annuler',
      nzData: {
        exportForm: this.exportForm
      },
      nzOkDisabled: !this.exportForm.valid,
      nzOnOk: () => {
        let filename: string = this.exportForm.get('filename')?.value ?? 'export';
        filename = filename.trim() === '' ? 'export' : filename.trim();
        filename = filename.toLowerCase().replaceAll(' ', '-');

        const dates: Date[] = this.exportForm.get('dates')?.value;
        const onlyCurrentPage = (this.exportForm.get('page')?.value ?? 'current') === 'current';

        const pagedRequest = new PagedRequest<ReportingServiceStatusFiltersRequest>({
          page: onlyCurrentPage ? (this.currentPage ? +this.currentPage : 1) : undefined,
          limit: onlyCurrentPage ? 10 : undefined,
          filters: {
            minDate: dates[0] ?? this.minDate,
            maxDate: dates[1] ?? this.maxDate,
            type: this.exportForm.get('role')?.value
          },
          sortType: this.exportForm.get('sort')?.value
        });

        this.reportingsService.reportingServiceStatusExcel(pagedRequest).pipe(take(1)).subscribe({
          next: (url => {
            if (url.data) {
              Utils.download(url.data, filename);
            } else {
              this.message.error('Une erreur s\'est produite durant l\'export. Réessayez plus tard');
            }
          }),
          error: (error => {
            console.error(error);
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
          })
        });
      }
    });
  }

  private setForm(): void {
    this.form = this.formBuilder.group({
      role: this.formBuilder.control(this.selectedRole),
      dates: this.formBuilder.control([this.minDate, this.maxDate]),
    });

    const roleSubscription = this.form.get('role')?.valueChanges.subscribe(role => {
      this.selectedRole = role;
      this.currentPage = 1;
      this.loadData();
    });

    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }

    const datesSubscription = this.form.get('dates')?.valueChanges.subscribe(dates => {
      this.minDate = dates[0];
      this.maxDate = dates[1];
      this.currentPage = 1;
      this.loadData();
    });

    if (datesSubscription) {
      this.subscriptions.push(datesSubscription);
    }
  }

  private loadRole(): void {
    const roleSubscription = this.userService.currentRole.subscribe(role => {
      if (role.type === UserRoleType.admin) {
        this.userCanChooseSelectedRole = true;
      }
    });

    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }
  }

  private setTitle(): void {
    this.titleService.setTitle('Lavéo - Rapport : Statut des prestations');
  }
}
