"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const consts_1 = require("../consts");
const Decorator_1 = require("./Decorator");
function Type(type) {
  return Decorator_1.createDecorator("Type", consts_1.TypeSymbol, type);
}
exports.Type = Type;
