import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzTableQueryParams, NzTableSortOrder } from 'ng-zorro-antd/table';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PagedRequest } from 'src/app/shared/models/api/shared/paged/paged-request';
import { PagedResponse } from 'src/app/shared/models/api/shared/paged/paged-response.interface';
import { CustomerSite } from 'src/app/shared/models/entities/customer-site';
import { CustomerSitesService } from 'src/app/shared/services/api/customer-sites.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UserRight, UserRightCategory } from 'src/app/shared/models/entities/user-right';
import { UserService } from 'src/app/shared/services/api/user.service';
import { UserRole, UserRoleType } from 'src/app/shared/models/entities/user-role';
import { Title } from '@angular/platform-browser';
import { ApiErrorMessageUtil } from '../../../../shared/utils/api-error-message.util';

@Component({
  selector: 'laveo-admin-customer-sites',
  templateUrl: './admin-customer-sites.component.html',
  styleUrls: ['./admin-customer-sites.component.scss']
})
export class AdminCustomerSitesComponent implements OnInit, OnDestroy {
  category = UserRightCategory.customerSites;
  isLoading = true;
  actionLoading = false;
  datas?: PagedResponse<CustomerSite>;
  currentPage = 1;
  limit = 10;

  searchForm: UntypedFormGroup;
  checked: string[] = [];
  error?: Error;

  userCanReadClients = false;
  userCanReadStructures = false;
  userCanAdd = false;
  userCanEdit = false;
  userCanDelete = false;
  isAdmin = false;
  currentRole?: UserRole;

  private sort: { key: string; value: NzTableSortOrder }[] = [{ key: 'name', value: 'ascend' }];
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly customerSitesServices: CustomerSitesService,
    private readonly userService: UserService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly message: NzMessageService,
    private readonly titleService: Title,
    private modal: NzModalService,
  ) {}

  get description(): string {
    const desc = 'Gérez ici les sites clients. ';
    const total = this.datas?.metadata?.totalResults ?? 0;

    if (total > 1) {
      return desc + 'Il y a ' + total + ' sites client.';
    }

    if (total > 0) {
      return desc + 'Il y a ' + total + ' site client.';
    }

    return desc + 'Aucun site client trouvé. Commencez par ajouter des clients en utilisant le bouton "Ajouter un site client".';
  }

  ngOnInit(): void {
    this.setTitle();
    this.loadRole();
    this.setForm();
    this.setSearchIfExist();
    this.loadData();
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  loadData(): void {
    let sortProperty: string | undefined;
    let sortType: 'ASC' | 'DESC' | undefined;

    const currentSort = this.sort.find(s => s.value);
    if (currentSort) {
      sortProperty = currentSort.key;
      sortType = currentSort.value === 'ascend' ? 'ASC' : 'DESC';
    }

    const search: string = this.searchForm.get('search')?.value;

    const parameters = new PagedRequest({
      page: this.currentPage,
      limit: this.limit,
      sortProperty,
      sortType,
      search: search?.toLowerCase()?.trim()
    });

    this.isLoading = true;
    this.error = undefined;

    const usersSubscription = this.customerSitesServices.allCustomerSites(parameters).subscribe({
      next: data => {
        this.datas = data.data;
        this.isLoading = data.loading;
      },
      error: error => {
        this.isLoading = false;
        console.error(error);
        this.error = error;
      }
    });

    this.subscriptions.push(usersSubscription);
  }

  setPage(event: NzTableQueryParams): void {
    const indexSame = !event.pageIndex || this.currentPage === event.pageIndex;
    const limitSame = this.limit === event.pageSize;
    let sortSame = true;
    for (const sortObject of event.sort) {
      const originalSort = this.sort.find(sortElement => sortElement.key === sortObject.key);
      if (originalSort?.value !== sortObject.value) {
        sortSame = false;
        break;
      }
    }

    if (indexSame && limitSame && sortSame) {
      return;
    }

    this.currentPage = event.pageIndex;
    this.limit = event.pageSize;
    this.sort = event.sort;
    this.loadData();
  }

  setChecked(check: boolean, id: string): void {
    if (check && !this.checked.includes(id)) {
      this.checked.push(id);
    } else if (!check && this.checked.includes(id)) {
      this.checked = this.checked.filter(c => c !== id);
    }
  }

  goToAdmin(): void {
    void this.router.navigate(['/admin']);
  }

  goToCustomer(fromCustomerSite: CustomerSite): void {
    void this.router.navigate(['/', 'admin', 'customers', fromCustomerSite.customer.id]);
  }

  goToStructure(fromCustomerSite: CustomerSite): void {
    void this.router.navigate(['/', 'admin', 'structures', fromCustomerSite.structure.id]);
  }

  add(): void {
    void this.router.navigate(['/', 'admin', 'customer-sites', 'new']);
  }

  edit(customerSite: CustomerSite): void {
    void this.router.navigate(['/', 'admin', 'customer-sites', customerSite.id], { fragment: 'edit' });
  }

  delete(customerSite: CustomerSite): void {
    this.modal.confirm({
      nzTitle: 'Désactivation',
      nzContent: 'Êtes-vous sûr de vouloir désactiver le site client <b>' + customerSite.name + '</b>?',
      nzOkText: 'Désactiver',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzCancelText: 'Annuler',
      nzOnOk: () => {
        this.customerSitesServices.deleteCustomerSite(customerSite.id).subscribe({
          next: () => {
            this.loadData();
          },
          error: error => {
            console.error(error);
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
          }
        });
      }
    });
  }

  deleteChecked(): void {
    this.modal.confirm({
      nzTitle: 'Désactivation multiple',
      nzContent: 'Êtes-vous sûr de vouloir désactiver les <b> ' + this.checked.length + ' sites client sélectionnés</b>?',
      nzOkText: 'Désactiver',
      nzOkType: 'primary',
      nzCancelText: 'Annuler',
      nzOnOk: () => {
        this.actionLoading = true;
        this.customerSitesServices.deleteCustomerSite(...this.checked).subscribe({
          next: () => {
            this.actionLoading = false;
            this.loadData();
            this.checked.splice(0, this.checked.length);
          },
          error: error => {
            console.error(error);
            this.actionLoading = false;
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
          }
        });
      }
    });
  }

  private setForm(): void {
    this.searchForm = this.formBuilder.group({
      search: this.formBuilder.control(null)
    });

    const searchSubscription = this.searchForm.valueChanges.pipe(debounceTime(500)).subscribe(formValues => {
      this.currentPage = 1;
      let queryParameters = { s: null };
      if (formValues.search && formValues.search !== '') {
        queryParameters = { s: formValues.search };
      }

      void this.router.navigate([], { queryParams: queryParameters, queryParamsHandling: 'merge' });
      this.loadData();
    });
    this.subscriptions.push(searchSubscription);
  }

  private setSearchIfExist(): void {
    const querySubscription = this.route.queryParamMap.subscribe(parameters => {
      const search = parameters.get('s');
      if (search) {
        this.searchForm.get('search')?.setValue(search);
      }
      this.loadData();
    });
    this.subscriptions.push(querySubscription);
  }

  private loadRole(): void {
    const roleSubscription = this.userService.currentRole.subscribe(role => {
      this.currentRole = role;
      this.isAdmin = role.type === UserRoleType.admin;
      this.userCanReadClients = role.rights.customers.includes(UserRight.read);
      this.userCanReadStructures = role.rights.structures.includes(UserRight.read);
      this.userCanAdd = role.rights.customerSites.includes(UserRight.create);
      this.userCanEdit = role.rights.customerSites.includes(UserRight.update);
      this.userCanDelete = role.rights.customerSites.includes(UserRight.delete);
    });

    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }
  }

  private setTitle(): void {
    this.titleService.setTitle('Lavéo - Gestion des sites clients');
  }
}
