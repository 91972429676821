import { Component, OnDestroy, OnInit } from '@angular/core';
import { PagedResponse } from '../../../../../shared/models/api/shared/paged/paged-response.interface';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NzTableQueryParams, NzTableSortOrder } from 'ng-zorro-antd/table';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { PagedRequest } from '../../../../../shared/models/api/shared/paged/paged-request';
import { debounceTime } from 'rxjs/operators';
import { VehicleBrandsService } from '../../../../../shared/services/api/vehicles-brand.service';
import { VehicleBrand } from '../../../../../shared/models/entities/vehicle-brand';
import { ApiErrorMessageUtil } from '../../../../../shared/utils/api-error-message.util';

@Component({
  selector: 'laveo-vehicle-brand-list',
  templateUrl: './vehicle-brand-list.component.html',
  styleUrls: ['./vehicle-brand-list.component.scss']
})
export class VehicleBrandListComponent implements OnInit, OnDestroy {

  brandsIsLoading = true;
  brandsActionIsLoading = false;
  brandsData?: PagedResponse<VehicleBrand>;
  brandsSearchForm: UntypedFormGroup;
  brandsCurrentPage = 1;
  brandsLimit = 10;
  brandsChecked: string[] = [];
  brandsError?: Error;

  private brandsSort: { key: string; value: NzTableSortOrder }[] = [{ key: 'brand', value: 'ascend' }];
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly router: Router,
    private readonly vehicleBrandsService: VehicleBrandsService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly modal: NzModalService,
    private readonly message: NzMessageService
  ) {}

  ngOnInit(): void {
    this.setBrandsForm();
    this.loadBrandsData();
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  loadBrandsData(): void {
    let sortProperty: string | undefined;
    let sortType: 'ASC' | 'DESC' | undefined;

    const currentSort = this.brandsSort.find(s => s.value);
    if (currentSort) {
      sortProperty = currentSort.key;
      sortType = currentSort.value === 'ascend' ? 'ASC' : 'DESC';
    }

    const search: string = this.brandsSearchForm.get('search')?.value;

    const parameters = new PagedRequest({
      page: this.brandsCurrentPage,
      limit: this.brandsLimit,
      sortProperty,
      sortType,
      search: search?.toLowerCase()?.trim()
    });

    this.brandsIsLoading = true;

    const brandsSubscription = this.vehicleBrandsService.allVehicleBrands(parameters).subscribe({
      next: response => {
        this.brandsIsLoading = response.loading;
        this.brandsData = response.data;
        brandsSubscription?.unsubscribe();
      },
      error: error => {
        console.error(error);
        this.brandsError = error;
        this.brandsIsLoading = false;
        brandsSubscription?.unsubscribe();
      }
    });

    this.subscriptions.push(brandsSubscription);
  }

  setBrandsPage(event: NzTableQueryParams): void {
    const indexSame = !event.pageIndex || this.brandsCurrentPage === event.pageIndex;
    const limitSame = this.brandsLimit === event.pageSize;
    let sortSame = true;
    for (const sortObject of event.sort) {
      const originalSort = this.brandsSort.find(sortElement => sortElement.key === sortObject.key);
      if (originalSort?.value !== sortObject.value) {
        sortSame = false;
        break;
      }
    }

    if (indexSame && limitSame && sortSame) {
      return;
    }

    this.brandsCurrentPage = event.pageIndex;
    this.brandsLimit = event.pageSize;
    this.brandsSort = event.sort;
    this.loadBrandsData();
  }

  setBrandsChecked(check: boolean, id: string): void {
    if (check && !this.brandsChecked.includes(id)) {
      this.brandsChecked.push(id);
    } else if (!check && this.brandsChecked.includes(id)) {
      this.brandsChecked = this.brandsChecked.filter(c => c !== id);
    }
  }

  addVehicleBrand(): void {
    void this.router.navigate(['/', 'admin', 'params', 'vehicle-brands', 'new']);
  }

  editVehicleBrand(vehicleBrand: VehicleBrand): void {
    void this.router.navigate(['/', 'admin', 'params', 'vehicle-brands', vehicleBrand.id], { fragment: 'edit' });
  }

  deleteVehicleBrand(vehicleBrand: VehicleBrand): void {
    this.modal.confirm({
      nzTitle: 'Désactivation',
      nzContent: 'Êtes-vous sûr de vouloir désactiver la marque sélectionnée ?',
      nzOkText: 'Désactiver',
      nzOkType: 'primary',
      nzCancelText: 'Annuler',
      nzOnOk: () => {
        this.vehicleBrandsService.deleteVehicleBrand(vehicleBrand.id).subscribe({
          next: () => {
            this.loadBrandsData();
            this.message.info('La marque à bien étée désactivée');
          },
          error: error => {
            console.error(error);
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
          }
        });
      }
    });
  }

  deleteCheckedVehicleModel(): void {
    let nzContent = 'Êtes-vous sûr de vouloir désactiver les <b> ' + this.brandsChecked.length + ' modèles sélectionnés</b> ?';
    if (this.brandsChecked.length === 1) {
      nzContent = 'Êtes-vous sûr de vouloir désactiver le modèle sélectionné ?';
    }
    this.modal.confirm({
      nzTitle: 'Désactivation multiple',
      nzContent,
      nzOkText: 'Désactiver',
      nzOkType: 'primary',
      nzCancelText: 'Annuler',
      nzOnOk: () => {
        this.brandsActionIsLoading = true;
        this.vehicleBrandsService.deleteVehicleBrand(...this.brandsChecked).subscribe({
          next: () => {
            this.brandsActionIsLoading = false;
            this.loadBrandsData();
            this.brandsChecked.splice(0, this.brandsChecked.length);
          },
          error: error => {
            console.error(error);
            this.brandsActionIsLoading = false;
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
          }
        });
      }
    });
  }

  private setBrandsForm(): void {
    this.brandsSearchForm = this.formBuilder.group({
      search: this.formBuilder.control(null)
    });

    const searchSubscription = this.brandsSearchForm.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this.brandsCurrentPage = 1;
      this.loadBrandsData();
    });
    this.subscriptions.push(searchSubscription);
  }

}
