import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportingComponent } from './reporting.component';
import { ReportingVehicleComponent } from './reporting-vehicle/reporting-vehicle.component';
import { ReportingServiceStatusComponent } from './reporting-service-status/reporting-service-status.component';
import { ReportingServiceSpecificationsComponent } from './reporting-service-specifications/reporting-service-specifications.component';
import { ReportingVehicleMonitoringComponent } from './reporting-vehicle-monitoring/reporting-vehicle-monitoring.component';
import { ReportingFleetComponent } from './reporting-fleet/reporting-fleet.component';
import { ReportingRseComponent } from './reporting-rse/reporting-rse.component';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzCardModule } from 'ng-zorro-antd/card';
import { SharedModule } from '../../../shared/shared.module';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { MainSharedModule } from '../shared/main-shared.module';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { ReactiveFormsModule } from '@angular/forms';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { RouterModule } from '@angular/router';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzInputModule } from 'ng-zorro-antd/input';

@NgModule({
  declarations: [
    ReportingComponent,
    ReportingVehicleComponent,
    ReportingServiceStatusComponent,
    ReportingServiceSpecificationsComponent,
    ReportingVehicleMonitoringComponent,
    ReportingFleetComponent,
    ReportingRseComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    MainSharedModule,
    SharedModule,
    ReactiveFormsModule,
    NzCardModule,
    NzTableModule,
    NzButtonModule,
    NzIconModule,
    NzResultModule,
    NzEmptyModule,
    NzGridModule,
    NzFormModule,
    NzSelectModule,
    NzDatePickerModule,
    NzRadioModule,
    NzInputModule
  ]
})
export class ReportingModule {}
