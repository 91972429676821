import { Deserializer, Type } from 'typeserializer';
import { ServiceCarpetStainRemovalNamePipe } from '../../pipes/service-carpet-stain-removal-name.pipe';
import { ServiceUpholsteryStainRemovalNamePipe } from '../../pipes/service-upholstery-stain-removal-name.pipe';
import { DateConverter } from '../../type-converters/date.converter';
import { Entity } from './entity';
import { Preparer } from './preparer';
import { ServiceStatus, ServiceType, ServiceFinishing, ServiceUpholsteryStainRemoval, ServiceCarpetStainRemoval } from './service';

export class ServiceHistory extends Entity {
  @Type(Date)
  @Deserializer(DateConverter.deserialize)
  createdDate: Date;

  @Type(Date)
  @Deserializer(DateConverter.deserialize)
  currentDate: Date;

  @Type(Date)
  @Deserializer(DateConverter.deserialize)
  dateAsked: Date;

  @Type(Date)
  @Deserializer(DateConverter.deserialize)
  dateCanceled: Date;

  @Type(Date)
  @Deserializer(DateConverter.deserialize)
  datePerformed: Date;

  @Type(Date)
  @Deserializer(DateConverter.deserialize)
  datePlanned: Date;

  @Type(Date)
  @Deserializer(DateConverter.deserialize)
  dateProposed: Date;

  @Type(Preparer)
  preparer: Preparer;

  carpetStainRemoval: ServiceCarpetStainRemoval[];
  contactZoneDisinfection: boolean;
  conveying: boolean;
  desticking: boolean;
  finishing: ServiceFinishing;
  firstIntervention: boolean;
  hoursToInvoice?: number;
  interiorDisinfection: boolean;
  status: ServiceStatus;
  sticking: boolean;
  type: ServiceType;
  upholsteryStainRemoval: ServiceUpholsteryStainRemoval[];
  vehicleStateEnabled: boolean;
  vehicleVeryDirty: boolean;

  get options(): string | undefined {
    const list: string[] = [];

    if (this.vehicleStateEnabled) {
      list.push('État des lieux');
    }

    if (this.firstIntervention) {
      list.push('1ère intervention');
    }

    if (this.vehicleVeryDirty) {
      list.push('Véhicule très sale');
    }

    if (this.conveying) {
      list.push('Convoyage');
    }

    if (this.sticking) {
      list.push('Stickage');
    }

    if (this.desticking) {
      list.push('Déstickage');
    }

    if (this.interiorDisinfection) {
      list.push('Désinfection habitacle');
    }

    if (this.contactZoneDisinfection) {
      list.push('Désinfection zone de contact');
    }

    if (this.upholsteryStainRemoval.length > 0) {
      const upholsteryStainNamePipe = new ServiceUpholsteryStainRemovalNamePipe();
      const names = this.upholsteryStainRemoval.map(stain => upholsteryStainNamePipe.transform(stain)).join(', ');
      list.push(`Détachage sellerie (${names})`);
    }

    if (this.carpetStainRemoval.length > 0) {
      const carpetStainNamePipe = new ServiceCarpetStainRemovalNamePipe();
      const names = this.carpetStainRemoval.map(stain => carpetStainNamePipe.transform(stain)).join(', ');
      list.push(`Détachage moquette (${names})`);
    }

    const formatter = new (Intl as any).ListFormat('fr', { style: 'long', type: 'conjunction' });
    if (list.length === 0) {
      return;
    }

    return formatter.format(list);
  }
}
