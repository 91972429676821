import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo, gql, MutationResult } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { Rse } from '../../models/entities/rse';
import { TypeSerializerUtils } from '../../utils/type-serializer.util';

@Injectable({
  providedIn: 'root'
})
export class RseService {
  constructor(
    private readonly apollo: Apollo
  ) {}

  rseParamsForYear(year: number): Observable<ApolloQueryResult<Rse>> {
    const query = gql`
      query rse($year: Int!) {
        params {
          rse(year: $year) {
            id
            year
            extraJsonData
          }
        }
      }
    `;
    return this.apollo.query<{ params: { rse: Rse } }>({
      query,
      variables: {
        year
      }
    }).pipe(map(result => ({
      data: TypeSerializerUtils.deserialize(result.data?.params?.rse, Rse),
      error: result.error,
      errors: result.errors,
      partial: result.partial,
      loading: result.loading,
      networkStatus: result.networkStatus
    })));
  }

  updateRse(year: number, jsonData: string): Observable<MutationResult<Rse>> {
    const query = gql`
      mutation updateRse($year: Int!, $data: RseUpdateRequestType!) {
        params {
          rse {
            update(year: $year, data: $data) {
              id
              year
              extraJsonData
            }
          }
        }
      }
    `;

    return this.apollo.mutate<{ params: { rse: { update: Rse } } }>({
      mutation: query,
      variables: {
        year,
        data: {
          extraJsonData: jsonData
        }
      }
    }).pipe(map(result => ({

      data: TypeSerializerUtils.deserialize(result.data?.params?.rse?.update, Rse),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }
}
