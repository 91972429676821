import { environment } from 'src/environments/environment';
import { Entity } from './entity';

export class LaveoFile extends Entity {
  /** La date de création */
  createdDate: Date;

  /** La date de mise à jour */
  updatedDate: Date;

  /** L'extension */
  extension: string;

  /** Le nom complet */
  fullName: string;

  /** Le type MIME */
  mimeType: string;

  /** La taille du fichier en octets */
  size: number;

  /** Le chemin du fichier */
  url: string;

  /**
   * Le chemin du fichier avec le domaine (api)
   * @returns {string} Api url with domain
   */
  get urlWithDomain(): string {
    if (environment.api.mock) {
      return this.url;
    }
    return environment.api.url + '/' + this.url;
  }
}
