import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subscription } from 'rxjs';
import { ROUTES_CONFIG } from 'src/app/configs/tokens.config';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ApiErrorMessageUtil } from '../../shared/utils/api-error-message.util';

@Component({
  selector: 'laveo-confirmation-mail',
  templateUrl: './confirmation-mail.component.html',
  styleUrls: ['./confirmation-mail.component.scss']
})
export class ConfirmationMailComponent implements OnInit, OnDestroy {
  isLoading = true;

  private subscriptions: Subscription[] = [];
  private token: string;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly message: NzMessageService,
    private readonly authService: AuthService,
    private readonly titleService: Title,
    @Inject(ROUTES_CONFIG) private readonly routes: Record<string, Route>
  ) {}

  ngOnInit(): void {
    this.checkToken();
    this.setTitle();
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  private checkToken(): void {
    const querySubscription = this.route.queryParamMap.subscribe(parameters => {
      if (parameters.has('token')) {
        this.token = parameters.get('token')!;
        this.authService.confirmMailCheckToken(this.token).subscribe({
          next: () => {
            this.isLoading = false;
            void this.router.navigate([], { queryParams: { token: null }, queryParamsHandling: 'merge' });
          },
          error: error => {
            console.error(error);
            this.isLoading = false;
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
            void this.router.navigate([this.routes.login.path]);
          }
        });
      } else if (!this.token) {
        this.message.error('Erreur, votre lien à expiré. Réessayez votre demande.');
        void this.router.navigate([this.routes.login.path]);
      }
    });

    this.subscriptions.push(querySubscription);
  }

  private setTitle() {
    this.titleService.setTitle('Lavéo - Confirmation du mail');
  }
}
