<nz-form-item>
  <nz-form-label *ngIf="showLabel" nzFor="actor">{{ calculatedLabel }}</nz-form-label>
  <nz-form-control [nzErrorTip]="controlError">
    <nz-select
        [formControl]="formControl"
        [nzMode]="typeSelection === 'single' ? 'default' : 'multiple'"
        [nzPlaceHolder]="calculatedPlaceholder"
        [nzShowArrow]="true"
        [nzShowSearch]="!data || data.length === 0"
        [nzAllowClear]="true"
        [nzServerSearch]="!data || data.length === 0"
        [nzLoading]="isActorLoading"
        (nzOnSearch)="onSearchActor($event)"
        [nzSize]="typeSize" class="w-100-p"
        [compareWith]="comparer"
        [nzDisabled]="disabled">
      <nz-option *ngIf="isActorLoading; else actorData" nzDisabled nzCustomContent>
        <i nz-icon nzType="loading" class="loading-icon"></i> Chargement…
      </nz-option>

      <ng-template #actorData>
        <ng-container *ngFor="let actor of actorList">
          <nz-option nzCustomContent
            [nzValue]="actor"
            [nzLabel]="(showRoleName ? ((actor.__typename | roleName) + ' - ') : '') + actor.name"
            [nzDisabled]="isDisabled(actor) || !actor.active">
            <span *ngIf="!showRoleName && !isCustomerAndCustomerSiteOnly && typeEntity.length > 1">
              <b *ngIf="actor.__typename === 'Admin'">Admin : </b>
              <b *ngIf="actor.__typename === 'Customer'">Client : </b>
              <b *ngIf="actor.__typename === 'CustomerSite'">Site client : </b>
              <b *ngIf="actor.__typename === 'Preparer'">Préparateur : </b>
              <b *ngIf="actor.__typename === 'Structure'">Structure : </b>
            </span>
            <span *ngIf="!showRoleName && isCustomerAndCustomerSiteOnly">
              <b *ngIf="actor.__typename === 'Customer'">Client : </b>
              <b *ngIf="actor.__typename === 'CustomerSite'" class="mr-32">&nbsp;</b>
            </span>
            <span *ngIf="showRoleName"><b>{{ actor.__typename | roleName }}</b> - </span>
            {{ actor.name }}
          </nz-option>
        </ng-container>
      </ng-template>
    </nz-select>
  </nz-form-control>
  <ng-template #controlError let-control>
    <ng-container *ngIf="control.hasError('required')">Ce champ est requis</ng-container>
  </ng-template>
</nz-form-item>
