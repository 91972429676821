import { Type } from 'typeserializer';
import { Actor } from './actor';
import { Entity } from './entity';
import { LaveoFile } from './file';
import { UserRight, UserRightCategory } from './user-right';

export class UserRole extends Entity {
  @Type(LaveoFile)
  logo?: LaveoFile;
  @Type(Actor)
  actor: Actor;
  type: UserRoleType;
  rights: Record<UserRightCategory, UserRight[]>;
}

export class UserRoleArray {
  @Type([UserRole])
  children: UserRole[];
}

export enum UserRoleType {
  admin = 'SUPER_ADMIN',
  structure = 'STRUCTURE',
  structureRead = 'STRUCTURE_READ',
  customer = 'CUSTOMER',
  customerSite = 'CUSTOMER_SITE',
  customerSiteRead = 'CUSTOMER_SITE_READ',
  preparer = 'PREPARER',
}
