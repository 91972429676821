import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared.module';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzFormModule } from 'ng-zorro-antd/form';
import { ReactiveFormsModule } from '@angular/forms';
import { SelectCustomerSiteBillingPersonComponent } from './select-customer-site-billing-person.component';
import { NzIconModule } from 'ng-zorro-antd/icon';

@NgModule({
  declarations: [
    SelectCustomerSiteBillingPersonComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    NzFormModule,
    NzSelectModule,
    NzIconModule
  ],
  exports: [
    SelectCustomerSiteBillingPersonComponent
  ]
})
export class SelectCustomerSiteBillingPersonModule {}
