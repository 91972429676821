import { Component, Input } from '@angular/core';
import { Structure } from 'src/app/shared/models/entities/structure';

@Component({
  selector: 'laveo-structure-detail',
  templateUrl: './structure-detail.component.html',
  styleUrls: ['./structure-detail.component.scss']
})
export class StructureDetailComponent {
  @Input() structure?: Structure;

  preparers(structure: Structure): string {
    if (structure?.preparersMetaData?.totalResults > 0) {
      return structure.preparersMetaData?.totalResults > 1 ? structure.preparersMetaData?.totalResults + ' préparateurs associés' : structure.preparersMetaData?.totalResults + ' préparateur associé';
    }
    return 'Aucun préparateur associé';
  }

  customerSites(structure: Structure): string {
    if (structure?.customerSitesMetaData?.totalResults > 0) {
      return structure.customerSitesMetaData?.totalResults > 1 ? structure.customerSitesMetaData?.totalResults + ' sites associés' : structure.customerSitesMetaData?.totalResults + ' site associé';
    }
    return 'Aucun site associé';
  }

}
