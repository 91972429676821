<div nz-row class="h-100-p">
  <div nz-col  nzXs="0" nzMd="8" nzLg="16" nzXl="18" *ngIf="(googleMapsUtil.loaded | async); else noMaps">
    <google-map [options]="mapsOptions">
      <map-marker *ngFor="let markerPosition of mapsMarkerPositions" [position]="markerPosition" [options]="mapsMarkerOptions"></map-marker>
    </google-map>
  </div>
  <ng-template #noMaps>
    <div nz-col nzXs="0" nzMd="8" nzLg="16" nzXl="18" class="background"></div>
  </ng-template>
  <div nz-col nzXs="24" nzMd="16" nzLg="8" nzXl="6" class="sidebar">
    <div nz-row nzAlign="middle" class="h-100-p">
      <div nz-col nzSpan="24">
        <div nz-row [nzGutter]="[0, 16]">
          <div nz-col nzSpan="24">
            <img src="/assets/img/logo.png" class="logo" />
          </div>
          <div nz-col nzSpan="24">
            <form nz-form nzLayout="vertical" [formGroup]="form" (submit)="login()" class="w-100-p">
              <div>
                <nz-form-item>
                  <nz-form-label nzFor="username" nzRequired>Nom d'utilisateur</nz-form-label>
                  <nz-form-control [nzErrorTip]="loginError" [nzWarningTip]="loginWarning" [nzValidateStatus]="usernameValidationStatus ? usernameValidationStatus : 'validating'">
                    <input
                      nz-input
                      id="username"
                      type="email"
                      placeholder="identifiant@email.com"
                      nzSize="large"
                      formControlName="username"/>
                    <ng-template #loginError let-control>
                      <ng-container *ngIf="control?.hasError('required')">
                        Le nom d'utilisateur est requis
                      </ng-container>
                      <ng-container *ngIf="control?.hasError('email')">
                        Le nom d'utilisateur doit être un email valide
                      </ng-container>
                    </ng-template>
                    <ng-template #loginWarning>
                      <ng-container *ngIf="emailErrors?.suggestion">
                        <span>Vouliez-vous dire : </span>
                        <span (click)="this.form.get('username')?.setValue(emailErrors?.suggestion.full)" [style.textDecoration]="'underline'" [style.cursor]="'pointer'">
                          {{ emailErrors?.suggestion.domain }}
                        </span>
                        <span> ?</span>
                      </ng-container>
                    </ng-template>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzFor="password" nzRequired>Mot de passe</nz-form-label>
                  <nz-form-control [nzErrorTip]="passwordError">
                    <nz-input-group [nzSuffix]="suffixTemplate" nzSize="large">
                      <input nz-input id="password" [type]="passwordVisible ? 'text' : 'password'" placeholder="•••••••"
                        formControlName="password"/>
                    </nz-input-group>
                    <ng-template #suffixTemplate>
                      <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                        (click)="passwordVisible = !passwordVisible"></i>
                    </ng-template>
                    <ng-template #passwordError let-control>
                      <ng-container *ngIf="control.hasError('required')">
                        Le mot de passe est requis
                      </ng-container>
                    </ng-template>
                  </nz-form-control>
                </nz-form-item>
                <button nz-button nzSize="large" nzType="primary" class="w-100-p" [nzLoading]="isLoading">Se connecter</button>
              </div>
            </form>
          </div>
          <div nz-col nzSpan="24" class="tc">
            <a [routerLink]="['/', routes.forgotPassword.path]">Mot de passe oublié ?</a>
          </div>
          <div nz-col nzSpan="24" class="tc" *ngIf="environmentString">
            <button class="env" nz-button nzGhost disabled>{{ environmentString }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
