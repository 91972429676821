import { Type, Deserializer } from 'typeserializer';
import { PagedResponse, PagedResponseMetadata } from '../api/shared/paged/paged-response.interface';
import { Actor } from './actor';
import { CustomerSite } from './customer-site';
import { PagedResponseConverter } from '../../type-converters/paged-response.converter';
import addressFormatter from '@fragaria/address-formatter/dist/es/address-formatter';
import { Contact } from './contact';
import { LaveoFile } from './file';

export class Customer extends Actor {
  @Type(LaveoFile)
  logo?: LaveoFile;

  @Deserializer(PagedResponseConverter.deserialize)
  customerSites: PagedResponse<CustomerSite>;

  @Type([Contact])
  contacts: Contact[];

  address: string;
  city: string;
  postalCode: string;
  country: string;
  latitude: number;
  longitude: number;
  customerSitesMetaData: PagedResponseMetadata;

  public get fullAddress(): string {
    const address: string = addressFormatter.format(
      {
        road: this.address,
        city: this.city,
        postcode: this.postalCode,
        countryCode: this.country,
      },
      {
        appendCountry: true,
        fallbackCountryCode: 'FR',
      } as any
    ) as string;
    return address.trim().replaceAll('\n', ', ');
  }
}

export class CustomerArray {
  @Type([Customer])
  children: Customer[];
}
