<form nz-form [formGroup]="form" nzLayout="vertical">
  <div nz-row  [nzGutter]="[24, 0]">
    <div nz-col nzSpan="24">
      <div nz-row [nzGutter]="[24, 0]">
        <div nz-col nzFlex="0 0 auto" *ngIf="vehicle">
          <nz-form-item>
            <nz-form-label nzFor="active">Statut</nz-form-label>
            <nz-form-control>
              <nz-radio-group formControlName="active" nzButtonStyle="solid" nzSize="large">
                <label nz-radio-button [nzValue]="true">Activé</label>
                <label nz-radio-button [nzValue]="false">Désactivé</label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzFlex="1">
          <laveo-input-license-plate [control]="form.get('licensePlate')" typeSize="large"
                                     [warning]="changedWarning" [hasWarning]="licensePlateChanged"></laveo-input-license-plate>
          <ng-template #changedWarning>
            <ng-container *ngIf="licensePlateChanged">
              &#9888; Attention, il est interdit de remplacer une immatriculation par une autre !<br>
              Ne doit etre utiliser que pour la correction d'erreur de saisie.
            </ng-container>
          </ng-template>
        </div>
      </div>
    </div>

    <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="6">
      <laveo-select-actor [control]="form.get('customerSite')" [typeEntity]="customerSiteTypeEntity" typeSize="large"></laveo-select-actor>
    </div>

    <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="6">
      <laveo-select-vehicle-model [control]="form.get('model')" typeSize="large"></laveo-select-vehicle-model>
    </div>

    <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="6">
      <laveo-select-vehicle-frequency [control]="form.get('frequency')" typeSize="large"></laveo-select-vehicle-frequency>
    </div>

    <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="6">
      <laveo-select-vehicle-attribution [control]="form.get('attribution')" typeSize="large"></laveo-select-vehicle-attribution>
    </div>

    <div nz-col [nzXs]="24" [nzMd]="12">
      <nz-form-item>
        <nz-form-label nzFor="group">Groupe</nz-form-label>
        <nz-form-control>
          <input formControlName="group" nz-input placeholder="Groupe" nzSize="large"/>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzXs]="24" [nzMd]="12">
      <nz-form-item>
        <nz-form-label nzFor="collaborator">Collaborateur</nz-form-label>
        <nz-form-control>
          <input formControlName="collaborator" nz-input placeholder="Collaborateur" nzSize="large"/>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-label nzFor="comment">Commentaire</nz-form-label>
        <nz-form-control>
          <nz-textarea-count [nzMaxCharacterCount]="500">
            <textarea formControlName="comment" nz-input placeholder="Commentaire" nzSize="large" [nzAutosize]="{ minRows: 3, maxRows: 10 }"></textarea>
          </nz-textarea-count>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>
