<form nz-form [formGroup]="filters" nzLayout="vertical">
  <div nz-row [nzGutter]="8">
    <div nz-col nzFlex="0 0 auto" *ngIf="checked.length > 0">
      <button nz-button nzType="default" nzSize="large" nz-dropdown [nzDropdownMenu]="actionMenu" [disabled]="actionLoading">
        <i *ngIf="actionLoading" nz-icon nzType="loading" class="loading-icon"></i>
        {{ checked.length }} {{ checked.length > 1 ? 'prestations séléctionnées' : 'prestation séléctionnée' }}
        <i nz-icon nzType="down"></i>
      </button>
      <nz-dropdown-menu #actionMenu="nzDropdownMenu">
        <ul nz-menu nzSelectable>
          <li nz-menu-item (click)="copyChecked()">
            <i nz-icon nzType="copy"></i>
            Renouveler
          </li>
          <li nz-menu-item nzDanger (click)="deleteChecked(cancelServiceTemplate, cancelServiceFooterTemplate)" *ngIf="checkedContainsDeletable">
            <i nz-icon nzType="delete"></i>
            Annuler
          </li>
          <ng-container *ngIf="checkedPossibleActions.length > 0">
            <li nz-menu-divider></li>
            <li nz-menu-item *ngFor="let action of checkedPossibleActions" (click)="doActionForChecked(action)">
              <ng-container *ngIf="(action | serviceActionsIcon) as icon">
                <i nz-icon [nzType]="icon"></i>
              </ng-container>
              {{ action | serviceActionsName}}
            </li>
          </ng-container>
          <li nz-menu-divider></li>
          <li nz-menu-item (click)="checked.splice(0, checked.length)">
            <i nz-icon nzType="minus-square"></i>
            Tout déséléctionner
          </li>
        </ul>
      </nz-dropdown-menu>
    </div>
    <div nz-col nzFlex="1 1 auto">
      <nz-input-group [nzPrefix]="prefixIconSearch" nzSize="large">
        <ng-template #prefixIconSearch>
          <i nz-icon nzType="search"></i>
        </ng-template>
        <input type="text" nz-input placeholder="Rechercher une prestation" formControlName="search" #search />
      </nz-input-group>
    </div>
    <div nz-col nzFlex="0 0 auto">
      <button nz-button [nzType]="filtersAreVisible ? 'primary' : 'default'" (click)="toggleFilter()" nzSize="large">
        <i nz-icon nzType="filter"></i>
      </button>
    </div>
    <div nz-col nzFlex="0 0 auto">
      <button nz-button nzType="default" (click)="toggleSort()" nzSize="large">
        <i nz-icon [nzType]="sortType === 'ASC' ? 'sort-ascending' : 'sort-descending'"></i>
      </button>
    </div>
    <div nz-col nzFlex="0 0 auto">
      <button nz-button nzType="default" nzSize="large" nz-dropdown [nzDropdownMenu]="exportMenu">
        <i nz-icon nzType="download"></i>
        Exporter
        <i nz-icon nzType="down"></i>
      </button>
      <nz-dropdown-menu #exportMenu="nzDropdownMenu">
        <ul nz-menu nzSelectable>
          <li nz-menu-item (click)="exportExcel()">
            <i nz-icon nzType="file-excel"></i>
            &nbsp;Exporter en .xlsx
          </li>
        </ul>
      </nz-dropdown-menu>
    </div>
  </div>

  <div *ngIf="filtersAreVisible">
    <div nz-row nzGutter="8" class="mt-8">
      <div nz-col [nzSm]="24" [nzMd]="11" [nzXl]="3">
        <nz-form-item>
          <nz-form-label nzFor="status">Statut</nz-form-label>
          <nz-form-control>
            <nz-select id="status" nzSize="large" nzMode="multiple" nzPlaceHolder="Tous les statuts" [nzShowArrow]="true" [nzShowSearch]="true" [nzAllowClear]="true" formControlName="status">
              <nz-option *ngFor="let status of filterStatus" [nzLabel]="status | serviceStatusName | nullCoal" [nzValue]="status"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSm]="24" [nzMd]="1" [nzXl]="1" *ngIf="userCanSeeInvoiceFilter">
        <nz-form-item>
          <nz-form-label nzFor="invoiced">Facturées</nz-form-label>
          <nz-form-control class="text-center mh-40">
            <label nz-checkbox formControlName="invoiced"></label>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSm]="24" [nzMd]="12" [nzXl]="5" *ngIf="filterTypes.length > 1">
        <nz-form-item>
          <nz-form-label nzFor="types">Type de lavage</nz-form-label>
          <nz-form-control>
            <nz-select id="types" nzSize="large" nzMode="multiple" nzPlaceHolder="Tous les types de lavages" [nzShowArrow]="true" [nzShowSearch]="true" [nzAllowClear]="true" formControlName="types">
              <nz-option *ngFor="let type of filterTypes" [nzLabel]="type | serviceTypeName | nullCoal" [nzValue]="type"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSm]="24" [nzMd]="12" [nzXl]="5" *ngIf="userCanSeeCustomerFilter">
        <laveo-select-actor
          [control]="filters.get('customers')"
          [typeEntity]="filterClientTypeEntity"
          [typeSelection]="'multiple'"
          [typeSize]="'large'"
          [placeholder]="filterCientPlaceholder">
        </laveo-select-actor>
      </div>
      <div nz-col [nzSm]="24" [nzMd]="12" [nzXl]="5" *ngIf="userCanSeeStructureFilter">
        <laveo-select-actor
          [control]="filters.get('structures')"
          [typeEntity]="filterStructureTypeEntity"
          [typeSelection]="'multiple'"
          [typeSize]="'large'"
          [label]="filterStructurePlaceholder"
          [placeholder]="filterStructurePlaceholder">
        </laveo-select-actor>
      </div>
      <div nz-col [nzSm]="24" [nzMd]="12" [nzXl]="5">
        <nz-form-item>
          <nz-form-label nzFor="dates">Dates</nz-form-label>
          <nz-form-control>
            <nz-range-picker id="dates" class="w-100-p" nzSize="large" nzFormat="dd/MM/yyyy" formControlName="dates" [nzRanges]="dateRanges"></nz-range-picker>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </div>
</form>

<ng-template #copyForm let-params>
  <ng-container *ngIf="this.checked.length === 1; else renewAll">
    <p>Voulez-vous vraiment renouveler <b>la prestation sélectionnée</b> ?</p>
  </ng-container>
  <ng-template #renewAll>
    <p>Voulez-vous vraiment renouveler les <b>{{ this.checked.length }} préstations sélectionnées</b> ?</p>
  </ng-template>
  <p>Choississez une date pour le renouvellement</p>
  <form nz-form [formGroup]="params.copyForm">
    <nz-form-item>
      <nz-form-control [nzErrorTip]="dateErrorTip" [nzWarningTip]="dateWarningTip" [nzValidateStatus]="params.dateValidation(params.copyForm.get('date'))">
        <nz-date-picker
          id="date"
          name="date"
          formControlName="date"
          nzSize="large"
          class="w-100-p"
          [nzFormat]="'dd/MM/yyyy'"
          [nzDisabledDate]="params.disabledDates"
          [nzShowToday]="false">
        </nz-date-picker>
        <ng-template #dateErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">La date de renouvellement est requise</ng-container>
        </ng-template>
        <ng-template #dateWarningTip>
          <ng-container *ngIf="params.isDateTooSoon(params.copyForm.get('date').value)">
            La date demandée est dans moins de 2 jours. Êtes-vous certain de ne pas avoir fait d'erreur ?
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control [nzErrorTip]="timeErrorTip">
        <nz-time-picker
          id="time"
          name="time"
          formControlName="time"
          nzFormat="HH'h'mm"
          nzSize="large"
          class="w-100-p"
          [nzMinuteStep]="15"
          [nzDisabledHours]="params.disabledHours"
          [nzHideDisabledOptions]="true"
          [nzDefaultOpenValue]="defaultTime">
        </nz-time-picker>
      </nz-form-control>
      <ng-template #timeErrorTip let-control>
        <ng-container *ngIf="control.hasError('required')">L'heure est requise</ng-container>
      </ng-template>
    </nz-form-item>
  </form>
</ng-template>

<ng-template #workflowForm let-params>
  <div [innerHtml]="params.content" class="mb-8"></div>
  <div nz-row>
    <laveo-service-workflow [services]="params.services" [isInModal]="true" [actionEmitter]="params.emitter" (actionPerformed)="actionPerformed($event)"></laveo-service-workflow>
  </div>
</ng-template>

<ng-template #exportForm let-params>
  <form nz-form [formGroup]="params.exportForm">
    <h5 [style.marginTop]="0" [style.marginBottom]="'16px'">Informations</h5>
    <nz-form-item>
      <nz-form-label [nzSm]="10" nzFor="search">Recherche</nz-form-label>
      <nz-form-control [nzSm]="14">
        <input nz-input id="search" name="search" placeholder="Aucune recherche" formControlName="search">
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="10" nzFor="statut">Statut</nz-form-label>
      <nz-form-control [nzSm]="14">
        <nz-select nzMode="multiple" nzPlaceHolder="Tous les statuts" formControlName="status">
          <nz-option *ngFor="let item of params.exportForm.get('status')?.value" [nzLabel]="item | serviceStatusName | nullCoal" [nzValue]="item"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="10" nzFor="type">Types de lavage</nz-form-label>
      <nz-form-control [nzSm]="14">
        <nz-select nzMode="multiple" nzPlaceHolder="Tous les types de lavages" formControlName="types">
          <nz-option *ngFor="let item of params.exportForm.get('types')?.value" [nzLabel]="item | serviceTypeName | nullCoal" [nzValue]="item"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="10" nzFor="clients">Clients</nz-form-label>
      <nz-form-control [nzSm]="14">
        <nz-select nzMode="multiple" nzPlaceHolder="Tous les clients et sites clients" formControlName="customers">
          <nz-option *ngFor="let item of params.exportForm.get('customers')?.value" [nzLabel]="item.name | nullCoal" [nzValue]="item"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="10" nzFor="structures">Structures et préparateurs</nz-form-label>
      <nz-form-control [nzSm]="14">
        <nz-select nzMode="multiple" nzPlaceHolder="Toutes les structures et préparateurs" formControlName="structures">
          <nz-option *ngFor="let item of params.exportForm.get('structures')?.value" [nzLabel]="item.name | nullCoal" [nzValue]="item"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="10" nzFor="dates">Dates</nz-form-label>
      <nz-form-control [nzSm]="14">
        <nz-range-picker id="dates" name="dates" formControlName="dates" [nzFormat]="'dd/MM/yyyy'"
          [nzAllowClear]="false" [nzRanges]="dateRanges"></nz-range-picker>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="10">Facturées</nz-form-label>
      <nz-form-control [nzSm]="14">
        <nz-radio-group formControlName="invoiced">
          <label nz-radio-button [nzValue]="true">Oui</label>
          <label nz-radio-button [nzValue]="false">Non</label>
          <label nz-radio-button [nzValue]="null">Tous</label>
        </nz-radio-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="10">Trié par</nz-form-label>
      <nz-form-control [nzSm]="14">
        <nz-radio-group formControlName="sort">
          <label nz-radio-button nzValue="ASC">Ascendant</label>
          <label nz-radio-button nzValue="DESC">Descendant</label>
        </nz-radio-group>
      </nz-form-control>
    </nz-form-item>
    <h5 [style.marginTop]="0" [style.marginBottom]="'16px'">Options</h5>
    <p [style.marginBottom]="0">Données à exporter</p>
    <nz-radio-group formControlName="exportType">
      <label nz-radio-button nzValue="services">Préstations</label>
      <label nz-radio-button nzValue="reports">États des lieux</label>
    </nz-radio-group>
    <p [style.marginBottom]="0">Nombre de résultats :</p>
    <nz-radio-group formControlName="page">
      <label nz-radio-button nzValue="current" class="radio-line">Page actuelle</label>
      <label nz-radio-button nzValue="all" class="radio-line">Tous les résultats</label>
    </nz-radio-group>
    <nz-form-item>
      <nz-form-label [nzSm]="24" nzFor="filename" [style.textAlign]="'left'">Nom du fichier</nz-form-label>
      <nz-form-control>
        <nz-input-group nzAddOnAfter=".xlsx">
          <input nz-input id="filename" name="filename" placeholder="export" formControlName="filename">
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
  </form>
</ng-template>

<ng-template #cancelServiceTemplate>
  <p *ngIf="this.deletable.length === 1">Êtes-vous sûr de vouloir annuler la demande de prestation ?</p>
  <p *ngIf="this.deletable.length > 1">Êtes-vous sûr de vouloir annuler les <b>{{deletable.length}} prestations sélectionnées</b> ?</p>
  <ng-container *ngIf="this.checked.length !== this.deletable.length">
    <p *ngIf="this.checked.length - this.deletable.length === 1">({{ this.checked.length - this.deletable.length }} autre prestation ne peut plus plus être annulée à cause de son statut)</p>
    <p *ngIf="this.checked.length - this.deletable.length > 1">({{ this.checked.length - this.deletable.length }} autres prestations ne peuvent plus plus être annulées à cause de leurs statut)</p>
  </ng-container>
  <form>
    <nz-form-item>
      <nz-form-label nzFor="comment" nzRequired>Commentaire</nz-form-label>
      <nz-form-control nzErrorTip="Le commentaire est requis">
        <textarea [formControl]="cancelComment" nz-input id="comment" name="comment" [nzAutosize]="true" rows="3" placeholder="Commentaire"></textarea>
      </nz-form-control>
    </nz-form-item>
  </form>
</ng-template>

<ng-template #cancelServiceFooterTemplate let-ref="modalRef">
  <button nz-button (click)="ref.destroy()">Fermer</button>
  <button nz-button nzType="primary" nzDanger (click)="closeCancelModal(ref)" [nzLoading]="actionLoading">Annuler</button>
</ng-template>
