import { Component, Input, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Actor } from '../../../models/entities/actor';
import { PagedRequest } from '../../../models/api/shared/paged/paged-request';
import { ActorsService } from '../../../services/api/actors.service';
import { Customer } from '../../../models/entities/customer';
import { Admin } from 'src/app/shared/models/entities/admin';
import { Structure } from '../../../models/entities/structure';
import { Preparer } from '../../../models/entities/preparer';
import { CustomerSite } from '../../../models/entities/customer-site';

@Component({
  selector: 'laveo-select-actor',
  templateUrl: './select-actor.component.html',
  styleUrls: ['./select-actor.component.scss']
})
export class SelectActorComponent implements OnInit, OnDestroy {
  @Input() control: AbstractControl | null;
  @Input() typeEntity: (typeof Actor)[] = [Customer, CustomerSite];
  @Input() typeSelection: 'single' | 'multiple' = 'single';
  @Input() typeSize: 'default' | 'small' | 'large' = 'large';
  @Input() label: string;
  @Input() placeholder: string;
  @Input() showRoleName = false;
  @Input() disabled = false;
  @Input() showLabel = true;

  @Input() data?: Actor[] = [];
  @Input() disabledList: Actor[] = [];
  @Input() resetList = new EventEmitter();

  isActorLoading = true;
  private privateActorList: Actor[] = [];

  private searchParams = new BehaviorSubject<PagedRequest>(new PagedRequest({
    page: 0,
    limit: 10
  }));

  private subscriptions: Subscription[] = [];

  constructor(
    private readonly actorsService: ActorsService
  ) {}

  public get actorList(): Actor[] {
    if (this.data && this.data.length > 0) {
      return this.data;
    }

    const current = this.control?.value;

    if (Array.isArray(current)) {
      const temporaryList = [...this.privateActorList];
      for (const c of current) {
        if (c && !this.privateActorList.some(actor => actor.id === c.id)) {
          temporaryList.push(c);
        }
      }
      return temporaryList;
    } else {
      if (current && !this.privateActorList.some(actor => actor.id === current.id)) {
        return [...this.privateActorList, current];
      }
    }

    return this.privateActorList;
  }

  public get calculatedLabel(): string {
    return this.label ?? this.labelAndPlaceholder;
  }

  public get calculatedPlaceholder(): string {
    return this.placeholder ?? this.labelAndPlaceholder;
  }

  public get isCustomerAndCustomerSiteOnly(): boolean {
    const typeEntity = this.typeEntity;
    return typeEntity.length === 2 && typeEntity.includes(Customer) && typeEntity.includes(CustomerSite);
  }

  public get formControl(): UntypedFormControl {
    return this.control as UntypedFormControl;
  }

  private get labelAndPlaceholder(): string {
    const typeEntity = this.typeEntity;
    if (typeEntity.length === 1) {
      switch (typeEntity[0]) {
        case Admin: {
          return this.typeSelection === 'single' ? 'Administrateur' : 'Administrateurs';
        }
        case Customer: {
          return this.typeSelection === 'single' ? 'Client' : 'Clients';
        }
        case CustomerSite: {
          return this.typeSelection === 'single' ? 'Site client' : 'Sites client';
        }
        case Structure: {
          return this.typeSelection === 'single' ? 'Structure' : 'Structures';
        }
        case Preparer: {
          return this.typeSelection === 'single' ? 'Préparateur' : 'Préparateurs';
        }
        default: {
          return this.typeSelection === 'single' ? 'Acteur' : 'Acteurs';
        }
      }
    } else if (this.isCustomerAndCustomerSiteOnly) {
      return this.typeSelection === 'single' ? 'Client' : 'Clients';
    } else {
      return this.typeSelection === 'single' ? 'Acteur' : 'Acteurs';
    }
  }

  ngOnInit(): void {
    const searchSubscription = this.searchParams.pipe(debounceTime(500)).subscribe(parameters => {
      if (this.disabled || !parameters || (this.data && this.data.length > 0)) {
        this.isActorLoading = false;
        return;
      }

      this.isActorLoading = true;
      const actorSubscription = this.actorsService.allActors(parameters, this.typeEntity).subscribe({
        next: response => {
          if (this.isCustomerAndCustomerSiteOnly) {
            const customers = response.data.data.filter(a => a.__typename === 'Customer') as Customer[];
            const customerSites = response.data.data.filter(a => a.__typename === 'CustomerSite') as CustomerSite[];

            const newData: (Customer | CustomerSite)[] = [];
            for (const customer of customers) {
              newData.push(customer);
              for (const customerSite of customerSites.filter(filteredCustomerSite => filteredCustomerSite.customer.id === customer.id)) {
                newData.push(customerSite);
              }
            }
            this.privateActorList = newData;
          } else {
            this.privateActorList = response.data.data;
          }
          this.isActorLoading = false;
          if (actorSubscription) {
            actorSubscription.unsubscribe();
          }
        },
        error: error => {
          console.error(error);
          this.isActorLoading = false;
        }
      });

      if (actorSubscription) {
        this.subscriptions.push(actorSubscription);
      }
    });

    if (searchSubscription) {
      this.subscriptions.push(searchSubscription);
    }

    this.subscriptions.push(
      this.resetList.subscribe(() => {
        this.privateActorList = [];
        this.searchParams.next(this.searchParams.value);
      })
    );
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  onSearchActor(search: string): void {
    if (this.data && this.data.length > 0) {
      return;
    }

    const parameters = new PagedRequest({
      page: 0,
      limit: 10,
      search: search?.toLowerCase()?.trim()
    });

    this.searchParams.next(parameters);
  }

  comparer(a: any, b: any): boolean {
    return a?.id === b?.id;
  }

  isDisabled(actor: Actor): boolean {
    return this.disabledList.findIndex((a) => a?.id === actor?.id) !== -1;
  }
}
