import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'laveo-admin-params',
  templateUrl: './admin-params.component.html',
  styleUrls: ['./admin-params.component.scss']
})
export class AdminParamsComponent implements OnInit {

  constructor(
    private readonly router: Router,
    private readonly titleService: Title,
  ) {}

  ngOnInit(): void {
    this.setTitle();
  }

  goToAdmin(): void {
    void this.router.navigate(['/admin']);
  }

  private setTitle(): void {
    this.titleService.setTitle('Lavéo - Gestion des paramètres');
  }
}
