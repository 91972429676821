export abstract class Entity {
  id: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  __typename: string;

  valueOf(): any {
    return this.id;
  }
}

