import { Component, Input } from '@angular/core';
import { VehicleBrand } from '../../../../../../shared/models/entities/vehicle-brand';

@Component({
  selector: 'laveo-vehicle-brand-detail',
  templateUrl: './vehicle-brand-detail.component.html',
  styleUrls: ['./vehicle-brand-detail.component.scss']
})
export class VehicleBrandDetailComponent {
  @Input() vehicleBrand?: VehicleBrand;
}
