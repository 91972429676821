import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { EntityWrapperComponent } from '../../../shared/entity-wrapper/entity-wrapper.component';
import { Subscription } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { VehicleBrand } from '../../../../../shared/models/entities/vehicle-brand';
import { VehicleBrandsService } from '../../../../../shared/services/api/vehicles-brand.service';
import { VehicleBrandFormComponent } from './vehicle-brand-form/vehicle-brand-form.component';

@Component({
  selector: 'laveo-vehicle-brand',
  templateUrl: './vehicle-brand.component.html',
  styleUrls: ['./vehicle-brand.component.scss']
})
export class VehicleBrandComponent implements AfterViewInit, OnDestroy {
  @ViewChild(EntityWrapperComponent) entityWrapperComponent: EntityWrapperComponent;
  @ViewChild(VehicleBrandFormComponent) vehicleBrandFormComponent: VehicleBrandFormComponent;

  isReady = false;
  vehicleBrand: VehicleBrand;

  private subscriptions: Subscription[] = [];

  constructor(public readonly vehicleBrandsService: VehicleBrandsService) {}

  get title(): string {
    const vehicleBrand = this.vehicleBrand;
    if (vehicleBrand) {
      return vehicleBrand.name;
    }

    if (vehicleBrand === null) {
      return 'Nouvelle marque de véhicule';
    }

    return '';
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(this.entityWrapperComponent?.entity
      .subscribe(entity => {
        this.vehicleBrand = entity as VehicleBrand;
        if (this.vehicleBrand !== undefined) {
          this.isReady = true;
        }
      }));
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  public form(): UntypedFormGroup {
    return this.vehicleBrandFormComponent?.form;
  }
}
