import { TypeSerializerUtils } from '../../utils/type-serializer.util';
import { Entity } from './entity';
import { Type, serialize } from 'typeserializer';

export class Rse extends Entity {
  year: number;
  extraJsonData: string;

  get values(): RseValues | undefined {
    try {
      const json = JSON.parse(this.extraJsonData);
      return TypeSerializerUtils.deserialize(this.keysToCamel(json), RseValues);
    } catch {
      return;
    }
  }

  set values(v: RseValues | undefined) {
    this.extraJsonData = serialize(v);
  }

  private keysToCamel(o: any): any {
    const isObject = o === Object(o) && !Array.isArray(o) && typeof o !== 'function';
    if (isObject) {
      const n = {};

      Object.keys(o).forEach((k) => {
        n[`${ k[0].toLowerCase() }${ k.slice(1) }`] = this.keysToCamel(o[k]);
      });

      return n;
    } else if (Array.isArray(o)) {
      return o.map((index) => this.keysToCamel(index));
    }

    return o;
  }
}

export class RseVehicleCategory {
  aCitadine: number;
  bCompacte: number;
  cBerlineRoutiere: number;
  dSuv: number;
  vu: number;
  porteur: number;
  pl: number;
  horsGabarit: number;
  moto: number;
}

export class RseOptions {
  firstIntervention: number;
  vehicleVeryDirty: number;
  vehicleState: number;
  conveying: number;
  sticking: number;
  desticking: number;
  contactZoneDisinfection: number;
  interiorDisinfection: number;
}

export class RseFinishing {
  @Type(RseVehicleCategory)
  classic: RseVehicleCategory;

  @Type(RseVehicleCategory)
  employeeDiscount: RseVehicleCategory;

  @Type(RseVehicleCategory)
  restitution: RseVehicleCategory;

  @Type(RseVehicleCategory)
  fleetManagement: RseVehicleCategory;
}

export class RseStainRemoval {
  driver: number;
  passenger: number;
  backLeft: number;
  backRight: number;
  boot?: number;
  all: number;
}

export class RseValuesData {
  @Type(RseFinishing)
  interior: RseFinishing;

  @Type(RseFinishing)
  exterior: RseFinishing;

  @Type(RseFinishing)
  full: RseFinishing;

  @Type(RseOptions)
  options: RseOptions;

  @Type(RseStainRemoval)
  carpetStainRemoval: RseStainRemoval;

  @Type(RseStainRemoval)
  upholsteryStainRemoval: RseStainRemoval;
}

export class RseValues {
  @Type(RseValuesData)
  waterSaving: RseValuesData;

  @Type(RseValuesData)
  insertion: RseValuesData;

  waterConsumptionPerHousehold: number;
  etp: number;
  co2EmissionsSavings: number;
  internationalSolidarityComponent: number;
}
