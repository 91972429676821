import { Pipe, PipeTransform } from '@angular/core';
import { CustomerSiteBillingPerson } from '../models/entities/customer-site-billing-person';

@Pipe({
  name: 'customerSiteBillingPersonName'
})
export class CustomerSiteBillingPersonNamePipe implements PipeTransform {
  transform(type?: CustomerSiteBillingPerson): string | undefined {
    if (!type) {
      return;
    }

    switch (type) {
      case CustomerSiteBillingPerson.laveo: {
        return 'Lavéo';
      }
      case CustomerSiteBillingPerson.structure: {
        return 'Structure';
      }
      default: {
        return;
      }
    }
  }
}
