import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { EmailValidators, PasswordValidators } from 'ngx-validators';
import { Subscription, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ROUTES_CONFIG } from 'src/app/configs/tokens.config';
import { emailOptionsConfig } from 'src/app/configs/validators.config';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ApiErrorMessageUtil } from '../../shared/utils/api-error-message.util';

@Component({
  selector: 'laveo-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  emailForm: UntypedFormGroup;
  emailFormSent = false;
  emailResetTimeout = 0;
  emailErrors: ValidationErrors | null;
  emailFormLoading = false;

  passwordForm: UntypedFormGroup;
  passwordFormSent = false;
  token: string;

  loading = true;

  passwordVisible = false;
  confirmPasswordVisible = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly authService: AuthService,
    private readonly message: NzMessageService,
    private readonly activedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly titleService: Title,
    @Inject(ROUTES_CONFIG) private readonly routes: Record<string, Route>
  ) {}

  get emailValidationStatus(): string | AbstractControl | null {
    if (this.emailErrors?.suggestion) {
      return 'warning';
    }

    return this.emailForm.get('email');
  }

  ngOnInit(): void {
    this.setTitle();
    this.setEmailForm();
    this.setPasswordForm();

    const parametersSubscription = this.activedRoute.queryParamMap.subscribe(parameters => {
      if (parameters.has('token')) {
        this.token = parameters.get('token')!;
        this.authService.resetPasswordCheckToken(this.token).subscribe({
          next: () => {
            this.loading = false;
            void this.router.navigate([], { queryParams: { token: null }, queryParamsHandling: 'merge' });
          },
          error: error => {
            console.error(error);
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
            void this.router.navigate([this.routes.login.path]);
          }
        });
      } else {
        this.loading = false;
      }
    });
    this.subscriptions.push(parametersSubscription);
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  askForPasswordReset(): void {
    this.emailForm.controls.email.markAsDirty();
    this.emailForm.controls.email.updateValueAndValidity();

    if (this.emailForm.invalid || this.emailResetTimeout > 0) {
      return;
    }

    this.emailFormLoading = true;
    const email = (this.emailForm.get('email')?.value as string)?.trim()?.toLowerCase();
    const resetSubscription = this.authService.resetPassword(email).subscribe({
      next: () => {
        this.emailFormLoading = false;
        this.emailResetTimeout = 15;
        this.emailFormSent = true;
        const resetSubscribe = timer(0, 1000).pipe(map(index => 15 - index), take(15 + 1)).subscribe(index => {
          this.emailResetTimeout = index;
          if (index === 0) {
            resetSubscribe.unsubscribe();
          }
        });
      },
      error: error => {
        console.error(error);
        this.emailFormLoading = false;
        this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
      }
    });
    this.subscriptions.push(resetSubscription);
  }

  resetPassword(): void {
    this.passwordForm.controls.password.markAsDirty();
    this.passwordForm.controls.confirmPassword.markAsDirty();
    this.passwordForm.controls.password.updateValueAndValidity();
    this.passwordForm.controls.confirmPassword.updateValueAndValidity();

    if (this.passwordForm.invalid) {
      return;
    }

    const password = this.passwordForm.get('password')?.value;
    const resetSubscription = this.authService.resetPasswordChangePassword(this.token, password).subscribe({
      next: () => {
        this.passwordFormSent = true;
      },
      error: error => {
        console.error(error);
        this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
      }
    });
    this.subscriptions.push(resetSubscription);
  }

  private setEmailForm(): void {
    this.emailForm = this.formBuilder.group({
      email: this.formBuilder.control(null, [Validators.required, Validators.email])
    });

    const emailSubscription = this.emailForm.get('email')?.valueChanges.subscribe(() => {
      const validate = EmailValidators.suggest(emailOptionsConfig);
      const emailField = this.emailForm.get('email');
      if (emailField) {
        this.emailErrors = validate(emailField);
      }
    });

    if (emailSubscription) {
      this.subscriptions.push(emailSubscription);
    }
  }

  private setPasswordForm(): void {
    this.passwordForm = this.formBuilder.group({
      password: this.formBuilder.control(null, [
        Validators.required,
        Validators.minLength(8),
        PasswordValidators.digitCharacterRule(1),
        PasswordValidators.lowercaseCharacterRule(1),
        PasswordValidators.uppercaseCharacterRule(1),
        PasswordValidators.specialCharacterRule(1)
      ]),
      confirmPassword: this.formBuilder.control(null, [Validators.required, Validators.minLength(8)])
    });
    this.passwordForm.validator = PasswordValidators.mismatchedPasswords('password', 'confirmPassword');
  }

  private setTitle() {
    this.titleService.setTitle('Lavéo - Mot de passe oublié');
  }
}
