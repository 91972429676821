<div class="license-plate license-plate-{{ country | lowercase }}">
  <span class="license-plate-symbol" [ngSwitch]="country">
    <ng-container *ngSwitchCase="'FR'">F</ng-container>
    <ng-container *ngSwitchCase="'BE'">B</ng-container>
    <ng-container *ngSwitchCase="'SN'">SN</ng-container>
    <ng-container *ngSwitchCase="'GA'"></ng-container>
    <ng-container *ngSwitchCase="'CH'">+</ng-container>
    <ng-container *ngSwitchDefault>{{ country }}</ng-container>
  </span>
  <span>{{ licensePlate }}</span>
</div>
