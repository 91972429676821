import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError, of } from 'rxjs';
import { AuthResponseInterface } from '../models/interfaces/auth/auth-response.interface';
import { environment } from '../../../environments/environment';
import { tap, catchError, map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Apollo, gql, MutationResult } from 'apollo-angular';
import { CookieName } from 'src/app/configs/cookies.config';
import { CustomHttpParameterEncoder } from '../utils/custom-http-parameter-encoder';
import { ApiErrorMessageUtil } from '../utils/api-error-message.util';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private refreshInProgress: boolean;
  private refreshRequest$ = new Subject<boolean | AuthResponseInterface>();

  constructor(
      private http: HttpClient,
      private apollo: Apollo,
      private cookie: CookieService,
      private message: NzMessageService
  ) {}

  get token(): string | undefined {
      if (this.cookie.check(CookieName.accessToken)) {
          return this.cookie.get(CookieName.accessToken);
      }

      return;
  }

  get isAuthenticated(): boolean {
    return this.cookie.check(CookieName.accessToken);
  }

  get canRefresh(): boolean {
    return this.cookie.check(CookieName.refreshToken);
  }

  get roleId(): string | undefined {
    return this.cookie.check(CookieName.role) ? this.cookie.get(CookieName.role) : undefined;
  }

  logout(): void {
    this.cookie.deleteAll('/');
  }

  login(username: string, password: string): Observable<AuthResponseInterface> {
    if (environment.api.mock) {
      return this.http.get<AuthResponseInterface>('/assets/mock/auth/login.json').pipe(
        tap(data => this.saveToken(data))
      );
    }

    const parameters = new HttpParams({
      encoder: new CustomHttpParameterEncoder()
    })
      .append('username', username.trim())
      .append('password', password.trim())
      .append('grant_type', 'password')
      .append('client_id', environment.oauth.clientId)
      .append('client_secret', environment.oauth.clientSecret);

    return this.http.post<AuthResponseInterface>('/connect/token', parameters, {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    }).pipe(
      tap((data: AuthResponseInterface) => this.saveToken(data))
    );
  }

  refresh(): Observable<boolean | AuthResponseInterface> {
    if (this.canRefresh) {
      if (!this.refreshInProgress) {
        this.refreshInProgress = true;

        if (environment.api.mock) {
          return this.http.get<AuthResponseInterface>('/assets/mock/auth/login.json').pipe(
            tap((data: AuthResponseInterface) => {
              this.saveToken(data);
              this.refreshInProgress = false;
              this.refreshRequest$.next(data);
            })
          );
        }

        const parameters = new HttpParams({
          encoder: new CustomHttpParameterEncoder()
        })
          .append('grant_type', 'refresh_token')
          .append('refresh_token', this.cookie.get(CookieName.refreshToken))
          .append('client_id', environment.oauth.clientId)
          .append('client_secret', environment.oauth.clientSecret)
          .append('scope', 'api offline_access');

        return this.http.post<AuthResponseInterface>('/connect/token', parameters, {
          headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        }).pipe(
          catchError(error => {
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
            this.logout();
            this.refreshInProgress = false;
            this.refreshRequest$.next(false);
            return throwError(() => error);
          }),
          tap((data: AuthResponseInterface) => {
            this.saveToken(data);
            this.refreshInProgress = false;
            this.refreshRequest$.next(data);
          })
        );
      }

      return this.refreshRequest$.asObservable();
    }

    this.logout();
    return of(false);
  }

  resetPassword(username: string): Observable<MutationResult<void>> {
    const mutation = gql`
      mutation resetPassword($username: String!) {
        resetPassword {
          sendMail(username: $username)
        }
      }
    `;

    return this.apollo.mutate<void>({ mutation, variables: { username } });
  }

  resetPasswordCheckToken(token: string): Observable<MutationResult<void>> {
    const mutation = gql`
      mutation resetPasswordCheckToken($token: Guid!) {
        resetPassword {
          checkToken(token: $token)
        }
      }
    `;

    return this.apollo.mutate<void>({ mutation, variables: { token }});
  }

  resetPasswordChangePassword(token: string, password: string): Observable<MutationResult<void>> {
    const mutation = gql`
      mutation resetPasswordChangePassword($token: Guid!, $password: String!) {
        resetPassword {
          changePassword(token: $token, password: $password) {
            id
          }
        }
      }
    `;
    return this.apollo.mutate<void>({ mutation, variables: { token, password }});
  }

  confirmMailCheckToken(token: string): Observable<MutationResult<boolean>> {
    const mutation = gql`
      mutation confirmMailCheckToken($token: Guid!) {
        users {
          confirmMail(token: $token) {
            id
          }
        }
      }
    `;

    return this.apollo.mutate<{ confirmMail: { confirmMail: boolean } }>({
      mutation,
      variables: { token }
    }).pipe(map(result => ({

      data: result.data?.confirmMail?.confirmMail,
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }

  checkToken(): Observable<any> {
    if (environment.api.mock) {
      return this.http.get<any>('/assets/mock/auth/check-token.json').pipe(
        tap(data => this.saveToken(data))
      );
    }

    return this.http.get<any>('/connect/userinfo');
  }

  private saveToken(token: AuthResponseInterface): void {
    const expireDate = new Date();
    const expireRefreshDate = new Date();
    expireDate.setSeconds(expireDate.getSeconds() + token.expires_in);
    expireRefreshDate.setDate(expireRefreshDate.getDate() + 300); // 5 minutes pour etre sur de choper une 401 (pas très beau ...)
    this.cookie.set(CookieName.accessToken, token.access_token, expireDate, '/', undefined, !environment.hmr);
    this.cookie.set(CookieName.refreshToken, token.refresh_token, expireRefreshDate, '/', undefined, !environment.hmr);
    this.cookie.delete(CookieName.role, '/', undefined, !environment.hmr);
  }
}
