<div class="content" nz-row nzAlign="middle">
  <div nz-col [nzXs]="24" [nzLg]="{ span: 12, offset: 6 }">
    <nz-card [nzBordered]="false">
      <div *ngIf="!this.isLoading else loadingTemplate">
        <div *ngIf="this.formSent else formTemplate">
          <nz-result
            nzStatus="success"
            nzTitle="Compte activé avec succès"
            nzSubTitle="Votre compte à bien été activé. Utilisez le bouton ci-dessous pour vous connecter.">
            <div nz-result-extra>
              <button nz-button [routerLink]="'/'">Se connecter</button>
            </div>
          </nz-result>
        </div>
      </div>
    </nz-card>
  </div>
</div>
<ng-template #loadingTemplate>
  <nz-skeleton></nz-skeleton>
</ng-template>
<ng-template #formTemplate>
  <div nz-col nzSpan="24" class="tc">
    <img src="/assets/img/logo.png" class="logo" />
  </div>
  <div nz-col nzSpan="24">
    <h4>Bienvenue sur votre nouveau compte Lavéo,</h4>
    <p>Pour vous connecter, veuillez entrer le nouveau mot de passe de votre compte ci-dessous.</p>
  </div>
  <div nz-col nzSpan="24">
    <form nz-form [formGroup]="form" nzLayout="vertical" (submit)="confirmAccount()">
      <nz-form-item>
        <nz-form-label nzFor="password" nzRequired>Nouveau mot de passe</nz-form-label>
        <nz-form-control [nzErrorTip]="passwordError">
          <nz-input-group [nzSuffix]="suffixTemplate" nzSize="large">
            <input
              nz-input
              id="password"
              [type]="passwordVisible ? 'text' : 'password'"
              placeholder="••••••••••"
              passwordrules="required: lower; required: upper; required: digit; required: special; minlength: 8;"
              nzSize="large"
              formControlName="password"/>
            <ng-template #suffixTemplate>
              <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="passwordVisible = !passwordVisible"></i>
            </ng-template>
          </nz-input-group>
          <ng-template #passwordError let-control>
            <span *ngIf="control.hasError('required')">Le nouveau mot de passe est requis</span>
            <span *ngIf="control.hasError('minlength')">Le nouveau mot de passe doit contenir au minimum 8 caractères</span>
            <div *ngIf="!control.hasError('required')" class="validation-errors">
              <i nz-icon [nzType]="control.hasError('digitCharacterRule') ? 'warning' : 'check'"></i><span> Chiffre</span>
              <i nz-icon [nzType]="control.hasError('lowercaseCharacterRule') ? 'warning' : 'check'"></i><span> Minuscule</span>
              <i nz-icon [nzType]="control.hasError('uppercaseCharacterRule') ? 'warning' : 'check'"></i><span> Majuscule</span>
              <i nz-icon [nzType]="control.hasError('specialCharacterRule') ? 'warning' : 'check'"></i><span> Caractère Spécial</span>
            </div>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzFor="confirmPassword" nzRequired>Confirmez le mot de passe</nz-form-label>
        <nz-form-control [nzErrorTip]="confirmPasswordError">
          <nz-input-group [nzSuffix]="confirmSuffixTemplate" nzSize="large">
            <input
              nz-input
              id="confirmPassword"
              [type]="confirmPasswordVisible ? 'text' : 'password'"
              placeholder="••••••••••"
              nzSize="large"
              formControlName="confirmPassword"/>
            <ng-template #confirmSuffixTemplate>
              <i nz-icon [nzType]="confirmPasswordVisible ? 'eye-invisible' : 'eye'" (click)="confirmPasswordVisible = !confirmPasswordVisible"></i>
            </ng-template>
          </nz-input-group>
          <ng-template #confirmPasswordError let-control>
            <span *ngIf="control.hasError('required')">Confirmer le mot de passe est requis</span>
            <span *ngIf="form.hasError('mismatchedPasswords')">Les mots de passe doivent être identique</span>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <div nz-row [nzGutter]="[16, 16]">
        <div nz-col nzFlex="1">
          <button nz-button type="submit" nzSize="large" nzType="primary" class="w-100-p" [nzLoading]="isFormLoading">Activer mon compte</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
