import { Component, Input } from '@angular/core';
import { Contact } from '../../models/entities/contact';

@Component({
  selector: 'laveo-list-contacts',
  templateUrl: './list-contacts.component.html',
  styleUrls: ['./list-contacts.component.scss']
})
export class ListContactsComponent {
  @Input() contacts: Contact[];
  @Input() showNotificationsCheckbox = false;
}
