"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const consts_1 = require("../consts");
const Decorator_1 = require("./Decorator");
function Expose(fn) {
  return Decorator_1.createDecorator("Expose", consts_1.ExposeSymbol, fn || (() => true));
}
exports.Expose = Expose;
