import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'laveo-image-button',
  templateUrl: './image-button.component.html',
  styleUrls: ['./image-button.component.scss']
})
export class ImageButtonComponent {
  /** Titre du bouton */
  @Input() title: string;

  /** Sous-titre du bouton (optionel) */
  @Input() description?: string;

  /** Image du bouton (à gauche) (optionel) */
  @Input() image?: string;

  /** Avatar du bouton (à gauche) (optionel) */
  @Input() avatar?: string;

  /** Icon du bouton (à droite) (optionel), par défaut : 'right-square' */
  @Input() icon?: string = 'right-square';

  /** État du bouton, par défaut : activé */
  @Input() enabled = true;

  /** Action au click */
  @Output() buttonClick = new EventEmitter();


  onClick(): void {
    if (this.enabled) {
      this.buttonClick.emit();
    }
  }
}
