"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
require("reflect-metadata");
// Property Decorators
exports.ExcludeSymbol = Symbol('Exclude');
exports.ExposeSymbol = Symbol('Expose');
exports.GroupsSymbol = Symbol('Groups');
exports.BeforeSymbol = Symbol('Before');
exports.AfterSymbol = Symbol('After');
exports.NameSymbol = Symbol('Name');
exports.TypeSymbol = Symbol('Type');
exports.SerializerSymbol = Symbol('Serializer');
exports.DeserializerSymbol = Symbol('Deserializer');
// Class Decorators
exports.StrategySymbol = Symbol('Strategy');
// Enums
var ExclusionPolicy;
(function (ExclusionPolicy) {
  ExclusionPolicy[ExclusionPolicy["NONE"] = 0] = "NONE";
  ExclusionPolicy[ExclusionPolicy["ALL"] = 1] = "ALL";
})(ExclusionPolicy = exports.ExclusionPolicy || (exports.ExclusionPolicy = {}));
// Consts
exports.Detector = {
  CIRCULAR_REFERENCE: Symbol('CircularReference')
};
