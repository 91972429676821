import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { PasswordValidators } from 'ngx-validators';
import { Subscription } from 'rxjs';
import { ROUTES_CONFIG } from 'src/app/configs/tokens.config';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ApiErrorMessageUtil } from '../../shared/utils/api-error-message.util';

@Component({
  selector: 'laveo-new-account',
  templateUrl: './new-account.component.html',
  styleUrls: ['./new-account.component.scss']
})
export class NewAccountComponent implements OnInit, OnDestroy {
  isLoading = true;
  isFormLoading = false;
  passwordVisible = false;
  confirmPasswordVisible = false;
  formSent = false;
  form: UntypedFormGroup;

  private subscriptions: Subscription[] = [];
  private token: string;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly message: NzMessageService,
    private readonly authService: AuthService,
    private readonly titleService: Title,
    @Inject(ROUTES_CONFIG) private readonly routes: Record<string, Route>
  ) {}

  ngOnInit(): void {
    this.setTitle();
    this.setForm();
    this.checkToken();
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  confirmAccount(): void {
    for (const key of Object.keys(this.form.controls)) {
      this.form.get(key)?.markAsDirty();
      this.form.get(key)?.updateValueAndValidity();
    }

    if (this.form.invalid) {
      return;
    }

    this.isFormLoading = true;
    const password = this.form.get('password')?.value;

    const resetSubscription = this.authService.resetPasswordChangePassword(this.token, password).subscribe({
      next: () => {
        this.isFormLoading = false;
        this.formSent = true;
      },
      error: error => {
        console.error(error);
        this.isFormLoading = false;
        this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
      }
    });
    this.subscriptions.push(resetSubscription);
  }

  private setForm(): void {
    this.form = this.formBuilder.group({
      password: this.formBuilder.control(null, [
        Validators.required,
        Validators.minLength(8),
        PasswordValidators.digitCharacterRule(1),
        PasswordValidators.lowercaseCharacterRule(1),
        PasswordValidators.uppercaseCharacterRule(1),
        PasswordValidators.specialCharacterRule(1)
      ]),
      confirmPassword: this.formBuilder.control(null, [Validators.required, Validators.minLength(8)])
    });
    this.form.validator = PasswordValidators.mismatchedPasswords('password', 'confirmPassword');
  }

  private checkToken(): void {
    const querySubscription = this.route.queryParamMap.subscribe(parameters => {
      if (parameters.has('token')) {
        this.token = parameters.get('token')!;
        this.authService.resetPasswordCheckToken(this.token).subscribe({
          next: () => {
            this.isLoading = false;
            void this.router.navigate([], { queryParams: { token: null }, queryParamsHandling: 'merge' });
          },
          error: error => {
            console.error(error);
            this.isLoading = false;
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
            void this.router.navigate([this.routes.login.path]);
          }
        });
      } else if (!this.token) {
        this.message.error('Erreur, votre lien à expiré. Réessayez votre demande.');
        void this.router.navigate([this.routes.login.path]);
      }
    });

    this.subscriptions.push(querySubscription);
  }

  private setTitle() {
    this.titleService.setTitle('Lavéo - Activation du compte');
  }
}
