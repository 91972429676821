<div nz-row nzAlign="middle" class="card-header">
  <div nz-col class="card-loading" *ngIf="isLoading">
    <nz-spin nzSimple></nz-spin>
  </div>
  <div nz-col class="card-back-button" *ngIf="!isLoading && backButtonEnabled">
    <button nz-button nzType="default" nzShape="circle" (click)="this.backButtonClick.emit()"><i nz-icon nzType="arrow-left"></i></button>
  </div>
  <div nz-col nzFlex="1 1 auto" class="card-title">
    <h2 *ngIf="!isTitleSkeleton" nz-typography>{{ title }}</h2>
    <p *ngIf="!isDescriptionSkeleton && description">{{ description }}</p>

    <nz-skeleton *ngIf="isTitleSkeleton" class="skeleton-title" [nzParagraph]="{ rows: 0 }" [nzActive]="true"></nz-skeleton>
    <nz-skeleton *ngIf="isDescriptionSkeleton" class="skeleton-description" [nzParagraph]="{ rows: 0 }" [nzActive]="true"></nz-skeleton>
  </div>
  <div nz-col nzFlex="0 0 auto" *ngIf="buttonTitle || role" class="card-details">
    <nz-tag *ngIf="role">{{ role }}</nz-tag>
    <button
      nz-button
      nzType="primary"
      nzSize="large"
      (click)="this.buttonClick.emit()"
      *ngIf="buttonTitle"
      [disabled]="!buttonEnabled"
    ><i *ngIf="buttonIcon" nz-icon [nzType]="buttonIcon"></i>{{ buttonTitle }}</button>
  </div>
</div>
