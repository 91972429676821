import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { VehicleBrand } from '../../../../../../shared/models/entities/vehicle-brand';

@Component({
  selector: 'laveo-vehicle-brand-form',
  templateUrl: './vehicle-brand-form.component.html',
  styleUrls: ['./vehicle-brand-form.component.scss']
})
export class VehicleBrandFormComponent implements OnInit {
  @Input() vehicleBrand: VehicleBrand;

  form: UntypedFormGroup;


  ngOnInit(): void {
    this.setForm();
  }

  private setForm(): void {
    this.form = new UntypedFormGroup({
      id: new UntypedFormControl(this.vehicleBrand?.id, []),
      name: new UntypedFormControl(this.vehicleBrand?.name, [Validators.required]),
      active: new UntypedFormControl(this.vehicleBrand?.active),
    });
  }
}
