<nz-form-item *ngIf="formControl">
  <nz-form-label nzFor="category">Catégorie</nz-form-label>
  <nz-form-control [nzErrorTip]="error">
    <nz-select
      [formControl]="formControl"
      [nzSize]="typeSize"
      [nzMode]="typeSelection === 'single' ? 'default' : 'multiple'"
      [nzShowArrow]="true"
      nzPlaceHolder="Catégorie"
      nzAllowClear
      class="w-100-p">
      <nz-option *ngFor="let category of vehicleCategoryValues" [nzValue]="category" [nzLabel]="category | vehicleCategoryName | nullCoal"></nz-option>
    </nz-select>
  </nz-form-control>
  <ng-template #error let-control>
    <ng-container *ngIf="control?.hasError('required')">
      La catégorie est requise
    </ng-container>
  </ng-template>
</nz-form-item>
