import { Pipe, PipeTransform } from '@angular/core';
import { VehicleCategory } from '../models/entities/vehicle-category';

@Pipe({
  name: 'vehicleCategoryName'
})
export class VehicleCategoryNamePipe implements PipeTransform {
  transform(type?: VehicleCategory | string): string | undefined {
    if (!type) {
      return;
    }

    switch (type) {
      case VehicleCategory.citadine:
      case 'ACitadine': {
        return '(A) Citadine';
      }
      case VehicleCategory.compacte:
      case 'BCompacte': {
        return '(B) Compacte';
      }
      case VehicleCategory.berline_routiere:
      case 'CBerlineRoutiere': {
        return '(C) Berline/Routière';
      }
      case VehicleCategory.suv:
      case 'DSuv': {
        return '(D) SUV';
      }
      case VehicleCategory.vu:
      case 'Vu': {
        return 'Utilitaire';
      }
      case VehicleCategory.porteur:
      case 'Porteur': {
        return 'Porteur';
      }
      case VehicleCategory.pl:
      case 'Pl': {
        return 'Poids Lourd';
      }
      case VehicleCategory.hors_gabarit:
      case 'HorsGabarit': {
        return 'Hors Gabarit';
      }
      case VehicleCategory.moto:
      case 'Moto': {
        return 'Moto';
      }
      default: {
        return 'Voiture';
      }
    }
  }
}
