import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo, gql, MutationResult } from 'apollo-angular';
import { PagedRequest } from '../../models/api/shared/paged/paged-request';
import { PagedResponse } from '../../models/api/shared/paged/paged-response.interface';
import { map } from 'rxjs/operators';
import { VehicleBrand } from '../../models/entities/vehicle-brand';
import { TypeSerializerUtils } from '../../utils/type-serializer.util';
import { VehicleBrandWithModels, VehicleBrandWithModelsArray } from '../../models/entities/vehicle-model';
import { VehicleBrandRequest } from '../../models/api/services/vehicle-brand-requests.interface';

@Injectable({
  providedIn: 'root'
})
export class VehicleBrandsService {
  constructor(
    private readonly apollo: Apollo
  ) {}

  vehicleBrand(id: string): Observable<ApolloQueryResult<VehicleBrand>> {
    const query = gql`
      query vehicleBrand($id: Guid!) {
        params {
          vehicleBrand(id: $id){
            id
            name
            active
            logo
          }
        }
      }
    `;

    return this.apollo.query<{ params: { vehicleBrand: VehicleBrand } }>({
      query,
      variables: { id }
    }).pipe(map(result => ({
      data: TypeSerializerUtils.deserialize(result.data?.params?.vehicleBrand, VehicleBrand),
      error: result.error,
      errors: result.errors,
      partial: result.partial,
      loading: result.loading,
      networkStatus: result.networkStatus
    })));
  }

  allVehicleBrands(parameters: PagedRequest): Observable<ApolloQueryResult<PagedResponse<VehicleBrandWithModels>>> {
    const query = gql`
      query vehicleBrands($offset: Int, $limit: Int, $sortProperty: String, $sortType: ESortType, $search: String) {
        params {
          vehicleBrands(offset: $offset, limit: $limit, sortProperty: $sortProperty, sortType: $sortType, search: $search) {
            data {
              id
              active
              name
              logo
            }
            metadata {
              currentPage
              currentResult
              totalPages
              totalResults
              hasMore
            }
          }
        }
      }
    `;
    return this.apollo.query<{ params: { vehicleBrands: PagedResponse<VehicleBrandWithModels> } }>({
      query,
      variables: {
        offset: parameters.offset,
        limit: parameters.limit,
        // sortProperty: parameters.sortProperty,
        sortType: parameters.sortType,
        search: parameters.search
      }
    }).pipe(map(result => {
      const raw = result.data?.params?.vehicleBrands;
      const response: PagedResponse<VehicleBrandWithModels> = {
        data: TypeSerializerUtils.deserializeArr(raw.data, VehicleBrandWithModelsArray),
        metadata: raw.metadata
      };
      return {
        data: response,
        error: result.error,
        errors: result.errors,
        partial: result.partial,
        loading: result.loading,
        networkStatus: result.networkStatus
      };
    }));
  }

  addVehicleBrand(data: VehicleBrandRequest): Observable<MutationResult<VehicleBrand>> {
    const query = gql`
      mutation addVehicleBrand($data: VehicleBrandRequest) {
        params {
          vehicleBrands {
            add (data: $data) {
              id
              active
              name
              logo
            }
          }
        }
      }
    `;

    return this.apollo.mutate<{ params: { vehicleBrands: { add: VehicleBrand } } }>({
      mutation: query,
      variables: {
        data: {
          name: data.name,
          active: data.active,
        }
      }
    }).pipe(map(result => ({

      data: TypeSerializerUtils.deserialize(result.data?.params?.vehicleBrands?.add, VehicleBrand),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }

  updateVehicleBrand(data: VehicleBrandRequest): Observable<MutationResult<VehicleBrand>> {
    const query = gql`
      mutation updateVehicleBrand($id: Guid!, $data: VehicleBrandRequest) {
        params {
          vehicleBrands {
            update (id: $id, data: $data) {
              id
              active
              name
              logo
            }
          }
        }
      }
    `;

    return this.apollo.mutate<{ params: { vehicleBrands: { update: VehicleBrand } } }>({
      mutation: query,
      variables: {
        id: data.id,
        data: {
          name: data.name,
          active: data.active,
        }
      }
    }).pipe(map(result => ({

      data: TypeSerializerUtils.deserialize(result.data?.params?.vehicleBrands?.update, VehicleBrand),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }

  deleteVehicleBrand(...ids: string[]): Observable<MutationResult<void>> {
    const mutation = `
      mutation deleteBrand(${ids.map((_, index) => '$id' + index + ': Guid!').join(', ')}) {
        params {
          vehicleBrands {
            ${ ids.map((_, index) => 'id' + index + ': delete (id: $id' + index + ')').join('\n') }
          }
        }
      }
    `;

    const variables = ids.reduce((response, item, index) => {
      response['id' + index] = item;
      return response;
    }, {});

    return this.apollo.mutate<void>({
      mutation: gql(mutation),
      variables
    });
  }
}
