import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { UserRoleType } from '../../../models/entities/user-role';

@Component({
  selector: 'laveo-select-role-type',
  templateUrl: './select-role-type.component.html',
  styleUrls: ['./select-role-type.component.scss']
})
export class SelectRoleTypeComponent {
  @Input() control: AbstractControl | null;
  @Input() typeSize: 'default' | 'small' | 'large' = 'large';
  @Input() showLabel = true;
  @Input() disabled = false;

  public get values(): UserRoleType[] {
    return [
      UserRoleType.admin,
      UserRoleType.customer,
      UserRoleType.customerSite,
      UserRoleType.customerSiteRead,
      UserRoleType.preparer,
      UserRoleType.structure,
      UserRoleType.structureRead
    ];
  }

  public get formControl(): UntypedFormControl {
    return this.control as UntypedFormControl;
  }
}
