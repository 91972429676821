<div nz-row [nzGutter]="[48, 24]">
  <div nz-col nzSpan="24">
    <div nz-row [nzGutter]="[48, 0]">
      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Actif</span>
          <span class="detail-content" nz-typography>
            <i nz-icon [nzType]="user?.active ? 'check-square' : 'close-square'" nzTheme="outline"></i>
            {{ user?.active ? 'Activé' : 'Désactivé' }}
          </span>
        </div>
      </div>

      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Compte activé par l'utilisateur</span>
          <span class="detail-content" nz-typography>
            <span>
              <i nz-icon [nzType]="user?.activatedByUser ? 'check-square' : 'close-square'" nzTheme="outline"></i>
              {{ user?.activatedByUser ? 'Oui' : 'Non' }}
            </span>
          </span>

          <div *ngIf="!user?.activatedByUser">
            <button nz-button nzType="primary" [disabled]="actionLoading" (click)="sendMailActivation()">
              Renvoyer le mail d'activation
            </button>
          </div>
        </div>
      </div>

      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Nom</span>
          <span class="detail-content" nz-typography>{{ user?.name | nullCoal }}</span>
        </div>
      </div>
    </div>
  </div>

  <div nz-col nzSpan="24">
    <div class="detail">
      <span class="detail-title" nz-typography nzType="secondary">Mail</span>
      <span class="detail-content" nz-typography>
        {{ (user?.mailValidation ? user?.mailValidation : user?.mail) | nullCoal }}
        <i nz-icon [nzType]="user?.mailValidation ? 'clock-circle' : 'check'" nz-tooltip [nzTooltipTitle]="user?.mailValidation ? 'En attente de validation' : 'Validé'"></i>
      </span>
    </div>
  </div>

  <div nz-col nzSpan="24">
    <div class="detail">
      <span class="detail-title" nz-typography nzType="secondary">Rôles</span>
      <ng-container *ngIf="user?.roles?.length === 0; else roles">
        <span class="detail-content" nz-typography>Aucun rôle associé</span>
      </ng-container>
      <ng-template #roles>
        <ul>
          <li class="detail-content" nz-typography *ngFor="let role of user?.roles">
            {{ role.actor.name }} ( {{ role.type | roleName }} <i nz-icon nzType="crown" nzTheme="outline" *ngIf="role.type === 'SUPER_ADMIN'"></i>)
          </li>
        </ul>
      </ng-template>
    </div>
  </div>
</div>
