import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { CardHeaderComponent } from './card-header/card-header.component';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { EntityWrapperComponent } from './entity-wrapper/entity-wrapper.component';

@NgModule({
  declarations: [
    CardHeaderComponent,
    EntityWrapperComponent,
  ],
  imports: [
    CommonModule,
    NzGridModule,
    NzCardModule,
    NzIconModule,
    NzButtonModule,
    NzTagModule,
    NzSpinModule,
    NzSkeletonModule,
  ],
  exports: [
    CardHeaderComponent,
    EntityWrapperComponent,
  ]
})
export class MainSharedModule {}
