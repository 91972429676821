<form nz-form [formGroup]="form" nzLayout="vertical" (keydown.enter)="$event.preventDefault()">
  <div nz-row [nzGutter]="[24, 0]">
    <div nz-col nzSpan="24">
      <div nz-row [nzGutter]="24">
        <div nz-col nzSpan="6">
          <nz-form-item>
            <nz-form-label>
              <ng-container *ngIf="customerSite?.logo; else logoTitle">Nouveau Logo</ng-container>
              <ng-template #logoTitle>Logo</ng-template>
            </nz-form-label>
            <nz-form-control>
              <file-pond [options]="fileUploadOptions" (onpreparefile)="addLogo($event)" (onremovefile)="removeLogo()"></file-pond>
            </nz-form-control>
            <span class="hint">Taille max. {{ this.fileUploadOptions.maxFileSize }}</span>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="6" *ngIf="customerSite?.logo">
          <p>Logo actuel</p>
          <img class="current-logo" [src]="customerSite?.logo?.urlWithDomain" alt="Logo" />
        </div>
      </div>
    </div>

    <div nz-col nzSpan="24">
      <div nz-row [nzGutter]="[24, 0]">
        <div nz-col nzFlex="0 0 auto" *ngIf="customerSite">
          <nz-form-item>
            <nz-form-label nzFor="active">Actif</nz-form-label>
            <nz-form-control>
              <nz-radio-group formControlName="active" nzButtonStyle="solid" [nzSize]="'large'">
                <label nz-radio-button [nzValue]="true">Activé</label>
                <label nz-radio-button [nzValue]="false">Désactivé</label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzFlex="1">
          <nz-form-item>
            <nz-form-label nzFor="name">Nom</nz-form-label>
            <nz-form-control [nzErrorTip]="nameError">
              <input formControlName="name" nz-input [nzSize]="'large'" placeholder="Nom"/>
            </nz-form-control>
            <ng-template #nameError let-control>
              <ng-container *ngIf="control.hasError('required')">Le nom du client est requis</ng-container>
            </ng-template>
          </nz-form-item>
        </div>
      </div>
    </div>

    <div nz-col [nzXs]="24" *ngIf="googleMapsUtil.loaded | async">
      <nz-form-item>
        <nz-form-label nzFor="fullAddress">Adresse</nz-form-label>
        <nz-form-control [nzErrorTip]="fullAddressError">
          <input
            formControlName="fullAddress"
            nz-input
            nzSize="large"
            placeholder="Adresse"
            ngx-gp-autocomplete
            (onAddressChange)="onFullAddressChange($event)"
            [options]="placesAutocompleteOptions" />
        </nz-form-control>
        <ng-template #fullAddressError let-control>
          <ng-container *ngIf="control.hasError('required')">Une adresse complète est requise</ng-container>
        </ng-template>
      </nz-form-item>
    </div>

    <div nz-col [nzXs]="24" [nzSm]="12">
      <laveo-select-actor [control]="form.get('structure')" [typeEntity]="typeStructure"></laveo-select-actor>
    </div>

    <div nz-col [nzXs]="24" [nzSm]="12">
      <laveo-select-actor [control]="form.get('customer')" [typeEntity]="typeCustomer"></laveo-select-actor>
    </div>

    <div nz-col [nzXs]="24" [nzSm]="12">
      <laveo-select-customer-site-contract-type [control]="form.get('contractType')"></laveo-select-customer-site-contract-type>
    </div>

    <div nz-col [nzXs]="24" [nzSm]="12">
      <laveo-select-customer-site-workflow-type [control]="form.get('workflowType')"></laveo-select-customer-site-workflow-type>
    </div>

    <div nz-col [nzXs]="24" [nzSm]="12">
      <nz-form-item>
        <nz-form-label nzFor="frequencyServiceAffectedVehicle">Fréquence affecté</nz-form-label>
        <nz-form-control>
          <nz-input-group nzPrefix="Tous les" nzSuffix="jours" [nzSize]="'large'">
            <input type="number" formControlName="frequencyServiceAffectedVehicle" nz-input placeholder="Fréquence affecté"/>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzXs]="24" [nzSm]="12">
      <nz-form-item>
        <nz-form-label nzFor="frequencyServiceMutualizedVehicle">Fréquence mutualisé</nz-form-label>
        <nz-form-control>
          <nz-input-group nzPrefix="Tous les" nzSuffix="jours" [nzSize]="'large'">
            <input type="number" formControlName="frequencyServiceMutualizedVehicle" nz-input placeholder="Fréquence mutualisé"/>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzXs]="12">
      <laveo-select-customer-site-billing-person [control]="form.get('billingPerson')"></laveo-select-customer-site-billing-person>
    </div>

    <div nz-col [nzXs]="3">
      <nz-form-item>
        <nz-form-label nzFor="shelteredSite">Site couvert</nz-form-label>
        <nz-form-control>
          <nz-radio-group formControlName="shelteredSite" nzButtonStyle="solid" [nzSize]="'large'">
            <label nz-radio-button [nzValue]="true">Oui</label>
            <label nz-radio-button [nzValue]="false">Non</label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzXs]="3">
      <nz-form-item>
        <nz-form-label nzFor="electricityAccess">Accès à l'électricité</nz-form-label>
        <nz-form-control>
          <nz-radio-group formControlName="electricityAccess" nzButtonStyle="solid" [nzSize]="'large'">
            <label nz-radio-button [nzValue]="true">Oui</label>
            <label nz-radio-button [nzValue]="false">Non</label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzXs]="24">
      <nz-form-item>
        <nz-form-label nzFor="billingText">Texte facturation</nz-form-label>
        <nz-form-control>
          <nz-textarea-count [nzMaxCharacterCount]="500">
            <textarea formControlName="billingText" nz-input placeholder="Texte facturation" nzSize="large" [nzAutosize]="{ minRows: 3, maxRows: 10 }"></textarea>
          </nz-textarea-count>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzXs]="24">
      <nz-form-item>
        <nz-form-label>Permission formulaire</nz-form-label>
        <nz-form-control>
          <nz-tree
            [nzData]="permissions"
            nzCheckable
            nzMultiple
            [nzCheckedKeys]="checkedPermissions"
            (nzCheckBoxChange)="updatePermissions()">
          </nz-tree>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzXs]="24">
      <nz-form-item>
        <nz-form-label>Points de controle</nz-form-label>
        <nz-form-control>
          <nz-tree
            [nzData]="vehicleCheckpoints"
            nzCheckable
            nzMultiple
            [nzCheckedKeys]="checkedVehicleCheckpoints"
            (nzCheckBoxChange)="updateVehicleCheckpoints()">
          </nz-tree>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzXs]="24">
      <laveo-inputs-contacts [controlArray]="contactFormArray" [showNotificationsCheckbox]="true"></laveo-inputs-contacts>
    </div>
  </div>
</form>
