import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  Service,
  ServiceStatus,
  ServiceUpholsteryStainRemoval,
  ServiceCarpetStainRemoval,
  ServiceType,
  ServiceFinishing
} from '../../../../shared/models/entities/service';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomerSite } from 'src/app/shared/models/entities/customer-site';
import { Vehicle } from 'src/app/shared/models/entities/vehicle';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Preparer } from 'src/app/shared/models/entities/preparer';
import { UserService } from '../../../../shared/services/api/user.service';
import { UserRoleType, UserRole } from '../../../../shared/models/entities/user-role';
import { addDays, format, isBefore, startOfToday } from 'date-fns';
import { CustomerSiteWorkflowType } from 'src/app/shared/models/entities/customer-site-workflow-type';
import { VehicleFrequency } from 'src/app/shared/models/entities/vehicle-frequency';
import { VehicleAttribution } from 'src/app/shared/models/entities/vehicle-attribution';
import { ServicesService } from 'src/app/shared/services/api/services.service';
import { debounceTime } from 'rxjs/operators';
import { CustomerSitesService } from 'src/app/shared/services/api/customer-sites.service';

@Component({
  selector: 'laveo-service-form',
  templateUrl: './service-form.component.html',
  styleUrls: ['./service-form.component.scss']
})
export class ServiceFormComponent implements OnInit, OnDestroy {
  @Input() service?: Service;

  form: UntypedFormGroup;
  preparerTypeEntity = [Preparer];
  customerSiteTypeEntity = [CustomerSite];
  customerSite = new BehaviorSubject<CustomerSite | null>(null);

  serviceTypeValues: ServiceType[] = Object.values(ServiceType);
  serviceFinishingValues: ServiceFinishing[] = Object.values(ServiceFinishing);
  serviceUpholsteryStainRemovalValues: ServiceUpholsteryStainRemoval[] = Object.values(ServiceUpholsteryStainRemoval);
  serviceCarpetStainRemovalValues: ServiceCarpetStainRemoval[] = Object.values(ServiceCarpetStainRemoval);

  frequencyDetails?: string;
  frequencyStyle = 'Unique';
  showFrequencyWarning = false;
  role?: UserRole;
  defaultTime = new Date(0, 0, 0, 0, 0, 0);
  vehicleChanged = false;
  isDateTooSoon = false;
  isTimeOptional = true;

  private subscriptions: Subscription[] = [];
  private originalVehicle?: Vehicle;
  private correspondanceApiformfields: any = {
    date: ['date', 'time', 'frequency', 'frequencyStyle', 'iteration'],
    customerSiteId: ['customerSite'],
    vehicleId: ['vehicle'],
    type: ['type'],
    finishing: ['finishing'],
    preparerId: ['preparer'],
    comment: ['comment'],
    contacts: ['contacts'],
    vehicleStateEnabled: ['vehicleStateEnabled'],
    firstIntervention: ['firstIntervention'],
    vehicleVeryDirty: ['vehicleVeryDirty'],
    upholsteryStainRemoval: ['upholsteryStainRemoval'],
    carpetStainRemoval: ['carpetStainRemoval'],
    conveying: ['conveying'],
    sticking: ['sticking'],
    desticking: ['desticking'],
    contactZoneDisinfection: ['contactZoneDisinfection'],
    interiorDisinfection: ['interiorDisinfection']
  };

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly userService: UserService,
    private readonly servicesService: ServicesService,
    private readonly customerSiteService: CustomerSitesService
  ) {}

  get dateValidation(): string | AbstractControl | null {
    if (this.isDateTooSoon) {
      return 'warning';
    }

    return this.form.get('date');
  }

  get frequencyValidation(): string | AbstractControl | null {
    if (this.showFrequencyWarning) {
      return 'error';
    } else if (this.frequencyDetails) {
      return 'success';
    }

    return this.form.get('frequency');
  }

  get contactFormArray(): UntypedFormArray {
    return this.form.get('contacts') as UntypedFormArray;
  }

  ngOnInit(): void {
    this.setForm();
    this.loadRole();
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  disabledHours(): number[] {
    return [0, 1, 2, 3, 4, 5, 22, 23];
  }

  disabledDates = (currentDate: Date) => {
    if (this.form.get('forceUpdate')?.value ?? false) {
      return false;
    }

    return isBefore(currentDate, startOfToday());
  }

  private setForm(): void {
    this.form = this.formBuilder.group({
      id: this.formBuilder.control(this.service?.id, []),
      date: this.formBuilder.control(this.service?.date, [Validators.required]),
      time: this.formBuilder.control(this.service?.date, []),
      frequency: this.formBuilder.control(null, [Validators.min(0), Validators.max(365)]),
      frequencyStyle: this.formBuilder.control(this.frequencyStyle, []),
      iteration: this.formBuilder.control(2, [Validators.min(2), Validators.max(50)]),
      status: this.formBuilder.control(this.service?.status ?? ServiceStatus.created),
      customerSite: this.formBuilder.control(this.service?.vehicle?.customerSite, []),
      vehicle: this.formBuilder.control(this.service?.vehicle, [Validators.required]),
      type: this.formBuilder.control(this.service?.type, [Validators.required]),
      finishing: this.formBuilder.control(this.service?.finishing, [   ]),
      preparer: this.formBuilder.control(this.service?.preparer, []),
      comment: this.formBuilder.control(this.service?.comment, [Validators.maxLength(500)]),
      contacts: this.formBuilder.array(this.service?.contacts ?? [], []),
      vehicleStateEnabled: this.formBuilder.control(this.service?.vehicleStateEnabled ?? this.isEtatDesLieuxEnabledByDefault(this.service?.vehicle?.customerSite), []),
      firstIntervention: this.formBuilder.control(this.service?.firstIntervention ?? false, []),
      vehicleVeryDirty: this.formBuilder.control(this.service?.vehicleVeryDirty ?? false, []),
      upholsteryStainRemoval: this.formBuilder.control(this.service?.upholsteryStainRemoval ?? [], []),
      carpetStainRemoval: this.formBuilder.control(this.service?.carpetStainRemoval ?? [], []),
      conveying: this.formBuilder.control(this.service?.conveying ?? false, []),
      sticking: this.formBuilder.control(this.service?.sticking ?? false, []),
      desticking: this.formBuilder.control(this.service?.desticking ?? false, []),
      contactZoneDisinfection: this.formBuilder.control(this.service?.contactZoneDisinfection ?? false, []),
      interiorDisinfection: this.formBuilder.control(this.service?.interiorDisinfection ?? false, []),
      forceUpdate: this.formBuilder.control(false)
    });

    if (this.service?.id) {
      this.form.disable();
    }

    this.originalVehicle = this.service?.vehicle;

    if (this.service) {
      this.isTimeOptional = false;
      const timeControl = this.form.get('time');
      timeControl?.setValidators([Validators.required]);
      timeControl?.updateValueAndValidity();
    }

    if (this.service?.vehicle?.customerSite) {
      this.customerSite.next(this.service?.vehicle?.customerSite);
    }

    const vehicleSubscription = this.form.get('vehicle')?.valueChanges.subscribe((vehicle: Vehicle) => {
      const vehicles: Vehicle[] = Array.isArray(vehicle) ? vehicle : [vehicle];
      if (vehicles.length > 0 && vehicles[0]) {
        const v = vehicles[0];
        if (this.originalVehicle) {
          this.vehicleChanged = v.licensePlate !== this.originalVehicle?.licensePlate;
        }

        if (v.frequency === VehicleFrequency.recurrent) {
          // this.form.get('frequencyStyle')?.setValue('Récurrent');
          // this.form.get('frequencyStyle')?.updateValueAndValidity();
          if (v.attribution === VehicleAttribution.affected) {
            this.form.get('frequency')?.setValue(v.customerSite.frequencyServiceAffectedVehicle);
            this.form.get('frequency')?.updateValueAndValidity();
          } else if (v.attribution === VehicleAttribution.mutualized) {
            this.form.get('frequency')?.setValue(v.customerSite.frequencyServiceMutualizedVehicle);
            this.form.get('frequency')?.updateValueAndValidity();
          }
        }

        if (v.customerSite) {
          this.customerSiteService.customerSite(v.customerSite.id).subscribe(newCustomerSite => {
            this.form.get('customerSite')?.setValue(newCustomerSite.data);
          });
        } else {
          this.form.get('customerSite')?.setValue(null);
        }
      } else {
        this.form.get('customerSite')?.setValue(null);
      }
      this.form.get('customerSite')?.updateValueAndValidity();
    });

    const customerSiteSubscription = this.form.get('customerSite')?.valueChanges.subscribe((customerSite: CustomerSite) => {
      if (customerSite?.id !== this.customerSite.value?.id) {
        this.customerSite.next(customerSite);
        this.setOptions();

        this.form.get('type')?.setValue(null);
        this.form.get('finishing')?.setValue(null);
        this.form.get('firstIntervention')?.setValue(false);
        this.form.get('vehicleVeryDirty')?.setValue(false);
        this.form.get('upholsteryStainRemoval')?.setValue([]);
        this.form.get('carpetStainRemoval')?.setValue([]);
        this.form.get('conveying')?.setValue(false);
        this.form.get('sticking')?.setValue(false);
        this.form.get('desticking')?.setValue(false);
        this.form.get('contactZoneDisinfection')?.setValue(false);
        this.form.get('interiorDisinfection')?.setValue(false);
        this.form.get('vehicleStateEnabled')?.setValue(this.isEtatDesLieuxEnabledByDefault(customerSite));

        this.form.updateValueAndValidity();

        if (customerSite?.workflowType === CustomerSiteWorkflowType.light && this.role?.type === UserRoleType.structure) {
          this.isTimeOptional = false;
          const timeControl = this.form.get('time');
          timeControl?.setValidators([Validators.required]);
          timeControl?.updateValueAndValidity();
        }
      }
    });

    const dateSubscription = this.form.get('date')?.valueChanges.subscribe((date: Date) => {
      if (!this.form.get('forceUpdate')?.value) {
        this.isDateTooSoon = isBefore(date, addDays(new Date(), 2));
      }
      this.calculateFrequency();
    });

    const frequencySubscription = this.form.get('frequency')?.valueChanges.pipe(debounceTime(200)).subscribe(() => {
      this.calculateFrequency();
    });

    const iterationSubscription = this.form.get('iteration')?.valueChanges.pipe(debounceTime(200)).subscribe(() => {
      this.calculateFrequency();
    });

    const frequencyStyleSubscription = this.form.get('frequencyStyle')?.valueChanges.subscribe(v => {
      this.frequencyStyle = v;
      if (this.frequencyStyle === 'Unique') {
        this.form.get('frequency')?.setValue(0);
        this.form.get('iteration')?.setValue(2);
        this.form.get('frequency')?.updateValueAndValidity();
        this.form.get('iteration')?.updateValueAndValidity();
      }
    });

    const forceUpdateSubcription = this.form.get('forceUpdate')?.valueChanges.subscribe(v => {
      if (this.service?.id) {
        v ? this.form.get('status')?.enable() : this.form.get('status')?.disable();
      }
    });

    [
      vehicleSubscription,
      customerSiteSubscription,
      dateSubscription,
      frequencySubscription,
      frequencyStyleSubscription,
      iterationSubscription,
      forceUpdateSubcription
    ].filter((subscription): subscription is Subscription => subscription !== undefined).forEach(subscription => this.subscriptions.push(subscription));

    this.setOptions();

    if (this.service?.fields) {
      for (const field of this.service.fields) {
        const formFields = this.correspondanceApiformfields[field];
        if (formFields) {
          for (const formField of formFields) {
            this.form.get(formField)?.enable();
          }
        }
      }
    }

    this.form.get('forceUpdate')?.enable();
  }

  private isEtatDesLieuxEnabledByDefault(customerSite?: CustomerSite): boolean {
    return (customerSite?.vehicleCheckpoints?.filter(vc => vc.category !== 'Visuels')?.length ?? 0) > 0;
  }

  private loadRole(): void {
    const roleSubscription = this.userService.currentRole.subscribe(role => {
      this.role = role;
    });

    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }
  }

  private setOptions(): void {
    const customerSite: CustomerSite = this.form.get('customerSite')?.value;

    if (customerSite) {
      if (customerSite?.type?.length > 0) {
        this.form.get('type')?.enable();
      } else {
        this.form.get('type')?.disable();
        this.form.get('type')?.setValue(null);
      }

      if (customerSite?.finishing?.length > 0) {
        this.form.get('finishing')?.enable();
      } else {
        this.form.get('finishing')?.disable();
        this.form.get('finishing')?.setValue(null);
      }

      if (customerSite?.firstIntervention) {
        this.form.get('firstIntervention')?.enable();
      } else {
        this.form.get('firstIntervention')?.disable();
        this.form.get('firstIntervention')?.setValue(false);
      }

      if (customerSite?.vehicleVeryDirty) {
        this.form.get('vehicleVeryDirty')?.enable();
      } else {
        this.form.get('vehicleVeryDirty')?.disable();
        this.form.get('vehicleVeryDirty')?.setValue(false);
      }

      if (customerSite?.upholsteryStainRemoval?.length > 0) {
        this.form.get('upholsteryStainRemoval')?.enable();
      } else {
        this.form.get('upholsteryStainRemoval')?.disable();
        this.form.get('upholsteryStainRemoval')?.setValue([]);
      }

      if (customerSite?.carpetStainRemoval?.length > 0) {
        this.form.get('carpetStainRemoval')?.enable();
      } else {
        this.form.get('carpetStainRemoval')?.disable();
        this.form.get('carpetStainRemoval')?.setValue([]);
      }

      if (customerSite?.conveying) {
        this.form.get('conveying')?.enable();
      } else {
        this.form.get('conveying')?.disable();
        this.form.get('conveying')?.setValue(false);
      }

      if (customerSite?.sticking) {
        this.form.get('sticking')?.enable();
      } else {
        this.form.get('sticking')?.disable();
        this.form.get('sticking')?.setValue(false);
      }

      if (customerSite?.desticking) {
        this.form.get('desticking')?.enable();
      } else {
        this.form.get('desticking')?.disable();
        this.form.get('desticking')?.setValue(false);
      }

      if (customerSite?.contactZoneDisinfection) {
        this.form.get('contactZoneDisinfection')?.enable();
      } else {
        this.form.get('contactZoneDisinfection')?.disable();
        this.form.get('contactZoneDisinfection')?.setValue(false);
      }

      if (customerSite?.interiorDisinfection) {
        this.form.get('interiorDisinfection')?.enable();
      } else {
        this.form.get('interiorDisinfection')?.disable();
        this.form.get('interiorDisinfection')?.setValue(false);
      }

      this.form.get('frequency')?.enable();
      this.form.get('frequencyStyle')?.enable();
      this.form.get('iteration')?.enable();
    } else {
      this.form.get('frequency')?.disable();
      this.form.get('frequency')?.setValue(null);

      this.form.get('frequencyStyle')?.disable();
      this.form.get('frequencyStyle')?.setValue('Unique');

      this.form.get('iteration')?.disable();
      this.form.get('iteration')?.setValue(2);

      this.form.get('type')?.disable();
      this.form.get('type')?.setValue(null);
      this.form.get('finishing')?.disable();
      this.form.get('finishing')?.setValue(null);
      this.form.get('firstIntervention')?.disable();
      this.form.get('firstIntervention')?.setValue(false);
      this.form.get('vehicleVeryDirty')?.disable();
      this.form.get('vehicleVeryDirty')?.setValue(false);
      this.form.get('upholsteryStainRemoval')?.disable();
      this.form.get('upholsteryStainRemoval')?.setValue([]);
      this.form.get('carpetStainRemoval')?.disable();
      this.form.get('carpetStainRemoval')?.setValue([]);
      this.form.get('conveying')?.disable();
      this.form.get('conveying')?.setValue(false);
      this.form.get('sticking')?.disable();
      this.form.get('sticking')?.setValue(false);
      this.form.get('desticking')?.disable();
      this.form.get('desticking')?.setValue(false);
      this.form.get('contactZoneDisinfection')?.disable();
      this.form.get('contactZoneDisinfection')?.setValue(false);
      this.form.get('interiorDisinfection')?.disable();
      this.form.get('interiorDisinfection')?.setValue(false);
    }

    this.serviceTypeValues = customerSite?.type ?? [];
    this.serviceFinishingValues = customerSite?.finishing ?? [];
    this.serviceUpholsteryStainRemovalValues = customerSite?.upholsteryStainRemoval ?? [];
    this.serviceCarpetStainRemovalValues = customerSite?.carpetStainRemoval ?? [];
  }

  private calculateFrequency(): void {
    const customerSiteId: string = this.form.get('customerSite')?.value?.id;
    const dateStart: Date = this.form.get('date')?.value ?? new Date();
    const days: number = this.form.get('frequency')?.value;
    const iteration: number = this.form.get('iteration')?.value ?? 0;

    if (!customerSiteId || !days || days < 1 || days > 365 || iteration < 2) {
      this.frequencyDetails = undefined;
      return;
    }

    this.servicesService.getDatesForInterval({
      customerSiteId,
      dateStart,
      days,
      iteration: iteration - 1
    }).subscribe({
      next: response => {
        const realLength = response.data?.length ?? 0;
        this.frequencyDetails = 'Prochaines itérations : ' + response.data?.slice(0, 6).map(d => format(d, 'dd/MM/yyyy')).join(', ');
        this.frequencyDetails += realLength > 6 ? ' …' : '.';
        this.showFrequencyWarning = realLength < iteration;
      },
      error: error => {
        console.error(error);
      }
    });
  }
}
