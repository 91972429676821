"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const consts_1 = require("../consts");
const Decorator_1 = require("./Decorator");
function Deserializer(fn) {
  return Decorator_1.createDecorator('Deserializer', consts_1.DeserializerSymbol, fn);
}
exports.Deserializer = Deserializer;
