import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo, gql, MutationResult } from 'apollo-angular';
import { PagedRequest } from '../../models/api/shared/paged/paged-request';
import { PagedResponse } from '../../models/api/shared/paged/paged-response.interface';
import { map } from 'rxjs/operators';
import { VehicleCheckpoint, VehicleCheckpointArray } from '../../models/entities/vehicle-checkpoint';
import { TypeSerializerUtils } from '../../utils/type-serializer.util';
import { VehicleCheckpointRequest } from '../../models/api/services/vehicle-checkpoint-requests.interface';

@Injectable({
  providedIn: 'root'
})
export class VehicleCheckpointsService {
  constructor(
    private readonly apollo: Apollo
  ) {}

  vehicleCheckpoint(id: string): Observable<ApolloQueryResult<VehicleCheckpoint>> {
    const query = gql`
      query vehicleCheckpoint($id: Guid!) {
        params {
          vehicleCheckpoint(id: $id){
            id
            active
            order
            name
            category
            fieldType
            isOnRepport
            required
            values
          }
        }
      }
    `;

    return this.apollo.query<{ params: { vehicleCheckpoint: VehicleCheckpoint } }>({
      query,
      variables: { id }
    }).pipe(map(result => ({
      data: TypeSerializerUtils.deserialize(result.data?.params?.vehicleCheckpoint, VehicleCheckpoint),
      error: result.error,
      errors: result.errors,
      partial: result.partial,
      loading: result.loading,
      networkStatus: result.networkStatus
    })));
  }

  allVehicleCheckpoints(parameters: PagedRequest, onlyActive = false): Observable<ApolloQueryResult<PagedResponse<VehicleCheckpoint>>> {
    const query = gql`
      query checkpoints($offset: Int, $limit: Int, $sortProperty: String, $sortType: ESortType, $search: String) {
        params {
          vehicleCheckpoints(offset: $offset, limit: $limit, sortProperty: $sortProperty, sortType: $sortType, search: $search) {
            data {
              id
              active
              order
              name
              category
              fieldType
              isOnRepport
              required
              values
            }
            metadata {
              currentPage
              currentResult
              totalPages
              totalResults
              hasMore
            }
          }
        }
      }
    `;
    return this.apollo.query<{ params: { vehicleCheckpoints: PagedResponse<VehicleCheckpoint> } }>({
      query,
      variables: {
        offset: parameters.offset,
        limit: parameters.limit,
        sortProperty: parameters.sortProperty,
        sortType: parameters.sortType,
        search: parameters.search
      }
    }).pipe(map(result => {
      const raw = result.data?.params?.vehicleCheckpoints;
      const response: PagedResponse<VehicleCheckpoint> = {
        data: TypeSerializerUtils.deserializeArr(raw.data, VehicleCheckpointArray).filter(d => !onlyActive || onlyActive && d.active),
        metadata: raw.metadata
      };
      return {
        data: response,
        error: result.error,
        errors: result.errors,
        partial: result.partial,
        loading: result.loading,
        networkStatus: result.networkStatus
      };
    }));
  }

  addVehicleCheckpoint(data: VehicleCheckpointRequest): Observable<MutationResult<VehicleCheckpoint>> {
    const query = gql`
      mutation addVehicleCheckpoint($data: VehicleCheckpointRequest) {
        params {
          vehicleCheckpoints {
            add (data: $data) {
              id
              active
              order
              name
              category
              fieldType
              isOnRepport
              required
              values
            }
          }
        }
      }
    `;

    return this.apollo.mutate<{ params: { vehicleCheckpoints: { add: VehicleCheckpoint } } }>({
      mutation: query,
      variables: {
        data: {
          name: data.name,
          active: data.active,
          category: data.category,
          order: data.order,
          fieldData: {
            fieldType: data.fieldData.fieldType,
            values: data.fieldData.values,
            isOnRepport: data.fieldData.isOnRepport,
            required: data.fieldData.required,
          },
        }
      }
    }).pipe(map(result => ({

      data: TypeSerializerUtils.deserialize(result.data?.params?.vehicleCheckpoints?.add, VehicleCheckpoint),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }

  updateVehicleCheckpoint(data: VehicleCheckpointRequest): Observable<MutationResult<VehicleCheckpoint>> {
    const query = gql`
      mutation updateVehicleCheckpoint($id: Guid!, $data: VehicleCheckpointRequest) {
        params {
          vehicleCheckpoints {
            update (id: $id, data: $data) {
              id
              active
              order
              name
              category
              fieldType
              isOnRepport
              required
              values
            }
          }
        }
      }
    `;

    return this.apollo.mutate<{ params: { vehicleCheckpoints: { update: VehicleCheckpoint } } }>({
      mutation: query,
      variables: {
        id: data.id,
        data: {
          name: data.name,
          active: data.active,
          category: data.category,
          order: data.order,
          fieldData: {
            fieldType: data.fieldData.fieldType,
            values: data.fieldData.values,
            isOnRepport: data.fieldData.isOnRepport,
            required: data.fieldData.required,
          },
        }
      }
    }).pipe(map(result => ({

      data: TypeSerializerUtils.deserialize(result.data?.params?.vehicleCheckpoints?.update, VehicleCheckpoint),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }

  deleteVehicleCheckpoint(...ids: string[]): Observable<MutationResult<void>> {
    const mutation = `
      mutation deleteVehicleCheckpoint(${ids.map((_, index) => '$id' + index + ': Guid!').join(', ')}) {
        params {
          vehicleCheckpoints {
            ${ ids.map((_, index) => 'id' + index + ': delete (id: $id' + index + ')').join('\n') }
          }
        }
      }
    `;

    const variables = ids.reduce((response, item, index) => {
      response['id' + index] = item;
      return response;
    }, {});

    return this.apollo.mutate({
      mutation: gql(mutation),
      variables
    });
  }
}
