import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { SharedModule } from '../../shared.module';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { RowServiceModule } from '../row-service/row-service.module';
import { ListServicesComponent } from './list-services.component';
import { RouterModule } from '@angular/router';
import { NzResultModule } from 'ng-zorro-antd/result';

@NgModule({
  declarations: [
    ListServicesComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    RowServiceModule,
    NzButtonModule,
    NzEmptyModule,
    NzListModule,
    NzGridModule,
    NzPaginationModule,
    NzTypographyModule,
    NzDividerModule,
    NzCardModule,
    NzResultModule
  ],
  exports: [
    ListServicesComponent
  ]
})
export class ListServicesModule {}
