<div class="image-button" [class.disabled]="!enabled" (click)="onClick()">
  <img nz-image [nzSrc]="image" width="40" height="40" alt="Logo" class="image-button-image" *ngIf="image && !avatar" />
  <nz-avatar
    [nzIcon]="avatar"
    [nzSrc]="image"
    nzShape="square"
    nzSize="large"
    class="image-button-avatar"
    *ngIf="avatar">
  </nz-avatar>
  <div class="image-button-content">
    <span class="image-button-title">{{ title }}</span>
    <span class="image-button-description" *ngIf="description">{{ description }}</span>
  </div>
  <i nz-icon [nzType]="icon" nzTheme="outline" class="image-button-icon" *ngIf="icon && enabled"></i>
</div>
