import { Pipe, PipeTransform } from '@angular/core';
import { UserRoleType } from '../models/entities/user-role';

@Pipe({
  name: 'roleName'
})
export class RoleNamePipe implements PipeTransform {
  transform(role: UserRoleType | string): string {
    switch (role) {
      case UserRoleType.admin:
      case 'Admin': {
        return 'Super Administrateur';
      }
      case UserRoleType.structure:
      case 'Structure': {
        return 'Structure';
      }
      case UserRoleType.structureRead: {
        return 'Structure (lecture)';
      }
      case UserRoleType.customer:
      case 'Customer': {
        return 'Client';
      }
      case UserRoleType.customerSite:
      case 'CustomerSite': {
        return 'Site client';
      }
      case UserRoleType.customerSiteRead: {
        return 'Site client (lecture)';
      }
      case UserRoleType.preparer:
      case 'Preparer': {
        return 'Préparateur';
      }
      default: {
        return role?.replace('_', ' ').toLowerCase();
      }
    }
  }
}
