import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { SharedModule } from '../../shared.module';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { RouterModule } from '@angular/router';
import { RowServiceComponent } from './row-service.component';
import { LicensePlateModule } from '../license-plate/license-plate.module';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzTagModule } from 'ng-zorro-antd/tag';

@NgModule({
  declarations: [
    RowServiceComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    NzGridModule,
    NzButtonModule,
    NzDropDownModule,
    NzBadgeModule,
    NzTypographyModule,
    NzCardModule,
    NzAvatarModule,
    NzTagModule,
    NzToolTipModule,
    NzModalModule,
    NzCheckboxModule,
    LicensePlateModule,
    RouterModule,
  ],
  exports: [
    RowServiceComponent
  ]
})
export class RowServiceModule {}
