import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared.module';
import { SelectVehicleBrandComponent } from './select-vehicle-brand.component';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { ReactiveFormsModule } from '@angular/forms';
import { NzIconModule } from 'ng-zorro-antd/icon';

@NgModule({
  declarations: [
    SelectVehicleBrandComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    NzFormModule,
    NzSelectModule,
    NzIconModule
  ],
  exports: [
    SelectVehicleBrandComponent
  ]
})
export class SelectVehicleBrandModule {}
