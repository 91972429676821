export enum VehicleCategory {
  citadine = 'A_CITADINE',
  compacte = 'B_COMPACTE',
  berline_routiere = 'C_BERLINE_ROUTIERE',
  suv = 'D_SUV',
  vu = 'VU',
  porteur = 'PORTEUR',
  pl = 'PL',
  hors_gabarit = 'HORS_GABARIT',
  moto = 'MOTO'
}
