import { Pipe, PipeTransform } from '@angular/core';
import { VehicleCategory } from '../models/entities/vehicle-category';

@Pipe({
  name: 'vehicleCategoryIcon'
})
export class VehicleCategoryIconPipe implements PipeTransform {
  transform(type: VehicleCategory): string {
    switch (type) {
      case VehicleCategory.citadine: {
        return 'vehicles:citadine';
      }
      case VehicleCategory.compacte: {
        return 'vehicles:compacte';
      }
      case VehicleCategory.berline_routiere: {
        return 'vehicles:berline_routiere';
      }
      case VehicleCategory.suv: {
        return 'vehicles:suv';
      }
      case VehicleCategory.vu: {
        return 'vehicles:vu';
      }
      case VehicleCategory.porteur: {
        return 'vehicles:porteur';
      }
      case VehicleCategory.pl: {
        return 'vehicles:pl';
      }
      case VehicleCategory.hors_gabarit: {
        return 'vehicles:hors_gabarit';
      }
      case VehicleCategory.moto: {
        return 'vehicles:moto';
      }
      default: {
        return 'vehicles:citadine';
      }
    }
  }
}
