<div nz-row [nzGutter]="[48, 24]">
  <div nz-col nzSpan="24">
    <div class="detail">
      <span class="detail-title" nz-typography nzType="secondary">Nom</span>
      <span class="detail-content" nz-typography>{{ vehicleBrand?.name | nullCoal }}</span>
    </div>
  </div>

  <div nz-col>
    <div class="detail">
      <span class="detail-title" nz-typography nzType="secondary">Actif</span>
      <span class="detail-content" nz-typography>
            <i nz-icon [nzType]="vehicleBrand?.active ? 'check-square' : 'close-square'" nzTheme="outline"></i>
        {{ vehicleBrand?.active ? 'Activé' : 'Désactivé' }}
          </span>
    </div>
  </div>
</div>
