import { Component, Input } from '@angular/core';
import { VehicleCheckpoint } from '../../../../../../shared/models/entities/vehicle-checkpoint';

@Component({
  selector: 'laveo-vehicle-checkpoint-detail',
  templateUrl: './vehicle-checkpoint-detail.component.html',
  styleUrls: ['./vehicle-checkpoint-detail.component.scss']
})
export class VehicleCheckpointDetailComponent {
  @Input() vehicleCheckpoint?: VehicleCheckpoint;
}
