<div nz-row [nzGutter]="[48, 24]">
  <div nz-col nzSpan="24">
    <div class="detail">
      <span class="detail-title" nz-typography nzType="secondary">Nom</span>
      <span class="detail-content" nz-typography>{{ vehicleCheckpoint?.name | nullCoal }}</span>
    </div>
  </div>

  <div nz-col nzSpan="24">
    <div class="detail">
      <span class="detail-title" nz-typography nzType="secondary">Catégorie</span>
      <span class="detail-content" nz-typography>{{ vehicleCheckpoint?.category | nullCoal }}</span>
    </div>
  </div>

  <div nz-col nzSpan="24">
    <div class="detail">
      <span class="detail-title" nz-typography nzType="secondary">Ordre dans le formulaire et le rapport</span>
      <span class="detail-content" nz-typography>{{ vehicleCheckpoint?.order | nullCoal }}</span>
    </div>
  </div>

  <div nz-col nzSpan="24">
    <div class="detail">
      <span class="detail-title" nz-typography nzType="secondary">Type de champs dans le formulaire</span>
      <span class="detail-content" nz-typography>{{ vehicleCheckpoint?.fieldType | vehicleCheckpointFieldTypeName | nullCoal }}</span>
    </div>
  </div>

  <div nz-col nzSpan="24" *ngIf="vehicleCheckpoint?.fieldType === 'SELECT'">
    <div class="detail">
      <span class="detail-title" nz-typography nzType="secondary">Valeurs possible pour le champs</span>
      <span class="detail-content" nz-typography>{{ vehicleCheckpoint?.values?.join(', ') | nullCoal }}</span>
    </div>
  </div>

  <div nz-col nzSpan="24">
    <div class="detail">
      <span class="detail-title" nz-typography nzType="secondary">Mettre le champs dans le rapport (meme sans état des lieux)</span>
      <span class="detail-content" nz-typography>{{ vehicleCheckpoint?.isOnRepport ? 'Oui' : 'Non' }}</span>
    </div>
  </div>

  <div nz-col nzSpan="24">
    <div class="detail">
      <span class="detail-title" nz-typography nzType="secondary">Le champs est requis dans le formulaire</span>
      <span class="detail-content" nz-typography>{{ vehicleCheckpoint?.required ? 'Oui' : 'Non' }}</span>
    </div>
  </div>

  <div nz-col>
    <div class="detail">
      <span class="detail-title" nz-typography nzType="secondary">Actif</span>
      <span class="detail-content" nz-typography>
            <i nz-icon [nzType]="vehicleCheckpoint?.active ? 'check-square' : 'close-square'" nzTheme="outline"></i>
        {{ vehicleCheckpoint?.active ? 'Activé' : 'Désactivé' }}
          </span>
    </div>
  </div>
</div>
