import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { RseService } from 'src/app/shared/services/api/rse.service';
import { Rse } from 'src/app/shared/models/entities/rse';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ApiErrorMessageUtil } from '../../../../../shared/utils/api-error-message.util';
import { take } from 'rxjs/operators';

@Component({
  selector: 'laveo-rse-list',
  templateUrl: './rse-list.component.html',
  styleUrls: ['./rse-list.component.scss']
})
export class RseListComponent implements OnInit, OnDestroy {
  isLoading = true;
  error?: Error;


  rseTypes = ['WaterSaving', 'Insertion'];
  serviceTypes = ['Interior', 'Exterior', 'Full'];
  serviceFinishings = ['Classic', 'EmployeeDiscount', 'Restitution', 'FleetManagement'];
  vehicleCategories = ['ACitadine', 'BCompacte', 'CBerlineRoutiere', 'DSuv', 'Vu', 'Porteur', 'Pl', 'HorsGabarit', 'Moto'];
  serviceUpholsteryStainRemovals = ['Driver', 'Passenger', 'BackLeft', 'BackRight', 'All'];
  serviceCarpetStainRemovals = ['Driver', 'Passenger', 'BackLeft', 'BackRight', 'Boot', 'All'];
  years: number[] = [];
  currentRse?: Rse;

  form: UntypedFormGroup;

  private subscriptions: Subscription[] = [];
  private currentYear = new Date().getFullYear();

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly rseService: RseService,
    private readonly message: NzMessageService
  ) {
    this.setYears();
    this.setForm();
  }

  numberFormatter = (value: number): string | number => value?.toLocaleString() ?? 0;

  numberParser = (value: string) => value.trim().replace(/,/, '.').replaceAll(/[^\w.-]+/g, '');

  ngOnInit(): void {
    this.loadData();
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  loadData(): void {
    this.isLoading = true;
    this.error = undefined;
    const selectedYear = this.form.get('Year')?.value ?? this.currentYear;
    this.rseService.rseParamsForYear(selectedYear).subscribe({
      next: data => {
        this.currentRse = data.data;
        this.isLoading = data.loading;
        this.setFormData();
      },
      error: error => {
        this.isLoading = false;
        this.error = error;
        console.error(error);
      }
    });
  }

  save() {
    let raw = this.form.getRawValue();
    const year = +raw.Year;
    delete(raw.Year);
    raw = JSON.stringify(raw);
    this.rseService.updateRse(year, raw).pipe(take(1)).subscribe({
      next: (_ => {
        this.message.success('Paramètre RSE mis à jour');
      }),
      error: (error => {
        console.error(error);
        this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
      })
    });
  }

  private setForm() {
    this.form = this.formBuilder.group({
      Year: this.formBuilder.control(this.currentYear, [Validators.required]),
      WaterConsumptionPerHousehold: this.formBuilder.control(null, [Validators.required]),
      Etp: this.formBuilder.control(null, [Validators.required]),
      Co2EmissionsSavings: this.formBuilder.control(null, [Validators.required]),
      InternationalSolidarityComponent: this.formBuilder.control(null, [Validators.required])
    });

    this.rseTypes.forEach(rseType => {

      const rseTypeFormGroup = new UntypedFormGroup({});
      this.serviceTypes.forEach(serviceType => {

        const serviceTypeFormGroup = new UntypedFormGroup({});
        this.serviceFinishings.forEach(serviceFinishing => {

          const serviceFinishingFormGroup = new UntypedFormGroup({});
          this.vehicleCategories.forEach(vehicleCategory => {
            serviceFinishingFormGroup.addControl(vehicleCategory, new UntypedFormControl());
          });

          serviceTypeFormGroup.addControl(serviceFinishing, serviceFinishingFormGroup);
        });

        rseTypeFormGroup.addControl(serviceType, serviceTypeFormGroup);
      });

      const serviceUpholsteryStainRemovalsFormGroup = new UntypedFormGroup({});
      this.serviceUpholsteryStainRemovals.forEach(serviceUpholsteryStainRemoval => {
        serviceUpholsteryStainRemovalsFormGroup.addControl(serviceUpholsteryStainRemoval, new UntypedFormControl());
      });
      rseTypeFormGroup.addControl('UpholsteryStainRemoval', serviceUpholsteryStainRemovalsFormGroup);

      const carpetStainRemovalFormGroup = new UntypedFormGroup({});
      this.serviceCarpetStainRemovals.forEach(serviceCarpetStainRemoval => {
        carpetStainRemovalFormGroup.addControl(serviceCarpetStainRemoval, new UntypedFormControl());
      });
      rseTypeFormGroup.addControl('CarpetStainRemoval', carpetStainRemovalFormGroup);

      const optionsFormGroup = new UntypedFormGroup({});

      optionsFormGroup.addControl('FirstIntervention', new UntypedFormControl());
      optionsFormGroup.addControl('VehicleVeryDirty', new UntypedFormControl());
      optionsFormGroup.addControl('VehicleState', new UntypedFormControl());
      optionsFormGroup.addControl('Conveying', new UntypedFormControl());
      optionsFormGroup.addControl('Sticking', new UntypedFormControl());
      optionsFormGroup.addControl('Desticking', new UntypedFormControl());
      optionsFormGroup.addControl('ContactZoneDisinfection', new UntypedFormControl());
      optionsFormGroup.addControl('InteriorDisinfection', new UntypedFormControl());

      rseTypeFormGroup.addControl('Options', optionsFormGroup);

      this.form.addControl(rseType, rseTypeFormGroup);
    });

    this.form.get('Year')?.valueChanges.subscribe(_ => {
      this.loadData();
    });
  }

  private setYears() {
    const startYear = 2020;
    this.years = this.range(startYear, this.currentYear).sort((a, b) => b - a);
  }

  private range(start: number, end: number): number[] {
    return Array.from({length: end - start + 1}).fill(null).map((_, index) => start + index);
  }

  private setFormData() {
    if (!this.currentRse) {
      return;
    }

    const values = this.currentRse.values;
    if (!values) {
      return;
    }

    this.form.patchValue({
      WaterConsumptionPerHousehold: values.waterConsumptionPerHousehold,
      Etp: values.etp,
      Co2EmissionsSavings: values.co2EmissionsSavings,
      InternationalSolidarityComponent: values.internationalSolidarityComponent,
      WaterSaving: {
        Interior: {
          Classic: {
            ACitadine: values.waterSaving.interior.classic.aCitadine,
            BCompacte: values.waterSaving.interior.classic.bCompacte,
            CBerlineRoutiere: values.waterSaving.interior.classic.cBerlineRoutiere,
            DSuv: values.waterSaving.interior.classic.dSuv,
            Vu: values.waterSaving.interior.classic.vu,
            Porteur: values.waterSaving.interior.classic.porteur,
            Pl: values.waterSaving.interior.classic.pl,
            HorsGabarit: values.waterSaving.interior.classic.horsGabarit,
            Moto: values.waterSaving.interior.classic.moto
          },
          EmployeeDiscount: {
            ACitadine: values.waterSaving.interior.employeeDiscount.aCitadine,
            BCompacte: values.waterSaving.interior.employeeDiscount.bCompacte,
            CBerlineRoutiere: values.waterSaving.interior.employeeDiscount.cBerlineRoutiere,
            DSuv: values.waterSaving.interior.employeeDiscount.dSuv,
            Vu: values.waterSaving.interior.employeeDiscount.vu,
            Porteur: values.waterSaving.interior.employeeDiscount.porteur,
            Pl: values.waterSaving.interior.employeeDiscount.pl,
            HorsGabarit: values.waterSaving.interior.employeeDiscount.horsGabarit,
            Moto: values.waterSaving.interior.employeeDiscount.moto
          },
          Restitution: {
            ACitadine: values.waterSaving.interior.restitution.aCitadine,
            BCompacte: values.waterSaving.interior.restitution.bCompacte,
            CBerlineRoutiere: values.waterSaving.interior.restitution.cBerlineRoutiere,
            DSuv: values.waterSaving.interior.restitution.dSuv,
            Vu: values.waterSaving.interior.restitution.vu,
            Porteur: values.waterSaving.interior.restitution.porteur,
            Pl: values.waterSaving.interior.restitution.pl,
            HorsGabarit: values.waterSaving.interior.restitution.horsGabarit,
            Moto: values.waterSaving.interior.restitution.moto
          },
          FleetManagement: {
            ACitadine: values.waterSaving.interior.fleetManagement.aCitadine,
            BCompacte: values.waterSaving.interior.fleetManagement.bCompacte,
            CBerlineRoutiere: values.waterSaving.interior.fleetManagement.cBerlineRoutiere,
            DSuv: values.waterSaving.interior.fleetManagement.dSuv,
            Vu: values.waterSaving.interior.fleetManagement.vu,
            Porteur: values.waterSaving.interior.fleetManagement.porteur,
            Pl: values.waterSaving.interior.fleetManagement.pl,
            HorsGabarit: values.waterSaving.interior.fleetManagement.horsGabarit,
            Moto: values.waterSaving.interior.fleetManagement.moto
          }
        },
        Exterior: {
          Classic: {
            ACitadine: values.waterSaving.exterior.classic.aCitadine,
            BCompacte: values.waterSaving.exterior.classic.bCompacte,
            CBerlineRoutiere: values.waterSaving.exterior.classic.cBerlineRoutiere,
            DSuv: values.waterSaving.exterior.classic.dSuv,
            Vu: values.waterSaving.exterior.classic.vu,
            Porteur: values.waterSaving.exterior.classic.porteur,
            Pl: values.waterSaving.exterior.classic.pl,
            HorsGabarit: values.waterSaving.exterior.classic.horsGabarit,
            Moto: values.waterSaving.exterior.classic.moto
          },
          EmployeeDiscount: {
            ACitadine: values.waterSaving.exterior.employeeDiscount.aCitadine,
            BCompacte: values.waterSaving.exterior.employeeDiscount.bCompacte,
            CBerlineRoutiere: values.waterSaving.exterior.employeeDiscount.cBerlineRoutiere,
            DSuv: values.waterSaving.exterior.employeeDiscount.dSuv,
            Vu: values.waterSaving.exterior.employeeDiscount.vu,
            Porteur: values.waterSaving.exterior.employeeDiscount.porteur,
            Pl: values.waterSaving.exterior.employeeDiscount.pl,
            HorsGabarit: values.waterSaving.exterior.employeeDiscount.horsGabarit,
            Moto: values.waterSaving.exterior.employeeDiscount.moto
          },
          Restitution: {
            ACitadine: values.waterSaving.exterior.restitution.aCitadine,
            BCompacte: values.waterSaving.exterior.restitution.bCompacte,
            CBerlineRoutiere: values.waterSaving.exterior.restitution.cBerlineRoutiere,
            DSuv: values.waterSaving.exterior.restitution.dSuv,
            Vu: values.waterSaving.exterior.restitution.vu,
            Porteur: values.waterSaving.exterior.restitution.porteur,
            Pl: values.waterSaving.exterior.restitution.pl,
            HorsGabarit: values.waterSaving.exterior.restitution.horsGabarit,
            Moto: values.waterSaving.exterior.restitution.moto
          },
          FleetManagement: {
            ACitadine: values.waterSaving.exterior.fleetManagement.aCitadine,
            BCompacte: values.waterSaving.exterior.fleetManagement.bCompacte,
            CBerlineRoutiere: values.waterSaving.exterior.fleetManagement.cBerlineRoutiere,
            DSuv: values.waterSaving.exterior.fleetManagement.dSuv,
            Vu: values.waterSaving.exterior.fleetManagement.vu,
            Porteur: values.waterSaving.exterior.fleetManagement.porteur,
            Pl: values.waterSaving.exterior.fleetManagement.pl,
            HorsGabarit: values.waterSaving.exterior.fleetManagement.horsGabarit,
            Moto: values.waterSaving.exterior.fleetManagement.moto
          }
        },
        Full: {
          Classic: {
            ACitadine: values.waterSaving.full.classic.aCitadine,
            BCompacte: values.waterSaving.full.classic.bCompacte,
            CBerlineRoutiere: values.waterSaving.full.classic.cBerlineRoutiere,
            DSuv: values.waterSaving.full.classic.dSuv,
            Vu: values.waterSaving.full.classic.vu,
            Porteur: values.waterSaving.full.classic.porteur,
            Pl: values.waterSaving.full.classic.pl,
            HorsGabarit: values.waterSaving.full.classic.horsGabarit,
            Moto: values.waterSaving.full.classic.moto
          },
          EmployeeDiscount: {
            ACitadine: values.waterSaving.full.employeeDiscount.aCitadine,
            BCompacte: values.waterSaving.full.employeeDiscount.bCompacte,
            CBerlineRoutiere: values.waterSaving.full.employeeDiscount.cBerlineRoutiere,
            DSuv: values.waterSaving.full.employeeDiscount.dSuv,
            Vu: values.waterSaving.full.employeeDiscount.vu,
            Porteur: values.waterSaving.full.employeeDiscount.porteur,
            Pl: values.waterSaving.full.employeeDiscount.pl,
            HorsGabarit: values.waterSaving.full.employeeDiscount.horsGabarit,
            Moto: values.waterSaving.full.employeeDiscount.moto
          },
          Restitution: {
            ACitadine: values.waterSaving.full.restitution.aCitadine,
            BCompacte: values.waterSaving.full.restitution.bCompacte,
            CBerlineRoutiere: values.waterSaving.full.restitution.cBerlineRoutiere,
            DSuv: values.waterSaving.full.restitution.dSuv,
            Vu: values.waterSaving.full.restitution.vu,
            Porteur: values.waterSaving.full.restitution.porteur,
            Pl: values.waterSaving.full.restitution.pl,
            HorsGabarit: values.waterSaving.full.restitution.horsGabarit,
            Moto: values.waterSaving.full.restitution.moto
          },
          FleetManagement: {
            ACitadine: values.waterSaving.full.fleetManagement.aCitadine,
            BCompacte: values.waterSaving.full.fleetManagement.bCompacte,
            CBerlineRoutiere: values.waterSaving.full.fleetManagement.cBerlineRoutiere,
            DSuv: values.waterSaving.full.fleetManagement.dSuv,
            Vu: values.waterSaving.full.fleetManagement.vu,
            Porteur: values.waterSaving.full.fleetManagement.porteur,
            Pl: values.waterSaving.full.fleetManagement.pl,
            HorsGabarit: values.waterSaving.full.fleetManagement.horsGabarit,
            Moto: values.waterSaving.full.fleetManagement.moto
          }
        },
        UpholsteryStainRemoval: {
          Driver: values.waterSaving.upholsteryStainRemoval.driver,
          Passenger: values.waterSaving.upholsteryStainRemoval.passenger,
          BackLeft: values.waterSaving.upholsteryStainRemoval.backLeft,
          BackRight: values.waterSaving.upholsteryStainRemoval.backRight,
          All: values.waterSaving.upholsteryStainRemoval.all
        },
        CarpetStainRemoval: {
          Driver: values.waterSaving.carpetStainRemoval.driver,
          Passenger: values.waterSaving.carpetStainRemoval.passenger,
          BackLeft: values.waterSaving.carpetStainRemoval.backLeft,
          BackRight: values.waterSaving.carpetStainRemoval.backRight,
          Boot: values.waterSaving.carpetStainRemoval.boot,
          All: values.waterSaving.carpetStainRemoval.all,
        },
        Options: {
          FirstIntervention: values.waterSaving.options.firstIntervention,
          VehicleVeryDirty: values.waterSaving.options.vehicleVeryDirty,
          VehicleState: values.waterSaving.options.vehicleState,
          Conveying: values.waterSaving.options.conveying,
          Sticking: values.waterSaving.options.sticking,
          Desticking: values.waterSaving.options.desticking,
          ContactZoneDisinfection: values.waterSaving.options.contactZoneDisinfection,
          InteriorDisinfection: values.waterSaving.options.interiorDisinfection
        }
      },
      Insertion: {
        Interior: {
          Classic: {
            ACitadine: values.insertion.interior.classic.aCitadine,
            BCompacte: values.insertion.interior.classic.bCompacte,
            CBerlineRoutiere: values.insertion.interior.classic.cBerlineRoutiere,
            DSuv: values.insertion.interior.classic.dSuv,
            Vu: values.insertion.interior.classic.vu,
            Porteur: values.insertion.interior.classic.porteur,
            Pl: values.insertion.interior.classic.pl,
            HorsGabarit: values.insertion.interior.classic.horsGabarit,
            Moto: values.insertion.interior.classic.moto
          },
          EmployeeDiscount: {
            ACitadine: values.insertion.interior.employeeDiscount.aCitadine,
            BCompacte: values.insertion.interior.employeeDiscount.bCompacte,
            CBerlineRoutiere: values.insertion.interior.employeeDiscount.cBerlineRoutiere,
            DSuv: values.insertion.interior.employeeDiscount.dSuv,
            Vu: values.insertion.interior.employeeDiscount.vu,
            Porteur: values.insertion.interior.employeeDiscount.porteur,
            Pl: values.insertion.interior.employeeDiscount.pl,
            HorsGabarit: values.insertion.interior.employeeDiscount.horsGabarit,
            Moto: values.insertion.interior.employeeDiscount.moto
          },
          Restitution: {
            ACitadine: values.insertion.interior.restitution.aCitadine,
            BCompacte: values.insertion.interior.restitution.bCompacte,
            CBerlineRoutiere: values.insertion.interior.restitution.cBerlineRoutiere,
            DSuv: values.insertion.interior.restitution.dSuv,
            Vu: values.insertion.interior.restitution.vu,
            Porteur: values.insertion.interior.restitution.porteur,
            Pl: values.insertion.interior.restitution.pl,
            HorsGabarit: values.insertion.interior.restitution.horsGabarit,
            Moto: values.insertion.interior.restitution.moto
          },
          FleetManagement: {
            ACitadine: values.insertion.interior.fleetManagement.aCitadine,
            BCompacte: values.insertion.interior.fleetManagement.bCompacte,
            CBerlineRoutiere: values.insertion.interior.fleetManagement.cBerlineRoutiere,
            DSuv: values.insertion.interior.fleetManagement.dSuv,
            Vu: values.insertion.interior.fleetManagement.vu,
            Porteur: values.insertion.interior.fleetManagement.porteur,
            Pl: values.insertion.interior.fleetManagement.pl,
            HorsGabarit: values.insertion.interior.fleetManagement.horsGabarit,
            Moto: values.insertion.interior.fleetManagement.moto
          }
        },
        Exterior: {
          Classic: {
            ACitadine: values.insertion.exterior.classic.aCitadine,
            BCompacte: values.insertion.exterior.classic.bCompacte,
            CBerlineRoutiere: values.insertion.exterior.classic.cBerlineRoutiere,
            DSuv: values.insertion.exterior.classic.dSuv,
            Vu: values.insertion.exterior.classic.vu,
            Porteur: values.insertion.exterior.classic.porteur,
            Pl: values.insertion.exterior.classic.pl,
            HorsGabarit: values.insertion.exterior.classic.horsGabarit,
            Moto: values.insertion.exterior.classic.moto
          },
          EmployeeDiscount: {
            ACitadine: values.insertion.exterior.employeeDiscount.aCitadine,
            BCompacte: values.insertion.exterior.employeeDiscount.bCompacte,
            CBerlineRoutiere: values.insertion.exterior.employeeDiscount.cBerlineRoutiere,
            DSuv: values.insertion.exterior.employeeDiscount.dSuv,
            Vu: values.insertion.exterior.employeeDiscount.vu,
            Porteur: values.insertion.exterior.employeeDiscount.porteur,
            Pl: values.insertion.exterior.employeeDiscount.pl,
            HorsGabarit: values.insertion.exterior.employeeDiscount.horsGabarit,
            Moto: values.insertion.exterior.employeeDiscount.moto
          },
          Restitution: {
            ACitadine: values.insertion.exterior.restitution.aCitadine,
            BCompacte: values.insertion.exterior.restitution.bCompacte,
            CBerlineRoutiere: values.insertion.exterior.restitution.cBerlineRoutiere,
            DSuv: values.insertion.exterior.restitution.dSuv,
            Vu: values.insertion.exterior.restitution.vu,
            Porteur: values.insertion.exterior.restitution.porteur,
            Pl: values.insertion.exterior.restitution.pl,
            HorsGabarit: values.insertion.exterior.restitution.horsGabarit,
            Moto: values.insertion.exterior.restitution.moto
          },
          FleetManagement: {
            ACitadine: values.insertion.exterior.fleetManagement.aCitadine,
            BCompacte: values.insertion.exterior.fleetManagement.bCompacte,
            CBerlineRoutiere: values.insertion.exterior.fleetManagement.cBerlineRoutiere,
            DSuv: values.insertion.exterior.fleetManagement.dSuv,
            Vu: values.insertion.exterior.fleetManagement.vu,
            Porteur: values.insertion.exterior.fleetManagement.porteur,
            Pl: values.insertion.exterior.fleetManagement.pl,
            HorsGabarit: values.insertion.exterior.fleetManagement.horsGabarit,
            Moto: values.insertion.exterior.fleetManagement.moto
          }
        },
        Full: {
          Classic: {
            ACitadine: values.insertion.full.classic.aCitadine,
            BCompacte: values.insertion.full.classic.bCompacte,
            CBerlineRoutiere: values.insertion.full.classic.cBerlineRoutiere,
            DSuv: values.insertion.full.classic.dSuv,
            Vu: values.insertion.full.classic.vu,
            Porteur: values.insertion.full.classic.porteur,
            Pl: values.insertion.full.classic.pl,
            HorsGabarit: values.insertion.full.classic.horsGabarit,
            Moto: values.insertion.full.classic.moto
          },
          EmployeeDiscount: {
            ACitadine: values.insertion.full.employeeDiscount.aCitadine,
            BCompacte: values.insertion.full.employeeDiscount.bCompacte,
            CBerlineRoutiere: values.insertion.full.employeeDiscount.cBerlineRoutiere,
            DSuv: values.insertion.full.employeeDiscount.dSuv,
            Vu: values.insertion.full.employeeDiscount.vu,
            Porteur: values.insertion.full.employeeDiscount.porteur,
            Pl: values.insertion.full.employeeDiscount.pl,
            HorsGabarit: values.insertion.full.employeeDiscount.horsGabarit,
            Moto: values.insertion.full.employeeDiscount.moto
          },
          Restitution: {
            ACitadine: values.insertion.full.restitution.aCitadine,
            BCompacte: values.insertion.full.restitution.bCompacte,
            CBerlineRoutiere: values.insertion.full.restitution.cBerlineRoutiere,
            DSuv: values.insertion.full.restitution.dSuv,
            Vu: values.insertion.full.restitution.vu,
            Porteur: values.insertion.full.restitution.porteur,
            Pl: values.insertion.full.restitution.pl,
            HorsGabarit: values.insertion.full.restitution.horsGabarit,
            Moto: values.insertion.full.restitution.moto
          },
          FleetManagement: {
            ACitadine: values.insertion.full.fleetManagement.aCitadine,
            BCompacte: values.insertion.full.fleetManagement.bCompacte,
            CBerlineRoutiere: values.insertion.full.fleetManagement.cBerlineRoutiere,
            DSuv: values.insertion.full.fleetManagement.dSuv,
            Vu: values.insertion.full.fleetManagement.vu,
            Porteur: values.insertion.full.fleetManagement.porteur,
            Pl: values.insertion.full.fleetManagement.pl,
            HorsGabarit: values.insertion.full.fleetManagement.horsGabarit,
            Moto: values.insertion.full.fleetManagement.moto
          }
        },
        UpholsteryStainRemoval: {
          Driver: values.insertion.upholsteryStainRemoval.driver,
          Passenger: values.insertion.upholsteryStainRemoval.passenger,
          BackLeft: values.insertion.upholsteryStainRemoval.backLeft,
          BackRight: values.insertion.upholsteryStainRemoval.backRight,
          All: values.insertion.upholsteryStainRemoval.all
        },
        CarpetStainRemoval: {
          Driver: values.insertion.carpetStainRemoval.driver,
          Passenger: values.insertion.carpetStainRemoval.passenger,
          BackLeft: values.insertion.carpetStainRemoval.backLeft,
          BackRight: values.insertion.carpetStainRemoval.backRight,
          Boot: values.insertion.carpetStainRemoval.boot,
          All: values.insertion.carpetStainRemoval.all
        },
        Options: {
          FirstIntervention: values.insertion.options.firstIntervention,
          VehicleVeryDirty: values.insertion.options.vehicleVeryDirty,
          VehicleState: values.insertion.options.vehicleState,
          Conveying: values.insertion.options.conveying,
          Sticking: values.insertion.options.sticking,
          Desticking: values.insertion.options.desticking,
          ContactZoneDisinfection: values.insertion.options.contactZoneDisinfection,
          InteriorDisinfection: values.insertion.options.interiorDisinfection
        }
      }
    });
  }
}
