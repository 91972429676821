import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EntityWrapperComponent } from '../shared/entity-wrapper/entity-wrapper.component';
import { User } from '../../../shared/models/entities/user';
import { Subscription } from 'rxjs';
import { UserService } from '../../../shared/services/api/user.service';
import { UntypedFormGroup } from '@angular/forms';
import { AccountFormComponent } from './account-form/account-form.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'laveo-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(EntityWrapperComponent) entityWrapperComponent: EntityWrapperComponent;
  @ViewChild(AccountFormComponent) accountFormComponent: AccountFormComponent;

  isReady = false;
  user: User;

  private subscriptions: Subscription[] = [];

  constructor(
    private readonly titleService: Title,
    public readonly userService: UserService
    ) {}

  readonly title = 'Mon compte';
  readonly description = 'Modifiez ici les informations de votre compte utilisateur.';

  ngOnInit(): void {
    this.setTitle();
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(this.entityWrapperComponent?.entity
      .subscribe(entity => {
        this.user = entity as User;
        if (this.user !== undefined) {
          this.isReady = true;
        }
      }));
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  public form(): UntypedFormGroup {
    return this.accountFormComponent?.form;
  }

  private setTitle() {
    this.titleService.setTitle(`Lavéo - ${this.title}`);
  }
}
