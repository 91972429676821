import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo, gql, MutationResult } from 'apollo-angular';
import { User, UserArray } from '../../models/entities/user';
import { UserRole } from '../../models/entities/user-role';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { CookieName } from 'src/app/configs/cookies.config';
import { addDays } from 'date-fns';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';
import { CookieService } from 'ngx-cookie-service';
import { PagedRequest } from '../../models/api/shared/paged/paged-request';
import { PagedResponse } from '../../models/api/shared/paged/paged-response.interface';
import { UserRequest } from '../../models/api/services/user-requests.interface';
import { TypeSerializerUtils } from '../../utils/type-serializer.util';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private readonly apollo: Apollo,
    private readonly cookie: CookieService,
    private readonly authService: AuthService
  ) {}

  get currentRole(): Observable<UserRole> {
    const roleId = this.authService.roleId;
    if (roleId) {
      return this.currentUser().pipe(
        switchMap(result => result.data?.roles),
        first(r => r.id === roleId)
      );
    }

    return this.currentUser().pipe(switchMap(result => result.data?.roles), first(), tap(role => this.setCurrentRole(role.id)));
  }

  currentUser(): Observable<ApolloQueryResult<User>> {
    const query = gql`
      query me {
        users {
          me {
            id
            active
            mail
            mailValidation
            name
            roles {
              id
              type
              logo {
                id
                url
              }
              actor {
                ... on Actor {
                  id
                  name
                }
                ... on CustomerSite {
                  workflowType
                  type
                  finishing
                  firstIntervention
                  vehicleVeryDirty
                  upholsteryStainRemoval
                  carpetStainRemoval
                  conveying
                  sticking
                  desticking
                  contactZoneDisinfection
                  interiorDisinfection
                }
              }
              rights {
                services
                users
                vehicles
                customers
                customerSites
                preparers
                structures
                params
              }
            }
          }
        }
      }
    `;

    return this.apollo.query<{ users: { me: User } }>({ query }).pipe(map(response => ({
      data: TypeSerializerUtils.deserialize(response.data?.users?.me, User),
      loading: response.loading,
      networkStatus: response.networkStatus
    })));
  }

  setCurrentRole(roleId: string): void {
    this.cookie.set(CookieName.role, roleId, addDays(new Date(), 60), '/', undefined, !environment.hmr);
  }

  addUser(data: UserRequest): Observable<MutationResult<User>> {
    const query = gql`
      mutation addUser($data: UserRequest) {
        users {
          add (data: $data) {
            id
            active
            mail
            mailValidation
            name
            roles {
              id
              type
              actor {
                ... on Actor {
                  id
                  name
                }
              }
              rights {
                services
                users
                vehicles
                customers
                customerSites
                preparers
                structures
                params
              }
            }
          }
        }
      }
    `;

    return this.apollo.mutate<{ users: { add: User } }>({
      mutation: query,
      variables: {
        data: {
          active: data?.active,
          name: data?.name,
          mailValidation: data?.mailValidation,
          actors: data?.roles?.map(r => ({
              role: r.role,
              actorId: r.entity?.id
            })),
          plainPassword: data?.plainPassword
        }
      }
    }).pipe(map(result => ({

      data: TypeSerializerUtils.deserialize(result.data?.users?.add, User),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }

  updateUser(data: UserRequest): Observable<MutationResult<User>> {
    const query = gql`
      mutation updateUser($id: Guid!, $data: UserRequest) {
        users {
          update (id: $id, data: $data) {
            id
            active
            mail
            mailValidation
            activatedByUser
            name
            roles {
              id
              type
              actor {
                ... on Actor {
                  id
                  name
                }
              }
              rights {
                services
                users
                vehicles
                customers
                customerSites
                preparers
                structures
                params
              }
            }
          }
        }
      }
    `;

    return this.apollo.mutate<{ users: { update: User } }>({
      mutation: query,
      variables: {
        id: data.id,
        data: {
          active: data?.active,
          name: data?.name,
          mailValidation: data?.mailValidation,
          actors: data?.roles?.map(r => ({
              role: r.role,
              actorId: r.entity?.id
            })),
          plainPassword: data?.plainPassword
        }
      }
    }).pipe(map(result => ({

      data: TypeSerializerUtils.deserialize(result.data?.users?.update, User),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }


  sendMailActivation(id: string): Observable<MutationResult<User>> {
    const query = gql`
      mutation sendMailActivation($id: Guid!) {
        users {
          sendMailActivation (id: $id) {
            id
            active
            mail
            mailValidation
            name
            roles {
              id
              type
              actor {
                ... on Actor {
                  id
                  name
                }
              }
              rights {
                services
                users
                vehicles
                customers
                customerSites
                preparers
                structures
                params
              }
            }
          }
        }
      }
    `;

    return this.apollo.mutate<{ users: { sendMailActivation: User } }>({
      mutation: query,
      variables: {
        id
      }
    }).pipe(map(result => ({

      data: TypeSerializerUtils.deserialize(result.data?.users?.sendMailActivation, User),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }

  deleteUser(...ids: string[]): Observable<MutationResult<void>> {
    const query = `
      mutation deleteUser(` + ids.map((_, index) => '$id' + index + ': Guid!').join(', ') + `) {
        users {\n` + ids.map((_, index) => 'id' + index + ': delete (id: $id' + index + ')').join('\n') + `\n}
      }
    `;

    const variables = ids.reduce((response, item, index) => {
      response['id' + index] = item;
      return response;
    }, {});

    return this.apollo.mutate<void>({
      mutation: gql(query),
      variables
    });
  }

  user(id: string): Observable<ApolloQueryResult<User>> {
    const query = gql`
      query user($id: Guid!) {
        users {
          user(id: $id){
            id
            active
            mail
            mailValidation
            activatedByUser
            name
            roles {
              id
              type
              actor {
                ... on Actor {
                  id
                  name
                }
              }
              rights {
                services
                users
                vehicles
                customers
                customerSites
                preparers
                structures
                params
              }
            }
          }
        }
      }
    `;

    return this.apollo.query<{ users: { user: User } }>({
      query,
      variables: { id }
    }).pipe(map(result => ({
      data: TypeSerializerUtils.deserialize(result.data?.users?.user, User),
      error: result.error,
      errors: result.errors,
      partial: result.partial,
      loading: result.loading,
      networkStatus: result.networkStatus
    })));
  }

  allUsers(parameters: PagedRequest): Observable<ApolloQueryResult<PagedResponse<User>>> {
    const query = gql`
      query users($offset: Int, $limit: Int, $sortProperty: String, $sortType: ESortType, $filters: UsersFiltersRequest, $search: String) {
        users {
          users(offset: $offset, limit: $limit, sortProperty: $sortProperty, sortType: $sortType, filters: $filters, search: $search) {
            data {
              id
              active
              mail
              mailValidation
              activatedByUser
              name
              roles {
                id
                type
                actor {
                  ... on Actor {
                    id
                    name
                  }
                }
              }
            }
            metadata {
              currentPage
              currentResult
              totalPages
              totalResults
              hasMore
            }
          }
        }
      }
    `;
    return this.apollo.query<{ users: { users: PagedResponse<User> } }>({
      query, variables: {
        offset: parameters.offset,
        limit: parameters.limit,
        sortProperty: parameters.sortProperty,
        sortType: parameters.sortType,
        filters: parameters.filters,
        search: parameters.search
      }
    }).pipe(map(result => {
      const raw = result.data?.users?.users;
      const response: PagedResponse<User> = {
        data: TypeSerializerUtils.deserializeArr(raw.data, UserArray),
        metadata: raw.metadata
      };
      return {
        data: response,
        error: result.error,
        errors: result.errors,
        partial: result.partial,
        loading: result.loading,
        networkStatus: result.networkStatus
      };
    }));
  }
}
