import { Customer } from '../../entities/customer';
import { CustomerSite } from '../../entities/customer-site';
import { Structure } from '../../entities/structure';
import { Type } from 'typeserializer';
import { ServiceType } from '../../entities/service';

export class ReportingServiceSpecification {
  @Type(Customer)
  customer?: Customer;
  @Type(CustomerSite)
  customerSite?: CustomerSite;
  @Type(Structure)
  structure?: Structure;
  actor: Customer | CustomerSite | Structure;
  type: ServiceType;
  nb: number;
  nbVehicleStateEnabled: number;
  nbVehicleStateDisabled: number;
  nbConveying: number;
  nbNoConveying: number;
  nbSticking: number;
  nbNoSticking: number;
  nbDesticking: number;
  nbNoDesticking: number;
  nbContactZoneDisinfection: number;
  nbNoContactZoneDisinfection: number;
  nbInteriorDisinfection: number;
  nbNoInteriorDisinfection: number;
  nbFinishingClassic: number;
  nbFinishingEmployeeDiscount: number;
  nbFinishingRestitution: number;
  nbFinishingFleetManagement: number;
  nbFirstIntervention: number;
  nbNotFirstIntervention: number;
  nbVehicleVeryDirty: number;
  nbVehicleNotVeryDirty: number;
  nbUpholsteryStainRemovalDriver: number;
  nbUpholsteryStainRemovalPassenger: number;
  nbUpholsteryStainRemovalBackLeft: number;
  nbUpholsteryStainRemovalBackRight: number;
  nbCarpetStainRemovalDriver: number;
  nbCarpetStainRemovalPassenger: number;
  nbCarpetStainRemovalBackLeft: number;
  nbCarpetStainRemovalBackRight: number;
  nbCarpetStainRemovalBoot: number;
}
