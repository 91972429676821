import { Type } from 'typeserializer';
import { CustomerSite } from './customer-site';
import { Entity } from './entity';
import { VehicleAttribution } from './vehicle-attribution';
import { VehicleFrequency } from './vehicle-frequency';
import { VehicleModel } from './vehicle-model';

export class Vehicle extends Entity {
  @Type(CustomerSite)
  customerSite: CustomerSite;
  @Type(VehicleModel)
  model: VehicleModel;
  active: boolean;
  licensePlate: string;
  frequency: VehicleFrequency;
  attribution: VehicleAttribution;
  collaborator: string;
  comment: string;
  group: string;
}

export class VehicleArray {
  @Type([Vehicle])
  children: Vehicle[];
}
