import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'laveo-shortlink',
  templateUrl: './shortlink.component.html',
  styleUrls: ['./shortlink.component.scss']
})
export class ShortlinkComponent implements OnInit, OnDestroy {
  expandedLink = '';

  private routeSubscription?: Subscription;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.routeSubscription = this.route.params.subscribe(params => {
      const link: string | undefined = params.link;
      if (this.linkIsValid(link)) {
        this.expandLink(params.link);
      } else {
        void this.router.navigate(['/']);
      }
    });
  }

  ngOnDestroy(): void {
    this.routeSubscription?.unsubscribe();
  }

  private linkIsValid(link?: string): boolean {
    if (!link) {
      return false;
    }

    return /[\da-f]{8}-[\da-f]{4}-[1-5][\da-f]{3}-[89ab][\da-f]{3}-[\da-f]{12}/.test(link);
  }

  private expandLink(link: string): void {
    this.expandedLink = `${environment.api.url}/upload/${link}.pdf`;
    location.href = this.expandedLink;
  }
}
