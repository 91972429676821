export const DateConverter = {
  deserialize: (date?: string): Date | undefined => {
    if (date === null || date === undefined) {
      return;
    }
    return new Date(date.endsWith('Z') ? date : date + 'Z');
  },

  deserializeArr: (dateArray: string[]): Date[] => dateArray.map(dateString => DateConverter.deserialize(dateString)!)
};
