import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, Validators, AbstractControl, ValidationErrors, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { EmailValidators } from 'ngx-validators';
import { Subscription } from 'rxjs';
import { emailOptionsConfig } from 'src/app/configs/validators.config';
import { User } from 'src/app/shared/models/entities/user';
import { Customer } from '../../../../../../shared/models/entities/customer';
import { CustomerSite } from '../../../../../../shared/models/entities/customer-site';
import { Structure } from '../../../../../../shared/models/entities/structure';
import { Preparer } from '../../../../../../shared/models/entities/preparer';
import { UserRoleType } from 'src/app/shared/models/entities/user-role';

@Component({
  selector: 'laveo-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit, OnDestroy {
  @Input() user: User;

  form: UntypedFormGroup;
  emailErrors: ValidationErrors | null;

  roleTypeEntity = [
    Customer,
    CustomerSite,
    Structure,
    Preparer
  ];

  private subscriptions: Subscription[] = [];

  constructor(private readonly formBuilder: UntypedFormBuilder) {}

  get usernameValidationStatus(): string | AbstractControl | null {
    if (this.emailErrors?.suggestion) {
      return 'warning';
    }

    return this.form.get('mailValidation');
  }

  get rolesFormArray(): UntypedFormArray {
    return this.form.get('roles') as UntypedFormArray;
  }

  public get isAdmin(): boolean {
    return this.user?.roles?.some(r => r.type === UserRoleType.admin) ?? false;
  }

  ngOnInit(): void {
    this.setForm();
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  private setForm(): void {
    this.form = this.formBuilder.group({
      id: this.formBuilder.control(this.user?.id, []),
      active: this.formBuilder.control(this.user?.active ?? true, [Validators.required]),
      name: this.formBuilder.control(this.user?.name, [Validators.required]),
      mailValidation: this.formBuilder.control(this.user?.mailValidation ?? this.user?.mail, [Validators.required, Validators.email]),
      roles: this.formBuilder.array(this.user?.roles ?? [], [])
    });

    const mailSubscription = this.form.get('mailValidation')?.valueChanges.subscribe(() => {
      const validate = EmailValidators.suggest(emailOptionsConfig);
      const mail = this.form.get('mailValidation');
      if (mail) {
        this.emailErrors = validate(mail);
      }
    });

    if (mailSubscription) {
      this.subscriptions.push(mailSubscription);
    }
  }
}
