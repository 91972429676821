<nz-form-item>
  <nz-form-label nzFor="billingPerson">Responsable facturation</nz-form-label>
  <nz-form-control [nzErrorTip]="error">
    <nz-select [formControl]="formControl" nzMode="default" nzPlaceHolder="Responsable facturation" nzAllowClear [nzSize]="typeSize" class="w-100-p">
      <nz-option *ngFor="let customerSiteBillingPerson of customerSiteBillingPersonValues" [nzValue]="customerSiteBillingPerson" [nzLabel]="customerSiteBillingPerson | customerSiteBillingPersonName | nullCoal"></nz-option>
    </nz-select>
  </nz-form-control>
  <ng-template #error let-control>
    <ng-container *ngIf="control?.hasError('required')">
      Le responsable facturation est requis
    </ng-container>
  </ng-template>
</nz-form-item>
