<form nz-form [formGroup]="form" nzLayout="vertical">
  <div nz-col [nzXs]="24">
    <nz-form-item>
      <nz-form-label nzFor="name">Nom</nz-form-label>
      <nz-form-control [nzErrorTip]="nameError">
        <input formControlName="name" nz-input placeholder="Nom" nzSize="large"/>
      </nz-form-control>
    </nz-form-item>
    <ng-template #nameError let-control>
      <ng-container *ngIf="control?.hasError('required')">
        Le nom du modèle est requis
      </ng-container>
    </ng-template>
  </div>

  <div nz-col [nzXs]="12" [nzSm]="24">
    <laveo-select-vehicle-brand [control]="form.get('brand')" [canCreateBrand]="true" typeSize="large"></laveo-select-vehicle-brand>
  </div>

  <div nz-col [nzXs]="12" [nzSm]="24">
    <laveo-select-vehicle-category [control]="form.get('category')" typeSize="large"></laveo-select-vehicle-category>
  </div>

  <div nz-col [nzXs]="24">
    <nz-form-item>
      <nz-form-label nzFor="active">Statut</nz-form-label>
      <nz-form-control>
        <nz-radio-group formControlName="active" nzButtonStyle="solid" [nzSize]="'large'">
          <label nz-radio-button [nzValue]="true">Activé</label>
          <label nz-radio-button [nzValue]="false">Désactivé</label>
        </nz-radio-group>
      </nz-form-control>
    </nz-form-item>
  </div>
</form>
