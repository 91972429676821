<nz-form-item>
  <nz-form-label nzFor="customerSiteWorkflowType">Type workflow</nz-form-label>
  <nz-form-control [nzErrorTip]="error">
    <nz-select [formControl]="formControl" nzMode="default" nzPlaceHolder="Type workflow" nzAllowClear [nzSize]="typeSize" class="w-100-p">
      <nz-option *ngFor="let customerSiteWorkflowType of customerSiteWorkflowTypeValues" [nzValue]="customerSiteWorkflowType" [nzLabel]="customerSiteWorkflowType | customerSiteWorkflowTypeName | nullCoal"></nz-option>
    </nz-select>
  </nz-form-control>
  <ng-template #error let-control>
    <ng-container *ngIf="control?.hasError('required')">
      Le type workflow est requis
    </ng-container>
  </ng-template>
</nz-form-item>
