import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { VehicleModel } from '../../../../../../shared/models/entities/vehicle-model';

@Component({
  selector: 'laveo-vehicle-model-form',
  templateUrl: './vehicle-model-form.component.html',
  styleUrls: ['./vehicle-model-form.component.scss']
})
export class VehicleModelFormComponent implements OnInit {
  @Input() vehicleModel: VehicleModel;

  form: UntypedFormGroup;


  ngOnInit(): void {
    this.setForm();
  }

  private setForm(): void {
    this.form = new UntypedFormGroup({
      id: new UntypedFormControl(this.vehicleModel?.id, []),
      active: new UntypedFormControl(this.vehicleModel?.active),
      name: new UntypedFormControl(this.vehicleModel?.name, [Validators.required]),
      brand: new UntypedFormControl(this.vehicleModel?.brand, [Validators.required]),
      category: new UntypedFormControl(this.vehicleModel?.category, [Validators.required]),
    });
  }
}
