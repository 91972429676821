import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsUtil {
  loaded: Observable<boolean>;

  constructor(private readonly httpClient: HttpClient) {}

  static get styles(): google.maps.MapTypeStyle[] {
    return [{
      featureType: 'administrative',
      elementType: 'all',
      stylers: [{
        visibility: 'on'
      }, {
        saturation: -100
      }, {
        lightness: 20
      }]
    }, {
      featureType: 'road',
      elementType: 'all',
      stylers: [{
        visibility: 'on'
      }, {
        saturation: -100
      }, {
        lightness: 40
      }]
    }, {
      featureType: 'water',
      elementType: 'all',
      stylers: [{
        visibility: 'on'
      }, {
        saturation: -10
      }, {
        lightness: 30
      }]
    }, {
      featureType: 'landscape.man_made',
      elementType: 'all',
      stylers: [{
        visibility: 'simplified'
      }, {
        saturation: -60
      }, {
        lightness: 10
      }]
    }, {
      featureType: 'landscape.natural',
      elementType: 'all',
      stylers: [{
        visibility: 'simplified'
      }, {
        saturation: -60
      }, {
        lightness: 60
      }]
    }, {
      featureType: 'poi',
      elementType: 'all',
      stylers: [{
        visibility: 'off'
      }, {
        saturation: -100
      }, {
        lightness: 60
      }]
    }, {
      featureType: 'transit',
      elementType: 'all',
      stylers: [{
        visibility: 'off'
      }, {
        saturation: -100
      }, {
        lightness: 60
      }]
    }];
  }

  public load(): void {
    if (!environment.googleMapsKey || environment.googleMapsKey === '') {
      const warnMessage = '/!\\ Pas de clé d\'api Google Maps trouvée. Ajouté la dans l\'environnement';
      console.warn(warnMessage);
      this.loaded = throwError(() => new Error(warnMessage));
      return;
    }

    if ((window as any).google) {
      this.loaded = of(true);
      return;
    }

    this.loaded = this.httpClient
      .jsonp(`https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsKey}&libraries=places`, 'callback')
      .pipe(
        map(() => true),
        catchError(error => {
          console.error(error);
          return of(false);
        })
      );
  }
}
