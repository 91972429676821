import { Component, Input } from '@angular/core';
import { Customer } from 'src/app/shared/models/entities/customer';

@Component({
  selector: 'laveo-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.scss']
})
export class CustomerDetailComponent {
  @Input() customer?: Customer;

  customerSites(customer: Customer): string {
    if (customer?.customerSitesMetaData?.totalResults > 0) {
      return customer?.customerSitesMetaData?.totalResults > 1 ? customer?.customerSitesMetaData?.totalResults + ' sites associés' : customer?.customerSitesMetaData?.totalResults + ' site associé';
    }
    return 'Aucun site associé';
  }
}
