import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { CustomerSite } from 'src/app/shared/models/entities/customer-site';
import { Structure } from '../../../../../../shared/models/entities/structure';
import { Customer } from '../../../../../../shared/models/entities/customer';
import { CustomerSiteWorkflowType } from '../../../../../../shared/models/entities/customer-site-workflow-type';
import { CustomerSiteContractType } from '../../../../../../shared/models/entities/customer-site-contract-type';
import { ServiceCarpetStainRemoval, ServiceFinishing, ServiceType, ServiceUpholsteryStainRemoval } from 'src/app/shared/models/entities/service';
import { ServiceTypeNamePipe } from '../../../../../../shared/pipes/service-type-name.pipe';
import { ServiceFinishingNamePipe } from '../../../../../../shared/pipes/service-finishing-name.pipe';
import { ServiceUpholsteryStainRemovalNamePipe } from '../../../../../../shared/pipes/service-upholstery-stain-removal-name.pipe';
import { ServiceCarpetStainRemovalNamePipe } from '../../../../../../shared/pipes/service-carpet-stain-removal-name.pipe';
import { imageFileUploadOptions } from 'src/app/configs/file-upload.config';
import { GoogleMapsUtil } from 'src/app/shared/utils/google-maps.util';
import { NgxGpAutocompleteOptions } from '@angular-magic/ngx-gp-autocomplete';
import { NzTreeNode, NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { VehicleCheckpointsService } from '../../../../../../shared/services/api/vehicles-checkpoint.service';
import { PagedRequest } from '../../../../../../shared/models/api/shared/paged/paged-request';
import { map } from 'rxjs/operators';
import { Utils } from '../../../../../../shared/utils/utils';

@Component({
  selector: 'laveo-customer-site-form',
  templateUrl: './customer-site-form.component.html',
  styleUrls: ['./customer-site-form.component.scss']
})
export class CustomerSiteFormComponent implements OnInit {
  @Input() customerSite?: CustomerSite;

  form: UntypedFormGroup;

  placesAutocompleteOptions: NgxGpAutocompleteOptions = {
    types: ['address'],
  };

  typeStructure = [Structure];
  typeCustomer = [Customer];

  permissions: NzTreeNodeOptions[] | NzTreeNode[] = [
    {
      title: 'Type de lavage',
      key: 'type',
      checked: false,
      children: Object.values(ServiceType).map(element => ({
        title: (new ServiceTypeNamePipe()).transform(element) ?? '',
        key: 'type.' + element,
        isLeaf: true
      }))
    },
    {
      title: 'Finition',
      key: 'finishing',
      checked: false,
      children: Object.values(ServiceFinishing).map(element => ({
        title: (new ServiceFinishingNamePipe()).transform(element) ?? '',
        key: 'finishing.' + element,
        isLeaf: true
      }))
    },
    {
      title: 'Détachage sellerie',
      key: 'upholsteryStainRemoval',
      checked: false,
      children: Object.values(ServiceUpholsteryStainRemoval).map(element => ({
        title: (new ServiceUpholsteryStainRemovalNamePipe()).transform(element) ?? '',
        key: 'upholsteryStainRemoval.' + element,
        isLeaf: true
      }))
    },
    {
      title: 'Détachage moquette',
      key: 'carpetStainRemoval',
      children: Object.values(ServiceCarpetStainRemoval).map(element => ({
        title: (new ServiceCarpetStainRemovalNamePipe()).transform(element) ?? '',
        key: 'carpetStainRemoval.' + element,
        isLeaf: true
      }))
    },
    {
      title: '1ère intervention',
      key: 'firstIntervention',
      isLeaf: true,
    },
    {
      title: 'Véhicule très sale',
      key: 'vehicleVeryDirty',
      isLeaf: true,
    },
    {
      title: 'Convoyage',
      key: 'conveying',
      isLeaf: true,
    },
    {
      title: 'Stickage',
      key: 'sticking',
      isLeaf: true,
    },
    {
      title: 'Déstickage',
      key: 'desticking',
      isLeaf: true,
    },
    {
      title: 'Désinfection habitacle',
      key: 'interiorDisinfection',
      isLeaf: true,
    },
    {
      title: 'Désinfection zone de contact',
      key: 'contactZoneDisinfection',
      isLeaf: true,
    }
  ];
  checkedPermissions: string[] = [];

  vehicleCheckpoints: NzTreeNodeOptions[] = [];
  checkedVehicleCheckpoints: string[] = [];

  fileUploadOptions: any = {};

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    public readonly googleMapsUtil: GoogleMapsUtil,
    private vehicleCheckpointsService: VehicleCheckpointsService
  ) {}

  get contactFormArray(): UntypedFormArray {
    return this.form.get('contacts') as UntypedFormArray;
  }

  ngOnInit(): void {
    this.setForm();
    this.fileUploadOptions = imageFileUploadOptions;
    this.googleMapsUtil.load();
    this.setVehicleCheckpointsChecked();
  }

  onFullAddressChange(address: google.maps.places.PlaceResult): void {
    if (!address) {
      return;
    }

    const streetNumber = address.address_components?.find(x => x.types.includes('street_number'));
    const street = address.address_components?.find(x => x.types.includes('route'));
    const postalCode = address.address_components?.find(x => x.types.includes('postal_code'));
    const city = address.address_components?.find(x => x.types.includes('locality'));
    const country = address.address_components?.find(x => x.types.includes('country'));
    const latitude = address?.geometry?.location?.lat();
    const longitude = address?.geometry?.location?.lng();

    if (street && streetNumber) {
      this.form.get('address')?.setValue(streetNumber.long_name + ' ' + street.long_name);
    } else if (street) {
      this.form.get('address')?.setValue(street.long_name);
    } else {
      this.form.get('address')?.setValue(null);
    }
    if (postalCode) {
      this.form.get('postalCode')?.setValue(postalCode.long_name);
    } else {
      this.form.get('postalCode')?.setValue(null);
    }
    if (city) {
      this.form.get('city')?.setValue(city.long_name);
    } else {
      this.form.get('city')?.setValue(null);
    }
    if (country) {
      this.form.get('country')?.setValue(country.short_name);
    } else {
      this.form.get('country')?.setValue(null);
    }
    if (latitude && longitude) {
      this.form.get('latitude')?.setValue(latitude);
      this.form.get('longitude')?.setValue(longitude);
    } else {
      this.form.get('latitude')?.setValue(null);
      this.form.get('longitude')?.setValue(null);
    }

    this.form.updateValueAndValidity();
    this.form.get('fullAddress')?.updateValueAndValidity();
  }

  updatePermissions(): void {
    this.iteratePermissionNodes(this.permissions);
  }

  updateVehicleCheckpoints(): void {
    this.form.get('vehicleCheckpointsId')?.setValue(this.iterateVehicleCheckpointsNodes(this.vehicleCheckpoints));
  }

  addLogo(event: { output: File }): void {
    const logoInput = this.form.get('logo');
    logoInput?.setValue(event.output);
    logoInput?.updateValueAndValidity();
  }

  removeLogo(): void {
    const logoInput = this.form.get('logo');
    logoInput?.setValue(null);
    logoInput?.updateValueAndValidity();
  }

  private iteratePermissionNodes(nodes: any[]): void {
    for (const node of nodes) {
      if (node.children) {
        this.iteratePermissionNodes(node.children);
      } else {
        if (
          node.key.startsWith('upholsteryStainRemoval.') ||
          node.key.startsWith('carpetStainRemoval.') ||
          node.key.startsWith('type.') ||
          node.key.startsWith('finishing.')
        ) {
          const keys = node.key.split('.');
          const key = keys[0];
          const value = keys[1];

          const newValue = this.form.get(key)?.value;
          const index = newValue.indexOf(value);
          if (node.checked) {
            if (index === -1) {
              newValue.push(value);
            }
          } else {
            if (index > -1) {
              newValue.splice(index, 1);
            }
          }
          this.form.get(key)?.setValue(newValue);
        } else {
          this.form.get(node.key)?.setValue(node.checked);
        }
      }
    }
  }

  private iterateVehicleCheckpointsNodes(nodes: NzTreeNodeOptions[]): string[] {
    let toReturn: string[] = [];

    if (!this.customerSite) {
      const nodeGeneral = nodes.find(n => n.title === 'Visuels');
      if (nodeGeneral) {
        nodeGeneral.checked = true;
        nodeGeneral.children?.forEach(c => {
          if (c) {
            c.checked = true;
            toReturn.push(c.key);
          }
        });
      }
      return toReturn;
    }

    for (const node of nodes) {
      if (node.children) {
        const resultChildren = this.iterateVehicleCheckpointsNodes(node.children);
        node.checked = resultChildren.length === node.children.length;
        toReturn = [...toReturn, ...resultChildren];
      } else if (node.checked) {
        toReturn.push(node.key);
      }
    }

    return toReturn;
  }

  private setForm(): void {
    this.form = this.formBuilder.group({
      id: this.formBuilder.control(this.customerSite?.id, []),
      active: this.formBuilder.control(this.customerSite?.active ?? true, []),
      name: this.formBuilder.control(this.customerSite?.name, [Validators.required]),
      fullAddress: this.formBuilder.control(this.customerSite?.fullAddress, [this.fullAddressValidator()]),
      address: this.formBuilder.control(this.customerSite?.address, [Validators.required]),
      postalCode: this.formBuilder.control(this.customerSite?.postalCode, [Validators.required]),
      city: this.formBuilder.control(this.customerSite?.city, [Validators.required]),
      country: this.formBuilder.control(this.customerSite?.country, [Validators.required]),
      latitude: this.formBuilder.control(this.customerSite?.latitude, []),
      longitude: this.formBuilder.control(this.customerSite?.longitude, []),
      customer: this.formBuilder.control(this.customerSite?.customer, [Validators.required]),
      structure: this.formBuilder.control(this.customerSite?.structure, [Validators.required]),
      billingPerson: this.formBuilder.control(this.customerSite?.billingPerson, [Validators.required]),
      billingText: this.formBuilder.control(this.customerSite?.billingText, [Validators.maxLength(500)]),
      shelteredSite: this.formBuilder.control(this.customerSite?.shelteredSite),
      electricityAccess: this.formBuilder.control(this.customerSite?.electricityAccess),
      contractType: this.formBuilder.control(this.customerSite?.contractType ?? CustomerSiteContractType.bipartite, [Validators.required]),
      frequencyServiceAffectedVehicle: this.formBuilder.control(this.customerSite?.frequencyServiceAffectedVehicle, []),
      frequencyServiceMutualizedVehicle: this.formBuilder.control(this.customerSite?.frequencyServiceMutualizedVehicle, []),
      workflowType: this.formBuilder.control(this.customerSite?.workflowType ?? CustomerSiteWorkflowType.normal, [Validators.required]),
      contacts: this.formBuilder.array(this.customerSite?.contacts ?? [], []),
      type: this.formBuilder.control(this.customerSite?.type ?? Object.values(ServiceType), []),
      finishing: this.formBuilder.control(this.customerSite?.finishing ?? Object.values(ServiceFinishing), []),
      firstIntervention: this.formBuilder.control(this.customerSite?.firstIntervention ?? true, []),
      vehicleVeryDirty: this.formBuilder.control(this.customerSite?.vehicleVeryDirty ?? true, []),
      upholsteryStainRemoval: this.formBuilder.control(
        this.customerSite?.upholsteryStainRemoval ?? Object.values(ServiceUpholsteryStainRemoval), []
      ),
      carpetStainRemoval: this.formBuilder.control(this.customerSite?.carpetStainRemoval ?? Object.values(ServiceCarpetStainRemoval), []),
      conveying: this.formBuilder.control(this.customerSite?.conveying ?? true, []),
      sticking: this.formBuilder.control(this.customerSite?.sticking ?? true, []),
      desticking: this.formBuilder.control(this.customerSite?.desticking ?? true, []),
      contactZoneDisinfection: this.formBuilder.control(this.customerSite?.contactZoneDisinfection ?? true, []),
      interiorDisinfection: this.formBuilder.control(this.customerSite?.interiorDisinfection ?? true, []),
      logo: this.formBuilder.control(null, []),
      vehicleCheckpointsId: this.formBuilder.control(null, []),
    });

    this.setPermissionsChecked();

    this.updateBillingPersonEnable();
    this.form.get('contractType')?.valueChanges.subscribe(() => {
      this.updateBillingPersonEnable();
    });
  }

  private setPermissionsChecked(): void {
    const newCheckedPermissions: string[] = [];
    const data = this.form.getRawValue();

    for (const element of Object.values(ServiceType)) {
      if (data?.type?.includes(element)) {
        newCheckedPermissions.push('type.' + element);
      }
    }

    for (const element of Object.values(ServiceFinishing)) {
      if (data?.finishing?.includes(element)) {
        newCheckedPermissions.push('finishing.' + element);
      }
    }

    for (const element of Object.values(ServiceUpholsteryStainRemoval)) {
      if (data?.upholsteryStainRemoval?.includes(element)) {
        newCheckedPermissions.push('upholsteryStainRemoval.' + element);
      }
    }

    for (const element of Object.values(ServiceCarpetStainRemoval)) {
      if (data?.carpetStainRemoval?.includes(element)) {
        newCheckedPermissions.push('carpetStainRemoval.' + element);
      }
    }

    if (data?.firstIntervention) {
      newCheckedPermissions.push('firstIntervention');
    }

    if (data?.vehicleVeryDirty) {
      newCheckedPermissions.push('vehicleVeryDirty');
    }

    if (data?.conveying) {
      newCheckedPermissions.push('conveying');
    }

    if (data?.sticking) {
      newCheckedPermissions.push('sticking');
    }

    if (data?.desticking) {
      newCheckedPermissions.push('desticking');
    }

    if (data?.interiorDisinfection) {
      newCheckedPermissions.push('interiorDisinfection');
    }

    if (data?.contactZoneDisinfection) {
      newCheckedPermissions.push('contactZoneDisinfection');
    }

    this.checkedPermissions = newCheckedPermissions;
  }

  private updateBillingPersonEnable(): void {
    const f = this.form.get('contractType')?.value;
    if (f === CustomerSiteContractType.tripartite) {
      this.form.get('billingPerson')?.enable();
    } else {
      this.form.get('billingPerson')?.disable();
      this.form.get('billingPerson')?.setValue(null);
    }
  }

  private fullAddressValidator(): ValidatorFn {
    return (_control: AbstractControl): Record<string, boolean> | null => {
      const isValid = [
        this.form?.get('address'),
        this.form?.get('postalCode'),
        this.form?.get('city'),
        this.form?.get('country')
      ].every(fg => fg?.valid);

      if (!isValid) {
        return { required: true };
      }
      return null;
    };
  }

  private setVehicleCheckpointsChecked(): void {
    this.vehicleCheckpointsService.allVehicleCheckpoints(new PagedRequest({
      limit: 100
    }), true).pipe(
      map(result => result.data.data),
    ).subscribe(checkpoints => {
      const newVehicleCheckpoints: NzTreeNodeOptions[] = [];

      const groupedByCategory = Utils.groupBy(checkpoints.sort((a, b) => a.order - b.order), checkpoint => checkpoint.category);

      Object.keys(groupedByCategory).forEach(category => {
        newVehicleCheckpoints.push({
          title: category,
          key: category,
          children: groupedByCategory[category].map(v => ({
              title: v.name,
              key: v.id,
              checked: !!this.customerSite?.vehicleCheckpoints?.find(d => d.id === v.id),
              isLeaf: true
          }))
        });
      });

      this.vehicleCheckpoints = newVehicleCheckpoints;

      this.updateVehicleCheckpoints();
    });
  }
}
