import { Pipe, PipeTransform } from '@angular/core';
import { VehicleAttribution } from '../models/entities/vehicle-attribution';

@Pipe({
  name: 'vehicleAttributionName'
})
export class VehicleAttributionNamePipe implements PipeTransform {
  transform(type?: VehicleAttribution): string | undefined {
    if (!type) {
      return;
    }

    switch (type) {
      case VehicleAttribution.affected: {
        return 'Affecté';
      }
      case VehicleAttribution.mutualized: {
        return 'Mutualisé';
      }
      default: {
        return;
      }
    }
  }
}
