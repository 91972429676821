<div nz-row [nzGutter]="[48, 24]">
  <div nz-col nzSpan="24" *ngIf="structure?.logo?.urlWithDomain">
    <img [src]="structure?.logo?.urlWithDomain" alt="Logo {{ structure?.name }}" class="logo">
  </div>

  <div nz-col nzSpan="24">
    <div nz-row [nzGutter]="[48, 0]">
      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Statut</span>
          <span class="detail-content" nz-typography>
            <i nz-icon [nzType]="structure?.active ? 'check-square' : 'close-square'" nzTheme="outline"></i>
            {{ structure?.active ? 'Activé' : 'Désactivé' }}
          </span>
        </div>
      </div>

      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Nom</span>
          <span class="detail-content" nz-typography>{{ structure?.name }}</span>
        </div>
      </div>

      <div nz-col>
        <div class="detail">
          <span class="detail-title" nz-typography nzType="secondary">Raison Sociale</span>
          <span class="detail-content" nz-typography>{{ structure?.name2 }}</span>
        </div>
      </div>
    </div>
  </div>

  <div nz-col nzSpan="24">
    <div class="detail">
      <span class="detail-title" nz-typography nzType="secondary">Adresse</span>
      <span class="detail-content" nz-typography>{{ structure?.fullAddress | nullCoal }}</span>
    </div>
  </div>

  <div nz-col nzSpan="24">
    <div class="detail">
      <span class="detail-title" nz-typography nzType="secondary">Préparateurs</span>
      <span class="detail-content" nz-typography>
        <button
          nz-button
          nzSize="large"
          [routerLink]="['/', 'admin', 'preparers']"
          [queryParams]="{ s: structure?.name }"
          [disabled]="structure?.preparersMetaData?.totalResults === 0">
          {{ structure ? preparers(structure) : null }}<i nz-icon nzType="right-square"></i>
        </button>
      </span>
    </div>
  </div>

  <div nz-col nzSpan="24">
    <div class="detail">
      <span class="detail-title" nz-typography nzType="secondary">Site client</span>
      <span class="detail-content" nz-typography>
        <button
          nz-button
          nzSize="large"
          [routerLink]="['/', 'admin', 'customer-sites']"
          [queryParams]="{ s: structure?.name }"
          [disabled]="structure?.customerSitesMetaData?.totalResults === 0">
          {{ structure ? customerSites(structure) : null }}<i nz-icon nzType="right-square"></i>
        </button>
      </span>
    </div>
  </div>

  <div nz-col nzSpan="24">
    <laveo-list-contacts [contacts]="structure ? structure.contacts : []" [showNotificationsCheckbox]="true"></laveo-list-contacts>
  </div>
</div>
