import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzFormModule } from 'ng-zorro-antd/form';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../../shared.module';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { InputsRolesComponent } from './inputs-roles.component';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { SelectRoleTypeModule } from '../select-role-type/select-role-type.module';
import { SelectActorModule } from '../select-actor/select-actor.module';

@NgModule({
  declarations: [
    InputsRolesComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    NzFormModule,
    NzIconModule,
    NzTableModule,
    NzInputModule,
    NzButtonModule,
    NzEmptyModule,
    NzTypographyModule,
    NzGridModule,
    SelectRoleTypeModule,
    SelectActorModule
  ],
  exports: [
    InputsRolesComponent
  ]
})
export class InputsRolesModule {}
