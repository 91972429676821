<form nz-form [formGroup]="form" nzLayout="vertical">
  <div nz-col [nzXs]="24">
    <nz-form-item>
      <nz-form-label nzFor="name">Nom</nz-form-label>
      <nz-form-control [nzErrorTip]="nameError">
        <input formControlName="name" nz-input placeholder="Nom" nzSize="large"/>
      </nz-form-control>
    </nz-form-item>
    <ng-template #nameError let-control>
      <ng-container *ngIf="control?.hasError('required')">
        Le nom du point de controle est requis
      </ng-container>
    </ng-template>
  </div>

  <div nz-col [nzXs]="24">
    <nz-form-item>
      <nz-form-label nzFor="category">Catégorie</nz-form-label>
      <nz-form-control [nzErrorTip]="nameError">
        <input formControlName="category" nz-input placeholder="Catégorie" nzSize="large"/>
      </nz-form-control>
    </nz-form-item>
    <ng-template #nameError let-control>
      <ng-container *ngIf="control?.hasError('required')">
        La catégorie est requise
      </ng-container>
    </ng-template>
  </div>

  <div nz-col [nzXs]="24">
    <nz-form-item>
      <nz-form-label nzFor="order">Ordre d'apparition dans le formulaire et le rapport</nz-form-label>
      <nz-form-control [nzErrorTip]="nameError">
        <input type="number" step="1" formControlName="order" nz-input placeholder="50" nzSize="large"/>
      </nz-form-control>
    </nz-form-item>
    <ng-template #nameError let-control>
      <ng-container *ngIf="control?.hasError('required')">
        L'ordre est requis
      </ng-container>
    </ng-template>
  </div>

  <div formGroupName="fieldData">
    <div nz-col>
      <nz-form-label nzFor="fieldType">Type de champs</nz-form-label>
      <nz-form-control [nzErrorTip]="error">
        <nz-select formControlName="fieldType" nzMode="default" nzPlaceHolder="Le type de champs" nzSize="large" class="w-100-p">
          <nz-option *ngFor="let fieldType of vehicleCheckpointFieldTypeValues" [nzValue]="fieldType" [nzLabel]="fieldType | vehicleCheckpointFieldTypeName | nullCoal"></nz-option>
        </nz-select>
      </nz-form-control>
      <ng-template #error let-control>
        <ng-container *ngIf="control?.hasError('required')">
          Le type de champs est requis
        </ng-container>
      </ng-template>
    </div>

    <div nz-col>
      <nz-form-label nzFor="values">Valeurs possible du champs</nz-form-label>
      <nz-form-control [nzErrorTip]="error">
        <nz-select formControlName="values" nzMode="tags" nzPlaceHolder="Les valeurs" nzSize="large" class="w-100-p"></nz-select>
      </nz-form-control>
      <ng-template #error let-control>
        <ng-container *ngIf="control?.hasError('required')">
          Le type de champs est requis
        </ng-container>
      </ng-template>
    </div>

    <div nz-col>
      <nz-form-item>
        <nz-form-label nzFor="isOnRepport">Mettre le champs dans le rapport (meme sans état des lieux)</nz-form-label>
        <nz-form-control>
          <nz-radio-group formControlName="isOnRepport" nzButtonStyle="solid" nzSize="large">
            <label nz-radio-button [nzValue]="true">Oui</label>
            <label nz-radio-button [nzValue]="false">Non</label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col>
      <nz-form-item>
        <nz-form-label nzFor="required">Le champs est requis dans le formulaire</nz-form-label>
        <nz-form-control>
          <nz-radio-group formControlName="required" nzButtonStyle="solid" nzSize="large">
            <label nz-radio-button [nzValue]="true">Oui</label>
            <label nz-radio-button [nzValue]="false">Non</label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div nz-col [nzXs]="24">
    <nz-form-item>
      <nz-form-label nzFor="active">Statut</nz-form-label>
      <nz-form-control>
        <nz-radio-group formControlName="active" nzButtonStyle="solid" [nzSize]="'large'">
          <label nz-radio-button [nzValue]="true">Activé</label>
          <label nz-radio-button [nzValue]="false">Désactivé</label>
        </nz-radio-group>
      </nz-form-control>
    </nz-form-item>
  </div>
</form>
