import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

@Pipe({
  name: 'serviceDate'
})
export class ServiceDatePipe implements PipeTransform {
  transform(date?: Date): string {
    if (!date) {
      return '-';
    }
    return date.getHours() === 0 ? format(date, 'eeee dd MMM yyy', { locale: fr }) : format(date, 'eeee dd MMM yyy \'à\' HH\'h\'mm', { locale: fr });
  }
}
