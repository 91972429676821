<nz-form-item>
  <nz-form-label nzFor="category">Finition</nz-form-label>
  <nz-form-control [nzErrorTip]="error">
    <nz-select
      [formControl]="formControl"
      nzMode="default"
      nzPlaceHolder="Finition"
      nzAllowClear
      [nzSize]="typeSize"
      class="w-100-p">
      <nz-option *ngFor="let value of values" [nzValue]="value" [nzLabel]="value | serviceFinishingName | nullCoal"></nz-option>
    </nz-select>
  </nz-form-control>
  <ng-template #error let-control>
    <ng-container *ngIf="control?.hasError('required')">
      La finition est requise
    </ng-container>
  </ng-template>
</nz-form-item>
