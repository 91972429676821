<div class="content" nz-row nzAlign="middle">
  <div nz-col [nzXs]="24" [nzLg]="{ span: 12, offset: 6 }">
    <nz-card [nzBordered]="false">
      <div *ngIf="!this.loading else loadingTemplate">
        <div *ngIf="this.token">
          <div *ngIf="!this.passwordFormSent else passwordFormSentTemplate">
            <ng-container *ngTemplateOutlet="passwordFormTemplate"></ng-container>
          </div>
        </div>
        <div *ngIf="!this.token">
          <div *ngIf="!this.emailFormSent else mailFormSentTemplate">
            <ng-container *ngTemplateOutlet="emailFormTemplate" ></ng-container>
          </div>
        </div>
      </div>
    </nz-card>
  </div>
</div>
<ng-template #emailFormTemplate>
  <div nz-row [nzGutter]="[24, 16]">
    <div nz-col nzSpan="24" class="tc">
      <img src="/assets/img/logo.png" class="logo" />
    </div>
    <div nz-col nzSpan="24">
      <form nz-form [formGroup]="emailForm" nzLayout="vertical" (submit)="askForPasswordReset()">
        <nz-form-item>
          <nz-form-label nzFor="email" nzRequired>Email</nz-form-label>
          <nz-form-control [nzErrorTip]="emailError" [nzWarningTip]="emailWarning" [nzValidateStatus]="emailValidationStatus ? emailValidationStatus : 'validating'">
            <input nz-input id="email" type="email" placeholder="identifiant@email.com" nzSize="large" formControlName="email" />
            <ng-template #emailError let-control>
              <ng-container *ngIf="control.hasError('required')">
                L'email est requis
              </ng-container>
              <ng-container *ngIf="control.hasError('email')">
                L'email n'est pas valide
              </ng-container>
            </ng-template>
            <ng-template #emailWarning>
              <ng-container *ngIf="emailErrors?.suggestion">
                <span>Vouliez-vous dire : </span>
                <span (click)="this.emailForm.get('email')?.setValue(emailErrors?.suggestion.full)" [style.textDecoration]="'underline'" [style.cursor]="'pointer'">
                  {{ emailErrors?.suggestion.domain }}
                </span>
                <span> ?</span>
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <div nz-row [nzGutter]="[16, 16]">
          <div nz-col nzFlex="1">
            <button nz-button type="submit" nzSize="large" nzType="primary" class="w-100-p" [nzLoading]="emailFormLoading">Réinitialiser mon mot de passe</button>
          </div>
          <div nz-col nzFlex="1">
            <button nz-button type="button" nzSize="large" class="w-100-p" [routerLink]="'/'">Revenir à l'accueil</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<ng-template #mailFormSentTemplate>
  <nz-result
    nzStatus="success"
    nzTitle="Réinitialisation de mot de passe demandée"
    nzSubTitle="Un email contenant un lien de réinitialisation de mot de passe a été envoyé au compte associé à l'email {{ this.emailForm.get('email')?.value }} si celui-ci existe. Pensez à vérifier vos spam.">
    <div nz-result-extra>
      <button
        nz-button
        nzType="primary"
        [disabled]="emailResetTimeout > 0"
        (click)="askForPasswordReset()"
      >{{ emailResetTimeout > 0 ? 'Renvoyer le message (' + emailResetTimeout + ')' : 'Renvoyer le message' }}</button>
      <button nz-button [routerLink]="'/'">Revenir à l'accueil</button>
    </div>
  </nz-result>
</ng-template>
<ng-template #passwordFormTemplate>
  <div nz-col nzSpan="24" class="tc">
    <img src="/assets/img/logo.png" class="logo" />
  </div>
  <div nz-col nzSpan="24">
    <p>Entrez le nouveau mot de passe de votre compte ci-dessous.</p>
  </div>
  <div nz-col nzSpan="24">
    <form nz-form [formGroup]="passwordForm" nzLayout="vertical" (submit)="resetPassword()">
      <nz-form-item>
        <nz-form-label nzFor="password" nzRequired>Nouveau mot de passe</nz-form-label>
        <nz-form-control [nzErrorTip]="passwordError">
          <nz-input-group [nzSuffix]="suffixTemplate" nzSize="large">
            <input
              nz-input
              id="password"
              [type]="passwordVisible ? 'text' : 'password'"
              placeholder="••••••••••"
              passwordrules="required: lower; required: upper; required: digit; required: special; minlength: 8;"
              nzSize="large"
              formControlName="password"/>
            <ng-template #suffixTemplate>
              <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="passwordVisible = !passwordVisible"></i>
            </ng-template>
          </nz-input-group>
          <ng-template #passwordError let-control>
            <span *ngIf="control.hasError('required')">Le nouveau mot de passe est requis</span>
            <span *ngIf="control.hasError('minlength')">Le nouveau mot de passe doit contenir au minimum 8 caractères</span>
            <div *ngIf="!control.hasError('required')" class="validation-errors">
              <i nz-icon [nzType]="control.hasError('digitCharacterRule') ? 'warning' : 'check'"></i><span> Chiffre</span>
              <i nz-icon [nzType]="control.hasError('lowercaseCharacterRule') ? 'warning' : 'check'"></i><span> Minuscule</span>
              <i nz-icon [nzType]="control.hasError('uppercaseCharacterRule') ? 'warning' : 'check'"></i><span> Majuscule</span>
              <i nz-icon [nzType]="control.hasError('specialCharacterRule') ? 'warning' : 'check'"></i><span> Caractère Spécial</span>
            </div>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzFor="confirmPassword" nzRequired>Confirmez le mot de passe</nz-form-label>
        <nz-form-control [nzErrorTip]="confirmPasswordError">
          <nz-input-group [nzSuffix]="confirmSuffixTemplate" nzSize="large">
            <input nz-input id="confirmPassword" [type]="confirmPasswordVisible ? 'text' : 'password'" placeholder="••••••••••" nzSize="large" formControlName="confirmPassword"/>
            <ng-template #confirmSuffixTemplate>
              <i nz-icon [nzType]="confirmPasswordVisible ? 'eye-invisible' : 'eye'" (click)="confirmPasswordVisible = !confirmPasswordVisible"></i>
            </ng-template>
          </nz-input-group>
          <ng-template #confirmPasswordError let-control>
            <span *ngIf="control.hasError('required')">Confirmer le mot de passe est requis</span>
            <span *ngIf="passwordForm.hasError('mismatchedPasswords')">Les mots de passe doivent être identique</span>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <div nz-row [nzGutter]="[16, 16]">
        <div nz-col nzFlex="1">
          <button nz-button type="submit" nzSize="large" nzType="primary" class="w-100-p">Réinitialiser mon mot de
            passe</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #passwordFormSentTemplate>
  <nz-result
    nzStatus="success"
    nzTitle="Mot de passe réinitialisé avec succès"
    nzSubTitle="Votre mot de passe à bien été réinitialisé. Utilisez le bouton ci-dessous pour vous connecter.">
    <div nz-result-extra>
      <button nz-button [routerLink]="'/'">Se connecter</button>
    </div>
  </nz-result>
</ng-template>
<ng-template #loadingTemplate>
  <nz-skeleton></nz-skeleton>
</ng-template>
