"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const helpers_1 = require("../helpers");
function createDecorator(name, keySymbol, value) {
  return function (target, key) {
    const obj = Reflect.getMetadata(keySymbol, target) || {};
    if (!helpers_1.isUndefined(obj[key])) {
      throw new Error(`Cannot apply @${name} decorator twice on property '${key}' of class '${target.constructor.name}'.`);
    }
    Reflect.defineMetadata(keySymbol, Object.assign(Object.assign({}, obj), {
      [key]: value
    }), target);
  };
}
exports.createDecorator = createDecorator;
