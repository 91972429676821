import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, Validators, AbstractControl, ValidationErrors, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { EmailValidators, PasswordValidators } from 'ngx-validators';
import { Subscription } from 'rxjs';
import { emailOptionsConfig } from 'src/app/configs/validators.config';
import { User } from 'src/app/shared/models/entities/user';
import { Preparer } from '../../../../shared/models/entities/preparer';
import { Structure } from '../../../../shared/models/entities/structure';
import { CustomerSite } from '../../../../shared/models/entities/customer-site';
import { Customer } from '../../../../shared/models/entities/customer';
import { UserRoleType } from 'src/app/shared/models/entities/user-role';

@Component({
  selector: 'laveo-account-form',
  templateUrl: './account-form.component.html',
  styleUrls: ['./account-form.component.scss']
})
export class AccountFormComponent implements OnInit, OnDestroy {
  @Input() user: User;

  form: UntypedFormGroup;
  emailErrors: ValidationErrors | null;
  passwordVisible = false;
  confirmPasswordVisible = false;
  mailChanged = false;
  passwordValidatorsEnabled = false;

  roleTypeEntity = [
    Customer,
    CustomerSite,
    Structure,
    Preparer
  ];

  private subscriptions: Subscription[] = [];

  constructor(private readonly formBuilder: UntypedFormBuilder) {}

  get usernameValidationStatus(): string | AbstractControl | null {
    if (this.emailErrors?.suggestion) {
      return 'warning';
    }

    return this.form.get('mailValidation');
  }

  public get isAdmin(): boolean {
    return this.user?.roles?.some(r => r.type === UserRoleType.admin) ?? false;
  }

  public get rolesForm(): UntypedFormArray {
    return this.form.get('roles') as UntypedFormArray;
  }

  ngOnInit(): void {
    this.setForm();
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  private setForm(): void {
    this.form = this.formBuilder.group({
      id: this.formBuilder.control(this.user?.id, []),
      name: this.formBuilder.control(this.user?.name, [Validators.required]),
      mailValidation: this.formBuilder.control(this.user?.mail, [Validators.required, Validators.email]),
      roles: this.formBuilder.array(this.user?.roles ?? [], []),
      plainPassword: this.formBuilder.control(null, []),
      confirmPassword: this.formBuilder.control(null, [])
    });

    this.form.validator = PasswordValidators.mismatchedPasswords('plainPassword', 'confirmPassword');

    const mailSubscription = this.form.get('mailValidation')?.valueChanges.subscribe((mailValue: string) => {
      const validate = EmailValidators.suggest(emailOptionsConfig);
      const mail = this.form.get('mailValidation');
      if (mail) {
        this.emailErrors = validate(mail);
      }
      this.mailChanged = this.user?.mail?.trim() !== mailValue?.trim();
    });

    const passwordSubscription = this.form.get('plainPassword')?.valueChanges.subscribe((passwordValue: string) => {
      const passwordValidatorsEnabledOldState = this.passwordValidatorsEnabled;
      if (passwordValue?.trim() === '') {
        this.form.get('plainPassword')?.setValidators([]);
        this.form.get('confirmPassword')?.setValidators([]);
        this.passwordValidatorsEnabled = false;
      } else {
        this.form.get('plainPassword')?.setValidators([
          Validators.required,
          Validators.minLength(8),
          PasswordValidators.digitCharacterRule(1),
          PasswordValidators.lowercaseCharacterRule(1),
          PasswordValidators.uppercaseCharacterRule(1),
          PasswordValidators.specialCharacterRule(1)
        ]);
        this.form.get('confirmPassword')?.setValidators([Validators.required, Validators.minLength(8)]);
        this.passwordValidatorsEnabled = true;
      }

      if (passwordValidatorsEnabledOldState !== this.passwordValidatorsEnabled) {
        this.form.get('plainPassword')?.updateValueAndValidity();
        this.form.get('confirmPassword')?.updateValueAndValidity();
      }
    });

    if (mailSubscription && passwordSubscription) {
      this.subscriptions.push(mailSubscription, passwordSubscription);
    }
  }
}
