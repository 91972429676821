import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { Service } from '../../../../../shared/models/entities/service';
import { ServicesService } from '../../../../../shared/services/api/services.service';
import { EntityWrapperComponent } from '../../../shared/entity-wrapper/entity-wrapper.component';
import { Preparer } from '../../../../../shared/models/entities/preparer';
import { UntypedFormGroup, UntypedFormControl, Validators, FormControl } from '@angular/forms';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { StructuresService } from 'src/app/shared/services/api/structures.service';
import { Subscription } from 'rxjs';
import { ServiceActionPerformData, ServiceActions } from 'src/app/shared/models/entities/service-actions';
import { DisabledTimeConfig, SupportTimeOptions } from 'ng-zorro-antd/date-picker';
import { ApolloError } from '@apollo/client/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { isAfter, isBefore, startOfToday } from 'date-fns';
import { ApiErrorMessageUtil } from '../../../../../shared/utils/api-error-message.util';
import { UserService } from '../../../../../shared/services/api/user.service';
import { UserRoleType } from '../../../../../shared/models/entities/user-role';

@Component({
  selector: 'laveo-service-workflow',
  templateUrl: './service-workflow.component.html',
  styleUrls: ['./service-workflow.component.scss']
})
export class ServiceWorkflowComponent implements OnInit, OnDestroy {
  @Input() services: Service[] = [];
  @Input() actionsToFilter: Set<ServiceActions> = new Set<ServiceActions>([]);
  @Input() actionEmitter?: EventEmitter<ServiceActions>;
  @Input() isInModal = false;
  @Input() wrapper?: EntityWrapperComponent;

  @Output() actionPerformed = new EventEmitter<{action: ServiceActions; loading: boolean; service: Service[]; error?: Error}>();

  cancelLoading = false;
  actionLoading = false;
  availableActions: ServiceActions[] = [];

  preparers: Preparer[];
  typePreparer = [Preparer];
  form: UntypedFormGroup;

  timePickerOptions: SupportTimeOptions = {
    nzFormat: 'HH\'h\'mm',
    nzMinuteStep: 15,
    nzHideDisabledOptions: true
  };

  cancelComment = new FormControl('', [Validators.required]);

  private subscriptions: Subscription[] = [];

  constructor(
    private readonly servicesService: ServicesService,
    private readonly structuresService: StructuresService,
    private readonly modal: NzModalService,
    private readonly message: NzMessageService,
    private readonly userService: UserService
  ) {}

  public get showSendToCustomerSite(): boolean {
    if (this.services.length > 0) {
      const date = this.services[0].date;
      const allServicesHaveTheSameDate = this.services.every(s => s.date === date);
      const allServicesIncludeAction = this.services[0].actions?.includes(ServiceActions.send_to_customer_site);
      return allServicesHaveTheSameDate && allServicesIncludeAction;
    }

    return false;
  }

  public get showPerform(): boolean {
    if (this.services.length > 0) {
      const date = this.services[0].date;
      const allServicesHaveTheSameDate = this.services.every(s => s.date === date);
      const allServicesIncludeAction = this.services[0].actions?.includes(ServiceActions.perform) || this.services[0].actions?.includes(ServiceActions.vehicle_not_present)
      return allServicesHaveTheSameDate && allServicesIncludeAction;
    }

    return false;
  }

  ngOnInit(): void {
    this.setAvailableActions();
    this.loadPreparers();
    this.setForm();
    this.setListener();
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  disabledDatesPast(currentDate: Date): boolean {
    return isBefore(currentDate, startOfToday());
  }

  disabledDatesFuture(currentDate: Date): boolean {
    return isAfter(currentDate, new Date());
  }

  disabledTime(): DisabledTimeConfig {
    return {
      nzDisabledHours: () => [0, 1, 2, 3, 4, 5, 22, 23],
      nzDisabledMinutes: () => [],
      nzDisabledSeconds: () => []
    };
  }

  performAction(action: ServiceActions, template?: TemplateRef<any>, footer?: TemplateRef<any>): void {
    const form = this.form;
    if (!form.valid) {
      for (const key of Object.keys(form.controls)) {
        form.controls[key].markAllAsTouched();
        form.controls[key].updateValueAndValidity();
      }
      return;
    }

    switch (action) {
      case ServiceActions.cancel: {
        if (template && footer) {
          this.cancel(template, footer);
        }
        break;
      }
      case ServiceActions.send_to_structure: {
        this.sendToStructure();
        break;
      }
      case ServiceActions.send_to_customer_site: {
        this.sendToCustomerSite();
        break;
      }
      case ServiceActions.confirm: {
        this.confirm();
        break;
      }
      case ServiceActions.perform: {
        this.perform();
        break;
      }
      case ServiceActions.vehicle_not_present: {
        this.vehicleNotPresent();
        break;
      }
    }
  }

  getLoading(action: ServiceActions): boolean {
    switch (action) {
      case ServiceActions.cancel: {
        return this.cancelLoading;
      }
      default: {
        return this.actionLoading;
      }
    }
  }

  closeCancelModal(modal: NzModalRef): void {
    if (this.form.invalid || this.cancelComment.invalid) {
      this.cancelComment.markAsDirty();
      this.cancelComment.updateValueAndValidity();
      return;
    }

    const formValue: { ids: string[] } = this.form.value;
    this.cancelLoading = true;
    this.servicesService.cancel(formValue.ids[0], this.cancelComment.value ?? '').subscribe({
      next: response => {
        this.services = response.data ? [response.data] : [];
        this.wrapper?.entity.next(response.data);
        this.ngOnInit();
        this.cancelLoading = false;
        modal.destroy();
      },
      error: (error: ApolloError) => {
        console.error(error);
        this.cancelLoading = false;
        this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
      }
    });
  }

  private loadPreparers(): void {
    if (!this.availableActions.includes(ServiceActions.perform) || this.services.length === 0) {
      return;
    }

    const firstService = this.services[0];
    const id = firstService.vehicle?.customerSite?.structure?.id;

    if (!id) {
      return;
    }

    const structureSubscription = this.structuresService.structurePreparers(id).subscribe({
      next: preparers => {
        this.preparers = preparers.data?.data;
      },
      error: error => {
        console.error(error);
      }
    });

    if (structureSubscription) {
      this.subscriptions.push(structureSubscription);
    }
  }

  private setForm(): void {
    this.form = new UntypedFormGroup({
      ids: new UntypedFormControl(this.services.map(s => s.id) ?? [])
    });

    if (this.services.length === 0) {
      return;
    }

    const firstService = this.services[0];
    if (this.showSendToCustomerSite) {
      this.form.addControl('dateProposed', new UntypedFormControl(firstService.date, [Validators.required]));
    }

    if (this.showPerform) {
      // this.form.addControl('hoursToInvoice', new FormControl(null, [Validators.min(1)])); // Désactivé jusqu'a v5
      this.form.addControl('datePerformed', new UntypedFormControl(firstService.date ?? new Date(), []));
      this.form.addControl('preparer', new UntypedFormControl(firstService.preparer, []));
    }
  }

  private setAvailableActions(): void {
    if (this.services.length === 0) {
      return;
    }

    this.subscriptions.push(this.userService.currentRole.subscribe(role => {
      const toFilter: Set<ServiceActions> = new Set<ServiceActions>([ServiceActions.update, ServiceActions.upload_vehicle_state]);
      this.availableActions = this.services[0].actions?.filter(action => !toFilter.has(action) && !this.actionsToFilter.has(action)) ?? [];

      if (role.type === UserRoleType.structure) { // TODO faire mieux comme par exemple ajouter un rôle spécial
        this.availableActions = this.availableActions.filter(a => a !== ServiceActions.perform);
      }
    }));
  }

  private setListener(): void {
    if (!this.actionEmitter) {
      return;
    }

    const actionSubscription = this.actionEmitter.subscribe(action => {
      this.performAction(action);
    });

    this.subscriptions.push(actionSubscription);
  }

  // Actions

  private cancel(cancelServiceTemplate: TemplateRef<any>, cancelServiceFooterTemplate: TemplateRef<any>): void {
    this.modal.create({
      nzTitle: 'Annulation',
      nzContent: cancelServiceTemplate,
      nzFooter: cancelServiceFooterTemplate
    });
  }

  private sendToStructure(): void {
    const formValue: { ids: string[] } = this.form.value;
    this.actionLoading = true;
    this.servicesService.sendToStructure(formValue.ids[0]).subscribe({
      next: response => {
        // this.service = response.data;
        this.wrapper?.entity.next(response.data);
        this.ngOnInit();
        this.actionLoading = false;
      },
      error: (error: ApolloError) => {
        console.error(error);
        this.actionLoading = false;
        this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
      }
    });
  }

  private sendToCustomerSite(): void {
    const formValue: { ids: string[]; dateProposed?: Date } = this.form.value;
    this.actionLoading = true;
    this.actionPerformed?.emit({ action: ServiceActions.send_to_customer_site, loading: true, service: [] });

    let action: any = this.servicesService.sendToCustomerSiteMultiple(formValue.ids, formValue.dateProposed);
    let multiple = true;
    if (this.services.length === 1) {
      action = this.servicesService.sendToCustomerSite(formValue.ids[0], formValue.dateProposed);
      multiple = false;
    }

    action.subscribe(response => {
      if (response.data) {
        this.services = multiple ? response.data : [response.data];
        this.wrapper?.entity.next(multiple ? response.data[0] : response.data);
        this.actionPerformed?.emit({ action: ServiceActions.send_to_customer_site, loading: false, service: multiple ? response.data : [response.data] });
      }
      this.ngOnInit();
      this.actionLoading = false;
    }, (error: ApolloError) => {
      console.error(error);
      this.actionPerformed?.emit({ action: ServiceActions.send_to_customer_site, loading: false, service: [], error });
      this.actionLoading = false;
      this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
    });
  }

  private confirm(): void {
    const formValue: { ids: string[] } = this.form.getRawValue();
    this.actionLoading = true;
    this.servicesService.confirm(formValue.ids[0]).subscribe({
      next: response => {
        this.services = response.data ? [response.data] : [];
        this.wrapper?.entity.next(response.data);
        this.ngOnInit();
        this.actionLoading = false;
      },
      error: (error: ApolloError) => {
        console.error(error);
        this.actionLoading = false;
        this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
      }
    });
  }

  private perform(): void {
    const formValue: { ids: string[]; datePerformed?: Date; preparer?: Preparer } = this.form.value;
    const data: ServiceActionPerformData = {
      id: formValue.ids[0],
      datePerformed: formValue.datePerformed,
      preparerId: formValue.preparer?.id
    };

    this.actionLoading = true;
    this.actionPerformed?.emit({ action: ServiceActions.perform, loading: true, service: [] });

    let action: any = this.servicesService.performMultiple(formValue.ids, data);
    let multiple = true;
    if (this.services.length === 1) {
      action = this.servicesService.perform(data);
      multiple = false;
    }

    action.subscribe(response => {
      if (response.data) {
        this.services = multiple ? response.data : [response.data];
        this.wrapper?.entity.next(multiple ? response.data[0] : response.data);
        this.actionPerformed?.emit({ action: ServiceActions.perform, loading: false, service: multiple ? response.data : [response.data] });
      }
      this.ngOnInit();
      this.actionLoading = false;
    }, (error: ApolloError) => {
      console.error(error);
      this.actionPerformed?.emit({ action: ServiceActions.perform, loading: false, service: [], error });
      this.actionLoading = false;
      this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
    });
  }

  private vehicleNotPresent(): void {
    const formValue: { ids: string[]; datePerformed?: Date; preparer?: Preparer } = this.form.value;
    const data: ServiceActionPerformData = {
      id: formValue.ids[0],
      datePerformed: formValue.datePerformed,
      preparerId: formValue.preparer?.id
    };

    this.actionLoading = true;
    this.actionPerformed?.emit({ action: ServiceActions.vehicle_not_present, loading: true, service: [] });

    let action: any = this.servicesService.vehicleNotPresentMultiple(formValue.ids, data);
    let multiple = true;
    if (this.services.length === 1) {
      action = this.servicesService.vehicleNotPresent(data);
      multiple = false;
    }

    action.subscribe(response => {
      if (response.data) {
        this.services = multiple ? response.data : [response.data];
        this.wrapper?.entity.next(multiple ? response.data[0] : response.data);
        this.actionPerformed?.emit({ action: ServiceActions.vehicle_not_present, loading: false, service: multiple ? response.data : [response.data] });
      }
      this.ngOnInit();
      this.actionLoading = false;
    }, (error: ApolloError) => {
      console.error(error);
      this.actionPerformed?.emit({ action: ServiceActions.vehicle_not_present, loading: false, service: [], error });
      this.actionLoading = false;
      this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
    });
  }
}
