"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const consts_1 = require("../consts");
const helpers_1 = require("../helpers");
function serialize(obj, groups, version) {
  if (Array.isArray(obj)) {
    return JSON.stringify(transformArray(obj, groups, version));
  }
  return JSON.stringify(transform(obj, groups, version));
}
exports.serialize = serialize;
// -- Private --
function transformArray(arr, groups, version, stack) {
  return arr.map(elm => {
    return Array.isArray(elm) ? transformArray(elm, groups, version, stack) : transform(elm, groups, version, stack);
  });
}
function transform(obj, groups, version, stack) {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }
  const excludeMap = Reflect.getMetadata(consts_1.ExcludeSymbol, obj) || {};
  const exposeMap = Reflect.getMetadata(consts_1.ExposeSymbol, obj) || {};
  const groupsMap = Reflect.getMetadata(consts_1.GroupsSymbol, obj) || {};
  const beforeMap = Reflect.getMetadata(consts_1.BeforeSymbol, obj) || {};
  const afterMap = Reflect.getMetadata(consts_1.AfterSymbol, obj) || {};
  const nameMap = Reflect.getMetadata(consts_1.NameSymbol, obj) || {};
  const serializeMap = Reflect.getMetadata(consts_1.SerializerSymbol, obj) || {};
  const strategy = Reflect.getMetadata(consts_1.StrategySymbol, obj.constructor);
  const mySet = new Set(stack || []);
  if (mySet.has(obj)) {
    return consts_1.Detector.CIRCULAR_REFERENCE;
  }
  mySet.add(obj);
  return Object.getOwnPropertyNames(obj).reduce((json, key) => {
    const name = nameMap[key] || key;
    if (shouldAdd(obj, excludeMap, exposeMap, beforeMap, afterMap, groupsMap, strategy, key, groups, version)) {
      if (typeof serializeMap[key] === 'function') {
        json[name] = serializeMap[key].call(null, obj[key], obj);
      } else if (Array.isArray(obj[key])) {
        json[name] = transformArray(obj[key], groups, version, mySet).filter(elm => elm !== consts_1.Detector.CIRCULAR_REFERENCE);
      } else if (helpers_1.isObject(obj[key])) {
        const transformed = transform(obj[key], groups, version, mySet);
        if (transformed !== consts_1.Detector.CIRCULAR_REFERENCE) {
          json[name] = transformed;
        }
      } else {
        json[name] = obj[key];
      }
    }
    return json;
  }, {});
}
function shouldAdd(obj, excludeMap, exposeMap, beforeMap, afterMap, groupsMap, strategy, key, groups, version) {
  const propGroups = groupsMap && groupsMap[key] ? groupsMap[key] : [];
  if (strategy === consts_1.ExclusionPolicy.ALL && (!exposeMap.hasOwnProperty(key) || !exposeMap[key].call(null, obj, key))) {
    return false;
  }
  if (excludeMap.hasOwnProperty(key) && excludeMap[key].call(null, obj, key)) {
    return false;
  }
  if (version && beforeMap.hasOwnProperty(key)) {
    return helpers_1.versionCompare(beforeMap[key], version) === 1;
  }
  if (version && afterMap.hasOwnProperty(key)) {
    return helpers_1.versionCompare(afterMap[key], version) <= 0;
  }
  if (groups && groups.length) {
    return groups.some(group => !!~propGroups.indexOf(group));
  }
  return true;
}
