<nz-form-item>
  <nz-form-label nzFor="brand">Marque</nz-form-label>
  <nz-form-control [nzErrorTip]="error">
    <nz-select [formControl]="formControl"
        nzPlaceHolder="Marque"
        nzAllowClear
        nzShowSearch
        nzServerSearch
        [nzShowArrow]="true"
        [nzLoading]="isBrandLoading"
        [nzSize]="typeSize"
        [nzMode]="typeSelection === 'single' ? 'default' : 'multiple'"
        (nzOnSearch)="onSearchBrand($event)"
        class="w-100-p"
        [compareWith]="comparer">
      <nz-option *ngIf="isBrandLoading; else brandData" nzDisabled nzCustomContent>
        <i nz-icon nzType="loading" class="loading-icon"></i> Chargement…
      </nz-option>
      <ng-template #brandData>
        <ng-container *ngFor="let brand of brandList">
          <nz-option [nzValue]="brand" [nzLabel]="brand.name" nzCustomContent>
            <b *ngIf="brand.add">Ajouter : </b>
            <b *ngIf="showModels && brand.__typename === 'VehicleBrand'">Marque : </b>
            {{ brand.name }}
          </nz-option>
          <ng-container *ngIf="showModels">
            <nz-option *ngFor="let model of brand.models" [nzValue]="model" [nzLabel]="brand.name + ' : ' + model.name" nzCustomContent>
              <b class="mr-32">&nbsp;</b>
              {{ model.name }}
            </nz-option>
          </ng-container>
        </ng-container>
      </ng-template>
    </nz-select>
  </nz-form-control>
  <ng-template #error let-control>
    <ng-container *ngIf="control?.hasError('required')">
      La marque est requise
    </ng-container>
  </ng-template>
</nz-form-item>
