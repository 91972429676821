import { Component, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { EntityWrapperComponent } from '../../../shared/entity-wrapper/entity-wrapper.component';
import { VehicleModelFormComponent } from './vehicle-model-form/vehicle-model-form.component';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { VehicleModel } from '../../../../../shared/models/entities/vehicle-model';
import { VehicleModelsService } from '../../../../../shared/services/api/vehicles-model.service';

@Component({
  selector: 'laveo-vehicle-model',
  templateUrl: './vehicle-model.component.html',
  styleUrls: ['./vehicle-model.component.scss']
})
export class VehicleModelComponent implements AfterViewInit, OnDestroy {
  @ViewChild(EntityWrapperComponent) entityWrapperComponent: EntityWrapperComponent;
  @ViewChild(VehicleModelFormComponent) vehicleModelFormComponent: VehicleModelFormComponent;

  isReady = false;
  vehicleModel: VehicleModel;

  private subscriptions: Subscription[] = [];

  constructor(public readonly vehicleModelsService: VehicleModelsService) {}

  get title(): string {
    const vehicleModel = this.vehicleModel;
    if (vehicleModel) {
      return vehicleModel.brand.name + ' - ' + vehicleModel.name;
    }

    if (vehicleModel === null) {
      return 'Nouveau modèle de véhicule';
    }

    return '';
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(this.entityWrapperComponent?.entity
      .subscribe(entity => {
        this.vehicleModel = entity as VehicleModel;
        if (this.vehicleModel !== undefined) {
          this.isReady = true;
        }
      }));
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  public form(): UntypedFormGroup {
    return this.vehicleModelFormComponent?.form;
  }
}
