import { Component, Input } from '@angular/core';
import { Vehicle } from 'src/app/shared/models/entities/vehicle';

@Component({
  selector: 'laveo-vehicle-detail',
  templateUrl: './vehicle-detail.component.html',
  styleUrls: ['./vehicle-detail.component.scss']
})
export class VehicleDetailComponent {
  @Input() vehicle?: Vehicle;

}
