<nz-form-item>
  <nz-form-label nzFor="model">Marque/Modèle</nz-form-label>
  <nz-form-control [nzErrorTip]="error">
    <nz-select
      [formControl]="formControl"
      nzMode="default"
      nzPlaceHolder="Marque/Modèle"
      nzAllowClear
      nzShowSearch
      nzServerSearch
      [nzLoading]="isModelLoading"
      (nzOnSearch)="onSearchModel($event)"
      [nzSize]="typeSize"
      class="w-100-p"
      [compareWith]="comparer">
      <nz-option *ngIf="isModelLoading; else modelData" nzDisabled nzCustomContent>
        <i nz-icon nzType="loading" class="loading-icon"></i> Chargement…
      </nz-option>
      <ng-template #modelData>
        <ng-container *ngFor="let model of modelList">
          <nz-option [nzValue]="model" [nzDisabled]="!model.active" [nzLabel]="model.brand.name + ' - ' + model.name + ' - ' + (model.category | vehicleCategoryName)"></nz-option>
        </ng-container>
      </ng-template>
    </nz-select>
  </nz-form-control>
  <ng-template #error let-control>
    <ng-container *ngIf="control?.hasError('required')">
      Le modèle est requis
    </ng-container>
  </ng-template>
</nz-form-item>
