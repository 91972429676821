<laveo-entity-wrapper
  [title]="title"
  [description]="description"
  [entityService]="customersService"
  [getFunction]="customersService.customer"
  [addFunction]="customersService.addCustomer"
  [updateFunction]="customersService.updateCustomer"
  [deleteHardFunction]="customersService.deleteOneCustomerHard"
  [wrappedComponent]="this"
  [getFormFunction]="form"
  [editEnabled]="editEnabled"
  [deleteEnabled]="deleteEnabled">
  <laveo-customer-detail *ngIf="isReady" detail [customer]="customer"></laveo-customer-detail>
  <laveo-customer-form *ngIf="isReady" form [customer]="customer"></laveo-customer-form>
</laveo-entity-wrapper>
