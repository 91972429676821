<form nz-form [formGroup]="form" nzLayout="vertical" (keydown.enter)="$event.preventDefault()">
  <div nz-row [nzGutter]="[24, 0]">
    <div nz-col [nzXs]="24">
      <label nz-checkbox formControlName="forceUpdate" *ngIf="role?.type === 'SUPER_ADMIN' && this.service?.id">Forcer les dates et le statut</label>
      <div nz-row [nzGutter]="16">
        <div nz-col [nzXs]="24" [nzMd]="12" [nzXl]="6">
          <nz-form-item>
            <nz-form-label nzFor="date">{{ this.service ? 'Date' : 'Date demandée' }}</nz-form-label>
            <nz-form-control [nzErrorTip]="dateError" [nzWarningTip]="warningTip" [nzValidateStatus]="dateValidation ? dateValidation : 'validating'">
              <nz-date-picker
                formControlName="date"
                nzFormat="dd/MM/yyyy"
                nzSize="large"
                class="w-100-p"
                [nzDisabledDate]="disabledDates"
                [nzShowToday]="false">
              </nz-date-picker>
            </nz-form-control>
            <ng-template #dateError let-control>
              <ng-container *ngIf="control.hasError('required')">La date est requise</ng-container>
            </ng-template>
            <ng-template #warningTip>
              <ng-container *ngIf="isDateTooSoon">
                La date demandée est dans moins de 2 jours. Êtes-vous certain de ne pas avoir fait d'erreur ?
              </ng-container>
            </ng-template>
          </nz-form-item>
        </div>
        <div nz-col [nzXs]="24" [nzMd]="12" [nzXl]="6">
          <nz-form-item>
            <nz-form-label nzFor="time">{{ this.service ? 'Heure' : this.isTimeOptional ? 'Heure demandée (optionel)' : 'Heure demandée' }}</nz-form-label>
            <nz-form-control [nzErrorTip]="timeError">
              <nz-time-picker
                formControlName="time"
                nzFormat="HH'h'mm"
                nzSize="large"
                class="w-100-p"
                [nzMinuteStep]="15"
                [nzDisabledHours]="disabledHours"
                [nzHideDisabledOptions]="true"
                [nzDefaultOpenValue]="defaultTime">
              </nz-time-picker>
            </nz-form-control>
            <ng-template #timeError let-control>
              <ng-container *ngIf="control.hasError('required')">L'heure est requise</ng-container>
            </ng-template>
          </nz-form-item>
        </div>
        <div nz-col [nzXs]="24" [nzXl]="12" *ngIf="!this.service">
          <div nz-row [nzGutter]="8">
            <div nz-col [nzXs]="frequencyStyle === 'Récurrent' ? 8 : 24">
              <nz-form-item>
                <nz-form-label nzFor="frequency">Fréquence</nz-form-label>
                <nz-radio-group nzButtonStyle="solid" formControlName="frequencyStyle" nzSize="large">
                  <label nz-radio-button [nzValue]="style" *ngFor="let style of ['Unique', 'Récurrent']">{{ style }}</label>
                </nz-radio-group>
              </nz-form-item>
            </div>
            <div nz-col [nzXs]="16" *ngIf="frequencyStyle === 'Récurrent'">
              <div nz-row [nzGutter]="8">
                <div nz-col [nzXs]="16">
                  <nz-form-item>
                    <nz-form-label nzFor="frequency">Fréquence</nz-form-label>
                    <nz-form-control [nzSuccessTip]="frequencySuccessTip" [nzErrorTip]="frequencyErrorTip" [nzValidateStatus]="frequencyValidation ? frequencyValidation : 'validating'">
                      <nz-input-group nzSize="large" nzCompact nzPrefix="Tous les" nzSuffix="jours">
                        <input type="number" nz-input formControlName="frequency" placeholder="0" min="0" max="365"
                          [style.textAlign]="'right'" />
                      </nz-input-group>
                    </nz-form-control>
                    <ng-template #frequencyErrorTip let-control>
                      <ng-container *ngIf="control?.hasError('min')">La fréquence minimum est de zéro jour (1 seule préstation à crée)
                      </ng-container>
                      <ng-container *ngIf="control?.hasError('max')">La fréquence maximum est de 365 jours</ng-container>
                      <ng-container *ngIf="showFrequencyWarning">Impossible de créer des prestations au-delà de 12 mois</ng-container>
                    </ng-template>
                    <ng-template #frequencySuccessTip>
                      <ng-container *ngIf="frequencyDetails">{{ frequencyDetails }}</ng-container>
                    </ng-template>
                  </nz-form-item>
                </div>
                <div nz-col [nzXs]="8">
                  <nz-form-item>
                    <nz-form-control [nzErrorTip]="iterationErrorTip">
                      <nz-form-label nzFor="iteration">&nbsp;</nz-form-label>
                      <nz-input-group nzSize="large" nzCompact nzSuffix="fois">
                        <input type="number" nz-input formControlName="iteration" placeholder="0" min="2" max="50"
                          [style.textAlign]="'right'" [disabled]="this.form.get('frequency')?.value < 1" />
                      </nz-input-group>
                    </nz-form-control>
                    <ng-template #iterationErrorTip let-control>
                      <ng-container *ngIf="control.hasError('min')">min. 2</ng-container>
                      <ng-container *ngIf="control.hasError('max')">max. 50</ng-container>
                    </ng-template>
                  </nz-form-item>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div nz-col [nzXs]="24" [nzXl]="12" *ngIf="this.service">
          <laveo-select-service-status [control]="form.get('status')" typeSize="large"></laveo-select-service-status>
        </div>
      </div>
    </div>
    <div nz-col [nzXs]="24">
      <div nz-row [nzGutter]="16">
        <div nz-col [nzXs]="12">
          <laveo-select-actor
            [control]="form.get('customerSite')"
            [typeEntity]="customerSiteTypeEntity"
            [disabled]="role?.type === 'CUSTOMER_SITE' || (form.get('vehicle')?.valid ?? false)">
          </laveo-select-actor>
        </div>
        <div nz-col [nzXs]="12">
          <laveo-select-vehicle
            [control]="form.get('vehicle')"
            [customerSite]="customerSite"
            typeSize="large"
            [typeSelection]="service?.id ? 'single' : 'multiple'"
            [warning]="changedWarning"
            [hasWarning]="vehicleChanged">
          </laveo-select-vehicle>
          <ng-template #changedWarning>
            <ng-container *ngIf="vehicleChanged">
              &#9888; Attention, il est interdit de remplacer une immatriculation par une autre !<br>
              Ne doit etre utiliser que pour la correction d'erreur de saisie.
            </ng-container>
          </ng-template>
        </div>
      </div>
    </div>
    <div nz-col [nzXs]="24">
      <div nz-row [nzGutter]="16">
        <div nz-col [nzXs]="12">
          <laveo-select-service-type [control]="form.get('type')" [values]="serviceTypeValues" typeSize="large"></laveo-select-service-type>
        </div>
        <div nz-col [nzXs]="12">
          <laveo-select-service-finishing [control]="form.get('finishing')" [values]="serviceFinishingValues" typeSize="large"></laveo-select-service-finishing>
        </div>
      </div>
    </div>

    <div nz-col [nzXs]="24">
      <div nz-row [nzGutter]="16">
        <div nz-col [nzXs]="12">
          <laveo-select-service-upholstery-stain-removal [control]="form.get('upholsteryStainRemoval')" [values]="serviceUpholsteryStainRemovalValues" typeSize="large" typeSelection="multiple"></laveo-select-service-upholstery-stain-removal>
        </div>
        <div nz-col [nzXs]="12">
          <laveo-select-service-carpet-stain-removal [control]="form.get('carpetStainRemoval')" [values]="serviceCarpetStainRemovalValues" typeSize="large" typeSelection="multiple"></laveo-select-service-carpet-stain-removal>
        </div>
      </div>
    </div>
    <div nz-col [nzXs]="24">
      <div nz-row [nzGutter]="16">
        <div nz-col>
          <nz-form-item>
            <nz-form-label nzFor="vehicleStateEnabled">État des lieux</nz-form-label>
            <nz-form-control>
              <nz-radio-group formControlName="vehicleStateEnabled" nzButtonStyle="solid" nzSize="large">
                <label nz-radio-button [nzValue]="true">Oui</label>
                <label nz-radio-button [nzValue]="false">Non</label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col>
          <nz-form-item>
            <nz-form-label nzFor="firstIntervention">1ère intervention</nz-form-label>
            <nz-form-control>
              <nz-radio-group formControlName="firstIntervention" nzButtonStyle="solid" nzSize="large">
                <label nz-radio-button [nzValue]="true">Oui</label>
                <label nz-radio-button [nzValue]="false">Non</label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col>
          <nz-form-item>
            <nz-form-label nzFor="vehicleVeryDirty">Véhicule très sale</nz-form-label>
            <nz-form-control>
              <nz-radio-group formControlName="vehicleVeryDirty" nzButtonStyle="solid" nzSize="large">
                <label nz-radio-button [nzValue]="true">Oui</label>
                <label nz-radio-button [nzValue]="false">Non</label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col>
          <nz-form-item>
            <nz-form-label nzFor="conveying">Convoyage</nz-form-label>
            <nz-form-control>
              <nz-radio-group formControlName="conveying" nzButtonStyle="solid" nzSize="large">
                <label nz-radio-button [nzValue]="true">Oui</label>
                <label nz-radio-button [nzValue]="false">Non</label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col>
          <nz-form-item>
            <nz-form-label nzFor="sticking">Stickage</nz-form-label>
            <nz-form-control>
              <nz-radio-group formControlName="sticking" nzButtonStyle="solid" nzSize="large">
                <label nz-radio-button [nzValue]="true">Oui</label>
                <label nz-radio-button [nzValue]="false">Non</label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col>
          <nz-form-item>
            <nz-form-label nzFor="desticking">Déstickage</nz-form-label>
            <nz-form-control>
              <nz-radio-group formControlName="desticking" nzButtonStyle="solid" nzSize="large">
                <label nz-radio-button [nzValue]="true">Oui</label>
                <label nz-radio-button [nzValue]="false">Non</label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col>
          <nz-form-item>
            <nz-form-label nzFor="interiorDisinfection">Désinfection habitacle</nz-form-label>
            <nz-form-control>
              <nz-radio-group formControlName="interiorDisinfection" nzButtonStyle="solid" nzSize="large">
                <label nz-radio-button [nzValue]="true">Oui</label>
                <label nz-radio-button [nzValue]="false">Non</label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col>
          <nz-form-item>
            <nz-form-label nzFor="contactZoneDisinfection">Désinfection zone de contact</nz-form-label>
            <nz-form-control>
              <nz-radio-group formControlName="contactZoneDisinfection" nzButtonStyle="solid" nzSize="large">
                <label nz-radio-button [nzValue]="true">Oui</label>
                <label nz-radio-button [nzValue]="false">Non</label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
    <div nz-col [nzXs]="24">
      <laveo-select-actor [control]="form.get('preparer')" [typeEntity]="preparerTypeEntity" typeSize="large"></laveo-select-actor>
    </div>
    <div nz-col [nzXs]="24">
      <nz-form-item>
        <nz-form-label nzFor="comment">Commentaire</nz-form-label>
        <nz-form-control>
          <nz-textarea-count [nzMaxCharacterCount]="500">
            <textarea formControlName="comment" nz-input placeholder="Commentaire" nzSize="large" [nzAutosize]="{ minRows: 3, maxRows: 10 }"></textarea>
          </nz-textarea-count>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzXs]="24">
      <laveo-inputs-contacts [controlArray]="contactFormArray"></laveo-inputs-contacts>
    </div>
  </div>
</form>
