import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nullCoal'
})
export class NullCoalPipe implements PipeTransform {
  transform(value?: string | number, replaceValue: any = '-'): string {
    if (!value) {
      return replaceValue;
    }
    return value.toString().trim().length > 0 ? value : replaceValue;
  }
}
