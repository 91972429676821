import { Pipe, PipeTransform } from '@angular/core';
import { ServiceFinishing } from '../models/entities/service';

@Pipe({
  name: 'serviceFinishingName'
})
export class ServiceFinishingNamePipe implements PipeTransform {
  transform(type?: ServiceFinishing | ServiceFinishing[] | string | string[]): string | undefined {
    if (!type) {
      return;
    }

    if (Array.isArray(type)) {
      if (type.length <= 0) {
        return;
      }
      return type.map(t => this.getTypeName(t)).join(', ');
    } else {
      return this.getTypeName(type);
    }
  }

  private getTypeName(type: ServiceFinishing | string): string {
    switch (type) {
      case ServiceFinishing.classic:
      case 'Classic': {
        return 'Classique';
      }
      case ServiceFinishing.employee_discount:
      case 'EmployeeDiscount': {
        return 'Remise collaborateur';
      }
      case ServiceFinishing.restitution:
      case 'Restitution': {
        return 'Restitution';
      }
      case ServiceFinishing.fleet_management:
      case 'FleetManagement': {
        return 'Gestion de flotte';
      }
    }
    return type.toString();
  }
}
