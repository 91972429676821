import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { LicensePlateComponent } from './license-plate.component';

@NgModule({
  declarations: [
    LicensePlateComponent,
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    LicensePlateComponent
  ]
})
export class LicensePlateModule {}
