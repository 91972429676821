<div nz-row [nzGutter]="[48, 24]" >
  <div nz-col nzSpan="24">
    <h5 class="service-title"><i nz-icon nzType="user" nzTheme="outline"></i>&nbsp;Contacts</h5>
  </div>
</div>

<nz-table [nzShowPagination]="false" [nzTemplateMode]="true">
  <thead>
    <tr>
      <th>Nom</th>
      <th *ngIf="showNotificationsCheckbox" nz-tooltip nzTooltipTitle="Notifications">
        <i nz-icon nzType="mail" nzTheme="outline"></i>
      </th>
      <th>Mail</th>
      <th>Téléphone</th>
      <th>Commentaire</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let contact of contacts">
      <td>
        {{ contact.name | nullCoal }}
      </td>
      <td  *ngIf="showNotificationsCheckbox">
        <label nz-checkbox [nzDisabled]="true" [(ngModel)]="contact.sendMail"></label>
      </td>
      <td>
        <a *ngIf="contact.mail; else noContent" href="mailto:{{ contact.mail }}">{{ contact.mail | nullCoal }}</a>
      </td>
      <td>
        <a *ngIf="contact.phoneNumber; else noContent" href="tel:{{ contact.phoneNumber }}">{{ contact.phoneNumber | phone | nullCoal }}</a>
      </td>
      <td>
        {{ contact.comment | nullCoal }}
      </td>
    </tr>
  </tbody>
</nz-table>
<ng-template #noContent>-</ng-template>
<nz-empty
  *ngIf="(contacts?.length ? contacts.length : 0) <= 0"
  nzNotFoundImage="simple"
  nzNotFoundContent="Aucun contact">
</nz-empty>
