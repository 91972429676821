import { InMemoryCache, ApolloLink } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '../../../environments/environment';
import { ExtractFiles } from '../utils/extract-files';

export const apolloProviders = (httpLink: HttpLink, mockForwarder: ApolloLink): any => {
  const cache = new InMemoryCache({
    possibleTypes: {
      Actor: ['Structure', 'CustomerSite', 'Customer', 'Preparer', 'Admin']
    }
  });
  const all: ApolloLink[] = [
    mockForwarder,
    httpLink.create({
      uri: environment.api.graphQL,
      extractFiles: (body) => ExtractFiles.extractFiles(body),
    })
  ];
  const link = ApolloLink.from(all);

  return {
    cache,
    link
  };
};
