<nz-card [nzBordered]="false" [nzTitle]="cardTitle">
  <ng-template #cardTitle>
    <nz-skeleton [nzActive]="true" [nzLoading]="this.isLoading" [nzParagraph]="{ rows: 1 }">
      <laveo-card-header title="Bonjour {{ currentUser?.name }}" [description]="description"
        [role]="currentRole ? currentRole.actor.name + ' - ' + (currentRole.type | roleName) : undefined"
        [buttonTitle]="currentRole?.rights?.services?.includes(userRightCreate) ? 'Ajouter une prestation' : undefined"
        [buttonIcon]="currentRole?.rights?.services?.includes(userRightCreate) ? 'plus' : undefined"
        (buttonClick)="this.addService()">
      </laveo-card-header>
    </nz-skeleton>
  </ng-template>

  <div nz-row [nzGutter]="[16, 16]">
    <a nz-col nzSpan="8" [routerLink]="['/', 'prestations']" [queryParams]="{status: 'ASKED,PROPOSED', minDate: +minDate, maxDate: +maxDate}">
      <ng-container
        [ngTemplateOutlet]="infoCard"
        [class.loaded]="currentStats"
        [ngTemplateOutletContext]="{icon: 'smile', title: 'Prestations commencées', info: currentStats ? 'inProgress' : null }">
      </ng-container>
    </a>
    <a nz-col nzSpan="8" [routerLink]="['/', 'prestations']" [queryParams]="{status: 'PLANNED', minDate: +minDate, maxDate: +maxDate}">
      <ng-container
        [ngTemplateOutlet]="infoCard"
        [ngTemplateOutletContext]="{icon: 'clock-circle', title: 'Prestations planifiées', info:  currentStats ? 'planned' : null }">
      </ng-container>
    </a>
    <a nz-col nzSpan="8" [routerLink]="['/', 'prestations']" [queryParams]="{status: 'PERFORMED', minDate: +minDate, maxDate: +maxDate}">
      <ng-container
        [ngTemplateOutlet]="infoCard"
        [class.loaded]="currentStats"
        [ngTemplateOutletContext]="{icon: 'check-circle', title: 'Prestations réalisées', info: currentStats ? 'done' : null }">
      </ng-container>
    </a>
  </div>
  <div nz-row [nzGutter]="16" [style.marginTop]="'24px'">
    <div nz-col nzFlex="0 0 auto" *ngIf="checked.length > 0">
      <button nz-button nzType="default" nzSize="large" nz-dropdown [nzDropdownMenu]="actionMenu" [disabled]="actionLoading">
        <i *ngIf="actionLoading" nz-icon nzType="loading" class="loading-icon"></i>
        {{ checked.length }} {{ checked.length > 1 ? 'prestations séléctionnées' : 'prestation séléctionnée' }}
        <i nz-icon nzType="down"></i>
      </button>
      <nz-dropdown-menu #actionMenu="nzDropdownMenu">
        <ul nz-menu nzSelectable>
          <li nz-menu-item (click)="copyChecked()">
            <i nz-icon nzType="copy"></i>
            Renouveler
          </li>
          <li nz-menu-item nzDanger (click)="deleteChecked(cancelServiceTemplate, cancelServiceFooterTemplate)" *ngIf="checkedContainsDeletable">
            <i nz-icon nzType="delete"></i>
            Annuler
          </li>
          <ng-container *ngIf="checkedPossibleActions.length > 0">
            <li nz-menu-divider></li>
            <li nz-menu-item *ngFor="let action of checkedPossibleActions" (click)="doActionForChecked(action)">
              <ng-container *ngIf="(action | serviceActionsIcon) as icon">
                <i nz-icon [nzType]="icon"></i>
              </ng-container>
              {{ action | serviceActionsName}}
            </li>
          </ng-container>
          <li nz-menu-divider></li>
          <li nz-menu-item (click)="checked.splice(0, checked.length)">
            <i nz-icon nzType="minus-square"></i>
            Tout déséléctionner
          </li>
        </ul>
      </nz-dropdown-menu>
    </div>
    <div nz-col nzFlex="1 1 auto">
      <button nz-button nzSize="large" [routerLink]="['/', 'prestations']" class="w-100-p search-button">
        <i nz-icon nzType="search"></i>
        Rechercher une prestation
      </button>
    </div>
    <div nz-col [style.minWidth]="'500px'">
      <form nz-form [formGroup]="form">
        <nz-form-item>
          <nz-form-control>
            <nz-range-picker id="dates" name="dates" formControlName="dates" nzSize="large" class="w-100-p"
              [nzFormat]="'dd/MM/yyyy'" [nzAllowClear]="false" [nzRanges]="dateRanges">
            </nz-range-picker>
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>
    <div nz-col nzFlex="0 0 auto">
      <button nz-button nzType="default" nzSize="large" nz-dropdown [nzDropdownMenu]="exportMenu">
        <i nz-icon nzType="download"></i>
        Exporter
        <i nz-icon nzType="down"></i>
      </button>
      <nz-dropdown-menu #exportMenu="nzDropdownMenu">
        <ul nz-menu nzSelectable>
          <li nz-menu-item (click)="exportExcel()">
            <i nz-icon nzType="file-excel"></i>
            &nbsp;Exporter en .xlsx
          </li>
        </ul>
      </nz-dropdown-menu>
    </div>
  </div>
  <div nz-row [nzGutter]="16">
    <div nz-col nzFlex="auto">
      <laveo-list-services *ngIf="filters" #listServices [filters]="filters" (stats)="currentStats = $event" [checked]="checked" (pageChanged)="setPage($event)"></laveo-list-services>
    </div>
  </div>

  <ng-template #infoCard let-icon="icon" let-title="title" let-info="info">
    <nz-card class="card-info" [class.loaded]="currentStats">
      <div nz-row nzAlign="middle" [nzGutter]="[16, 16]">
        <div nz-col nzFlex="0 0 auto">
          <i nz-icon [nzType]="icon" nzTheme="outline" class="xlarge"></i>
        </div>
        <div nz-col nzFlex="1 1 auto">
          <p nz-typography nzType="secondary" class="info-title">{{ title }}</p>
          <p nz-typography class="info"><strong>{{ info && currentStats ? currentStats[info] : '…' }}</strong></p>
        </div>
      </div>
    </nz-card>
  </ng-template>
</nz-card>

<ng-template #copyForm let-params>
  <ng-container *ngIf="this.checked.length === 1; else renewAll">
    <p>Voulez-vous vraiment renouveler <b>la prestation sélectionnée</b> ?</p>
  </ng-container>
  <ng-template #renewAll>
    <p>Voulez-vous vraiment renouveler les <b>{{ this.checked.length }} préstations sélectionnées</b> ?</p>
  </ng-template>
  <p>Choississez une date pour le renouvellement</p>
  <form nz-form [formGroup]="params.copyForm">
    <nz-form-item>
      <nz-form-control [nzErrorTip]="dateErrorTip" [nzWarningTip]="dateWarningTip" [nzValidateStatus]="params.dateValidation(params.copyForm.get('date'))">
        <nz-date-picker
          id="date"
          name="date"
          formControlName="date"
          nzSize="large"
          class="w-100-p"
          [nzFormat]="'dd/MM/yyyy'"
          [nzDisabledDate]="params.disabledDates"
          [nzShowToday]="false">
        </nz-date-picker>
        <ng-template #dateErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">La date de renouvellement est requise</ng-container>
        </ng-template>
        <ng-template #dateWarningTip>
          <ng-container *ngIf="params.isDateTooSoon(params.copyForm.get('date').value)">
            La date demandée est dans moins de 2 jours. Êtes-vous certain de ne pas avoir fait d'erreur ?
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control [nzErrorTip]="timeErrorTip">
        <nz-time-picker
          id="time"
          name="time"
          formControlName="time"
          nzFormat="HH'h'mm"
          nzSize="large"
          class="w-100-p"
          [nzMinuteStep]="15"
          [nzDisabledHours]="params.disabledHours"
          [nzHideDisabledOptions]="true"
          [nzDefaultOpenValue]="defaultTime">
        </nz-time-picker>
      </nz-form-control>
      <ng-template #timeErrorTip let-control>
        <ng-container *ngIf="control.hasError('required')">L'heure est requise</ng-container>
      </ng-template>
    </nz-form-item>
  </form>
</ng-template>

<ng-template #workflowForm let-params>
  <div [innerHtml]="params.content" class="mb-8"></div>
  <div nz-row>
    <laveo-service-workflow [services]="params.services" [isInModal]="true" [actionEmitter]="params.emitter" (actionPerformed)="actionPerformed($event)"></laveo-service-workflow>
  </div>
</ng-template>

<ng-template #exportForm let-params>
  <form nz-form [formGroup]="params.exportForm">
    <h5 [style.marginTop]="0" [style.marginBottom]="'16px'">Informations</h5>
    <nz-form-item>
      <nz-form-label [nzSm]="10" nzFor="search">Recherche</nz-form-label>
      <nz-form-control [nzSm]="14">
        <input nz-input id="search" name="search" placeholder="Aucune recherche" [disabled]="true">
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="10" nzFor="statut">Statut</nz-form-label>
      <nz-form-control [nzSm]="14">
        <input nz-input id="statut" name="statut" placeholder="Tous les statuts" [disabled]="true">
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="10" nzFor="type">Types de lavage</nz-form-label>
      <nz-form-control [nzSm]="14">
        <input nz-input id="type" name="type" placeholder="Tous les types de lavages" [disabled]="true">
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="10" nzFor="clients">Clients</nz-form-label>
      <nz-form-control [nzSm]="14">
        <input nz-input id="clients" name="clients" placeholder="Tous les clients et sites clients" [disabled]="true">
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="10" nzFor="structures">Structures et préparateurs</nz-form-label>
      <nz-form-control [nzSm]="14">
        <input nz-input id="structures" name="structures" placeholder="Toutes les structures et préparateurs" [disabled]="true">
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="10" nzFor="dates">Dates</nz-form-label>
      <nz-form-control [nzSm]="14">
        <nz-range-picker id="dates" name="dates" formControlName="dates" [nzFormat]="'dd/MM/yyyy'" [nzAllowClear]="false" [nzRanges]="dateRanges"></nz-range-picker>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="10">Facturées</nz-form-label>
      <nz-form-control [nzSm]="14">
        <nz-radio-group formControlName="invoiced">
          <label nz-radio-button [nzValue]="true">Oui</label>
          <label nz-radio-button [nzValue]="false">Non</label>
          <label nz-radio-button [nzValue]="null">Tous</label>
        </nz-radio-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="10" >Trié par</nz-form-label>
      <nz-form-control [nzSm]="14">
        <nz-radio-group formControlName="sort">
          <label nz-radio-button nzValue="ASC">Ascendant</label>
          <label nz-radio-button nzValue="DESC">Descendant</label>
        </nz-radio-group>
      </nz-form-control>
    </nz-form-item>
    <h5 [style.marginTop]="0" [style.marginBottom]="'16px'">Options</h5>
    <p [style.marginBottom]="0">Données à exporter</p>
    <nz-radio-group formControlName="exportType">
      <label nz-radio-button nzValue="services">Préstations</label>
      <label nz-radio-button nzValue="reports">États des lieux</label>
    </nz-radio-group>
    <p [style.marginBottom]="0">Nombre de résultats :</p>
    <nz-radio-group formControlName="page">
      <label nz-radio-button nzValue="current" class="radio-line">Page actuelle</label>
      <label nz-radio-button nzValue="all" class="radio-line">Tous les résultats</label>
    </nz-radio-group>
    <nz-form-item>
      <nz-form-label [nzSm]="24" nzFor="filename" [style.textAlign]="'left'">Nom du fichier</nz-form-label>
      <nz-form-control>
        <nz-input-group nzAddOnAfter=".xlsx">
          <input nz-input id="filename" name="filename" placeholder="export" formControlName="filename">
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
  </form>
</ng-template>

<ng-template #cancelServiceTemplate>
  <p *ngIf="this.deletable.length === 1">Êtes-vous sûr de vouloir annuler la demande de prestation ?</p>
  <p *ngIf="this.deletable.length > 1">Êtes-vous sûr de vouloir annuler les <b>{{deletable.length}} prestations sélectionnées</b> ?</p>
  <ng-container *ngIf="this.checked.length !== this.deletable.length">
    <p *ngIf="this.checked.length - this.deletable.length === 1">({{ this.checked.length - this.deletable.length }} autre prestation ne peut plus plus être annulée à cause de son statut)</p>
    <p *ngIf="this.checked.length - this.deletable.length > 1">({{ this.checked.length - this.deletable.length }} autres prestations ne peuvent plus plus être annulées à cause de leurs statut)</p>
  </ng-container>
  <form>
    <nz-form-item>
      <nz-form-label nzFor="comment" nzRequired>Commentaire</nz-form-label>
      <nz-form-control nzErrorTip="Le commentaire est requis">
        <textarea [formControl]="cancelComment" nz-input id="comment" name="comment" [nzAutosize]="true" rows="3" placeholder="Commentaire"></textarea>
      </nz-form-control>
    </nz-form-item>
  </form>
</ng-template>

<ng-template #cancelServiceFooterTemplate let-ref="modalRef">
  <button nz-button (click)="ref.destroy()">Fermer</button>
  <button nz-button nzType="primary" nzDanger (click)="closeCancelModal(ref)" [nzLoading]="actionLoading">Annuler</button>
</ng-template>
