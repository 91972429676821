<nz-card [nzBordered]="false" [nzTitle]="cardTitle">
  <ng-template #cardTitle>
    <div class="header">
      <laveo-card-header
        title="Rapport : Monitoring Flotte"
        [description]="description"
        [backButtonEnabled]="true"
        (backButtonClick)="back()"
        [isLoading]="isLoading"
        buttonTitle="Export excel"
        buttonIcon="download"
        (buttonClick)="export()"
      ></laveo-card-header>
    </div>
  </ng-template>
  <div nz-row [nzGutter]="[16, 16]">
    <form nz-form [formGroup]="form" nzLayout="vertical">
      <div nz-row nzGutter="8" class="mt-8">
        <div nz-col [nzSm]="24" [nzMd]="12" [nzXl]="8" *ngIf="userCanChooseSelectedRole">
          <nz-form-item>
            <nz-form-label nzFor="group">Grouper par</nz-form-label>
            <nz-form-control>
              <nz-select
                id="role"
                nzSize="large"
                nzPlaceHolder="Grouper par"
                [nzShowArrow]="true"
                [nzShowSearch]="true"
                formControlName="role"
                [style.minWidth]="'200px'">
                <nz-option
                  [nzLabel]="UserRoleType.customerSite | roleName"
                  [nzValue]="UserRoleType.customerSite">
                </nz-option>
                <nz-option
                  [nzLabel]="UserRoleType.structure | roleName"
                  [nzValue]="UserRoleType.structure">
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSm]="24" [nzMd]="12" [nzXl]="8">
          <nz-form-item>
            <nz-form-label nzFor="dates">Dates</nz-form-label>
            <nz-form-control>
              <nz-range-picker id="dates" class="w-100-p" nzSize="large" nzFormat="dd/MM/yyyy" formControlName="dates" [nzRanges]="dateRanges" [nzAllowClear]="false"></nz-range-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </form>
    <nz-table
      nzBordered
      nzShowSizeChanger
      [nzScroll]="{ x: '1600px' }"
      [nzData]="data ? data.data : []"
      [nzFrontPagination]="false"
      [nzLoading]="isLoading"
      [nzTotal]="data ? data.metadata.totalResults : 0"
      [nzPageSize]="limit"
      [nzPageIndex]="currentPage ? currentPage : 1"
      [nzNoResult]="emptyTemplate"
      (nzQueryParams)="setPage($event)"
      *ngIf="!error; else errorTemplate">
      <thead>
        <tr>
          <th nzLeft nzColumnKey="customer" [rowSpan]="2" [nzWidth]="'200px'" *ngIf="selectedRole === UserRoleType.customerSite">Client</th>
          <th nzLeft [nzColumnKey]="selectedRole" [rowSpan]="2" [nzWidth]="'220px'">{{ selectedRole | roleName }}</th>
          <th nzColumnKey="attribution" rowspan="2">Flotte</th>
          <th colspan="2">Intérieur</th>
          <th colspan="2">Extérieur</th>
          <th colspan="2">Complet</th>
          <th nzColumnKey="realFrequencyVehicleState" rowspan="2">État des lieux : Fréquence réelle</th>
          <th nzColumnKey="realFrequencyTotal" rowspan="2">Tout type de prestation : Fréquence réelle</th>
        </tr>
        <tr>
          <th nzColumnKey="realFrequencyInterior">Fréquence réelle</th>
          <th nzColumnKey="contractualFrequencyInterior">Fréquence contractuelle</th>
          <th nzColumnKey="realFrequencyExterior">Fréquence réelle</th>
          <th nzColumnKey="contractualFrequencyExterior">Fréquence contractuelle</th>
          <th nzColumnKey="realFrequencyFull">Fréquence réelle</th>
          <th nzColumnKey="contractualFrequencyFull">Fréquence contractuelle</th>
        </tr>
      </thead>
      <tbody>
          <tr *ngFor="let row of data?.data">
            <td nzLeft *ngIf="selectedRole === UserRoleType.customerSite">
              <span>{{ row?.customer?.name}}</span>
            </td>
            <td nzLeft>
              <span *ngIf="selectedRole === UserRoleType.customerSite">{{ row?.customerSite?.name}}</span>
              <span *ngIf="selectedRole === UserRoleType.structure">{{ row?.structure?.name}}</span>
            </td>
            <td>{{ row.fleet }}</td>
            <td>{{ row.interiorFrequencyReal === 0 ? '-' : row.interiorFrequencyReal  }}</td>
            <td>{{ row.interiorFrequencyContractual === 0 ? '-' : row.interiorFrequencyContractual  }}</td>
            <td>{{ row.exteriorFrequencyReal === 0 ? '-' : row.exteriorFrequencyReal  }}</td>
            <td>{{ row.exteriorFrequencyContractual === 0 ? '-' : row.exteriorFrequencyContractual  }}</td>
            <td>{{ row.fullFrequencyReal === 0 ? '-' : row.fullFrequencyReal  }}</td>
            <td>{{ row.fullFrequencyContractual === 0 ? '-' : row.fullFrequencyContractual  }}</td>
            <td>{{ row.vehicleStateEnabledFrequencyReal === 0 ? '-' : row.vehicleStateEnabledFrequencyReal  }}</td>
            <td>{{ row.allFrequencyReal === 0 ? '-' : row.allFrequencyReal  }}</td>
          </tr>
      </tbody>
    </nz-table>
    <ng-template #errorTemplate>
      <nz-result
        nzTitle="Erreur de chargement"
        nzStatus="error"
        nzSubTitle="Une erreur est survenue lors du chargement du reporting">
        <div nz-result-extra>
          <button nz-button nzType="primary" (click)="loadData()">
            Réessayer
            <i nz-icon nzType="reload"></i>
          </button>
        </div>
      </nz-result>
    </ng-template>
    <ng-template #emptyTemplate>
      <nz-empty
        nzNotFoundImage="simple"
        nzNotFoundContent="Il n'y a encore aucune données pour le moment.">
      </nz-empty>
    </ng-template>
  </div>
</nz-card>

<ng-template #exportForm let-params>
  <form nz-form [formGroup]="params.exportForm">
    <h5 [style.marginTop]="0" [style.marginBottom]="'16px'">Informations</h5>
    <nz-form-item>
      <nz-form-label [nzSm]="10" nzFor="role">Grouper par</nz-form-label>
      <nz-form-control [nzSm]="14">
        <nz-select formControlName="role">
          <nz-option [nzLabel]="UserRoleType.customerSite | roleName" [nzValue]="UserRoleType.customerSite"></nz-option>
          <nz-option [nzLabel]="UserRoleType.structure | roleName" [nzValue]="UserRoleType.structure"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="10" nzFor="dates">Dates</nz-form-label>
      <nz-form-control [nzSm]="14">
        <nz-range-picker id="dates" name="dates" formControlName="dates" [nzFormat]="'dd/MM/yyyy'" [nzAllowClear]="false" [nzRanges]="dateRanges"></nz-range-picker>
      </nz-form-control>
    </nz-form-item>
    <h5 [style.marginTop]="0" [style.marginBottom]="'16px'">Options</h5>
    <p [style.marginBottom]="0">Nombre de résultats :</p>
    <nz-radio-group formControlName="page">
      <label nz-radio-button nzValue="current" class="radio-line">Page actuelle</label>
      <label nz-radio-button nzValue="all" class="radio-line">Tous les résultats</label>
    </nz-radio-group>
    <nz-form-item>
      <nz-form-label [nzSm]="24" nzFor="filename" [style.textAlign]="'left'">Nom du fichier</nz-form-label>
      <nz-form-control>
        <nz-input-group nzAddOnAfter=".xlsx">
          <input nz-input id="filename" name="filename" placeholder="export" formControlName="filename">
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
  </form>
</ng-template>
