<laveo-entity-wrapper
  [title]="title"
  [description]="description"
  [entityService]="structuresService"
  [getFunction]="structuresService.structure"
  [addFunction]="structuresService.addStructure"
  [updateFunction]="structuresService.updateStructure"
  [deleteHardFunction]="structuresService.deleteOneStructureHard"
  [wrappedComponent]="this"
  [getFormFunction]="form"
  [editEnabled]="editEnabled"
  [deleteEnabled]="deleteEnabled">
  <laveo-structure-detail *ngIf="isReady" detail [structure]="structure"></laveo-structure-detail>
  <laveo-structure-form *ngIf="isReady" form [structure]="structure"></laveo-structure-form>
</laveo-entity-wrapper>
