import { Component, Input } from '@angular/core';
import { User } from 'src/app/shared/models/entities/user';
import { UserService } from '../../../../../../shared/services/api/user.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ApiErrorMessageUtil } from '../../../../../../shared/utils/api-error-message.util';

@Component({
  selector: 'laveo-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent {
  @Input() user?: User;

  actionLoading = false;

  constructor(private readonly userService: UserService, private readonly message: NzMessageService) {}

  sendMailActivation() {
    if (!this.user) {
      return;
    }

    this.actionLoading = true;
    this.userService.sendMailActivation(this.user.id).subscribe({
      next: () => {
        this.actionLoading = false;
        if (!this.user) {
          return;
        }
        this.message.success(`Un mail de d'activation de compte a été envoyé à ${this.user.mail}`);
      },
      error: error => {
        console.error(error);
        this.actionLoading = false;
        this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
      }
    });
  }
}
