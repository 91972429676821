<nz-card [nzBordered]="false" [nzTitle]="cardTitle">
  <ng-template #cardTitle>
    <laveo-card-header
      title="Prestations : {{ currentDate |date:'MMMM yyy' | titlecase }}"
      buttonIcon="unordered-list"
      buttonTitle="Liste"
      (buttonClick)="goToServicesList()"
      [buttonEnabled]="true"
    ></laveo-card-header>
  </ng-template>

  <ng-container *ngIf="!this.error; else errorTemplate">
    <form nz-form [formGroup]="filters" nzLayout="vertical">
      <div nz-row nzGutter="8">
        <div nz-col [nzSm]="24" [nzMd]="12" [nzXl]="6">
          <nz-form-item>
            <nz-form-label nzFor="status">Statut</nz-form-label>
            <nz-form-control>
              <nz-select id="status" nzSize="large" nzMode="multiple" nzPlaceHolder="Tous les statuts" [nzShowArrow]="true" [nzShowSearch]="true" [nzAllowClear]="true" formControlName="status">
                <nz-option *ngFor="let status of filterStatus" [nzLabel]="status | serviceStatusName | nullCoal" [nzValue]="status"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSm]="24" [nzMd]="12" [nzXl]="6" *ngIf="filterTypes.length > 1">
          <nz-form-item>
            <nz-form-label nzFor="types">Type de lavage</nz-form-label>
            <nz-form-control>
              <nz-select id="types" nzSize="large" nzMode="multiple" nzPlaceHolder="Tous les types de lavages" [nzShowArrow]="true" [nzShowSearch]="true" [nzAllowClear]="true" formControlName="types">
                <nz-option *ngFor="let type of filterTypes" [nzLabel]="type | serviceTypeName | nullCoal" [nzValue]="type"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSm]="24" [nzMd]="12" [nzXl]="6" *ngIf="userCanSeeCustomerFilter">
          <laveo-select-actor
            [control]="filters.get('customers')"
            [typeEntity]="filterClientTypeEntity"
            [typeSelection]="'multiple'"
            [typeSize]="'large'"
            [placeholder]="filterCientPlaceholder">
          </laveo-select-actor>
        </div>
        <div nz-col [nzSm]="24" [nzMd]="12" [nzXl]="6" *ngIf="userCanSeeStructureFilter">
          <laveo-select-actor
            [control]="filters.get('structures')"
            [typeEntity]="filterStructureTypeEntity"
            [typeSelection]="'multiple'"
            [typeSize]="'large'"
            [label]="filterStructurePlaceholder"
            [placeholder]="filterStructurePlaceholder">
          </laveo-select-actor>
        </div>
      </div>
    </form>

    <nz-calendar [nzDateCell]="dateCell" (nzSelectChange)="this.onDateChanged($event)" [nzValue]="currentDate"></nz-calendar>
  </ng-container>

  <ng-template #dateCell let-date>
    <ng-container *ngIf="!isLoading; else dateCellLoading">
      <div
        nz-tooltip
        [nzTooltipTitle]="service.status | serviceStatusName"
        *ngFor="let service of servicesForDate(date)"
        [class.canceled]="service.status === 'CANCELED_BY_CUSTOMER_SITE' || service.status === 'CANCELED_BY_STRUCTURE'"
        (click)="goToService(service.id)">
        <ng-container [ngSwitch]="service.status">
          <nz-badge nzStatus="success" class="color-{{ service.status | lowercase }}" *ngSwitchCase="'PERFORMED'"></nz-badge>
          <nz-badge nzStatus="default" class="color-{{ service.status | lowercase }}" *ngSwitchCase="'CANCELED_BY_CUSTOMER_SITE'"></nz-badge>
          <nz-badge nzStatus="default" class="color-{{ service.status | lowercase }}" *ngSwitchCase="'CANCELED_BY_STRUCTURE'"></nz-badge>
          <nz-badge nzStatus="warning" class="color-{{ service.status | lowercase }}" *ngSwitchDefault></nz-badge>
        </ng-container>
        <span>{{ service.vehicle.licensePlate }}&nbsp;</span>
      </div>
    </ng-container>
    <ng-template #dateCellLoading>
      <nz-skeleton></nz-skeleton>
    </ng-template>
  </ng-template>

  <ng-template #errorTemplate>
    <nz-result nzTitle="Erreur de chargement" nzStatus="error"
      nzSubTitle="Une erreur est survenue lors du chargement de la liste des prestations">
      <div nz-result-extra>
        <button nz-button nzType="primary" (click)="loadData()">
          Réessayer
          <i nz-icon nzType="reload"></i>
        </button>
      </div>
    </nz-result>
  </ng-template>
</nz-card>
