export enum ServiceActions {
  cancel = 'CANCEL',
  update = 'UPDATE',
  send_to_structure = 'SEND_TO_STRUCTURE',
  send_to_customer_site = 'SEND_TO_CUSTOMER_SITE',
  confirm = 'CONFIRM',
  perform = 'PERFORM',
  vehicle_not_present = 'VEHICLE_NOT_PRESENT',
  upload_vehicle_state = 'UPLOAD_VEHICLE_STATE',
}

export interface ServiceActionPerformData {
  id: string;
  datePerformed?: Date;
  hoursToInvoice?: number;
  preparerId?: string;
}
