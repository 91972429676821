import { Pipe, PipeTransform } from '@angular/core';
import { ServiceStatus } from '../models/entities/service';

@Pipe({
  name: 'serviceStatusColor'
})
export class ServiceStatusColorPipe implements PipeTransform {
  transform(status?: ServiceStatus): string {
    if (!status) {
      return 'default';
    }

    switch (status) {
      case ServiceStatus.asked:
      case ServiceStatus.proposed: {
        return 'warning';
      }
      case ServiceStatus.planned: {
        return 'processing';
      }
      case ServiceStatus.performed: {
        return 'success';
      }
      case ServiceStatus.vehicleNotPresent: {
        return '#FF5500';
      }
      default: {
        return 'default';
      }
    }
  }
}
