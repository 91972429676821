"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const consts_1 = require("../consts");
const Decorator_1 = require("./Decorator");
function Serializer(fn) {
  return Decorator_1.createDecorator('Serializer', consts_1.SerializerSymbol, fn);
}
exports.Serializer = Serializer;
