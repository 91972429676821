import { CommonModule } from '@angular/common';
import { MainSharedModule } from '../shared/main-shared.module';
import { NgModule } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../../shared/shared.module';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { ServicesComponent } from './services.component';
import { FilterServicesComponent } from './filter-services/filter-services.component';
import { ListServicesModule } from '../../../shared/views/list-services/list-services.module';
import { SelectActorModule } from '../../../shared/views/inputs/select-actor/select-actor.module';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { ServicesCalendarComponent } from './calendar/calendar.component';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzResultModule } from 'ng-zorro-antd/result';
import { RouterModule } from '@angular/router';
import { ServiceModule } from '../service/service.module';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzRadioModule } from 'ng-zorro-antd/radio';

@NgModule({
  declarations: [
    ServicesComponent,
    ServicesCalendarComponent,
    FilterServicesComponent
  ],
    imports: [
        CommonModule,
        SharedModule,
        MainSharedModule,
        ReactiveFormsModule,
        RouterModule,
        ListServicesModule,
        NzBadgeModule,
        NzButtonModule,
        NzCalendarModule,
        NzCardModule,
        NzDatePickerModule,
        NzDividerModule,
        NzDropDownModule,
        NzFormModule,
        NzGridModule,
        NzInputModule,
        NzLayoutModule,
        NzRadioModule,
        NzResultModule,
        NzSelectModule,
        NzSkeletonModule,
        NzSliderModule,
        NzTimePickerModule,
        NzToolTipModule,
        NzTypographyModule,
        SelectActorModule,
        ServiceModule,
        NzCheckboxModule
    ]
})
export class ServicesModule {}
