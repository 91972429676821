"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const helpers_1 = require("../helpers");
const consts_1 = require("../consts");
function Strategy(strategy) {
  return function (constructor) {
    const _strategy = Reflect.getMetadata(consts_1.StrategySymbol, constructor);
    if (!helpers_1.isUndefined(_strategy)) {
      throw new Error(`Cannot apply @Strategy decorator twice on class '${constructor.name}'.`);
    }
    Reflect.defineMetadata(consts_1.StrategySymbol, strategy, constructor);
  };
}
exports.Strategy = Strategy;
