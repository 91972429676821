import { NgModule } from '@angular/core';
import { IconsProviderModule } from '../icons-provider.module';
import { PhonePipe } from './pipes/phone.pipe';
import { RightNamePipe } from './pipes/right-name.pipe';
import { RightIconPipe } from './pipes/right-icon.pipe';
import { RoleNamePipe } from './pipes/role-name.pipe';
import { ServiceStatusNamePipe } from './pipes/service-status-name.pipe';
import { ServiceTypeNamePipe } from './pipes/service-type-name.pipe';
import { VehicleCategoryIconPipe } from './pipes/vehicle-category-icon.pipe';
import { VehicleCategoryNamePipe } from './pipes/vehicle-category-name.pipe';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NullCoalPipe } from './pipes/null-coal.pipe';
import { VehicleFrequencyNamePipe } from './pipes/vehicle-frequency-name.pipe';
import { VehicleAttributionNamePipe } from './pipes/vehicle-attribution-name.pipe';
import { CustomerSiteBillingPersonNamePipe } from './pipes/customer-site-billing-person-name.pipe';
import { CustomerSiteContractTypeNamePipe } from './pipes/customer-site-contract-type-name.pipe';
import { CustomerSiteWorkflowTypeNamePipe } from './pipes/customer-site-workflow-type-name.pipe';
import { ServiceFinishingNamePipe } from './pipes/service-finishing-name.pipe';
import { ServiceActionsNamePipe } from './pipes/service-actions-name.pipe';
import { ServiceActionsIconPipe } from './pipes/service-actions-icon.pipe';
import { ServiceDatePipe } from './pipes/service-date.pipe';
import { ServiceUpholsteryStainRemovalNamePipe } from './pipes/service-upholstery-stain-removal-name.pipe';
import { ServiceCarpetStainRemovalNamePipe } from './pipes/service-carpet-stain-removal-name.pipe';
import { BooleanPipe } from './pipes/boolean.pipe';
import { VehicleCheckpointFieldTypeNamePipe } from './pipes/vehicle-checkpoint-field-type-name.pipe';
import { ServiceStatusColorPipe } from './pipes/service-status-color.pipe';
import { RseTypeNamePipe } from './pipes/rse-type-name.pipe';

@NgModule({
  declarations: [
    PhonePipe,
    RightNamePipe,
    RightIconPipe,
    RoleNamePipe,
    ServiceStatusColorPipe,
    ServiceStatusNamePipe,
    ServiceTypeNamePipe,
    ServiceFinishingNamePipe,
    ServiceActionsNamePipe,
    ServiceCarpetStainRemovalNamePipe,
    ServiceUpholsteryStainRemovalNamePipe,
    ServiceActionsIconPipe,
    VehicleCategoryIconPipe,
    VehicleCategoryNamePipe,
    VehicleFrequencyNamePipe,
    VehicleAttributionNamePipe,
    CustomerSiteBillingPersonNamePipe,
    CustomerSiteContractTypeNamePipe,
    CustomerSiteWorkflowTypeNamePipe,
    VehicleCheckpointFieldTypeNamePipe,
    NullCoalPipe,
    BooleanPipe,
    ServiceDatePipe,
    RseTypeNamePipe,
  ],
  imports: [
    IconsProviderModule,
    NzModalModule
  ],
  exports: [
    IconsProviderModule,
    PhonePipe,
    RightNamePipe,
    RightIconPipe,
    RoleNamePipe,
    ServiceStatusColorPipe,
    ServiceStatusNamePipe,
    ServiceTypeNamePipe,
    ServiceFinishingNamePipe,
    ServiceActionsNamePipe,
    ServiceCarpetStainRemovalNamePipe,
    ServiceUpholsteryStainRemovalNamePipe,
    ServiceActionsIconPipe,
    VehicleCategoryIconPipe,
    VehicleCategoryNamePipe,
    VehicleFrequencyNamePipe,
    VehicleAttributionNamePipe,
    CustomerSiteBillingPersonNamePipe,
    CustomerSiteContractTypeNamePipe,
    CustomerSiteWorkflowTypeNamePipe,
    VehicleCheckpointFieldTypeNamePipe,
    NullCoalPipe,
    BooleanPipe,
    ServiceDatePipe,
    RseTypeNamePipe,
  ]
})
export class SharedModule {}
