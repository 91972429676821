<laveo-entity-wrapper
  [title]="title"
  [description]="description"
  [entityService]="vehiclesService"
  [getFunction]="vehiclesService.vehicle"
  [addFunction]="vehiclesService.addVehicle"
  [updateFunction]="vehiclesService.updateVehicle"
  [deleteHardFunction]="vehiclesService.deleteOneVehicleHard"
  [wrappedComponent]="this"
  [getFormFunction]="form"
  [editEnabled]="editEnabled"
  [deleteEnabled]="deleteEnabled">
  <laveo-vehicle-detail *ngIf="isReady" detail [vehicle]="vehicle"></laveo-vehicle-detail>
  <laveo-vehicle-form *ngIf="isReady" form [vehicle]="vehicle"></laveo-vehicle-form>
</laveo-entity-wrapper>
