<laveo-entity-wrapper
  [title]="title"
  [entityService]="vehicleCheckpointsService"
  [getFunction]="vehicleCheckpointsService.vehicleCheckpoint"
  [addFunction]="vehicleCheckpointsService.addVehicleCheckpoint"
  [updateFunction]="vehicleCheckpointsService.updateVehicleCheckpoint"
  [wrappedComponent]="this"
  [getFormFunction]="form">
  <laveo-vehicle-checkpoint-detail *ngIf="isReady" detail [vehicleCheckpoint]="vehicleCheckpoint"></laveo-vehicle-checkpoint-detail>
  <laveo-vehicle-checkpoint-form *ngIf="isReady" form [vehicleCheckpoint]="vehicleCheckpoint"></laveo-vehicle-checkpoint-form>
</laveo-entity-wrapper>
