import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { SharedModule } from 'src/app/shared/shared.module';
import { SelectActorModule } from 'src/app/shared/views/inputs/select-actor/select-actor.module';
import { MainSharedModule } from '../../shared/main-shared.module';
import { AdminCustomersComponent } from './admin-customers.component';
import { CustomerDetailComponent } from './customer/customer-detail/customer-detail.component';
import { CustomerFormComponent } from './customer/customer-form/customer-form.component';
import { CustomerComponent } from './customer/customer.component';
import { InputsContactsModule } from '../../../../shared/views/inputs/inputs-contacts/inputs-contacts.module';
import { ListContactsModule } from '../../../../shared/views/list-contacts/list-contacts.module';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { FilePondModule } from 'ngx-filepond';

@NgModule({
  declarations: [
    AdminCustomersComponent,
    CustomerComponent,
    CustomerFormComponent,
    CustomerDetailComponent
  ],
  imports: [
    CommonModule,
    MainSharedModule,
    SharedModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzButtonModule,
    NzIconModule,
    NzCheckboxModule,
    NzRadioModule,
    NzCardModule,
    NzFormModule,
    NzTableModule,
    NzInputModule,
    NzResultModule,
    NzEmptyModule,
    NzDropDownModule,
    NgxGpAutocompleteModule,
    SelectActorModule,
    InputsContactsModule,
    ListContactsModule,
    FilePondModule
  ],
  exports: [
    AdminCustomersComponent
  ]
})
export class AdminCustomersModule {}
