import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { CommonModule } from '@angular/common';
import { MainSharedModule } from '../shared/main-shared.module';
import { AdminComponent } from './admin.component';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { AdminVehiclesModule } from './admin-vehicles/admin-vehicles.module';
import { AdminUsersModule } from './admin-users/admin-users.module';
import { AdminStructuresModule } from './admin-structures/admin-structures.module';
import { AdminPreparersModule } from './admin-preparers/admin-preparers.module';
import { AdminParamsModule as AdminParametersModule } from './admin-params/admin-params.module';
import { AdminCustomersModule } from './admin-customers/admin-customers.module';
import { AdminCustomerSitesModule } from './admin-customer-sites/admin-customer-sites.module';

@NgModule({
  declarations: [
    AdminComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MainSharedModule,
    AdminVehiclesModule,
    AdminUsersModule,
    AdminStructuresModule,
    AdminPreparersModule,
    AdminParametersModule,
    AdminCustomersModule,
    AdminCustomerSitesModule,
    NzCardModule,
    NzGridModule
  ]
})
export class AdminModule {}
