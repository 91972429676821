import { Route, Routes } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth.guard';
import { ForgotPasswordComponent } from '../pages/forgot-password/forgot-password.component';
import { LoginComponent } from '../pages/login/login.component';
import { LogoutComponent } from '../pages/logout/logout.component';
import { DashboardComponent } from '../pages/main/dashboard/dashboard.component';
import { ShortlinkComponent } from '../pages/shortlink/shortlink.component';
import { MainComponent } from '../pages/main/main.component';
import { AdminComponent } from '../pages/main/admin/admin.component';
import { AdminUsersComponent } from '../pages/main/admin/admin-users/admin-users.component';
import { AdminVehiclesComponent } from '../pages/main/admin/admin-vehicles/admin-vehicles.component';
import { AdminParamsComponent as AdminParametersComponent } from '../pages/main/admin/admin-params/admin-params.component';
import { AdminGuard } from '../shared/guards/admin.guard';
import { AdminCustomersComponent } from '../pages/main/admin/admin-customers/admin-customers.component';
import { AdminCustomerSitesComponent } from '../pages/main/admin/admin-customer-sites/admin-customer-sites.component';
import { AdminPreparersComponent } from '../pages/main/admin/admin-preparers/admin-preparers.component';
import { AdminStructuresComponent } from '../pages/main/admin/admin-structures/admin-structures.component';
import { ServicesComponent } from '../pages/main/services/services.component';
import { ServiceComponent } from '../pages/main/service/service.component';
import { NewAccountComponent } from '../pages/new-account/new-account.component';
import { AccountComponent } from '../pages/main/account/account.component';
import { UserComponent } from '../pages/main/admin/admin-users/user/user.component';
import { VehicleComponent } from '../pages/main/admin/admin-vehicles/vehicle/vehicle.component';
import { CustomerComponent } from '../pages/main/admin/admin-customers/customer/customer.component';
import { CustomerSiteComponent } from '../pages/main/admin/admin-customer-sites/customer-site/customer-site.component';
import { PreparerComponent } from '../pages/main/admin/admin-preparers/preparer/preparer.component';
import { StructureComponent } from '../pages/main/admin/admin-structures/structure/structure.component';
import { VehicleModelComponent } from '../pages/main/admin/admin-params/vehicle-model/vehicle-model.component';
import { VehicleCheckpointComponent } from '../pages/main/admin/admin-params/vehicle-checkpoint/vehicle-checkpoint.component';
import { ConfirmationMailComponent } from '../pages/confirmation-mail/confirmation-mail.component';
import { ServicesCalendarComponent } from '../pages/main/services/calendar/calendar.component';
import { VehicleBrandComponent } from '../pages/main/admin/admin-params/vehicle-brand/vehicle-brand.component';
import { ReportingComponent } from '../pages/main/reporting/reporting.component';
import { ReportingVehicleComponent } from '../pages/main/reporting/reporting-vehicle/reporting-vehicle.component';
import { ReportingServiceStatusComponent } from '../pages/main/reporting/reporting-service-status/reporting-service-status.component';
import { ReportingServiceSpecificationsComponent } from '../pages/main/reporting/reporting-service-specifications/reporting-service-specifications.component';
import { ReportingVehicleMonitoringComponent } from '../pages/main/reporting/reporting-vehicle-monitoring/reporting-vehicle-monitoring.component';
import { ReportingFleetComponent } from '../pages/main/reporting/reporting-fleet/reporting-fleet.component';
import { ReportingRseComponent } from '../pages/main/reporting/reporting-rse/reporting-rse.component';

export const AdminRouteList: Record<string, Route> = {
  admin: {
    path: '',
    component: AdminComponent,
    pathMatch: 'full'
  },
  users: {
    path: 'users',
    component: AdminUsersComponent,
    canActivate: [AdminGuard],
    pathMatch: 'full'
  },
  user: {
    path: 'users/:id',
    component: UserComponent,
    pathMatch: 'full'
  },
  params: {
    path: 'params',
    component: AdminParametersComponent,
    canActivate: [AdminGuard],
    pathMatch: 'full',
  },
  vehicleBrand: {
    path: 'params/vehicle-brands/:id',
    component: VehicleBrandComponent,
    pathMatch: 'full',
  },
  vehicleModel: {
    path: 'params/vehicle-models/:id',
    component: VehicleModelComponent,
    pathMatch: 'full',
  },
  vehicleCheckpoint: {
    path: 'params/vehicle-checkpoints/:id',
    component: VehicleCheckpointComponent,
    pathMatch: 'full',
  },
  customers: {
    path: 'customers',
    component: AdminCustomersComponent,
    canActivate: [AdminGuard],
    pathMatch: 'full'
  },
  customer: {
    path: 'customers/:id',
    component: CustomerComponent,
    pathMatch: 'full'
  },
  customerSites: {
    path: 'customer-sites',
    component: AdminCustomerSitesComponent,
    canActivate: [AdminGuard],
    pathMatch: 'full'
  },
  customerSite: {
    path: 'customer-sites/:id',
    component: CustomerSiteComponent,
    pathMatch: 'full'
  },
  preparers: {
    path: 'preparers',
    component: AdminPreparersComponent,
    canActivate: [AdminGuard],
    pathMatch: 'full'
  },
  preparer: {
    path: 'preparers/:id',
    component: PreparerComponent,
    pathMatch: 'full'
  },
  structures: {
    path: 'structures',
    component: AdminStructuresComponent,
    canActivate: [AdminGuard],
    pathMatch: 'full'
  },
  structure: {
    path: 'structures/:id',
    component: StructureComponent,
    pathMatch: 'full'
  }
};

export const ReportingRouteList: Record<string, Route> = {
  reporting: {
    path: '',
    component: ReportingComponent,
    pathMatch: 'full'
  },
  reportingVehicle: {
    path: 'vehicle',
    component: ReportingVehicleComponent,
    pathMatch: 'full'
  },
  reportingServiceStatus: {
    path: 'service-status',
    component: ReportingServiceStatusComponent,
    pathMatch: 'full'
  },
  reportingServiceSpecifications: {
    path: 'service-specifications',
    component: ReportingServiceSpecificationsComponent,
    pathMatch: 'full'
  },
  reportingVehicleMonitoring: {
    path: 'vehicle-monitoring',
    component: ReportingVehicleMonitoringComponent,
    pathMatch: 'full'
  },
  reportingFleet: {
    path: 'fleet',
    component: ReportingFleetComponent,
    pathMatch: 'full'
  },
  reportingRse: {
    path: 'rse',
    component: ReportingRseComponent,
    pathMatch: 'full'
  }
};

export const MainRouteList: Record<string, Route> = {
  dashboard: {
    path: '',
    component: DashboardComponent,
    pathMatch: 'full'
  },
  services: {
    path: 'prestations',
    component: ServicesComponent,
    pathMatch: 'full'
  },
  calendar: {
    path: 'prestations/calendrier',
    component: ServicesCalendarComponent,
    pathMatch: 'full'
  },
  service: {
    path: 'prestations/:id',
    component: ServiceComponent,
    pathMatch: 'full'
  },
  vehicles: {
    path: 'vehicles',
    component: AdminVehiclesComponent,
    canActivate: [AdminGuard],
    pathMatch: 'full'
  },
  vehicle: {
    path: 'vehicles/:id',
    component: VehicleComponent,
    pathMatch: 'full'
  },
  account: {
    path: 'account',
    component: AccountComponent,
    pathMatch: 'full'
  },
  admin: {
    path: 'admin',
    children: Object.values(AdminRouteList)
  },
  reporting: {
    path: 'reporting',
    children: Object.values(ReportingRouteList)
  }
};

export const RouteList: Record<string, Route> = {
  login: {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full'
  },
  logout: {
    path: 'logout',
    canActivate: [AuthGuard],
    component: LogoutComponent,
    pathMatch: 'full'
  },
  forgotPassword: {
    path: 'forgot-password',
    canActivate: [AuthGuard],
    component: ForgotPasswordComponent,
    pathMatch: 'full'
  },
  newAccount: {
    path: 'new-account',
    canActivate: [AuthGuard],
    component: NewAccountComponent,
    pathMatch: 'full'
  },
  confirmMail: {
    path: 'confirmation-mail',
    canActivate: [AuthGuard],
    component: ConfirmationMailComponent,
    pathMatch: 'full'
  },
  shortlink: {
    path: 'l/:link',
    canActivate: [AuthGuard],
    component: ShortlinkComponent,
    pathMatch: 'full'
  },
  main: {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: Object.values(MainRouteList)
  },
  catchAll: {
    path: '**',
    redirectTo: ''
  }
};

export const RoutesConfig: Routes = [
  RouteList.login,
  RouteList.logout,
  RouteList.forgotPassword,
  RouteList.newAccount,
  RouteList.confirmMail,
  RouteList.shortlink,
  RouteList.main,
  RouteList.catchAll,
];
