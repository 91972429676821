import { Component, Input } from '@angular/core';
import { Preparer } from 'src/app/shared/models/entities/preparer';

@Component({
  selector: 'laveo-preparer-detail',
  templateUrl: './preparer-detail.component.html',
  styleUrls: ['./preparer-detail.component.scss']
})
export class PreparerDetailComponent {
  @Input() preparer?: Preparer;
}
