import { Pipe, PipeTransform } from '@angular/core';
import { CustomerSiteContractType } from '../models/entities/customer-site-contract-type';

@Pipe({
  name: 'customerSiteContractTypeName'
})
export class CustomerSiteContractTypeNamePipe implements PipeTransform {
  transform(type?: CustomerSiteContractType): string | undefined {
    if (!type) {
      return;
    }

    switch (type) {
      case CustomerSiteContractType.bipartite: {
        return 'Bipartie';
      }
      case CustomerSiteContractType.tripartite: {
        return 'Tripartie';
      }
      default: {
        return;
      }
    }
  }
}
