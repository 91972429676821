"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const consts_1 = require("../consts");
const Decorator_1 = require("./Decorator");
function Exclude(fn) {
  return Decorator_1.createDecorator("Exclude", consts_1.ExcludeSymbol, fn || (() => true));
}
exports.Exclude = Exclude;
