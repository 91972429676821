<nz-form-item>
  <nz-form-label nzFor="licensePlate">Immatriculation</nz-form-label>
  <nz-form-control [nzErrorTip]="error" [nzWarningTip]="warning" [nzValidateStatus]="validationStatus ? validationStatus : 'validating'">
    <input [formControl]="formControl" nz-input placeholder="Immatriculation" [nzSize]="typeSize" (keydown)="filterCharsFromLicencePlate($event)"/>
  </nz-form-control>
  <ng-template #error let-control>
    <ng-container *ngIf="control?.hasError('required')">
      L'immatriculation est requise
    </ng-container>
  </ng-template>
</nz-form-item>
