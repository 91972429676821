import { Injectable } from '@angular/core';
import { Apollo, gql, MutationResult } from 'apollo-angular';
import { Observable } from 'rxjs';
import { PagedResponse } from '../../models/api/shared/paged/paged-response.interface';
import { PagedRequest } from '../../models/api/shared/paged/paged-request';
import { ServicesFiltersRequest } from '../../models/api/services/services-filters-requests.interface';
import { Service, ServiceArray } from '../../models/entities/service';
import { ApolloQueryResult } from '@apollo/client/core';
import { map } from 'rxjs/operators';
import { TypeSerializerUtils } from '../../utils/type-serializer.util';
import { ServiceRequest } from '../../models/api/services/services-requests.interface';
import { Structure } from '../../models/entities/structure';
import { DatesForIntervalRequests } from '../../models/api/services/date-for-interval-requests.interface';
import { DateConverter } from '../../type-converters/date.converter';
import { ServiceActionPerformData } from '../../models/entities/service-actions';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {
  constructor(
    private readonly apollo: Apollo
  ) {}

  service(id: string): Observable<ApolloQueryResult<Service>> {
    const query = gql`
      query service($id: Guid!) {
        vehicles {
          service(id: $id){
            id
            reference
            workflowType
            date
            dateProposed
            dateAsked
            datePlanned
            datePerformed
            dateCanceled
            status
            type
            finishing
            comment
            firstIntervention
            vehicleVeryDirty
            upholsteryStainRemoval
            carpetStainRemoval
            conveying
            sticking
            desticking
            contactZoneDisinfection
            interiorDisinfection
            vehicleStateEnabled
            vehicleStatePdf {
              id
              url
            }
            repportPdf {
              id
              url
            }
            vehicle {
              id
              licensePlate
              model {
                id
                name
                category
                brand {
                  id
                  name
                  logo
                }
              }
              customerSite {
                id
                name
                logo {
                  id
                  url
                }
                customer {
                  id
                  name
                  logo {
                    id
                    url
                  }
                }
                structure {
                  id
                  name
                  logo {
                    id
                    url
                  }
                }
                type
                finishing
                firstIntervention
                vehicleVeryDirty
                upholsteryStainRemoval
                carpetStainRemoval
                conveying
                sticking
                desticking
                contactZoneDisinfection
                interiorDisinfection
                contractType
              }
            }
            preparer {
              id
              name
            }
            actions
            fields
            currentDate
            contacts {
              id
              name
              sendMail
              mail
              phoneNumber
              comment
            }
            lastHistory {
              carpetStainRemoval
              contactZoneDisinfection
              conveying
              createdDate
              currentDate
              dateAsked
              dateCanceled
              datePerformed
              datePlanned
              dateProposed
              desticking
              finishing
              firstIntervention
              hoursToInvoice
              interiorDisinfection
              preparer {
                id
                name
              }
              status
              sticking
              type
              upholsteryStainRemoval
              vehicleStateEnabled
              vehicleVeryDirty
            }
            invoiced
          }
        }
      }
    `;

    return this.apollo.query<{ vehicles: { service: Service } }>({
      query,
      variables: { id }
    }).pipe(map(result => ({
      data: TypeSerializerUtils.deserialize(result.data?.vehicles?.service, Service),
      error: result.error,
      errors: result.errors,
      partial: result.partial,
      loading: result.loading,
      networkStatus: result.networkStatus
    })));
  }

  services(parameters: PagedRequest<ServicesFiltersRequest>): Observable<ApolloQueryResult<PagedResponse<Service>>> {
    const query = gql`
      query services($offset: Int, $limit: Int, $filters: ServicesFiltersRequest, $sortProperty: String, $sortType: ESortType, $search: String) {
        vehicles {
          services(offset: $offset, limit: $limit, filters: $filters, sortProperty: $sortProperty, sortType: $sortType, search: $search) {
            data {
              id
              reference
              date
              status
              type
              vehicleStatePdf {
                id
                url
              }
              repportPdf {
                id
                url
              }
              vehicle {
                id
                licensePlate
                model {
                  id
                  name
                  category
                  brand {
                    id
                    name
                    logo
                  }
                }
                customerSite {
                  id
                  name
                  country
                  contractType
                  logo {
                    id
                    url
                  }
                  customer {
                    id
                    name
                    logo {
                      id
                      url
                    }
                  }
                  structure {
                    id
                    name
                    logo {
                      id
                      url
                    }
                  }
                }
              }
              preparer {
                id
                name
              }
              actions
              fields
              currentDate
              invoiced
            }
            metadata {
              currentPage
              currentResult
              totalPages
              totalResults
              hasMore
            }
            extraMetadata {
              totalAsked
              totalProposed
              totalPlanned
              totalPerformed
              totalCanceled
              totalInvoiced
            }
          }
        }
      }
    `;

    return this.apollo.query<{ vehicles: { services: PagedResponse<Service>} }>({
      query,
      variables: {
        offset: parameters.offset,
        limit: parameters.limit,
        filters: parameters.filters,
        sortProperty: parameters.sortProperty,
        sortType: parameters.sortType,
        search: parameters.search
      },
      fetchPolicy: 'network-only'
    }).pipe(map(result => {
      const raw = result.data?.vehicles?.services;
      const response: PagedResponse<Service> = {
        data: TypeSerializerUtils.deserializeArr(raw.data, ServiceArray),
        metadata: raw.metadata,
        extraMetadata: raw.extraMetadata
      };
      response.data.map(s => {
        s.vehicle.customerSite.structure = TypeSerializerUtils.deserialize(s.vehicle.customerSite.structure, Structure);
        return s;
      });
      return {
        data: response,
        error: result.error,
        errors: result.errors,
        partial: result.partial,
        loading: result.loading,
        networkStatus: result.networkStatus
      };
    }));
  }

  servicesExcel(parameters: PagedRequest<ServicesFiltersRequest>, exportType: string): Observable<ApolloQueryResult<string>> {
    // TODO: Changer la requête en fonction du type d'export (rapports d'état des lieux ou prestations)
    const query = gql`
      query servicesExcel($offset: Int, $limit: Int, $filters: ServicesFiltersRequest, $sortProperty: String, $sortType: ESortType, $search: String, $exportType: String) {
        vehicles {
          services(offset: $offset, limit: $limit, filters: $filters, sortProperty: $sortProperty, sortType: $sortType, search: $search, exportType: $exportType) {
            excelUrl
          }
        }
      }
    `;

    return this.apollo.query<{ vehicles: { services: { excelUrl: string } } }>({
      query,
      fetchPolicy: 'network-only',
      variables: {
        offset: parameters.offset,
        limit: parameters.limit,
        filters: parameters.filters,
        sortProperty: parameters.sortProperty,
        sortType: parameters.sortType,
        search: parameters.search,
        exportType
      }
    }).pipe(map(result => ({
      data: result.data.vehicles.services.excelUrl,
      error: result.error,
      errors: result.errors,
      partial: result.partial,
      loading: result.loading,
      networkStatus: result.networkStatus
    })));
  }

  addService(data: ServiceRequest): Observable<MutationResult<Service>> {
    const ids = Array.isArray(data.vehicle) ? data.vehicle.map(v => v.id) : [data.vehicle.id];

    let query = `mutation addService(` + ids.map((_, index) => '$data' + index + ': ServiceRequest').join(', ') + `) {
      services {`;

    for (let index = 0; index < ids.length; index++) {
      query += `
        data` + index + `: add(data: $data` + index + `) {
          id
        `;

      if (index === 0 ) {
        query += `reference
          workflowType
          date
          dateAsked
          dateProposed
          datePlanned
          datePerformed
          dateCanceled
          status
          type
          finishing
          comment
          firstIntervention
          vehicleVeryDirty
          upholsteryStainRemoval
          carpetStainRemoval
          conveying
          sticking
          desticking
          contactZoneDisinfection
          interiorDisinfection
          vehicleStateEnabled
          vehicleStatePdf {
            id
            url
          }
          repportPdf {
            id
            url
          }
          vehicle {
            id
            licensePlate
            model {
              id
              name
              category
              brand {
                id
                name
                logo
              }
            }
            customerSite {
              id
              name
              customer {
                id
                name
              }
              structure {
                id
                name
              }
              type
              finishing
              firstIntervention
              vehicleVeryDirty
              upholsteryStainRemoval
              carpetStainRemoval
              conveying
              sticking
              desticking
              contactZoneDisinfection
              interiorDisinfection
            }
          }
          preparer {
            id
            name
          }
          actions
          fields
          currentDate
          contacts {
            id
            name
            sendMail
            mail
            phoneNumber
            comment
          }
        `;
      }

      query += `}
      `;
    }

    query += `
        }
      }
    `;

    const date: Date = data.date;
    if (data.time) {
      date.setHours(data.time.getHours(), data.time.getMinutes(), 0, 0);
    } else {
      date.setHours(0, 0, 0, 0);
    }

    const variables = ids.reduce((response, item, index) => {
      response['data' + index] = {
        date,
        vehicleId: item,
        type: data.type,
        finishing: data.finishing,
        preparerId: data.preparer?.id,
        comment: data.comment,
        contacts : data.contacts,
        firstIntervention : data.firstIntervention,
        vehicleVeryDirty : data.vehicleVeryDirty,
        upholsteryStainRemoval : data.upholsteryStainRemoval,
        carpetStainRemoval : data.carpetStainRemoval,
        conveying : data.conveying,
        sticking : data.sticking,
        desticking : data.desticking,
        contactZoneDisinfection : data.contactZoneDisinfection,
        interiorDisinfection : data.interiorDisinfection,
        vehicleStateEnabled: data.vehicleStateEnabled,
        recurrenceDays: data.frequency,
        recurrenceIteration: data.iteration
      };
      return response;
    }, {});

    return this.apollo.mutate<{ services: { data0: Service } }>({
      mutation: gql(query),
      variables
    }).pipe(map(result => ({

      data: TypeSerializerUtils.deserialize(result.data?.services?.data0, Service),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }

  updateService(data: ServiceRequest): Observable<MutationResult<Service>> {
    const id = Array.isArray(data.vehicle) ? data.vehicle.map(v => v.id)[0] : data.vehicle.id;

    const mutation = gql`
      mutation updateService($id: Guid!, $data: ServiceRequest) {
        services {
          update(id: $id, data: $data) {
            id
            reference
            workflowType
            date
            dateProposed
            dateAsked
            datePlanned
            datePerformed
            dateCanceled
            status
            type
            finishing
            comment
            firstIntervention
            vehicleVeryDirty
            upholsteryStainRemoval
            carpetStainRemoval
            conveying
            sticking
            desticking
            contactZoneDisinfection
            interiorDisinfection
            vehicleStateEnabled
            vehicleStatePdf {
              id
              url
            }
            repportPdf {
              id
              url
            }
            vehicle {
              id
              licensePlate
              model {
                id
                name
                category
                brand {
                  id
                  name
                  logo
                }
              }
              customerSite {
                id
                name
                customer {
                  id
                  name
                }
                structure {
                  id
                  name
                }
                type
                finishing
                firstIntervention
                vehicleVeryDirty
                upholsteryStainRemoval
                carpetStainRemoval
                conveying
                sticking
                desticking
                contactZoneDisinfection
                interiorDisinfection
              }
            }
            preparer {
              id
              name
            }
            actions
            fields
            currentDate
            contacts {
              id
              name
              sendMail
              mail
              phoneNumber
              comment
            }
            invoiced
          }
        }
      }
    `;

    const date: Date = data.date;
    if (data.time) {
      date.setHours(data.time.getHours(), data.time.getMinutes(), data.time.getSeconds(), data.time?.getMilliseconds());
    } else {
      date.setHours(0, 0, 0, 0);
    }
    return this.apollo.mutate<{ services: { update: Service } }>({
      mutation,
      variables: {
        id: data.id,
        data: {
          date: data.date,
          status: data.status,
          vehicleId: id,
          type: data.type,
          finishing: data.finishing,
          preparerId: data.preparer?.id,
          comment: data.comment,
          contacts : data.contacts,
          firstIntervention : data.firstIntervention,
          vehicleVeryDirty : data.vehicleVeryDirty,
          upholsteryStainRemoval : data.upholsteryStainRemoval,
          carpetStainRemoval : data.carpetStainRemoval,
          conveying : data.conveying,
          sticking : data.sticking,
          desticking : data.desticking,
          contactZoneDisinfection : data.contactZoneDisinfection,
          interiorDisinfection : data.interiorDisinfection,
          vehicleStateEnabled: data.vehicleStateEnabled,
          forceUpdate: data.forceUpdate
        }
      }
    }).pipe(map(result => ({

      data: TypeSerializerUtils.deserialize(result.data?.services?.update, Service),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }

  updateInvoicedService(id: string, invoiced: boolean): Observable<MutationResult<Service>> {
    const mutation = gql`
      mutation updateInvoicedService($id: Guid!, $data: ServiceRequest) {
        services {
          update(id: $id, data: $data) {
            id
            reference
            workflowType
            date
            dateProposed
            dateAsked
            datePlanned
            datePerformed
            dateCanceled
            status
            type
            finishing
            comment
            firstIntervention
            vehicleVeryDirty
            upholsteryStainRemoval
            carpetStainRemoval
            conveying
            sticking
            desticking
            contactZoneDisinfection
            interiorDisinfection
            vehicleStateEnabled
            vehicleStatePdf {
              id
              url
            }
            repportPdf {
              id
              url
            }
            vehicle {
              id
              licensePlate
              model {
                id
                name
                category
                brand {
                  id
                  name
                  logo
                }
              }
              customerSite {
                id
                name
                customer {
                  id
                  name
                }
                structure {
                  id
                  name
                }
                type
                finishing
                firstIntervention
                vehicleVeryDirty
                upholsteryStainRemoval
                carpetStainRemoval
                conveying
                sticking
                desticking
                contactZoneDisinfection
                interiorDisinfection
              }
            }
            preparer {
              id
              name
            }
            actions
            fields
            currentDate
            contacts {
              id
              name
              sendMail
              mail
              phoneNumber
              comment
            }
            invoiced
          }
        }
      }
    `;

    return this.apollo.mutate<{ services: { update: Service } }>({
      mutation,
      variables: {
        id,
        data: {
          invoiced
        }
      }
    }).pipe(map(result => ({

      data: TypeSerializerUtils.deserialize(result.data?.services?.update, Service),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }

  // Actions

  cancel(id: string, comment: string): Observable<MutationResult<Service>> {
    const mutation = gql`
      mutation cancelService($id: Guid!, $comment: String) {
        services {
          cancel(id: $id, comment: $comment) {
            id
            reference
            workflowType
            date
            dateProposed
            dateAsked
            datePlanned
            datePerformed
            dateCanceled
            status
            type
            finishing
            comment
            firstIntervention
            vehicleVeryDirty
            upholsteryStainRemoval
            carpetStainRemoval
            conveying
            sticking
            desticking
            contactZoneDisinfection
            interiorDisinfection
            vehicleStateEnabled
            vehicleStatePdf {
              id
              url
            }
            repportPdf {
              id
              url
            }
            vehicle {
              id
              licensePlate
              model {
                id
                name
                category
                brand {
                  id
                  name
                  logo
                }
              }
              customerSite {
                id
                name
                customer {
                  id
                  name
                }
                structure {
                  id
                  name
                }
                type
                finishing
                firstIntervention
                vehicleVeryDirty
                upholsteryStainRemoval
                carpetStainRemoval
                conveying
                sticking
                desticking
                contactZoneDisinfection
                interiorDisinfection
              }
            }
            preparer {
              id
              name
            }
            actions
            fields
            currentDate
            contacts {
              id
              name
              sendMail
              mail
              phoneNumber
              comment
            }
          }
        }
      }
    `;

    return this.apollo.mutate<{ services: { cancel: Service } }>({
      mutation,
      variables: {
        id,
        comment
      }
    }).pipe(map(result => ({

      data: TypeSerializerUtils.deserialize(result.data?.services?.cancel, Service),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }

  sendToStructure(id: string): Observable<MutationResult<Service>> {
    const mutation = gql`
      mutation sendToStructureService($id: Guid!) {
        services {
          sendToStructure(id: $id) {
            id
            reference
            workflowType
            date
            dateProposed
            dateAsked
            datePlanned
            datePerformed
            dateCanceled
            status
            type
            finishing
            comment
            firstIntervention
            vehicleVeryDirty
            upholsteryStainRemoval
            carpetStainRemoval
            conveying
            sticking
            desticking
            contactZoneDisinfection
            interiorDisinfection
            vehicleStateEnabled
            vehicleStatePdf {
              id
              url
            }
            repportPdf {
              id
              url
            }
            vehicle {
              id
              licensePlate
              model {
                id
                name
                category
                brand {
                  id
                  name
                  logo
                }
              }
              customerSite {
                id
                name
                customer {
                  id
                  name
                }
                structure {
                  id
                  name
                }
                type
                finishing
                firstIntervention
                vehicleVeryDirty
                upholsteryStainRemoval
                carpetStainRemoval
                conveying
                sticking
                desticking
                contactZoneDisinfection
                interiorDisinfection
              }
            }
            preparer {
              id
              name
            }
            actions
            fields
            currentDate
            contacts {
              id
              name
              sendMail
              mail
              phoneNumber
              comment
            }
          }
        }
      }
    `;

    return this.apollo.mutate<{ services: { sendToStructure: Service } }>({
      mutation,
      variables: {
        id
      }
    }).pipe(map(result => ({

      data: TypeSerializerUtils.deserialize(result.data?.services?.sendToStructure, Service),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }

  sendToCustomerSite(id: string, dateProposed?: Date): Observable<MutationResult<Service>> {
    const mutation = gql`
      mutation sendToCustomerSiteService($id: Guid!, $dateProposed: DateTime) {
        services {
          sendToCustomerSite(id: $id, dateProposed: $dateProposed) {
            id
            reference
            workflowType
            date
            dateAsked
            dateProposed
            datePlanned
            datePerformed
            dateCanceled
            status
            type
            finishing
            comment
            firstIntervention
            vehicleVeryDirty
            upholsteryStainRemoval
            carpetStainRemoval
            conveying
            sticking
            desticking
            contactZoneDisinfection
            interiorDisinfection
            vehicleStateEnabled
            vehicleStatePdf {
              id
              url
            }
            repportPdf {
              id
              url
            }
            vehicle {
              id
              licensePlate
              model {
                id
                name
                category
                brand {
                  id
                  name
                  logo
                }
              }
              customerSite {
                id
                name
                customer {
                  id
                  name
                }
                structure {
                  id
                  name
                }
                type
                finishing
                firstIntervention
                vehicleVeryDirty
                upholsteryStainRemoval
                carpetStainRemoval
                conveying
                sticking
                desticking
                contactZoneDisinfection
                interiorDisinfection
              }
            }
            preparer {
              id
              name
            }
            actions
            fields
            currentDate
            contacts {
              id
              name
              sendMail
              mail
              phoneNumber
              comment
            }
          }
        }
      }
    `;

    return this.apollo.mutate<{ services: { sendToCustomerSite: Service } }>({
      mutation,
      variables: {
        id,
        dateProposed
      }
    }).pipe(map(result => ({

      data: TypeSerializerUtils.deserialize(result.data?.services?.sendToCustomerSite, Service),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }

  confirm(id: string): Observable<MutationResult<Service>> {
    const mutation = gql`
      mutation confirmService($id: Guid!) {
        services {
          confirm(id: $id) {
            id
            reference
            workflowType
            date
            dateAsked
            dateProposed
            datePlanned
            datePerformed
            dateCanceled
            status
            type
            finishing
            comment
            firstIntervention
            vehicleVeryDirty
            upholsteryStainRemoval
            carpetStainRemoval
            conveying
            sticking
            desticking
            contactZoneDisinfection
            interiorDisinfection
            vehicleStateEnabled
            vehicleStatePdf {
              id
              url
            }
            repportPdf {
              id
              url
            }
            vehicle {
              id
              licensePlate
              model {
                id
                name
                category
                brand {
                  id
                  name
                  logo
                }
              }
              customerSite {
                id
                name
                customer {
                  id
                  name
                }
                structure {
                  id
                  name
                }
                type
                finishing
                firstIntervention
                vehicleVeryDirty
                upholsteryStainRemoval
                carpetStainRemoval
                conveying
                sticking
                desticking
                contactZoneDisinfection
                interiorDisinfection
              }
            }
            preparer {
              id
              name
            }
            actions
            fields
            currentDate
            contacts {
              id
              name
              sendMail
              mail
              phoneNumber
              comment
            }
          }
        }
      }
    `;

    return this.apollo.mutate<{ services: { confirm: Service } }>({
      mutation,
      variables: {
        id
      }
    }).pipe(map(result => ({

      data: TypeSerializerUtils.deserialize(result.data?.services?.confirm, Service),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }

  perform(data: ServiceActionPerformData): Observable<MutationResult<Service>> {
    const mutation = gql`
      mutation performService($id: Guid!, $hoursToInvoice: Int, $datePerformed: DateTime, $preparerId: Guid) {
        services {
          perform(id: $id, hoursToInvoice: $hoursToInvoice, datePerformed: $datePerformed, preparerId: $preparerId) {
            id
            reference
            workflowType
            date
            dateAsked
            dateProposed
            datePlanned
            datePerformed
            dateCanceled
            status
            type
            finishing
            comment
            firstIntervention
            vehicleVeryDirty
            upholsteryStainRemoval
            carpetStainRemoval
            conveying
            sticking
            desticking
            contactZoneDisinfection
            interiorDisinfection
            vehicleStateEnabled
            vehicleStatePdf {
              id
              url
            }
            repportPdf {
              id
              url
            }
            vehicle {
              id
              licensePlate
              model {
                id
                name
                category
                brand {
                  id
                  name
                  logo
                }
              }
              customerSite {
                id
                name
                customer {
                  id
                  name
                }
                structure {
                  id
                  name
                }
                type
                finishing
                firstIntervention
                vehicleVeryDirty
                upholsteryStainRemoval
                carpetStainRemoval
                conveying
                sticking
                desticking
                contactZoneDisinfection
                interiorDisinfection
              }
            }
            preparer {
              id
              name
            }
            actions
            fields
            currentDate
            contacts {
              id
              name
              sendMail
              mail
              phoneNumber
              comment
            }
          }
        }
      }
    `;

    return this.apollo.mutate<{ services: { perform: Service } }>({
      mutation,
      variables: {
        id: data?.id,
        datePerformed: data?.datePerformed,
        hoursToInvoice: data?.hoursToInvoice,
        preparerId: data?.preparerId,
      }
    }).pipe(map(result => ({

      data: TypeSerializerUtils.deserialize(result.data?.services?.perform, Service),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }

  vehicleNotPresent(data: ServiceActionPerformData): Observable<MutationResult<Service>> {
    const mutation = gql`
      mutation vehicleNotPresentService($id: Guid!, $datePerformed: DateTime, $preparerId: Guid) {
        services {
          vehicleNotPresent(id: $id, datePerformed: $datePerformed, preparerId: $preparerId) {
            id
            reference
            workflowType
            date
            dateAsked
            dateProposed
            datePlanned
            datePerformed
            dateCanceled
            status
            type
            finishing
            comment
            firstIntervention
            vehicleVeryDirty
            upholsteryStainRemoval
            carpetStainRemoval
            conveying
            sticking
            desticking
            contactZoneDisinfection
            interiorDisinfection
            vehicleStateEnabled
            vehicleStatePdf {
              id
              url
            }
            repportPdf {
              id
              url
            }
            vehicle {
              id
              licensePlate
              model {
                id
                name
                category
                brand {
                  id
                  name
                  logo
                }
              }
              customerSite {
                id
                name
                customer {
                  id
                  name
                }
                structure {
                  id
                  name
                }
                type
                finishing
                firstIntervention
                vehicleVeryDirty
                upholsteryStainRemoval
                carpetStainRemoval
                conveying
                sticking
                desticking
                contactZoneDisinfection
                interiorDisinfection
              }
            }
            preparer {
              id
              name
            }
            actions
            fields
            currentDate
            contacts {
              id
              name
              sendMail
              mail
              phoneNumber
              comment
            }
          }
        }
      }
    `;

    return this.apollo.mutate<{ services: { vehicleNotPresent: Service } }>({
      mutation,
      variables: {
        id: data?.id,
        datePerformed: data?.datePerformed,
        preparerId: data?.preparerId,
      }
    }).pipe(map(result => ({

      data: TypeSerializerUtils.deserialize(result.data?.services?.vehicleNotPresent, Service),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }

  uploadVehicleState(id: string, pdf: File): Observable<MutationResult<Service>> {
    const mutation = gql`
      mutation addVehicleStatePdf($id: Guid!, $pdf: Upload!) {
        services {
          addVehicleStatePdf(id: $id, pdf: $pdf) {
            id
            createdDate
            updatedDate
            vehicleStatePdf {
              id
              url
            }
          }
        }
      }
    `;

    return this.apollo.mutate<{ services: { addVehicleStatePdf: Service }}>({
      mutation,
      variables: {
        id,
        pdf
      },
      context: {
        useMultipart: true
      }
    }).pipe(map(result => ({

      data: TypeSerializerUtils.deserialize(result.data?.services?.addVehicleStatePdf, Service),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }

  removeVehicleState(id: string): Observable<MutationResult<Service>> {
    const mutation = gql`
      mutation removeVehicleStatePdf($id: Guid!) {
        services {
          removeVehicleStatePdf(id: $id) {
            id
          }
        }
      }
    `;

    return this.apollo.mutate<{ services: { removeVehicleStatePdf: Service }}>({
      mutation,
      variables: {
        id,
      },
    }).pipe(map(result => ({

      data: TypeSerializerUtils.deserialize(result.data?.services?.removeVehicleStatePdf, Service),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }

  // Utils

  getDatesForInterval(data: DatesForIntervalRequests): Observable<MutationResult<Date[]>> {
    const mutation = gql`
      mutation getDatesForInterval($customerSiteId: Guid!, $dateStart: DateTime!, $days: Int!, $iteration: Int!) {
        services {
          getDatesForInterval(customerSiteId: $customerSiteId, dateStart: $dateStart, days: $days, iteration: $iteration)
        }
      }
    `;

    return this.apollo.mutate<{ services: { getDatesForInterval: string[] }}>({
      mutation,
      variables: data
    }).pipe(map(result => ({

      data: DateConverter.deserializeArr(result.data?.services?.getDatesForInterval ?? []),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }

  // Actions Multiples

  copyMultiple(ids: string[], date: Date): Observable<MutationResult<Service[]>> {
    const query = `
      mutation duplicate(${ids.map((_, index) => `$id${index}: Guid!`).join(', ')}, $date: DateTime) {
        services {${ids.map((_, index) => `id${index}: duplicate (id: $id${index}, date: $date) {
            id
          }`).join('\n')}
        }
      }
    `;

    const variables: any = ids.reduce((response, item, index) => {
      response['id' + index] = item;
      return response;
    }, {});

    variables.date = date;

    return this.apollo.mutate<{ services: Record<string, unknown> }>({
      mutation: gql(query),
      variables
    }).pipe(map((result: MutationResult<any>) => {
      let data: (Record<string, unknown> | string)[] = Object.values(result.data?.services);
      data = data.filter(s => s !== 'ServicesMutation');
      return {

        data: TypeSerializerUtils.deserializeArr(data, Service),
        errors: result.errors,
        extensions: result.extensions,
        loading: result.loading
      };
    }));
  }

  cancelMultiple(comment:string, ...ids: string[]): Observable<MutationResult<Service[]>> {
    const query = `
      mutation cancelServices(` + ids.map((_, index) => '$id' + index + ': Guid!').join(', ') + `, $comment: String) {
        services {\n` + ids.map((_, index) => 'id' + index + ': cancel (id: $id' + index + ', comment: $comment) { id }').join('\n') + `\n}
      }
    `;

    const variables = ids.reduce((response: any, item, index) => {
      response['id' + index] = item;
      response.comment = comment;
      return response;
    }, {});

    return this.apollo.mutate({
      mutation: gql(query),
      variables
    }).pipe(map((result: MutationResult<any>) => {
      let data: (Record<string, unknown> | string)[] = Object.values(result.data?.services);
      data = data.filter(s => s !== 'ServicesMutation');
      return {

        data: TypeSerializerUtils.deserializeArr(data, Service),
        errors: result.errors,
        extensions: result.extensions,
        loading: result.loading
      };
    }));
  }

  sendToStructureMultiple(...ids: string[]): Observable<MutationResult<Service[]>> {
    const query = `
      mutation sendToStructureMultipleServices(` + ids.map((_, index) => '$id' + index + ': Guid!').join(', ') + `) {
        services {\n` + ids.map((_, index) => 'id' + index + ': sendToStructure(id: $id' + index + ') { id }').join('\n') + `\n}
      }
    `;

    const variables = ids.reduce((response, item, index) => {
      response['id' + index] = item;
      return response;
    }, {});

    return this.apollo.mutate({
      mutation: gql(query),
      variables
    }).pipe(map((result: MutationResult<any>) => {
      let data: (Record<string, unknown> | string)[] = Object.values(result.data?.services);
      data = data.filter(s => s !== 'ServicesMutation');
      return {

        data: TypeSerializerUtils.deserializeArr(data, Service),
        errors: result.errors,
        extensions: result.extensions,
        loading: result.loading
      };
    }));
  }

  sendToCustomerSiteMultiple(ids: string[], dateProposed?: Date): Observable<MutationResult<Service[]>> {
    const query = `
      mutation sendToCustomerSiteMultipleServices(` + ids.map((_, index) => '$id' + index + ': Guid!').join(', ') + `, $dateProposed: DateTime) {
        services {\n` + ids.map((_, index) => 'id' + index + ': sendToCustomerSite(id: $id' + index + ', dateProposed: $dateProposed) { id }').join('\n') + `\n}
      }
    `;

    const variables: any = ids.reduce((response, item, index) => {
      response['id' + index] = item;
      return response;
    }, {});

    variables.dateProposed = dateProposed;

    return this.apollo.mutate({
      mutation: gql(query),
      variables
    }).pipe(map((result: MutationResult<any>) => {
      let data: (Record<string, unknown> | string)[] = Object.values(result.data?.services);
      data = data.filter(s => s !== 'ServicesMutation');
      return {

        data: TypeSerializerUtils.deserializeArr(data, Service),
        errors: result.errors,
        extensions: result.extensions,
        loading: result.loading
      };
    }));
  }

  confirmMultiple(...ids: string[]): Observable<MutationResult<Service[]>> {
    const query = `
      mutation confirmMultipleServices(` + ids.map((_, index) => '$id' + index + ': Guid!').join(', ') + `) {
        services {\n` + ids.map((_, index) => 'id' + index + ': confirm(id: $id' + index + ') { id }').join('\n') + `\n}
      }
    `;

    const variables = ids.reduce((response, item, index) => {
      response['id' + index] = item;
      return response;
    }, {});

    return this.apollo.mutate({
      mutation: gql(query),
      variables
    }).pipe(map((result: MutationResult<any>) => {
      let data: (Record<string, unknown> | string)[] = Object.values(result.data?.services);
      data = data.filter(s => s !== 'ServicesMutation');
      return {

        data: TypeSerializerUtils.deserializeArr(data, Service),
        errors: result.errors,
        extensions: result.extensions,
        loading: result.loading
      };
    }));
  }

  performMultiple(ids: string[], performData: ServiceActionPerformData): Observable<MutationResult<Service[]>> {
    const query = `
      mutation performMultipleServices(` + ids.map((_, index) => '$id' + index + ': Guid!').join(', ') + `, $hoursToInvoice: Int, $datePerformed: DateTime, $preparerId: Guid) {
        services {\n` + ids.map((_, index) => 'id' + index + ': perform(id: $id' + index + ', hoursToInvoice: $hoursToInvoice, datePerformed: $datePerformed, preparerId: $preparerId) { id }').join('\n') + `\n}
      }
    `;

    const variables: any = ids.reduce((response, item, index) => {
      response['id' + index] = item;
      return response;
    }, {});

    variables.hoursToInvoice = performData.hoursToInvoice;
    variables.datePerformed = performData.datePerformed;
    variables.preparerId = performData.preparerId;

    return this.apollo.mutate({
      mutation: gql(query),
      variables
    }).pipe(map((result: MutationResult<any>) => {
      let data: (Record<string, unknown> | string)[] = Object.values(result.data?.services);
      data = data.filter(s => s !== 'ServicesMutation');
      return {

        data: TypeSerializerUtils.deserializeArr(data, Service),
        errors: result.errors,
        extensions: result.extensions,
        loading: result.loading
      };
    }));
  }

  vehicleNotPresentMultiple(ids: string[], performData: ServiceActionPerformData): Observable<MutationResult<Service[]>> {
    const query = `
      mutation performMultipleServices(` + ids.map((_, index) => '$id' + index + ': Guid!').join(', ') + `, $datePerformed: DateTime, $preparerId: Guid) {
        services {\n` + ids.map((_, index) => 'id' + index + ': vehicleNotPresent(id: $id' + index + ', datePerformed: $datePerformed, preparerId: $preparerId) { id }').join('\n') + `\n}
      }
    `;

    const variables: any = ids.reduce((response, item, index) => {
      response['id' + index] = item;
      return response;
    }, {});

    variables.datePerformed = performData.datePerformed;
    variables.preparerId = performData.preparerId;

    return this.apollo.mutate({
      mutation: gql(query),
      variables
    }).pipe(map((result: MutationResult<any>) => {
      let data: (Record<string, unknown> | string)[] = Object.values(result.data?.services);
      data = data.filter(s => s !== 'ServicesMutation');
      return {

        data: TypeSerializerUtils.deserializeArr(data, Service),
        errors: result.errors,
        extensions: result.extensions,
        loading: result.loading
      };
    }));
  }
}
