"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const consts_1 = require("../consts");
const helpers_1 = require("../helpers");
function deserialize(json, classType) {
  return transform(toObject(json), classType);
}
exports.deserialize = deserialize;
// -- Private --
function transformArray(arr, classType) {
  return arr.map(elm => Array.isArray(elm) ? transformArray(elm, classType) : transform(elm, classType));
}
function transform(obj, classType) {
  if (!helpers_1.isObject(obj)) {
    return obj;
  }
  const instance = new classType();
  const typeMap = Reflect.getMetadata(consts_1.TypeSymbol, instance) || {};
  const deserializerMap = Reflect.getMetadata(consts_1.DeserializerSymbol, instance) || {};
  const nameMap = Reflect.getMetadata(consts_1.NameSymbol, instance) || {};
  const reverseNames = Object.keys(nameMap).reduce((o, key) => Object.assign(Object.assign({}, o), {
    [nameMap[key]]: key
  }), {});
  Object.keys(obj).forEach(key => {
    if (reverseNames.hasOwnProperty(key)) {
      instance[reverseNames[key]] = obj[key];
    } else {
      instance[key] = obj[key];
    }
    if (typeof deserializerMap[key] === 'function') {
      instance[key] = deserializerMap[key].call(null, instance[key], instance);
      return;
    }
    if (!typeMap.hasOwnProperty(key)) {
      return;
    }
    const type = typeMap[key];
    if (Array.isArray(type) && Array.isArray(obj[key])) {
      if (type.length !== 1) {
        throw new Error('`@Type` can only be defined with a single value, or an array with a single value. for ex: `@Type(User)` or `@Type([User])`');
      }
      instance[key] = transformArray(obj[key], type[0]);
    } else if (type === Date) {
      instance[key] = new Date(obj[key]);
    } else {
      instance[key] = transform(obj[key], type);
    }
  });
  return instance;
}
function toObject(json) {
  try {
    return JSON.parse(json);
  } catch (_) {
    throw `Unable to deserialize, not a valid JSON.`;
  }
}
