<div nz-row nzAlign="middle" nzJustify="space-between" [nzGutter]="[16, 16]" class="row-wrapper">
  <div nz-col nzFlex="1 1 auto">
    <div nz-row nzAlign="middle" nzJustify="start" [nzGutter]="[32, 16]">
      <div nz-col class="check">
        <label nz-checkbox [nzChecked]="isServiceChecked(service)" (nzCheckedChange)="setChecked($event, service)"></label>
      </div>

      <div nz-col class="hours">
        <span nz-typography nzType="secondary" class="reference">{{ service.reference }}</span><br/>
        <span nz-typography *ngIf="isNotMidnight(service.date); else noTime">{{ service.date | date:' HH\'h\'mm' }}</span>
        <ng-template #noTime>
          <span nz-typography nzType="secondary">-</span>
        </ng-template>
      </div>

      <div nz-col class="statut">
        <nz-tag
          nzColor="{{ service.status | serviceStatusColor }}"
          class="w-100-p"
          nz-tooltip
          [nzTooltipTitle]="(service.status === 'CANCELED_BY_CUSTOMER_SITE' || service.status === 'CANCELED_BY_STRUCTURE') ? (service.status | serviceStatusName) : null">
          {{ service.status | serviceStatusName:true }}<i nz-icon nzType="lock" class="pl-4" *ngIf="isStatusLocked"></i>
        </nz-tag>
      </div>

      <div nz-col class="service">
        <div class="service-inner">
          <span nz-typography><strong>Type de lavage</strong></span><br />
          <span nz-typography nzType="secondary">{{ service.type | serviceTypeName }}</span>
        </div>
      </div>

      <div nz-col class="client" *ngIf="showClient">
        <div class="avatar-info-wrapper">
          <nz-avatar
            [nzSrc]="service.vehicle.customerSite.logo ? service.vehicle.customerSite.logo.urlWithDomain : service.vehicle.customerSite.customer.logo?.urlWithDomain"
            nzShape="square"
            nzSize="large"
            nzIcon="user"
            class="mr-8">
          </nz-avatar>
          <div class="client-inner">
            <span nz-typography><strong>{{ service.vehicle.customerSite.customer.name }}</strong></span><br />
            <span nz-typography nzType="secondary" nz-tooltip [nzTooltipTitle]="service.vehicle.customerSite.name.length > 30 ? service.vehicle.customerSite.name : null">{{ service.vehicle.customerSite.name }}</span>
          </div>
        </div>
      </div>

      <div nz-col class="vehicule" nz-tooltip [nzTooltipTitle]="service.vehicle.model.category | vehicleCategoryName">
        <div class="avatar-info-wrapper">
          <nz-avatar
            [nzIcon]="service.vehicle.model.category | vehicleCategoryIcon"
            [nzSrc]="service.vehicle.model.brand.logo"
            nzShape="square"
            nzSize="large"
            class="vehicle-avatar mr-8">
          </nz-avatar>
          <div class="vehicule-inner">
            <span nz-typography><strong>{{ service.vehicle.model.brand.name }} {{ service.vehicle.model.name }}</strong></span><br />
            <laveo-license-plate [licensePlate]="service.vehicle.licensePlate" [country]="service.vehicle.customerSite.country"></laveo-license-plate>
          </div>
        </div>
      </div>

      <div nz-col class="structure" *ngIf="showStructure">
        <div class="avatar-info-wrapper">
          <nz-avatar
            [nzSrc]="service.vehicle.customerSite.structure.logo?.urlWithDomain"
            [nzShape]="'square'"
            [nzSize]="'large'"
            [nzIcon]="'shop'"
            class="mr-8">
          </nz-avatar>
          <div class="structure-inner">
            <span nz-typography><strong>{{ service.vehicle.customerSite.structure.name }}</strong></span><br />
            <span nz-typography nzType="secondary" *ngIf="service.preparer; else emptyPreparer">{{ service.preparer.name }}</span>
            <ng-template #emptyPreparer>
              <span nz-typography nzType="secondary">-</span>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="large-button" nz-col>
    <div nz-row nzAlign="middle" nzJustify="space-between">
      <nz-badge [nzDot]="showDot" [nzCount]="iconTemplate" nz-tooltip [nzTooltipTitle]="service.actionsListTitle" class="mr-8">
        <div class="actions">
          <button
            *ngFor="let action of this.availableActions"
            nz-button
            nzSize="default"
            [nzLoading]="actionLoading"
            [disabled]="actionLoading"
            (click)="performAction(action)"
            class="w-120">
            {{ action | serviceActionsName:true }}
            <i nz-icon *ngIf="action | serviceActionsIcon as actionIcon" [nzType]="actionIcon"></i>
          </button>
        </div>
      </nz-badge>

      <button
        *ngIf="userCanReadService && (service.status === 'PERFORMED' || service.status === 'VEHICLE_NOT_PRESENT') && userCanSeeInvoiced && contractIsTripartite"
        nz-button
        nz-tooltip
        nzSize="default"
        [nzTooltipTitle]="service.invoiced ? 'Marquer comme non facturé' : 'Marquer comme facturé'"
        class="mr-8"
        [style.color]="service.invoiced ? '#52c41a' : '#fa8c16'"
        (click)="toggleInvoiced()"
        [disabled]="!userIsAdmin">
        {{ service.invoiced ? 'Facturé' : 'Non facturé' }}
        <i nz-icon nzType="euro" [style.color]="service.invoiced ? '#52c41a' : '#fa8c16'"></i>
      </button>

      <a
        *ngIf="userCanReadService && service.vehicleStatePdf"
        nz-button
        nz-tooltip
        class="mr-8"
        nzSize="default"
        target="_blank"
        nzTooltipTitle="Voir l'état des lieux"
        [href]="service.vehicleStatePdf.urlWithDomain">
        <i nz-icon nzType="file-done"></i>
      </a>

      <button
        *ngIf="userCanReadService"
        nz-button
        nz-tooltip
        nzSize="default"
        nzTooltipTitle="Détails"
        [routerLink]="['/', 'prestations', service.id]"
        class="mr-8">
        <i nz-icon nzType="eye"></i>
      </button>

      <ng-template #iconTemplate>
        <i nz-icon nzType="warning" class="ant-scroll-number-custom-component" [style.color]="'#f5222d'" *ngIf="showDot"></i>
      </ng-template>

      <button
        *ngIf="canEdit && userCanUpdateService"
        nz-button
        nz-tooltip
        nzSize="default"
        nzTooltipTitle="Modifier"
        [routerLink]="['/', 'prestations', service.id]"
        fragment="edit">
        <i nz-icon nzType="edit"></i>
      </button>
    </div>
  </div>

  <div class="small-button" nz-col>
    <nz-badge [nzDot]="showDot" [nzCount]="iconTemplate" class="mr-8" nz-tooltip [nzTooltipTitle]="service.actionsListTitle">
      <button nz-button nzSize="default" nz-dropdown nzTrigger="click" [nzDropdownMenu]="menuActions">
        <i nz-icon nzType="more"></i>
      </button>
    </nz-badge>

    <nz-dropdown-menu #menuActions="nzDropdownMenu">
      <ul nz-menu>
        <li *ngFor="let action of this.availableActions" nz-menu-item (click)="performAction(action)" [nzDisabled]="actionLoading">
          <i *ngIf="action | serviceActionsIcon as actionIcon" nz-icon [nzType]="!actionLoading ? actionIcon : 'loading'"></i>
          {{ action | serviceActionsName }}
        </li>
        <li *ngIf="userCanReadService && (service.status === 'PERFORMED' || service.status === 'VEHICLE_NOT_PRESENT') && userIsAdmin && contractIsTripartite"
          nz-menu-item
          nz-tooltip
          [nzTooltipTitle]="service.invoiced ? 'Marquer comme non facturé' : 'Marquer comme facturé'"
          [style.color]="service.invoiced ? '#52c41a' : '#fa8c16'"
          (click)="toggleInvoiced()">
          <i nz-icon nzType="euro" [style.color]="service.invoiced ? '#52c41a' : '#fa8c16'"></i>
          {{ service.invoiced ? 'Facturé' : 'Non facturé' }}
        </li>
        <li *ngIf="userCanReadService && service.vehicleStatePdf" nz-menu-item>
          <a target="_blank" [href]="service.vehicleStatePdf.urlWithDomain">
            <i nz-icon nzType="file-done"></i>
            Voir l'état des lieux
          </a>
        </li>
        <li *ngIf="userCanReadService" nz-menu-item [routerLink]="['/', 'prestations', service.id]">
          <i nz-icon nzType="eye"></i>
          Détails
        </li>
        <li *ngIf="canEdit && userCanUpdateService" nz-menu-item [routerLink]="['/', 'prestations', service.id]" fragment="edit">
          <i nz-icon nzType="edit"></i>
          Modifier
        </li>
      </ul>
    </nz-dropdown-menu>
  </div>
</div>
