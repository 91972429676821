import { Component, Input, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { UntypedFormArray, AbstractControl, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CustomerSite } from '../../../models/entities/customer-site';
import { Customer } from '../../../models/entities/customer';
import { Preparer } from '../../../models/entities/preparer';
import { Structure } from '../../../models/entities/structure';
import { UserRoleType, UserRole } from '../../../models/entities/user-role';
import { Admin } from '../../../models/entities/admin';
import { Actor } from '../../../models/entities/actor';

@Component({
  selector: 'laveo-inputs-roles',
  templateUrl: './inputs-roles.component.html',
  styleUrls: ['./inputs-roles.component.scss']
})
export class InputsRolesComponent implements OnInit, OnDestroy {
  @Input() controlArray: UntypedFormArray;
  resetList = new EventEmitter();

  private subscriptions: Subscription[] = [];


  public get disabledList(): Actor[] {
    return this.controlArray.getRawValue().map(f => f.entity).filter(a => !!a);
  }

  ngOnInit(): void {
    this.setForm();
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  add(): void {
    const form = new UntypedFormGroup({
      role: new UntypedFormControl(null, [Validators.required]),
      entity: new UntypedFormControl(null, [Validators.required])
    });

    this.toggleEntity(form);
    form.get('role')?.valueChanges.subscribe(() => {
      this.toggleEntity(form);
    });

    this.controlArray.controls.unshift(form);
  }

  delete(control: AbstractControl): void {
    this.controlArray.removeAt(this.controlArray.controls.indexOf(control));
  }

  getTypeEntity(value: UserRoleType): (typeof Actor)[] {
    switch (value) {
      case UserRoleType.admin: {
        return [Admin];
      }
      case UserRoleType.customer: {
        return [Customer];
      }
      case UserRoleType.customerSite:
      case UserRoleType.customerSiteRead: {
        return [CustomerSite];
      }
      case UserRoleType.preparer: {
        return [Preparer];
      }
      case UserRoleType.structure:
      case UserRoleType.structureRead: {
        return [Structure];
      }
      default: {
        return [Admin, Customer, CustomerSite, Preparer, Structure];
      }
    }
  }

  isEntityAdmin(entity: any): boolean {
    return entity?.__typename === 'Admin';
  }

  private setForm(): void {
    for (let index = 0; index < this.controlArray.controls.length; index++) {
      const role: UserRole = this.controlArray.controls[index].value;
      const form = new UntypedFormGroup({
        role: new UntypedFormControl(role.type, [Validators.required]),
        entity: new UntypedFormControl(role.actor, [Validators.required])
      });

      this.toggleEntity(form);
      form.get('role')?.valueChanges.subscribe(() => {
        this.toggleEntity(form);
      });

      this.controlArray.controls[index] = form;
    }
  }

  private toggleEntity(form: UntypedFormGroup): void {
    const role = form.get('role')?.value;
    const actor = form.get('entity')?.value;
    if (role) {
      form.get('entity')?.enable();
    } else {
      form.get('entity')?.disable();
    }

    const actorRoles: UserRoleType[] = [];
    switch (actor?.__typename) {
      case 'Admin': {
        actorRoles.push(UserRoleType.admin);
        break;
      }
      case 'CustomerSite': {
        actorRoles.push(UserRoleType.customerSite, UserRoleType.customerSiteRead);
        break;
      }
      case 'Customer': {
        actorRoles.push(UserRoleType.customer);
        break;
      }
      case 'Preparer': {
        actorRoles.push(UserRoleType.preparer);
        break;
      }
      case 'Structure': {
        actorRoles.push(UserRoleType.structure, UserRoleType.structureRead);
        break;
      }
    }

    if (!actorRoles.includes(role)) {
      form.get('entity')?.setValue(null);
      this.resetList.emit();
    }
  }
}
