import { Customer } from '../../entities/customer';
import { CustomerSite } from '../../entities/customer-site';
import { Structure } from '../../entities/structure';
import { Type } from 'typeserializer';

export class ReportingVehicleFleet {
  @Type(Customer)
  customer?: Customer;
  @Type(CustomerSite)
  customerSite?: CustomerSite;
  @Type(Structure)
  structure?: Structure;
  actor: Customer | CustomerSite | Structure;
  fleet: string;
  interiorFrequencyReal: number;
  interiorFrequencyContractual: number;
  exteriorFrequencyReal: number;
  exteriorFrequencyContractual: number;
  fullFrequencyReal: number;
  fullFrequencyContractual: number;
  vehicleStateEnabledFrequencyReal: number;
  allFrequencyReal: number;
}
