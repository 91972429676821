import { Component, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, Route } from '@angular/router';
import { ROUTES_CONFIG } from 'src/app/configs/tokens.config';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'laveo-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly titleService: Title,
    @Inject(ROUTES_CONFIG) private readonly routes: Record<string, Route>
  ) {}

  ngOnInit(): void {
    this.setTitle();
    this.authService.logout();
    void this.router.navigate(['/', this.routes.login.path]);
  }

  private setTitle() {
    this.titleService.setTitle('Lavéo - Déconnexion…');
  }
}
