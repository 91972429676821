import { ApolloError } from '@apollo/client/core';
import { HttpErrorResponse } from '@angular/common/http';

export class ApiErrorMessageUtil {
  public static getMessageFromError(error: any): string {
    if (error instanceof ApolloError) {
      const networkError: HttpErrorResponse = error.networkError as HttpErrorResponse;
      if (error.graphQLErrors?.length > 0) {
        return error.graphQLErrors[0].message;
      } else if (networkError?.error?.errors?.length > 0) {
        const errors: { message: string }[] = networkError.error?.errors;
        return 'Erreur. ' + (errors.map(error_ => error_.message).join('. '));
      }
    } else if (error instanceof Error) {
      return error.message;
    } else if (error?.error?.error_description) {
      return error.error.error_description;
    }
    return 'Une erreur s’est produite, veuillez réessayer plus tard';
  }
}
