<div class="detail mb-8">
  <span class="detail-title" nz-typography>Contacts</span>
</div>

<nz-table [nzShowPagination]="false" [nzTemplateMode]="true">
  <thead>
    <tr>
      <th>Nom</th>
      <th *ngIf="showNotificationsCheckbox" nz-tooltip nzTooltipTitle="Notifications">
        <i nz-icon nzType="mail" nzTheme="outline"></i>
      </th>
      <th>Mail</th>
      <th>Téléphone</th>
      <th>Commentaire</th>
      <th class="actions">
        <button nz-button nzType="primary" (click)="add()">
          <i nz-icon nzType="plus"></i>
        </button>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let control of controlArray.controls; index as index">
      <td class="name">
        <nz-form-item>
          <nz-form-control [nzErrorTip]="nameError">
            <input [formControl]="this.getAbstractAsFormControl(control, 'name')" nz-input placeholder="Nom"/>
            <ng-template #nameError let-control>
              <ng-container *ngIf="control?.hasError('required')">Ce champ est requis</ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </td>
      <td class="notifications" *ngIf="showNotificationsCheckbox">
        <nz-form-item>
          <nz-form-control>
            <label nz-checkbox [formControl]="this.getAbstractAsFormControl(control, 'sendMail')"></label>
          </nz-form-control>
        </nz-form-item>
      </td>
      <td class="mail">
        <nz-form-item>
          <nz-form-control [nzErrorTip]="mailError">
            <input [formControl]="this.getAbstractAsFormControl(control, 'mail')" nz-input placeholder="Mail"/>
            <ng-template #mailError let-control>
              <ng-container *ngIf="control?.hasError('email')">Adresse email incorrect</ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </td>
      <td class="phoneNumber">
        <nz-form-item>
          <nz-form-control>
            <input [formControl]="this.getAbstractAsFormControl(control, 'phoneNumber')" type="tel" nz-input placeholder="Téléphone"/>
          </nz-form-control>
        </nz-form-item>
      </td>
      <td>
        <nz-form-item class="comment">
          <nz-form-control>
            <nz-textarea-count [nzMaxCharacterCount]="500">
              <textarea [formControl]="this.getAbstractAsFormControl(control, 'comment')" nz-input placeholder="Commentaire" [nzAutosize]="{ maxRows: 5 }"></textarea>
            </nz-textarea-count>
          </nz-form-control>
        </nz-form-item>
      </td>
      <td class="actions action">
        <button nz-button nzType="primary" (click)="delete(control)">
          <i nz-icon nzType="delete"></i>
        </button>
      </td>
    </tr>
  </tbody>
</nz-table>
<nz-empty
  *ngIf="controlArray.controls.length === 0"
  nzNotFoundImage="simple"
  nzNotFoundContent="Aucun contact"
  [nzNotFoundFooter]="emptyFooter">
  <ng-template #emptyFooter>
    <button nz-button nzType="primary" (click)="add()">
      <i nz-icon nzType="plus"></i>
      Ajouter un contact
    </button>
  </ng-template>
</nz-empty>
