<nz-card [nzBordered]="false" [nzTitle]="cardTitle">
  <ng-template #cardTitle>
    <laveo-card-header
      [title]="category | rightName:currentRole?.type"
      [buttonTitle]="this.userCanAdd ? 'Ajouter un client' : undefined"
      [buttonIcon]="this.userCanAdd ? 'plus' : undefined"
      [description]="description"
      [backButtonEnabled]="true"
      (backButtonClick)="goToAdmin()"
      (buttonClick)="add()"
      [isLoading]="isLoading">
    </laveo-card-header>
  </ng-template>
  <div nz-row [nzGutter]="[16, 16]">
    <form nz-form [formGroup]="searchForm">
      <div nz-row [nzGutter]="8" [nzAlign]="'middle'">
        <div nz-col *ngIf="checked.length > 0 && userCanDelete">
          <button nz-button nzType="default" nzSize="large" nz-dropdown [nzDropdownMenu]="actionMenu">
            {{ checked.length }} {{ checked.length > 1 ? 'clients séléctionnés' : 'client séléctionné' }}
            <i nz-icon nzType="down"></i>
          </button>
          <nz-dropdown-menu #actionMenu="nzDropdownMenu">
            <ul nz-menu nzSelectable>
              <!-- <li nz-menu-item (click)="copyChecked()">
                <i nz-icon nzType="copy"></i>
                Dupliquer
              </li> -->
              <li nz-menu-item nzDanger (click)="deleteChecked()">
                <i nz-icon nzType="delete"></i>
                Désactiver
              </li>
              <li nz-menu-divider></li>
              <li nz-menu-item (click)="checked.splice(0, checked.length)">
                <i nz-icon nzType="minus-square"></i>
                Tout déséléctionner
              </li>
            </ul>
          </nz-dropdown-menu>
        </div>
        <div nz-col nzFlex="1 1 auto">
          <nz-input-group [nzPrefix]="prefixIconSearch" nzSize="large">
            <ng-template #prefixIconSearch>
              <i nz-icon nzType="search"></i>
            </ng-template>
            <input type="text" nz-input placeholder="Rechercher un client" formControlName="search" />
          </nz-input-group>
        </div>
      </div>
    </form>
    <nz-table
      nzShowSizeChanger
      [nzData]="customers ? customers.data : []"
      [nzFrontPagination]="false"
      [nzLoading]="isLoading"
      [nzTotal]="customers ? customers.metadata.totalResults : 0"
      [nzPageSize]="limit"
      [nzPageIndex]="customers ? customers.metadata.currentPage : 0"
      [nzNoResult]="emptyTemplate"
      (nzQueryParams)="setPage($event)"
      *ngIf="!error; else errorTemplate">
      <thead>
        <tr>
          <th nzWidth="60px" *ngIf="userCanDelete"></th>
          <th nzColumnKey="active" nzWidth="60px" [nzSortFn]="true">Actif</th>
          <th nzColumnKey="name" [nzSortFn]="true">Nom</th>
          <th nzColumnKey="address">Adresse</th>
          <th nzColumnKey="customerSite">Site Client</th>
          <th nzColumnKey="actions" class="actions">Actions</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let customer of customers?.data">
          <tr>
            <td [nzChecked]="checked.includes(customer.id)" (nzCheckedChange)="setChecked($event, customer.id)" *ngIf="userCanDelete"></td>
            <td><label nz-checkbox nzDisabled [(ngModel)]="customer.active"></label></td>
            <td>{{ customer.name }}</td>
            <td>{{ customer.fullAddress }}</td>
            <td><button nz-button (click)="goToCustomerSites(customer)" [disabled]="customer?.customerSitesMetaData?.totalResults === 0">
              {{ customerSites(customer) }}
              <i nz-icon nzType="right-square" nzTheme="outline" *ngIf="(customer?.customerSitesMetaData?.totalResults ? customer.customerSitesMetaData.totalResults: 0) > 0"></i>
            </button></td>
            <td class="actions">
              <button nz-button class="mr-8" [routerLink]="['/', 'admin', 'customers', customer.id]">
                <i nz-icon nzType="eye"></i>
                Détails
              </button>
              <button nz-button class="mr-8" (click)="edit(customer)" *ngIf="userCanEdit">Modifier</button>
              <button nz-button nzType="primary" (click)="delete(customer)" *ngIf="this.customer.active && userCanDelete">
                <i nz-icon nzType="delete"></i>
              </button>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>
    <ng-template #errorTemplate>
      <nz-result
        nzTitle="Erreur de chargement"
        nzStatus="error"
        nzSubTitle="Une erreur est survenue lors du chargement de la liste des clients">
        <div nz-result-extra>
          <button nz-button nzType="primary" (click)="loadData()">
            Réessayer
            <i nz-icon nzType="reload"></i>
          </button>
        </div>
      </nz-result>
    </ng-template>
    <ng-template #emptyTemplate>
      <nz-empty
        nzNotFoundImage="simple"
        [nzNotFoundContent]="this.userCanAdd ? 'Aucun client trouvé. Commencez par en ajouter un en utilisant le bouton ci-dessous.' : 'Aucun client trouvé'"
        [nzNotFoundFooter]="emptyFooter">
        <ng-template #emptyFooter>
          <button nz-button nzType="primary" (click)="add()" *ngIf="this.userCanAdd">
            <i nz-icon nzType="plus"></i>
            Ajouter un client
          </button>
        </ng-template>
      </nz-empty>
    </ng-template>
  </div>
</nz-card>
