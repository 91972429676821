"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const consts_1 = require("../consts");
const Decorator_1 = require("./Decorator");
function Before(version) {
  return Decorator_1.createDecorator("Before", consts_1.BeforeSymbol, version);
}
exports.Before = Before;
