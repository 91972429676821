import { NgModule } from '@angular/core';
import { DashboardComponent } from './dashboard.component';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { SharedModule } from '../../../shared/shared.module';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { CommonModule } from '@angular/common';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { MainSharedModule } from '../shared/main-shared.module';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { ListServicesModule } from '../../../shared/views/list-services/list-services.module';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { IsTodayPipeModule } from 'ngx-date-fns';
import { RouterModule } from '@angular/router';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { ServiceModule } from '../service/service.module';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzRadioModule } from 'ng-zorro-antd/radio';

@NgModule({
  declarations: [
    DashboardComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    ListServicesModule,
    MainSharedModule,
    NzInputModule,
    NzButtonModule,
    NzCalendarModule,
    NzCardModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzDividerModule,
    NzFormModule,
    NzRadioModule,
    NzGridModule,
    NzSkeletonModule,
    NzTypographyModule,
    NzDropDownModule,
    IsTodayPipeModule,
    SharedModule,
    ServiceModule
  ]
})
export class DashboardModule {}
