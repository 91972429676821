import { Pipe, PipeTransform } from '@angular/core';
import { ServiceActions } from '../models/entities/service-actions';

@Pipe({
  name: 'serviceActionsIcon'
})
export class ServiceActionsIconPipe implements PipeTransform {
  transform(action: ServiceActions): string | null {
    switch (action) {
      case ServiceActions.confirm:
      case ServiceActions.perform: {
        return 'check';
      }
      case ServiceActions.send_to_customer_site:
      case ServiceActions.send_to_structure: {
        return 'arrow-right';
      }
      case ServiceActions.upload_vehicle_state: {
        return 'upload';
      }
      default: {
        return null;
      }
    }
  }
}
