import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Entity } from '../../../../shared/models/entities/entity';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ApolloError, ApolloQueryResult } from '@apollo/client/core';
import { MutationResult } from 'apollo-angular';
import { ApiErrorMessageUtil } from '../../../../shared/utils/api-error-message.util';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'laveo-entity-wrapper',
  templateUrl: './entity-wrapper.component.html',
  styleUrls: ['./entity-wrapper.component.scss']
})
export class EntityWrapperComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() description?: string;
  @Input() entityService: any;
  @Input() getFunction: GetFunctionType<Entity>;
  @Input() addFunction: AddFunctionType<Entity>;
  @Input() updateFunction: UpdateFunctionType<Entity>;
  @Input() deleteHardFunction?: DeleteHardFunctionType;
  @Input() wrappedComponent: any;
  @Input() getFormFunction: GetFormFunctionType;
  @Input() editEnabled = true;
  @Input() deleteEnabled = false; // TODO: Conditionné par les claims

  entity: BehaviorSubject<Entity | null | undefined>;
  isLoading = true;
  isEditing = false;
  isSaving = false;

  private entityId: string;
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly modal: NzModalService,
    private readonly message: NzMessageService
  ) {
    this.entityId = '' + this.route.snapshot.params.id;
    this.entity = new BehaviorSubject<Entity | null | undefined>(this.entityId === 'new' ? null : undefined);
  }

  get buttonIcon(): string {
    return this.isSaving ? 'loading' : (this.isEditing ? 'save' : 'edit');
  }

  get buttonTitle(): string {
    return this.isSaving ? 'Sauvegarde…' : (this.isEditing ? 'Sauvegarder' : 'Modifier');
  }

  private get paths(): string[] {
    // TODO: find a better way
    // eslint-disable-next-line no-underscore-dangle
    return (this.route.snapshot as any).segments?.map(u => u.path) ?? [];
  }

  private get pathsList(): string[] {
    // TODO: find a better way
    // eslint-disable-next-line no-underscore-dangle
    return (this.route.snapshot as any).segments?.slice(0, -1)?.map(u => u.path) ?? [];
  }

  ngOnInit(): void {
    this.isEditing = this.route.snapshot.fragment === 'edit' || this.route.snapshot.params.id === 'new';

    this.subscriptions.push(
      this.route.fragment.subscribe( fragment => {
        this.isEditing = fragment === 'edit' || this.route.snapshot.params.id === 'new';
      })
    );

    if (this.entityId === 'new') {
      this.isLoading = false;
    } else {
      this.entityService[this.getFunction.name](this.entityId).subscribe(response => {
        this.entity.next(response.data);
        this.isLoading = false;
      }, (error: Error) => {
        console.error(error);
        this.message.warning('Element introuvable !');
        void this.router.navigate(['/']);
      });
    }
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions)  {s.unsubscribe();}
  }

  action(): void {
    if (this.isEditing) {
      this.save();
    } else {
      this.edit();
    }
  }

  deleteHard(): void {
    const form: UntypedFormGroup = this.wrappedComponent[this.getFormFunction.name]();
    if (!form) {
      return;
    }

    if (this.deleteHardFunction) {
      this.modal.confirm({
        nzTitle: 'Suppression définitive',
        nzContent: 'Êtes-vous sûr de vouloir supprimer l\'entité <b>définitivement</b> ?<br><br>Attention, cette action est irréversible.',
        nzOkText: 'Supprimer',
        nzOkType: 'primary',
        nzCancelText: 'Annuler',
        nzOnOk: () => {
          if (this.deleteHardFunction) {
            this.entityService[this.deleteHardFunction.name](form.get('id')?.value).subscribe(() => {
              this.message.success('Entité supprimée !');
              void this.router.navigate(this.pathsList, { fragment: undefined, replaceUrl: true });
            }, (error: ApolloError) => {
              console.error(error);
              this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
            });
          }
        }
      });
    }
  }

  back(): void {
    window.history.back();
  }

  private edit(): void {
    void this.router.navigate(this.paths, { fragment: 'edit' });
    this.isEditing = true;
  }

  private save(): void {
    const form: UntypedFormGroup = this.wrappedComponent[this.getFormFunction.name]();
    if (!form) {
      return;
    }

    if (!this.isFormValid(form)) {
      this.updateValueAndValidityOfControls(Object.values(form.controls));
      return;
    }

    this.isSaving = true;
    const id: string = form.get('id')?.value;
    const add = this.addFunction ? this.entityService[this.addFunction.name](form.getRawValue()) : undefined;
    const update = this.updateFunction ? this.entityService[this.updateFunction.name](form.getRawValue()) : undefined;
    const addOrUpdate: Observable<ApolloQueryResult<Entity>> = id ? update : add;
    addOrUpdate?.subscribe({
      next: response => {
        form.get('id')?.setValue(response.data.id);
        form.get('id')?.updateValueAndValidity();
        this.entity.next(response.data);
        this.isSaving = false;
        this.isEditing = false;
        const paths = this.paths;

        if (paths.length === 1 && paths[0] === 'account') {
          void this.router.navigate(paths, { fragment: undefined, replaceUrl: true });
          return;
        }

        paths[paths.length - 1] = response.data.id;
        void this.router.navigate(paths, { fragment: undefined, replaceUrl: true });
      },
      error: (error: ApolloError) => {
        console.error(error);
        this.isSaving = false;
        this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
      }
    });
  }

  private isFormValid(form: UntypedFormGroup): boolean {
    // TODO: Check nested form array
    return form.valid;
  }

  private updateValueAndValidityOfControls(controls: AbstractControl[]): void {
    try {
      for (const control of controls) {
        control.markAllAsTouched();
        control.updateValueAndValidity();

        const newControls = (control as any)?.controls;
        if (newControls) {
          this.updateValueAndValidityOfControls(
            newControls.flatMap((c) => Object.values(c.controls))
          );
        }
      }
    } catch {
      // Ignored
    }
  }
}

type GetFunctionType<T extends Entity> = (id: string) => Observable<ApolloQueryResult<T>>;
type AddFunctionType<T extends Entity> = (entity: any) => Observable<ApolloQueryResult<T>> | Observable<MutationResult<T>>;
type UpdateFunctionType<T extends Entity> = (entity: any) => Observable<ApolloQueryResult<T>> | Observable<MutationResult<T>>;
type DeleteHardFunctionType = (id: string) => Observable<ApolloQueryResult<void>> | Observable<MutationResult<void>>;
type GetFormFunctionType = () => UntypedFormGroup;
