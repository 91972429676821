import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormArray, AbstractControl, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Contact } from '../../../models/entities/contact';
import { Subscription } from 'rxjs';

@Component({
  selector: 'laveo-inputs-contacts',
  templateUrl: './inputs-contacts.component.html',
  styleUrls: ['./inputs-contacts.component.scss']
})
export class InputsContactsComponent implements OnInit, OnDestroy {
  @Input() controlArray: UntypedFormArray;
  @Input() showNotificationsCheckbox = false;

  private subscriptions: Subscription[] = [];


  ngOnInit(): void {
    this.setForm();
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  add(): void {
    const form = new UntypedFormGroup({
      id: new UntypedFormControl(null, []),
      name: new UntypedFormControl(null, [Validators.required]),
      mail: new UntypedFormControl(null, [Validators.email]),
      phoneNumber: new UntypedFormControl(null, []),
      comment: new UntypedFormControl(null, []),
      sendMail: new UntypedFormControl(false, [])
    });

    this.controlArray.controls.push(form);
  }

  delete(control: AbstractControl): void {
    this.controlArray.removeAt(this.controlArray.controls.indexOf(control));
  }

  getAbstractAsFormControl(form: AbstractControl, controlName: string): UntypedFormControl {
    return form.get(controlName) as UntypedFormControl;
  }

  private setForm(): void {
    for (let index = 0; index < this.controlArray.controls.length; index++) {
      const contact: Contact = this.controlArray.controls[index].value;
      const form = new UntypedFormGroup({
        id: new UntypedFormControl(contact?.id, []),
        name: new UntypedFormControl(contact?.name, [Validators.required]),
        mail: new UntypedFormControl(contact?.mail, [Validators.email]),
        phoneNumber: new UntypedFormControl(contact?.phoneNumber, []),
        comment: new UntypedFormControl(contact?.comment, []),
        sendMail: new UntypedFormControl(contact?.sendMail, [])
      });

      if (form.get('mail')?.invalid) {
        form.get('sendMail')?.disable();
      }

      form.get('mail')?.valueChanges?.subscribe((value) => {
        if (value && form.get('mail')?.valid) {
          form.get('sendMail')?.enable();
        } else {
          form.get('sendMail')?.disable();
          form.get('sendMail')?.setValue(false);
        }
      });

      this.controlArray.controls[index] = form;
    }
  }
}
