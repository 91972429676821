<nz-card [nzBordered]="false" [nzTitle]="cardTitle">
  <ng-template #cardTitle>
    <laveo-card-header
      title="Gestion des paramètres"
      description="Gérez ici les paramètres généraux de Lavéo. Marques et modèles de véhicules, etc."
      [backButtonEnabled]="true"
      (backButtonClick)="goToAdmin()">
    </laveo-card-header>
  </ng-template>
  <div>
    <nz-tabset #tabset>
      <nz-tab [nzTitle]="brandTitle">
        <ng-template #brandTitle>
          <i nz-icon nzType="car"></i>
          Marques
        </ng-template>
        <ng-template nz-tab>
          <laveo-vehicle-brand-list></laveo-vehicle-brand-list>
        </ng-template>
      </nz-tab>

      <nz-tab [nzTitle]="modelTitle">
        <ng-template #modelTitle>
          <i nz-icon nzType="car"></i>
          Modèles
        </ng-template>
        <ng-template nz-tab>
          <laveo-vehicle-model-list></laveo-vehicle-model-list>
        </ng-template>
      </nz-tab>

      <nz-tab [nzTitle]="checkpointsTitle">
        <ng-template #checkpointsTitle>
          <i nz-icon nzType="pushpin"></i>
          Points de contrôles
        </ng-template>
        <ng-template nz-tab>
          <laveo-vehicle-checkpoint-list></laveo-vehicle-checkpoint-list>
        </ng-template>
      </nz-tab>

      <nz-tab [nzTitle]="rseTitle">
        <ng-template #rseTitle>
          <i nz-icon nzType="line-chart"></i>
          Bilan RSE
        </ng-template>
        <ng-template nz-tab>
          <laveo-rse-list></laveo-rse-list>
        </ng-template>
      </nz-tab>
    </nz-tabset>
  </div>
</nz-card>
