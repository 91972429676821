<nz-card [nzBordered]="false" [nzTitle]="cardTitle">
  <ng-template #cardTitle>
    <div class="header-wrapper">
      <laveo-card-header
        title="Prestations"
        [buttonTitle]="userCanAdd ? 'Ajouter une prestation' : undefined"
        [buttonIcon]="userCanAdd ? 'plus' : undefined"
        (buttonClick)="addService()">
      </laveo-card-header>
      <a nz-button nzType="primary" nzSize="large" [routerLink]="['/', 'prestations', 'calendrier']" queryParamsHandling="merge">
        <i nz-icon nzType="calendar"></i>
        Calendrier
      </a>
<!--      <a nz-button nzType="primary" nzSize="large" class="ml-8" target="_blank" [href]="bilanRseUrl" *ngIf="bilanRseUrl">-->
<!--        Voir le bilan RSE en PDF-->
<!--      </a>-->
    </div>
  </ng-template>

  <div class="mb-16">
      <laveo-filter-services
        (filtersChanged)="setFilters($event)"
        (checkedChanged)="reloadData()"
        [queryStringFilters]="queryStringFilters"
        [autofocus]="shouldAutofocusSearch"
        [checked]="checked">
      </laveo-filter-services>
  </div>

  <laveo-list-services *ngIf="filters" #listServices [filters]="filters" [checked]="checked" (pageChanged)="setPage($event)"></laveo-list-services>
</nz-card>
