<div class="content" nz-row nzAlign="middle">
  <div nz-col [nzXs]="24" [nzLg]="{ span: 12, offset: 6 }">
    <nz-card [nzBordered]="false">
      <div *ngIf="!this.isLoading else loadingTemplate">

          <nz-result
            nzStatus="success"
            nzTitle="Mail confirmé avec succès"
            nzSubTitle="Votre nouveau mail à bien été confirmé. Utilisez le bouton ci-dessous pour vous re-connecter.">
            <div nz-result-extra>
              <button nz-button [routerLink]="'/logout'">Se connecter</button>
            </div>
          </nz-result>
        </div>
    </nz-card>
  </div>
</div>
<ng-template #loadingTemplate>
  <nz-skeleton></nz-skeleton>
</ng-template>
