import { Pipe, PipeTransform } from '@angular/core';
import { VehicleFrequency } from '../models/entities/vehicle-frequency';

@Pipe({
  name: 'vehicleFrequencyName'
})
export class VehicleFrequencyNamePipe implements PipeTransform {
  transform(type?: VehicleFrequency): string | undefined {
    if (!type) {
      return;
    }

    switch (type) {
      case VehicleFrequency.on_demand: {
        return 'À la demande';
      }
      case VehicleFrequency.recurrent: {
        return 'Récurrent';
      }
      default: {
        return;
      }
    }
  }
}
