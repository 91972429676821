import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { endOfDay, endOfMonth, isSameDay, isSameMonth, startOfDay, startOfMonth } from 'date-fns';
import { NzMessageService } from 'ng-zorro-antd/message';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { debounceTime, expand, map, reduce } from 'rxjs/operators';
import { ServicesFiltersRequest } from 'src/app/shared/models/api/services/services-filters-requests.interface';
import { PagedRequest } from 'src/app/shared/models/api/shared/paged/paged-request';
import { Customer } from 'src/app/shared/models/entities/customer';
import { CustomerSite } from 'src/app/shared/models/entities/customer-site';
import { Preparer } from 'src/app/shared/models/entities/preparer';
import { Service, ServiceArray, ServiceStatus, ServiceType } from 'src/app/shared/models/entities/service';
import { Structure } from 'src/app/shared/models/entities/structure';
import { UserRoleType } from 'src/app/shared/models/entities/user-role';
import { UserService } from 'src/app/shared/services/api/user.service';
import { ApiErrorMessageUtil } from '../../../../shared/utils/api-error-message.util';
import { PagedResponse } from 'src/app/shared/models/api/shared/paged/paged-response.interface';
import { Apollo, gql } from 'apollo-angular';
import { TypeSerializerUtils } from 'src/app/shared/utils/type-serializer.util';
import { ApolloQueryResult } from '@apollo/client';

@Component({
  selector: 'laveo-services-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class ServicesCalendarComponent implements OnInit, OnDestroy {
  isLoading = true;
  error?: Error;
  currentDate = new Date();

  filters: FormGroup<{
    status: FormControl<ServiceStatus[]>,
    customers: FormControl<Customer[]>,
    structures: FormControl<Structure[]>,
    types: FormControl<ServiceType[]>,
    minDate: FormControl<Date>
    maxDate: FormControl<Date>
  }>;
  filterStatus: ServiceStatus[] = Object.values(ServiceStatus).filter(s => s !== ServiceStatus.created);
  filterTypes: ServiceType[] = Object.values(ServiceType);
  filterClientTypeEntity = [Customer, CustomerSite];
  filterStructureTypeEntity = [Preparer, Structure];
  userCanSeeStructureFilter = true;
  userCanSeeCustomerFilter = true;
  filterStructurePlaceholder = 'Structures et préparateurs';
  filterCientPlaceholder = 'Clients et sites client';

  private services: Service[] = [];
  private subscriptions: Subscription[] = [];
  private pagedRequest: PagedRequest<ServicesFiltersRequest> = {
    offset: 0,
    limit: 100,
    filters: {}
  };

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly message: NzMessageService,
    private readonly userService: UserService,
    private readonly titleService: Title,
    private readonly apollo: Apollo
  ) {}

  ngOnInit(): void {
    this.setTitle();
    this.setForm();
    this.loadRole();
    this.loadData();
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  loadData(): void {
    this.error = undefined;
    this.isLoading = true;
    this.services = [];

    const servicesSubscription = this.loadServicesPage().subscribe({
      next: services => {
        this.isLoading = false;
        this.services = services;
        servicesSubscription?.unsubscribe();
      },
      error: error => {
        this.isLoading = false;
        this.error = error;
        console.error(error);
        this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
        servicesSubscription?.unsubscribe();
      }
    })

    if (servicesSubscription) {
      this.subscriptions.push(servicesSubscription);
    }

    this.setQueryParams();
  }

  goToServicesList(): void {
    void this.router.navigate(['/', 'prestations'], { queryParamsHandling: 'merge' });
  }

  goToService(id: string): void {
    void this.router.navigate(['/', 'prestations', id]);
  }

  servicesForDate(date: Date): Service[] {
    const date1 = new Date(date);
    date1.setHours(0, 0, 0, 0);
    return this.services.filter(s => {
      const date2 = new Date(s.currentDate);
      date2.setHours(0, 0, 0, 0);
      return isSameDay(date1, date2);
    });
  }

  onDateChanged(date: Date): void {
    if (isSameMonth(this.currentDate, date)) {
      void this.router.navigate(['/', 'prestations'], {
        queryParams: {
          minDate: startOfDay(date).getTime(),
          maxDate: endOfDay(date).getTime()
        },
        queryParamsHandling: 'merge'
      });
    } else{
      this.currentDate = date;
      if (this.pagedRequest.filters) {
        this.pagedRequest.filters.minDate = startOfMonth(this.currentDate);
        this.pagedRequest.filters.maxDate = endOfMonth(this.currentDate);
      }
      this.loadData();
    }
  }

  private setForm(): void {
    const parameters = this.route.snapshot.queryParamMap;

    const status = parameters.get('status');
    const types = parameters.get('types');
    const minDate = parameters.get('minDate');

    let customers = parameters.get('customers')?.split(',')?.map(id => ({
      id,
      __typename: 'Customer'
    })) ?? [];
    customers = [...customers, ...(parameters.get('customerSites')?.split(',') ?? []).map(id => ({
      id,
      __typename: 'CustomerSite'
    }))];

    let structures = parameters.get('structures')?.split(',')?.map(id => ({
      id,
      __typename: 'Structure'
    })) ?? [];
    structures = [...structures, ...(parameters.get('preparers')?.split(',') ?? []).map(id => ({
      id,
      __typename: 'Preparer'
    }))];

    this.currentDate = minDate ? new Date(+minDate) : this.currentDate;

    this.filters = this.formBuilder.group({
      status: this.formBuilder.control(status ? (status.split(',') as ServiceStatus[]) : []),
      customers: this.formBuilder.control(customers),
      structures: this.formBuilder.control(structures),
      types: this.formBuilder.control(types ? (types.split(',') as ServiceType[]) : []),
      minDate: this.formBuilder.control(startOfMonth(this.currentDate)),
      maxDate: this.formBuilder.control(endOfMonth(this.currentDate))
    });

    this.pagedRequest.filters = this.getFiltersFromForm();

    const filterSubscription = this.filters.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this.pagedRequest = {
        offset: 0,
        limit: 100,
        filters: this.getFiltersFromForm()
      };
      this.loadData();
    });

    this.subscriptions.push(filterSubscription);
  }

  private getFiltersFromForm(): ServicesFiltersRequest {
    const filtersForm = this.filters.value;

    return {
      invoiced: null,
      types: filtersForm.types ?? [],
      status: filtersForm.status ?? [],
      customers: filtersForm.customers?.filter(c => c.__typename === 'Customer').map(c => c.id) ?? [],
      customerSites: filtersForm.customers?.filter(c => c.__typename === 'CustomerSite').map(c => c.id) ?? [],
      structures: filtersForm.structures?.filter(c => c.__typename === 'Structure').map(c => c.id) ?? [],
      preparers: filtersForm.structures?.filter(c => c.__typename === 'Preparer').map(c => c.id) ?? [],
      minDate: filtersForm.minDate ?? this.currentDate,
      maxDate: filtersForm.maxDate ?? this.currentDate
    };
  }

  private loadRole(): void {
    const roleSubscription = this.userService.currentRole.subscribe((role) => {
      if (role.type === UserRoleType.customer) {
        this.filterCientPlaceholder = 'Sites client';
        this.filterClientTypeEntity = [CustomerSite];
        this.userCanSeeStructureFilter = false;
      }

      if (role.type === UserRoleType.structure || role.type === UserRoleType.structureRead) {
        this.userCanSeeStructureFilter = false;
        this.filterStructurePlaceholder = 'Préparateurs';
        this.filterStructureTypeEntity = [Preparer];
      }

      if (
        role.type === UserRoleType.customerSite ||
        role.type === UserRoleType.customerSiteRead ||
        role.type === UserRoleType.preparer
      ) {
        this.userCanSeeCustomerFilter = false;
        this.userCanSeeStructureFilter = false;
      }

      if (role.type === UserRoleType.customerSite || role.type === UserRoleType.customerSiteRead) {
        const customerSite: CustomerSite = role.actor as CustomerSite;
        this.filterTypes = customerSite.type;
      }
    });
    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }
  }

  private setQueryParams(): void {
    let customerSites: string | undefined | null = null;
    if ((this.pagedRequest.filters?.customerSites?.length ?? 0) > 0) {
      customerSites = this.pagedRequest.filters?.customerSites?.join(',');
    }

    const queryParameters = {
      status: (this.pagedRequest.filters?.status?.length ?? 0) > 0 ? this.pagedRequest.filters?.status?.join(',') : null,
      types: (this.pagedRequest.filters?.types?.length ?? 0) > 0 ? this.pagedRequest.filters?.types?.join(',') : null,
      customers: (this.pagedRequest.filters?.customers?.length ?? 0) > 0 ? this.pagedRequest.filters?.customers?.join(',') : null,
      customerSites,
      structures: (this.pagedRequest.filters?.structures?.length ?? 0) > 0 ? this.pagedRequest.filters?.structures?.join(',') : null,
      preparers: (this.pagedRequest.filters?.preparers?.length ?? 0) > 0 ? this.pagedRequest.filters?.preparers?.join(',') : null,
      minDate: +startOfMonth(this.currentDate),
      maxDate: +endOfMonth(this.currentDate),
    };

    for (const [key, value] of Object.entries(queryParameters)) {
      if (value === null) {
        delete queryParameters[key];
      }
    }

    void this.router.navigate([], { queryParams: queryParameters });
  }

  private setTitle() {
    this.titleService.setTitle('Lavéo - Calendrier des prestations');
  }

  private loadServicesPage(): Observable<Service[]> {
    return this.loadServices().pipe(
      expand(result =>
        result.data.metadata.hasMore
          ? this.loadServices(result.data.metadata.currentPage + 1)
          : EMPTY
      ),
      reduce((accumulator: Service[], current) => [...accumulator, ...current.data.data], [])
    );
  }

  private loadServices(page = 1): Observable<ApolloQueryResult<PagedResponse<Service>>> {
    const query = gql`
      query allServicesForCalendar($offset: Int, $limit: Int, $filters: ServicesFiltersRequest, $sortProperty: String, $sortType: ESortType, $search: String) {
        vehicles {
          services(offset: $offset, limit: $limit, filters: $filters, sortProperty: $sortProperty, sortType: $sortType, search: $search) {
            data {
              id
              date
              status
              vehicle {
                id
                licensePlate
              }
              currentDate
            }
            metadata {
              currentPage
              hasMore
            }
          }
        }
      }
    `;

    let parameters = this.pagedRequest;
    if (page) {
      parameters = new PagedRequest<ServicesFiltersRequest>({
        limit: parameters.limit,
        page: page,
        filters: parameters.filters,
        sortProperty: parameters.sortProperty,
        sortType: parameters.sortType,
        search: parameters.search,
      });
    }

    console.log(`Loading Page ${parameters.page} for date`, parameters.filters?.minDate, parameters.filters?.maxDate);

    return this.apollo.query<{ vehicles: { services: PagedResponse<Service> } }>({
      query,
      variables: {
        offset: parameters.offset,
        limit: parameters.limit,
        filters: parameters.filters,
        sortProperty: parameters.sortProperty,
        sortType: parameters.sortType,
        search: parameters.search
      },
      fetchPolicy: 'network-only'
    }).pipe(map(result => {
      const raw = result.data?.vehicles?.services;
      const response: PagedResponse<Service> = {
        data: TypeSerializerUtils.deserializeArr(raw.data, ServiceArray),
        metadata: raw.metadata,
        extraMetadata: raw.extraMetadata
      };
      return {
        data: response,
        error: result.error,
        errors: result.errors,
        partial: result.partial,
        loading: result.loading,
        networkStatus: result.networkStatus
      };
    }));
  }
}
