import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subscription } from 'rxjs';
import { UserRightCategory } from 'src/app/shared/models/entities/user-right';
import { UserRole, UserRoleType } from 'src/app/shared/models/entities/user-role';
import { UserService } from 'src/app/shared/services/api/user.service';
import { ApiErrorMessageUtil } from '../../../shared/utils/api-error-message.util';

@Component({
  selector: 'laveo-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, OnDestroy {
  isLoading = true;
  rightsKeys: UserRightCategory[];
  isAdmin = false;
  currentRole?: UserRole;

  private userSubscription: Subscription;
  private hiddenCategories: string[] = [
    '__typename',
    UserRightCategory.services.toString(),
    UserRightCategory.vehicles.toString()
  ];

  constructor(
    private readonly userService: UserService,
    private readonly message: NzMessageService,
    private readonly router: Router,
    private readonly titleService: Title
  ) {}

  ngOnInit(): void {
    this.setTitle();
    this.loadRoles();
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
  }

  goToRight(right: UserRightCategory): void {
    if (right === UserRightCategory.customerSites) {
      void this.router.navigate(['/', 'admin', 'customer-sites']);
      return;
    }

    void this.router.navigate(['/', 'admin', right]);
  }

  private loadRoles(): void {
    this.userService.currentRole.subscribe({
      next: role => {
        this.currentRole = role;
        this.isAdmin = role.type === UserRoleType.admin;
        this.rightsKeys = Object.keys(role.rights).filter(r => !this.hiddenCategories.includes(r) && role.rights[r]?.length > 0).map(r => UserRightCategory[r]);
        this.isLoading = false;
      },
      error: error => {
        console.error(error);
        this.isLoading = false;
        this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
      }
    });
  }

  private setTitle(): void {
    this.titleService.setTitle('Lavéo - Administration');
  }
}
