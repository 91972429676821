import { Pipe, PipeTransform } from '@angular/core';
import { RseType } from '../models/entities/rse-type';

@Pipe({
  name: 'rseTypeName'
})
export class RseTypeNamePipe implements PipeTransform {
  transform(type?: RseType | RseType[] | string | string[]): string | undefined {
    if (!type) {
      return;
    }

    if (Array.isArray(type)) {
      if (type.length <= 0) {
        return;
      }
      return type.map(t => this.getTypeName(t)).join(', ');
    } else {
      return this.getTypeName(type);
    }
  }

  private getTypeName(type: RseType | string): string {
    switch (type) {
      case RseType.waterSaving:
      case 'WaterSaving': {
          return 'Economie d\'eau (litres)';
      }
      case RseType.insertion:
      case 'Insertion': {
        return 'Insertion (heures)';
      }
    }
    return type.toString();
  }
}
