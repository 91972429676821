import { Component, ViewChild, OnDestroy, AfterViewInit, OnInit } from '@angular/core';
import { Customer } from 'src/app/shared/models/entities/customer';
import { EntityWrapperComponent } from '../../../shared/entity-wrapper/entity-wrapper.component';
import { CustomerFormComponent } from './customer-form/customer-form.component';
import { UntypedFormGroup } from '@angular/forms';
import { CustomersService } from 'src/app/shared/services/api/customers.service';
import { Subscription } from 'rxjs';
import { UserRight } from 'src/app/shared/models/entities/user-right';
import { UserService } from 'src/app/shared/services/api/user.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'laveo-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(EntityWrapperComponent) entityWrapperComponent: EntityWrapperComponent;
  @ViewChild(CustomerFormComponent) customerFormComponent: CustomerFormComponent;

  isReady = false;
  customer: Customer;
  editEnabled = false;
  deleteEnabled = false;

  private subscriptions: Subscription[] = [];

  constructor(
    public readonly customersService: CustomersService,
    private readonly userService: UserService,
    private readonly titleService: Title,
  ) {}

  get title(): string {
    return this.customer?.name ?? 'Nouveau client';
  }

  readonly description = '';

  ngOnInit(): void {
    this.loadRole();
  }

  ngAfterViewInit(): void {
    const entitySubscription = this.entityWrapperComponent?.entity.subscribe((customer: Customer) => {
      if (customer !== undefined) {
        this.isReady = true;
      }
      this.customer = customer;
      this.setTitle();
    });

    if (entitySubscription) {
      this.subscriptions.push(entitySubscription);
    }
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  public form(): UntypedFormGroup {
    return this.customerFormComponent?.form;
  }

  private loadRole(): void {
    const roleSubscription = this.userService.currentRole.subscribe(role => {
      this.editEnabled = role.rights.customers.includes(UserRight.update);
      this.deleteEnabled = role.rights.customers.includes(UserRight.delete);
    });

    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }
  }

  private setTitle(): void {
    this.titleService.setTitle(`Lavéo - ${this.title}`);
  }
}
