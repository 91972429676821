<form nz-form [formGroup]="form" nzLayout="vertical">
  <div nz-row  [nzGutter]="[24, 0]">

    <div nz-col [nzXs]="24" [nzSm]="12">
      <laveo-select-vehicle-frequency [control]="form.get('frequency')" typeSize="large"></laveo-select-vehicle-frequency>
    </div>

    <div nz-col [nzXs]="24" [nzSm]="12">
      <laveo-select-vehicle-attribution [control]="form.get('attribution')" typeSize="large"></laveo-select-vehicle-attribution>
    </div>

    <div nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-label nzFor="group">Groupe</nz-form-label>
        <nz-form-control>
          <input formControlName="group" nz-input placeholder="Groupe" nzSize="large"/>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-label nzFor="comment">Commentaire</nz-form-label>
        <nz-form-control>
          <nz-textarea-count [nzMaxCharacterCount]="500">
            <textarea formControlName="comment" nz-input placeholder="Commentaire" nzSize="large" [nzAutosize]="{ minRows: 3, maxRows: 10 }"></textarea>
          </nz-textarea-count>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>
