import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { VehicleCategory } from '../../../models/entities/vehicle-category';

@Component({
  selector: 'laveo-select-vehicle-category',
  templateUrl: './select-vehicle-category.component.html',
  styleUrls: ['./select-vehicle-category.component.scss']
})
export class SelectVehicleCategoryComponent {
  @Input() control: AbstractControl | null;
  @Input() typeSelection: 'single' | 'multiple' = 'single';
  @Input() typeSize: 'default' | 'small' | 'large' = 'large';

  public get vehicleCategoryValues(): VehicleCategory[] {
    return Object.values(VehicleCategory);
  }

  public get formControl(): UntypedFormControl | undefined {
      return this.control as UntypedFormControl;
  }
}
