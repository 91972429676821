import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'booleanPipe'
})
export class BooleanPipe implements PipeTransform {
  transform(value: any, replaceValue: any = '-'): string {
    if (value === null) {
      return replaceValue;
    }
    return value ? 'Oui' : 'Non';
  }
}
