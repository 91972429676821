import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { CustomerSiteContractType } from '../../../models/entities/customer-site-contract-type';

@Component({
  selector: 'laveo-select-customer-site-contract-type',
  templateUrl: './select-customer-site-contract-type.component.html',
  styleUrls: ['./select-customer-site-contract-type.component.scss']
})
export class SelectCustomerSiteContractTypeComponent {
  @Input() control: AbstractControl | null;
  @Input() typeSize: 'default' | 'small' | 'large' = 'large';

  public get customerSiteContractTypeValues(): CustomerSiteContractType[] {
    return Object.values(CustomerSiteContractType);
  }

  public get formControl(): UntypedFormControl {
    return this.control as UntypedFormControl;
  }
}
