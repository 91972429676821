import { Component, Input } from '@angular/core';

@Component({
  selector: 'laveo-license-plate',
  templateUrl: './license-plate.component.html',
  styleUrls: ['./license-plate.component.scss']
})
export class LicensePlateComponent {
  @Input() licensePlate: string;
  @Input() country = 'FR';

}
