import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Vehicle } from '../../../../../shared/models/entities/vehicle';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { VehiclesService } from '../../../../../shared/services/api/vehicles.service';
import { VehicleFrequency } from '../../../../../shared/models/entities/vehicle-frequency';
import { Subscription } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ApiErrorMessageUtil } from '../../../../../shared/utils/api-error-message.util';
import { VehicleRequest } from 'src/app/shared/models/api/services/vehicle-requests.interface';

@Component({
  selector: 'laveo-admin-vehicle-modal-update',
  templateUrl: './admin-vehicle-modal-update.component.html',
  styleUrls: ['./admin-vehicle-modal-update.component.scss']
})
export class AdminVehicleModalUpdateComponent implements OnInit, OnDestroy {
  @Input() vehicles: Vehicle[];
  @Input() actionEmitter?: EventEmitter<void>;
  @Output() updating = new EventEmitter<{ loading: boolean; error?: Error }>();
  form: UntypedFormGroup;

  private frequencySubscription: Subscription;

  constructor(private vehicleService: VehiclesService, private message: NzMessageService) {}

  ngOnInit(): void {
    this.setForm();

    if (this.actionEmitter) {
      this.actionEmitter.subscribe(() => this.update());
    }
  }

  ngOnDestroy() {
    if (this.frequencySubscription) {
      this.frequencySubscription.unsubscribe();
    }
  }

  private setForm(): void {
    let vehicle: Vehicle | null = null;

    if (this.vehicles?.length) {
      vehicle = this.vehicles[0];
    }

    this.form = new UntypedFormGroup({
      frequency: new UntypedFormControl(vehicle?.frequency, [Validators.required]),
      attribution: new UntypedFormControl(vehicle?.attribution, []),
      group: new UntypedFormControl(vehicle?.group, []),
      comment: new UntypedFormControl(vehicle?.comment, [Validators.maxLength(500)]),
    });

    this.updateAttributionEnable();

    const frequency = this.form.get('frequency');
    if (frequency) {
      this.frequencySubscription = frequency.valueChanges.subscribe(() => {
        this.updateAttributionEnable();
      });
    }
  }

  private updateAttributionEnable(): void {
    const f = this.form.get('frequency')?.value;
    if (f === VehicleFrequency.recurrent) {
      this.form.get('attribution')?.enable();
    } else {
      this.form.get('attribution')?.disable();
      this.form.get('attribution')?.setValue(null);
    }
  }

  private update(): void {
    this.updating.emit({ loading: true });
    const ids = this.vehicles.map(vehicle => vehicle.id);
    const data: Partial<VehicleRequest> = {
      frequency: this.form.get('frequency')?.value,
      attribution: this.form.get('attribution')?.value,
      group: this.form.get('group')?.value,
      comment: this.form.get('comment')?.value
    };
    this.vehicleService.updateVehicles(ids, data).subscribe({
      next: () => {
        this.message.success('Les véhicules ont été modifiés');
        this.updating.emit({ loading: false });
      },
      error: error => {
        this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
        this.updating.emit({ loading: false, error });
      }
    });
  }
}
