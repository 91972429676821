<laveo-entity-wrapper
  [title]="title"
  [entityService]="vehicleBrandsService"
  [getFunction]="vehicleBrandsService.vehicleBrand"
  [addFunction]="vehicleBrandsService.addVehicleBrand"
  [updateFunction]="vehicleBrandsService.updateVehicleBrand"
  [wrappedComponent]="this"
  [getFormFunction]="form">
  <laveo-vehicle-brand-detail *ngIf="isReady" detail [vehicleBrand]="vehicleBrand"></laveo-vehicle-brand-detail>
  <laveo-vehicle-brand-form *ngIf="isReady" form [vehicleBrand]="vehicleBrand"></laveo-vehicle-brand-form>
</laveo-entity-wrapper>
