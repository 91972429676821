<form nz-form [formGroup]="form" nzLayout="vertical">
  <div nz-row [nzGutter]="[24, 0]">
    <div nz-col nzSpan="24">
      <div nz-row [nzGutter]="[24, 0]">
        <div nz-col nzFlex="0 0 auto" *ngIf="this.preparer">
          <nz-form-item>
            <nz-form-label nzFor="active">Actif</nz-form-label>
            <nz-form-control>
              <nz-radio-group formControlName="active" nzButtonStyle="solid" [nzSize]="'large'">
                <label nz-radio-button [nzValue]="true">Activé</label>
                <label nz-radio-button [nzValue]="false">Désactivé</label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzFlex="1">
          <nz-form-item>
            <nz-form-label nzFor="name">Nom</nz-form-label>
            <nz-form-control [nzErrorTip]="nameError">
              <input formControlName="name" nz-input [nzSize]="'large'" placeholder="Nom"/>
            </nz-form-control>
            <ng-template #nameError let-control>
              <ng-container *ngIf="control.hasError('required')">Le nom du préparateur est requis</ng-container>
            </ng-template>
          </nz-form-item>
        </div>
      </div>
    </div>
    <div nz-col nzSpan="24">
      <laveo-select-actor
        [control]="this.form.get('structure')"
        [typeEntity]="typeEntity"
        label="Structure"
        placeholder="Structure"
        typeSize="large">
      </laveo-select-actor>
    </div>

    <div nz-col [nzXs]="24">
      <nz-form-item>
        <nz-form-label nzFor="tel">Téléphone</nz-form-label>
        <nz-form-control>
          <input formControlName="tel" nz-input [nzSize]="'large'" placeholder="Téléphone"/>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzXs]="24">
      <nz-form-item>
        <nz-form-label nzFor="email">Email</nz-form-label>
        <nz-form-control [nzErrorTip]="emailError" [nzWarningTip]="emailWarning" [nzValidateStatus]="emailValidationStatus ? emailValidationStatus : 'validating'">
          <input formControlName="email" nz-input [nzSize]="'large'" placeholder="Email" />
        </nz-form-control>
        <ng-template #emailError let-control>
          <ng-container *ngIf="control.hasError('email')">L'email n'est pas valide</ng-container>
        </ng-template>
        <ng-template #emailWarning let-control>
          <ng-container *ngIf="emailErrors?.suggestion">
            <span>Vouliez-vous dire : </span>
            <span (click)="control.setValue(emailErrors?.suggestion.full)" [style.textDecoration]="'underline'" [style.cursor]="'pointer'">
              {{ emailErrors?.suggestion.domain }}
            </span>
            <span> ?</span>
          </ng-container>
        </ng-template>
      </nz-form-item>
    </div>
  </div>
</form>
