import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { ServiceStatus } from 'src/app/shared/models/entities/service';

@Component({
  selector: 'laveo-select-service-status',
  templateUrl: './select-service-status.component.html',
  styleUrls: ['./select-service-status.component.scss']
})
export class SelectServiceStatusComponent {
  @Input() control: AbstractControl | null;
  @Input() typeSize: 'default' | 'small' | 'large' = 'large';

  public get values(): ServiceStatus[] {
    return Object.values(ServiceStatus).filter(s => s !== ServiceStatus.created);
  }

  public get formControl(): UntypedFormControl {
    return this.control as UntypedFormControl;
  }
}
