import { CommonModule } from '@angular/common';
import { MainSharedModule } from '../shared/main-shared.module';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../../shared/shared.module';
import { ServiceComponent } from './service.component';
import { ServiceDetailComponent } from './service-detail/service-detail.component';
import { ServiceFormComponent } from './service-form/service-form.component';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { RouterModule } from '@angular/router';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { SelectActorModule } from 'src/app/shared/views/inputs/select-actor/select-actor.module';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { SelectVehicleModule } from 'src/app/shared/views/inputs/select-vehicle/select-vehicle.module';
import { SelectServiceFinishingModule } from 'src/app/shared/views/inputs/select-service-finishing/select-service-finishing.module';
import { SelectServiceStatusModule } from 'src/app/shared/views/inputs/select-service-status/select-service-status.module';
import { SelectServiceTypeModule } from 'src/app/shared/views/inputs/select-service-type/select-service-type.module';
import { DateFnsModule } from 'ngx-date-fns';
import { ServiceWorkflowComponent } from './service-detail/service-workflow/service-workflow.component';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { InputsContactsModule } from '../../../shared/views/inputs/inputs-contacts/inputs-contacts.module';
import { ListContactsModule } from '../../../shared/views/list-contacts/list-contacts.module';
import { SelectServiceCarpetStainRemovalModule } from '../../../shared/views/inputs/select-service-carpet-stain-removal/select-service-carpet-stain-removal.module';
import { SelectServiceUpholsteryStainRemovalModule } from 'src/app/shared/views/inputs/select-service-upholstery-stain-removal/select-service-upholstery-stain-removal.module';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { FilePondModule } from 'ngx-filepond';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { ImageButtonModule } from 'src/app/shared/views/image-button/image-button.module';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';

@NgModule({
  declarations: [
    ServiceComponent,
    ServiceDetailComponent,
    ServiceFormComponent,
    ServiceWorkflowComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MainSharedModule,

    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    NzGridModule,
    NzFormModule,
    NzInputModule,
    NzCheckboxModule,
    NzIconModule,
    NzButtonModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzEmptyModule,
    NzTypographyModule,
    NzRadioModule,
    NzSelectModule,
    NzTagModule,
    NzToolTipModule,

    SelectActorModule,
    SelectVehicleModule,
    SelectServiceTypeModule,
    SelectServiceFinishingModule,
    SelectServiceCarpetStainRemovalModule,
    SelectServiceUpholsteryStainRemovalModule,
    SelectServiceStatusModule,
    InputsContactsModule,
    ListContactsModule,
    ImageButtonModule,

    DateFnsModule.forRoot(),
    FilePondModule
  ],
  exports: [
    ServiceWorkflowComponent
  ]
})
export class ServiceModule {}
