import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class TimingInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const started = Date.now();
    return next.handle(request).pipe(tap((event) => {
      if (!environment.production && event instanceof HttpResponse) {
        const elapsed = Date.now() - started;
        if (request.body?.operationName) {
          console.log(`La requete graphql pour '${request.body.operationName}' à pris ${elapsed} ms.`);
        } else {
          console.log(`La requete pour '${request.urlWithParams}' à pris ${elapsed} ms.`);
        }
      }
    }));
  }
}
