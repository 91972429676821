import { Component, Input, TemplateRef } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'laveo-input-license-plate',
  templateUrl: './input-license-plate.component.html',
  styleUrls: ['./input-license-plate.component.scss']
})
export class InputLicensePlateComponent {
  @Input() control: AbstractControl | null;
  @Input() typeSize: 'default' | 'small' | 'large' = 'large';
  @Input() warning: TemplateRef<any>;
  @Input() hasWarning = false;

  get validationStatus(): string | UntypedFormControl | null {
    if (this.hasWarning) {
      return 'warning';
    }

    return this.control as UntypedFormControl;
  }

  get formControl(): UntypedFormControl {
    return this.control as UntypedFormControl;
  }

  filterCharsFromLicencePlate(event: KeyboardEvent): boolean {
    if (/[\dA-Za-z-]/.test(event.key)) {
      return true;
    }
    event.preventDefault();
    return false;
  }
}
