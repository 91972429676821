import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Preparer } from 'src/app/shared/models/entities/preparer';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Actor } from 'src/app/shared/models/entities/actor';
import { Structure } from 'src/app/shared/models/entities/structure';
import { EmailValidators } from 'ngx-validators';
import { emailOptionsConfig } from 'src/app/configs/validators.config';
import { Subscription } from 'rxjs';

@Component({
  selector: 'laveo-preparer-form',
  templateUrl: './preparer-form.component.html',
  styleUrls: ['./preparer-form.component.scss']
})
export class PreparerFormComponent implements OnInit, OnDestroy {
  @Input() preparer: Preparer;
  form: UntypedFormGroup;
  typeEntity: (typeof Actor)[] = [Structure];
  emailErrors: ValidationErrors | null;

  private subscriptions: Subscription[] = [];

  constructor(private readonly formBuilder: UntypedFormBuilder) {}

  get emailValidationStatus(): string | AbstractControl | null {
    if (this.emailErrors?.suggestion) {
      return 'warning';
    }

    return this.form.get('email');
  }

  ngOnInit(): void {
    this.setForm();
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  private setForm(): void {
    this.form = this.formBuilder.group({
      id: this.formBuilder.control(this.preparer?.id, []),
      active: this.formBuilder.control(this.preparer?.active ?? true, []),
      name: this.formBuilder.control(this.preparer?.name, [Validators.required]),
      structure: this.formBuilder.control(this.preparer?.structure, [Validators.required]),
      tel: this.formBuilder.control(this.preparer?.contacts?.length > 0 ? this.preparer?.contacts[0].phoneNumber : null, []),
      email: this.formBuilder.control(this.preparer?.contacts?.length > 0 ? this.preparer?.contacts[0].mail : null, [Validators.email]),
      contactId: this.formBuilder.control(this.preparer?.contacts?.length > 0 ? this.preparer?.contacts[0].id : null, []),
    });

    const emailSubscription = this.form.get('email')?.valueChanges.subscribe(() => {
      const validate = EmailValidators.suggest(emailOptionsConfig);
      const mail = this.form.get('email');
      if (mail) {
        this.emailErrors = validate(mail);
      }
    });

    if (emailSubscription) {
      this.subscriptions.push(emailSubscription);
    }
  }
}
