import { Customer } from '../../entities/customer';
import { CustomerSite } from '../../entities/customer-site';
import { Structure } from '../../entities/structure';
import { Type } from 'typeserializer';

export class ReportingVehicle {
  @Type(Customer)
  customer?: Customer;
  @Type(CustomerSite)
  customerSite?: CustomerSite;
  @Type(Structure)
  structure?: Structure;
  actor: Customer | CustomerSite | Structure;
  nb: number;
  nbActive: number;
  nbCatA: number;
  nbCatB: number;
  nbCatC: number;
  nbCatD: number;
  nbCatVU: number;
  nbCatPorteur: number;
  nbCatPL: number;
  nbCatHG: number;
  nbCatMoto: number;
  nbOnDemand: number;
  nbMutualized: number;
  nbAffected: number;

  // total: number;
  // active: number;
  // vehicleCategories: { [key in VehicleCategory]: number };
  // vehicleFrequencies: { [key in VehicleFrequency]: number };
  // vehicleAttributions: { [key in VehicleAttribution]: number };
}
