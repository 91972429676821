"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const consts_1 = require("../consts");
const Decorator_1 = require("./Decorator");
function After(version) {
  return Decorator_1.createDecorator("After", consts_1.AfterSymbol, version);
}
exports.After = After;
