<laveo-entity-wrapper
  [title]="title"
  [description]="description"
  [entityService]="preparersService"
  [getFunction]="preparersService.preparer"
  [addFunction]="preparersService.addPreparer"
  [updateFunction]="preparersService.updatePreparer"
  [deleteHardFunction]="preparersService.deleteOnePreparerHard"
  [wrappedComponent]="this"
  [getFormFunction]="form"
  [editEnabled]="editEnabled"
  [deleteEnabled]="deleteEnabled">
  <laveo-preparer-detail *ngIf="isReady" detail [preparer]="preparer"></laveo-preparer-detail>
  <laveo-preparer-form *ngIf="isReady" form [preparer]="preparer"></laveo-preparer-form>
</laveo-entity-wrapper>
