<div class="detail mb-8">
  <span class="detail-title" nz-typography>Rôles</span>
</div>

<nz-table [nzShowPagination]="false" [nzTemplateMode]="true">
  <thead>
    <tr>
      <th class="roles">Rôle</th>
      <th class="entities">Entité</th>
      <th class="actions">
        <button nz-button nzType="primary" (click)="add()">
          <i nz-icon nzType="plus"></i>
        </button>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let control of controlArray.controls">
      <td class="roles" [class.disabled]="isEntityAdmin(control.get('entity')?.value)">
        <laveo-select-role-type
          [control]="control.get('role')"
          [showLabel]="false"
          [typeSize]="'default'"
          [disabled]="isEntityAdmin(control.get('entity')?.value)">
        </laveo-select-role-type>
      </td>
      <td class="entities">
        <laveo-select-actor
          [label]="'Entité'"
          [placeholder]="'Entité'"
          [control]="control.get('entity')"
          [typeEntity]="getTypeEntity(control.get('role')?.value)"
          [showLabel]="false"
          [typeSize]="'default'"
          [disabledList]="disabledList"
          [resetList]="resetList"
          [disabled]="isEntityAdmin(control.get('entity')?.value)"
          >
        </laveo-select-actor>
      </td>
      <td class="actions action">
        <button nz-button nzType="primary" (click)="delete(control)" [disabled]="isEntityAdmin(control.get('entity')?.value)">
          <i nz-icon nzType="delete"></i>
        </button>
      </td>
    </tr>
  </tbody>
</nz-table>
<nz-empty
  *ngIf="controlArray.controls.length === 0"
  nzNotFoundImage="simple"
  nzNotFoundContent="Aucun role"
  [nzNotFoundFooter]="emptyFooter">
  <ng-template #emptyFooter>
    <button nz-button nzType="primary" (click)="add()">
      <i nz-icon nzType="plus"></i>
      Ajouter un role
    </button>
  </ng-template>
</nz-empty>
