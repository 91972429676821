import { Pipe, PipeTransform } from '@angular/core';
import { CustomerSiteWorkflowType } from '../models/entities/customer-site-workflow-type';

@Pipe({
  name: 'customerSiteWorkflowTypeName'
})
export class CustomerSiteWorkflowTypeNamePipe implements PipeTransform {
  transform(type?: CustomerSiteWorkflowType): string | undefined {
    if (!type) {
      return;
    }

    switch (type) {
      case CustomerSiteWorkflowType.light: {
        return 'Allégé';
      }
      case CustomerSiteWorkflowType.normal: {
        return 'Normal';
      }
      default: {
        return;
      }
    }
  }
}
