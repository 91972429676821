import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PagedRequest } from '../../models/api/shared/paged/paged-request';
import { PagedResponse } from '../../models/api/shared/paged/paged-response.interface';
import { Actor } from '../../models/entities/actor';
import { Customer, CustomerArray } from '../../models/entities/customer';
import { CustomerSite, CustomerSiteArray } from '../../models/entities/customer-site';
import { Preparer, PreparerArray } from '../../models/entities/preparer';
import { Structure, StructureArray } from '../../models/entities/structure';
import { Admin, AdminArray } from '../../models/entities/admin';
import { TypeSerializerUtils } from '../../utils/type-serializer.util';

@Injectable({
  providedIn: 'root'
})
export class ActorsService {
  constructor(
    private readonly apollo: Apollo
  ) {}

  allActors(parameters: PagedRequest, types: (typeof Actor)[]): Observable<ApolloQueryResult<PagedResponse<Actor>>> {
    const queryAdmin = `
      admins(offset: $offset, limit: $limit, sortProperty: $sortProperty, sortType: $sortType, search: $search) {
        data {
          id
          active
          name
        }
      }
    `;

    const queryCustomer = `
      customers(offset: $offset, limit: $limit, sortProperty: $sortProperty, sortType: $sortType, search: $search) {
        data {
          id
          active
          name
          address
          city
          postalCode
          country
          customerSites {
            metadata {
              totalResults
            }
          }
        }
      }
    `;

    const queryCustomerSite = `
      customerSites(offset: $offset, limit: $limit, sortProperty: $sortProperty, sortType: $sortType, search: $search) {
        data {
          id
          active
          name
          address
          city
          postalCode
          country
          customer {
            id
            name
          }
          structure {
            id
            name
          }
          type
          finishing
          firstIntervention
          vehicleVeryDirty
          upholsteryStainRemoval
          carpetStainRemoval
          conveying
          sticking
          desticking
          contactZoneDisinfection
          interiorDisinfection
          workflowType
          vehicleCheckpoints {
            id
            category
          }
        }
      }
    `;

    const queryPreparer = `
      preparers(offset: $offset, limit: $limit, sortProperty: $sortProperty, sortType: $sortType, search: $search) {
        data {
          id
          active
          name
          structure {
            id
            name
          }
        }
      }
    `;

    const queryStructure = `
      structures(offset: $offset, limit: $limit, sortProperty: $sortProperty, sortType: $sortType, search: $search) {
        data {
          id
          active
          name
          name2
          address
          city
          postalCode
          country
          preparers {
            metadata {
              totalResults
            }
          }
          customerSites {
            metadata {
              totalResults
            }
          }
        }
      }
    `;

    const queryString = `
      query actors($offset: Int, $limit: Int, $sortProperty: String, $sortType: ESortType, $search: String) {
        actors {
          ` + (types.includes(Admin) ? queryAdmin : ``) + `
          ` + (types.includes(Customer) ? queryCustomer : ``) + `
          ` + (types.includes(CustomerSite) ? queryCustomerSite : ``) + `
          ` + (types.includes(Preparer) ? queryPreparer : ``) + `
          ` + (types.includes(Structure) ? queryStructure : ``) + `
        }
      }
    `;

    const query = gql(queryString);

    return this.apollo.query<{ actors: {
      admins: PagedResponse<Admin>;
      customers: PagedResponse<Customer>;
      customerSites: PagedResponse<CustomerSite>;
      preparers: PagedResponse<Preparer>;
      structures: PagedResponse<Structure>;
    }; }>({
      query,
      variables: {
        offset: parameters.offset,
        limit: parameters.limit,
        sortProperty: parameters.sortProperty,
        sortType: parameters.sortType,
        search: parameters.search
      }
    }).pipe(map(result => {
      let newData: Actor[] = [];

      if (result.data.actors.admins) {
        newData = [...newData, ...TypeSerializerUtils.deserializeArr(result.data.actors.admins.data, AdminArray)];
      }

      if (result.data.actors.customers) {
        newData = [...newData, ...TypeSerializerUtils.deserializeArr(result.data.actors.customers.data, CustomerArray)];
      }

      if (result.data.actors.customerSites) {
        newData = [...newData, ...TypeSerializerUtils.deserializeArr(result.data.actors.customerSites.data, CustomerSiteArray)];
      }

      if (result.data.actors.preparers) {
        newData = [...newData, ...TypeSerializerUtils.deserializeArr(result.data.actors.preparers.data, PreparerArray)];
      }

      if (result.data.actors.structures) {
        newData = [...newData, ...TypeSerializerUtils.deserializeArr(result.data.actors.structures.data, StructureArray)];
      }

      const response: PagedResponse<Actor> = {
        data: newData,
        metadata: {
          currentPage: 1,
          totalPages: 1,
          currentResult: newData.length,
          hasMore: false,
          totalResults: newData.length
        }
      };
      return {
        data: response,
        error: result.error,
        errors: result.errors,
        partial: result.partial,
        loading: result.loading,
        networkStatus: result.networkStatus
      };
    }));
  }

  actorsByIds(ids: string[]): Observable<ApolloQueryResult<Actor[]>> {
    let queryString = `
      query actorsByIds {
        actors {
    `;

    ids.forEach((id, index) => {
      queryString += `
        actor${index}: actor (id: "${id}") {
          __typename
          ... on Admin {
            id
            name
          }
          ... on Structure {
            id
            name
          }
          ... on Customer {
            id
            name
          }
          ... on CustomerSite {
            id
            name
          }
          ... on Preparer {
            id
            name
          }
        }
      `;
    });

    queryString += `
        }
      }
    `;

    const query = gql(queryString);

    return this.apollo.query<{ actors: Record<string, Actor> }>({ query }).pipe(map(result => {
      const data: Actor[] = [];

      Object.keys(result.data.actors).forEach(key => {
        const actor = result.data.actors[key];
        switch (actor?.__typename) {
          case 'Admin': {
            data.push(TypeSerializerUtils.deserialize(actor, Admin));
            break;
          }
          case 'Structure': {
            data.push(TypeSerializerUtils.deserialize(actor, Structure));
            break;
          }
          case 'Customer': {
            data.push(TypeSerializerUtils.deserialize(actor, Customer));
            break;
          }
          case 'CustomerSite': {
            data.push(TypeSerializerUtils.deserialize(actor, CustomerSite));
            break;
          }
          case 'Preparer': {
            data.push(TypeSerializerUtils.deserialize(actor, Preparer));
            break;
          }
        }
      });

      return {
        data,
        error: result.error,
        errors: result.errors,
        partial: result.partial,
        loading: result.loading,
        networkStatus: result.networkStatus
      };
    }));
  }
}
