import { Type } from 'typeserializer';
import { Entity } from './entity';
import { VehicleBrand } from './vehicle-brand';
import { VehicleCategory } from './vehicle-category';

export class VehicleModel extends Entity {
  @Type(VehicleBrand)
  brand: VehicleBrand;
  name: string;
  active: boolean;
  category: VehicleCategory;
}

export class VehicleBrandWithModels extends VehicleBrand {
  @Type([VehicleModel])
  models: VehicleModel[];
}

export class VehicleBrandWithModelsArray {
  @Type([VehicleBrand])
  children: VehicleBrand[];
}

export class VehicleModelArray {
  @Type([VehicleModel])
  children: VehicleModel[];
}
