<laveo-entity-wrapper
  [title]="title"
  [description]="description"
  [entityService]="customerSitesService"
  [getFunction]="customerSitesService.customerSite"
  [addFunction]="customerSitesService.addCustomerSite"
  [updateFunction]="customerSitesService.updateCustomerSite"
  [deleteHardFunction]="customerSitesService.deleteOneCustomerSiteHard"
  [wrappedComponent]="this"
  [getFormFunction]="form"
  [editEnabled]="editEnabled"
  [deleteEnabled]="deleteEnabled">
  <laveo-customer-site-detail *ngIf="isReady" detail [customerSite]="customerSite"></laveo-customer-site-detail>
  <laveo-customer-site-form *ngIf="isReady" form [customerSite]="customerSite"></laveo-customer-site-form>
</laveo-entity-wrapper>
