<laveo-entity-wrapper
  #wrapper
  [title]="title"
  [description]="description"
  [entityService]="servicesService"
  [getFunction]="servicesService.service"
  [addFunction]="servicesService.addService"
  [updateFunction]="servicesService.updateService"
  [wrappedComponent]="this"
  [getFormFunction]="form"
  [editEnabled]="editEnabled"
  [deleteEnabled]="deleteEnabled">
  <laveo-service-detail *ngIf="isReady" detail [service]="service" [wrapper]="wrapper"></laveo-service-detail>
  <laveo-service-form *ngIf="isReady" form [service]="service"></laveo-service-form>
</laveo-entity-wrapper>
