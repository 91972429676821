<nz-form-item>
  <nz-form-label nzFor="category">Détachage moquette</nz-form-label>
  <nz-form-control [nzErrorTip]="error">
    <nz-select
      [formControl]="formControl"
      [nzMode]="typeSelection === 'single' ? 'default' : 'multiple'"
      nzPlaceHolder="Détachage moquette"
      nzAllowClear
      [nzSize]="typeSize"
      [nzShowArrow]="true"
      class="w-100-p">
      <ng-container *ngFor="let value of values">
        <nz-option [nzValue]="value" [nzLabel]="value | serviceCarpetStainRemovalName | nullCoal"></nz-option>
      </ng-container>
    </nz-select>
  </nz-form-control>
  <ng-template #error let-control>
    <ng-container *ngIf="control?.hasError('required')">
      Le détachage moquette est requise
    </ng-container>
  </ng-template>
</nz-form-item>
