import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { CustomerSiteWorkflowType } from '../../../models/entities/customer-site-workflow-type';

@Component({
  selector: 'laveo-select-customer-site-workflow-type',
  templateUrl: './select-customer-site-workflow-type.component.html',
  styleUrls: ['./select-customer-site-workflow-type.component.scss']
})
export class SelectCustomerSiteWorkflowTypeComponent {
  @Input() control: AbstractControl | null;
  @Input() typeSize: 'default' | 'small' | 'large' = 'large';

  public get customerSiteWorkflowTypeValues(): CustomerSiteWorkflowType[] {
    return Object.values(CustomerSiteWorkflowType);
  }

  public get formControl(): UntypedFormControl {
    return this.control as UntypedFormControl;
  }
}
