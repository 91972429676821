import { Type } from 'typeserializer';
import { Actor } from './actor';
import { Structure } from './structure';
import { Contact } from './contact';

export class Preparer extends Actor {
  @Type([Contact])
  contacts: Contact[];
  // @Type(Structure)
  structure: Structure;
}

export class PreparerArray {
  @Type([Preparer])
  children: Preparer[];
}
