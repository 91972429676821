<div nz-row [nzGutter]="[48, 24]">
  <div nz-col nzSpan="24">
    <div class="detail">
      <span class="detail-title" nz-typography nzType="secondary">Nom</span>
      <span class="detail-content" nz-typography>{{ user?.name | nullCoal }}</span>
    </div>
  </div>

  <div nz-col nzSpan="24">
    <div class="detail">
      <span class="detail-title" nz-typography nzType="secondary">Mail</span>
      <span class="detail-content" nz-typography>
        {{ (user?.mailValidation ? user?.mailValidation : user?.mail) | nullCoal }}
        <i nz-icon [nzType]="user?.mailValidation ? 'clock-circle' : 'check'" nz-tooltip [nzTooltipTitle]="user?.mailValidation ? 'En attente de validation' : 'Validé'"></i>
      </span>
    </div>
  </div>

  <div nz-col nzSpan="24">
    <div class="detail">
      <span class="detail-title" nz-typography nzType="secondary">Mot de passe</span>
      <span class="detail-content" nz-typography>•••••••••••••</span>
    </div>
  </div>

  <div nz-col nzSpan="24" *ngIf="userIsAdmin">
    <div class="detail">
      <span class="detail-title" nz-typography nzType="secondary">Mes rôles</span>
      <ng-container *ngIf="user?.roles?.length === 0; else roles">
        <span class="detail-content" nz-typography>Aucun rôle associé</span>
      </ng-container>
      <ng-template #roles>
        <ul>
          <li class="detail-content" nz-typography *ngFor="let role of user?.roles">
            {{ role.actor.name }} ( {{ role.type | roleName }} <i nz-icon nzType="crown" nzTheme="outline" *ngIf="role.type === 'SUPER_ADMIN'"></i>)
          </li>
        </ul>
      </ng-template>
    </div>
  </div>
</div>
