import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'laveo-card-header',
  templateUrl: './card-header.component.html',
  styleUrls: ['./card-header.component.scss']
})
export class CardHeaderComponent {
  @Input() title: string;
  @Input() description?: string;
  @Input() role?: string;
  @Input() buttonTitle?: string;
  @Input() buttonIcon?: string;
  @Input() buttonEnabled = true;
  @Input() backButtonEnabled: boolean;
  @Input() isLoading = false;
  @Input() isTitleSkeleton = false;
  @Input() isDescriptionSkeleton = false;

  @Output() buttonClick = new EventEmitter<void>();
  @Output() backButtonClick = new EventEmitter<void>();

}
