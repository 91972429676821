import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzTableModule } from 'ng-zorro-antd/table';
import { MainSharedModule } from '../../shared/main-shared.module';
import { AdminCustomerSitesComponent } from './admin-customer-sites.component';
import { CustomerSiteDetailComponent } from './customer-site/customer-site-detail/customer-site-detail.component';
import { CustomerSiteFormComponent } from './customer-site/customer-site-form/customer-site-form.component';
import { CustomerSiteComponent } from './customer-site/customer-site.component';
import { SharedModule } from '../../../../shared/shared.module';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { SelectCustomerSiteBillingPersonModule } from '../../../../shared/views/inputs/select-customer-site-billing-person/select-customer-site-billing-person.module';
import { SelectCustomerSiteWorkflowTypeModule } from '../../../../shared/views/inputs/select-customer-site-workflow-type/select-customer-site-workflow-type.module';
import { SelectCustomerSiteContractTypeModule } from '../../../../shared/views/inputs/select-customer-site-contract-type/select-customer-site-contract-type.module';
import { SelectActorModule } from '../../../../shared/views/inputs/select-actor/select-actor.module';
import { InputsContactsModule } from '../../../../shared/views/inputs/inputs-contacts/inputs-contacts.module';
import { ListContactsModule } from '../../../../shared/views/list-contacts/list-contacts.module';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { FilePondModule } from 'ngx-filepond';

@NgModule({
  declarations: [
    AdminCustomerSitesComponent,
    CustomerSiteComponent,
    CustomerSiteFormComponent,
    CustomerSiteDetailComponent
  ],
  imports: [
    CommonModule,
    MainSharedModule,
    SharedModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzButtonModule,
    NzCheckboxModule,
    NzIconModule,
    NzCardModule,
    NzFormModule,
    NzTableModule,
    NzInputModule,
    NzResultModule,
    NzEmptyModule,
    NzRadioModule,
    NzTreeModule,
    NzDropDownModule,
    SelectActorModule,
    SelectCustomerSiteBillingPersonModule,
    SelectCustomerSiteContractTypeModule,
    SelectCustomerSiteWorkflowTypeModule,
    InputsContactsModule,
    ListContactsModule,
    NgxGpAutocompleteModule,
    FilePondModule
  ],
  exports: [
    AdminCustomerSitesComponent
  ]
})
export class AdminCustomerSitesModule {}
