import { Component, OnInit, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { NzTableQueryParams, NzTableSortOrder } from 'ng-zorro-antd/table';
import { Title } from '@angular/platform-browser';
import { UserService } from '../../../../shared/services/api/user.service';
import { VehicleCategory } from '../../../../shared/models/entities/vehicle-category';
import { VehicleFrequency } from '../../../../shared/models/entities/vehicle-frequency';
import { VehicleAttribution } from '../../../../shared/models/entities/vehicle-attribution';
import { UserRoleType } from '../../../../shared/models/entities/user-role';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ReportingVehicle } from '../../../../shared/models/api/reportings/reporting-vehicle';
import { PagedResponse } from '../../../../shared/models/api/shared/paged/paged-response.interface';
import { ReportingsService } from '../../../../shared/services/api/reportings.service';
import { ReportingVehicleFiltersRequest } from '../../../../shared/models/api/services/reporting-vehicle-filters-requests.interface';
import { PagedRequest } from '../../../../shared/models/api/shared/paged/paged-request';
import { Utils } from '../../../../shared/utils/utils';
import { ApiErrorMessageUtil } from '../../../../shared/utils/api-error-message.util';
import { NzMessageService } from 'ng-zorro-antd/message';
import { take } from 'rxjs/operators';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'laveo-reporting-vehicle',
  templateUrl: './reporting-vehicle.component.html',
  styleUrls: ['./reporting-vehicle.component.scss']
})
export class ReportingVehicleComponent implements OnInit, OnDestroy {
  @ViewChild('exportForm') private exportFormContent: TemplateRef<any>;

  isLoading = true;
  actionLoading = false;
  currentPage = 1;
  limit = 10;

  form: UntypedFormGroup;
  exportForm: UntypedFormGroup;
  userCanChooseSelectedRole = false;
  selectedRole: UserRoleType.customer | UserRoleType.customerSite | UserRoleType.structure = UserRoleType.customer;
  UserRoleType = UserRoleType;

  vehicleCategories = Object.values(VehicleCategory);
  vehicleFrequency = VehicleFrequency;
  vehicleAttributions = Object.values(VehicleAttribution);

  error?: Error;

  data?: PagedResponse<ReportingVehicle>;

  private sort: { key: string; value: NzTableSortOrder }[] = [{ key: 'customer', value: 'ascend' }];
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly reportingsService: ReportingsService,
    private readonly userService: UserService,
    private readonly titleService: Title,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly message: NzMessageService,
    private readonly modal: NzModalService
  ) {}

  get description(): string | undefined {
    const total = this.data?.extraMetadata?.total?.nb;
    if (total) {
      return `Nombre total de véhicules : ${total}`;
    }
    return 'Chargement…';
  }

  ngOnInit(): void {
    this.setTitle();
    this.setForm();
    this.loadRole();
    this.loadData();
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  loadData(): void {
    let sortProperty: string | undefined;
    let sortType: 'ASC' | 'DESC' | undefined;

    const currentSort =  this.sort.find(s => s.value);
    if (currentSort) {
      sortProperty = currentSort.key;
      sortType = currentSort.value === 'ascend' ? 'ASC' : 'DESC';
    }

    const parameters = new PagedRequest<ReportingVehicleFiltersRequest>({
      page: this.currentPage,
      limit: this.limit,
      sortProperty,
      sortType,
      filters: {
        type: this.selectedRole
      },
    });

    this.isLoading = true;
    this.error = undefined;

    const reportingsSubscription = this.reportingsService.reportingVehicles(parameters).subscribe({
      next: data => {
        this.data = data.data;
        this.isLoading = data.loading;
      },
      error: error => {
        this.isLoading = false;
        console.error(error);
        this.error = error;
      }
    });

    this.subscriptions.push(reportingsSubscription);
  }

  setPage(event: NzTableQueryParams): void {
    const indexSame = !event.pageIndex || this.currentPage === event.pageIndex;
    const limitSame = this.limit === event.pageSize;
    let sortSame = true;
    for (const sortObject of event.sort) {
      const originalSort = this.sort.find(sortElement => sortElement.key === sortObject.key);
      if (originalSort?.value !== sortObject.value) {
        sortSame = false;
        break;
      }
    }

    if (indexSame && limitSame && sortSame) {
      return;
    }

    this.currentPage = event.pageIndex;
    this.limit = event.pageSize;
    this.sort = event.sort;
    this.loadData();
  }

  back(): void {
    window.history.back();
  }

  export(): void {
    this.exportForm = this.formBuilder.group({
      role: this.formBuilder.control(this.form.get('role')?.value, [Validators.required]),
      page: this.formBuilder.control('all', [Validators.required]),
      filename: this.formBuilder.control('')
    });

    const roleSubscription = this.exportForm.get('role')?.valueChanges.subscribe(role => {
      this.form.get('role')?.setValue(role);
      this.selectedRole = role;
      this.loadData();
    });

    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }

    this.modal.create({
      nzTitle: 'Exporter en .xlsx',
      nzContent: this.exportFormContent,
      nzOkText: 'Exporter',
      nzCancelText: 'Annuler',
      nzData: {
        exportForm: this.exportForm
      },
      nzOkDisabled: !this.exportForm.valid,
      nzOnOk: () => {
        let filename: string = this.exportForm.get('filename')?.value ?? 'export';
        filename = filename.trim() === '' ? 'export' : filename.trim();
        filename = filename.toLowerCase().replaceAll(' ', '-');

        const onlyCurrentPage = (this.exportForm.get('page')?.value ?? 'current') === 'current';

        const pagedRequest = new PagedRequest<ReportingVehicleFiltersRequest>({
          page: onlyCurrentPage ? (this.currentPage ? +this.currentPage : 1) : undefined,
          limit: onlyCurrentPage ? 10 : undefined,
          filters: {
            type: this.exportForm.get('role')?.value
          },
          sortType: this.exportForm.get('sort')?.value
        });

        this.reportingsService.reportingVehiclesExcel(pagedRequest).pipe(take(1)).subscribe({
          next: (url => {
            if (url.data) {
              Utils.download(url.data, filename);
            } else {
              this.message.error('Une erreur s\'est produite durant l\'export. Réessayez plus tard');
            }
          }),
          error: (error => {
            console.error(error);
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
          })
        });
      }
    });
  }

  private setForm(): void {
    this.form = this.formBuilder.group({
      role: this.formBuilder.control(this.selectedRole)
    });

    const roleSubscription = this.form.get('role')?.valueChanges.subscribe(role => {
      this.selectedRole = role;
      this.currentPage = 1;
      this.loadData();
    });

    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }
  }

  private loadRole(): void {
    const roleSubscription = this.userService.currentRole.subscribe(role => {
      if (role.type === UserRoleType.admin) {
        this.userCanChooseSelectedRole = true;
      }
    });

    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }
  }

  private setTitle(): void {
    this.titleService.setTitle('Lavéo - Rapport Véhicules');
  }
}
