import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzTableModule } from 'ng-zorro-antd/table';
import { SharedModule } from 'src/app/shared/shared.module';
import { SelectActorModule } from 'src/app/shared/views/inputs/select-actor/select-actor.module';
import { MainSharedModule } from '../../shared/main-shared.module';
import { AdminPreparersComponent } from './admin-preparers.component';
import { PreparerDetailComponent } from './preparer/preparer-detail/preparer-detail.component';
import { PreparerFormComponent } from './preparer/preparer-form/preparer-form.component';
import { PreparerComponent } from './preparer/preparer.component';
import { InputsContactsModule } from '../../../../shared/views/inputs/inputs-contacts/inputs-contacts.module';
import { ListContactsModule } from '../../../../shared/views/list-contacts/list-contacts.module';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';

@NgModule({
  declarations: [
    AdminPreparersComponent,
    PreparerComponent,
    PreparerFormComponent,
    PreparerDetailComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MainSharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NzCardModule,
    NzRadioModule,
    NzFormModule,
    NzButtonModule,
    NzIconModule,
    NzInputModule,
    NzTableModule,
    NzCheckboxModule,
    NzEmptyModule,
    NzResultModule,
    NzDropDownModule,
    SelectActorModule,
    InputsContactsModule,
    ListContactsModule
  ],
  exports: [
    AdminPreparersComponent
  ]
})
export class AdminPreparersModule {}
