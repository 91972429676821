import { Component, EventEmitter, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Route, Router, ActivatedRoute } from '@angular/router';
import {
  addDays,
  addMonths,
  endOfMonth,
  endOfToday,
  endOfWeek,
  format,
  isBefore,
  isSameDay,
  isToday,
  startOfMonth,
  startOfToday,
  startOfWeek
} from 'date-fns';
import { NzMessageRef, NzMessageService } from 'ng-zorro-antd/message';
import { Subscription } from 'rxjs';
import { ROUTES_CONFIG } from 'src/app/configs/tokens.config';
import { ServicesFiltersRequest } from 'src/app/shared/models/api/services/services-filters-requests.interface';
import { PagedRequest } from 'src/app/shared/models/api/shared/paged/paged-request';
import { User } from 'src/app/shared/models/entities/user';
import { UserRole, UserRoleType } from 'src/app/shared/models/entities/user-role';
import { ServicesStats } from 'src/app/shared/models/interfaces/services/services-stats.interface';
import { UserService } from 'src/app/shared/services/api/user.service';
import { ServicesService } from '../../../shared/services/api/services.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { fr } from 'date-fns/locale';
import { ApolloError } from '@apollo/client/core';
import { Service, ServiceStatus } from 'src/app/shared/models/entities/service';
import { ListServicesComponent } from 'src/app/shared/views/list-services/list-services.component';
import { ServiceActions } from 'src/app/shared/models/entities/service-actions';
import { UserRight } from 'src/app/shared/models/entities/user-right';
import { Title } from '@angular/platform-browser';
import { ApiErrorMessageUtil } from '../../../shared/utils/api-error-message.util';
import { Utils } from '../../../shared/utils/utils';
import { take } from 'rxjs/operators';

@Component({
  selector: 'laveo-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild('copyForm') private copyFormContent: TemplateRef<any>;
  @ViewChild('workflowForm') private workflowFormContent: TemplateRef<any>;
  @ViewChild('exportForm') private exportFormContent: TemplateRef<any>;
  @ViewChild('listServices') private listServices: ListServicesComponent;

  checked: Service[] = [];
  actionLoading = false;
  currentUser?: User;
  currentRole?: UserRole;
  currentStats?: ServicesStats;
  filters?: PagedRequest<ServicesFiltersRequest>;

  isLoading = true;

  form: UntypedFormGroup;
  copyForm: UntypedFormGroup;
  exportForm: UntypedFormGroup;

  page: number;
  minDate: Date;
  maxDate: Date;
  dateRanges: Record<string, Date[]> = {};
  defaultTime = new Date(0, 0, 0, 0, 0, 0);

  userRightCreate: UserRight = UserRight.create;

  cancelComment = new FormControl('', [Validators.required]);

  private isStructure = false;
  private subscriptions: Subscription[] = [];
  private currentLoadingMessageReference?: NzMessageRef;
  private confirmModal?: NzModalRef;

  constructor(
    private readonly userService: UserService,
    private readonly message: NzMessageService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly servicesService: ServicesService,
    private readonly titleService: Title,
    private modal: NzModalService,
    @Inject(ROUTES_CONFIG) private readonly routes: Record<string, Route>
  ) {}

  get description(): string {
    let description = 'Bienvenue sur votre tableau de bord.';
    if (this.currentStats) {
      if (isSameDay(this.minDate, this.maxDate)) {
        const date = isToday(this.minDate) ? 'aujourd\'hui' : 'le ' + format(this.minDate, 'dd/MM/yyyy', { locale: fr });
        if (this.currentStats.total > 1) {
          description += ' Il y a ' + this.currentStats.total + ' prestations ' + date + '.';
        } else if (this.currentStats.total > 0) {
          description += ' Il y a 1 prestation ' + date + '.';
        } else {
          description += ' Il n\'y a aucune prestation ' + date + '.';
        }
      } else {
        const minDateText = isToday(this.minDate) ? 'aujourd\'hui' : 'le ' + format(this.minDate, 'dd/MM/yyyy', { locale: fr });
        const maxDateText = isToday(this.maxDate) ? 'aujourd\'hui' : 'le ' + format(this.maxDate, 'dd/MM/yyyy', { locale: fr });
        if (this.currentStats.total > 1) {
          description += ' Il y a ' + this.currentStats.total + ' prestations entre ' + minDateText + ' et ' + maxDateText + '.';
        } else if (this.currentStats.total > 0) {
          description += ' Il y a 1 prestation entre ' + minDateText + ' et ' + maxDateText + '.';
        } else {
          description += ' Il n\'y a aucune prestation ' + minDateText + ' et ' + maxDateText + '.';
        }
      }
    }

    return description;
  }

  get checkedContainsDeletable(): boolean {
    const cancelable = this.checked.find(service => service.actions.includes(ServiceActions.cancel));
    return cancelable !== undefined;
  }

  get checkedPossibleActions(): ServiceActions[] {
    return [... new Set(this.checked.flatMap(service => service.actions).filter(action => ![
      ServiceActions.cancel,
      ServiceActions.update,
      ServiceActions.upload_vehicle_state
    ].includes(action)).filter(action => action !== ServiceActions.perform || (action === ServiceActions.perform && !this.isStructure)))]; // TODO rajouter un rôle car c'est franchement pas top de faire ça comme ça pour perform)];
  }

  get deletable(): Service[] {
    return this.checked.filter(service => [
      ServiceStatus.asked,
      ServiceStatus.planned,
      ServiceStatus.proposed
    ].includes(service.status));
  }

  ngOnInit(): void {
    this.setTitle();
    this.loadUser();
    this.setDates();
    this.setSearchIfExist();
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach(sub => {
      sub.unsubscribe();
    });
  }

  onDateRangeChange(dates: Date[]): void {
    if (
      dates.length > 1 &&
      dates[0] !== this.minDate &&
      dates[1] !== this.maxDate
    ) {
      this.minDate = dates[0];
      this.maxDate = dates[1];
      this.setFilters();
    }
  }

  addService(): void {
    void this.router.navigate(['/', 'prestations', 'new']);
  }

  isSameDay(): boolean {
    return isSameDay(this.minDate, this.maxDate);
  }

  copyChecked(): void {
    const modalReference = this.modal.create({
      nzTitle: 'Renouvellement de prestation',
      nzContent: this.copyFormContent,
      nzOkText: 'Renouveler',
      nzCancelText: 'Retour',
      nzData: {
        copyForm: this.copyForm,
        disabledDates: (currentDate: Date): boolean => isBefore(currentDate, startOfToday()),
        isDateTooSoon: (currentDate: Date): boolean => isBefore(currentDate, addDays(new Date(), 2)),
        dateValidation: (control: AbstractControl): string | AbstractControl | null => isBefore(control.value, addDays(new Date(), 2)) ? 'warning' : control,
        disabledHours: (): number[] => [0, 1, 2, 3, 4, 5, 22, 23]
      },
      nzOkDisabled: true,
      nzOnOk: () => {
        this.actionLoading = true;
        const messageReference = this.message.loading('Renouvellement en cours…');

        const date: Date = this.copyForm.value.date;
        if (this.copyForm.value.time) {
          date.setHours(this.copyForm.value.time.getHours(), this.copyForm.value.time.getMinutes(), 0, 0);
        } else {
          date.setHours(0, 0, 0, 0);
        }

        this.servicesService.copyMultiple(this.checked.map(s => s.id), date).subscribe({
          next: response => {
            this.message.remove(messageReference.messageId);
            if ((response.data?.length ?? 0) === 0) {
              this.message.error('Une erreur est survenue lors du renouvellement. Réessayer plus tard.');
            } else if ((response.data?.length ?? 0) === 1) {
              this.message.info(`1 prestation renouvelée avec succès.`);
            } else {
              this.message.info(`${response.data?.length} prestations renouvelées avec succès.`);
            }
            this.actionLoading = false;
            this.checked.splice(0, this.checked.length);
            setTimeout(() => {
              // Delai de reload (au cas ou)
              this.listServices.loadData();
            }, 200);
          },
          error: (error: ApolloError) => {
            console.error(error);
            this.message.remove(messageReference.messageId);
            this.actionLoading = false;
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
          }
        });
      },
    });

    const openSubscription = modalReference.afterOpen.subscribe(() => {
      this.copyForm.setValue({
        date: null,
        time: null
      });
      this.copyForm.updateValueAndValidity();
    });

    const copySubscription = this.copyForm.valueChanges.subscribe(() => {
      const config = modalReference.getConfig();
      config.nzOkDisabled = this.copyForm.invalid;
      modalReference.updateConfig(config);
    });

    if (openSubscription && copySubscription) {
      this.subscriptions.push(openSubscription, copySubscription);
    }
  }

  deleteChecked(content: TemplateRef<any>, footer: TemplateRef<any>): void {
    this.modal.create({
      nzTitle: 'Annulation multiple',
      nzContent: content,
      nzFooter: footer
    });
  }

  closeCancelModal(modal: NzModalRef): void {
    if (this.cancelComment.invalid) {
      this.cancelComment.markAsDirty();
      this.cancelComment.updateValueAndValidity();
      return;
    }

    this.actionLoading = true;
    this.servicesService.cancelMultiple(this.cancelComment.value ?? '', ...this.deletable.map(s => s.id)).subscribe({
      next: response => {
        if ((response.data?.length ?? 0) === 0) {
          this.message.error('Une erreur est survenue lors de l\'annulation. Réessayer plus tard.');
        } else if ((response.data?.length ?? 0) === 1) {
          this.message.info(`1 prestation annulée avec succès.`);
        } else {
          this.message.info(`${response.data?.length} prestations annulées avec succès.`);
        }
        this.actionLoading = false;
        this.checked.splice(0, this.checked.length);
        setTimeout(() => {
          // Delai de reload (au cas ou)
          this.listServices.loadData();
        }, 200);
        modal.destroy();
      },
      error: (error: ApolloError) => {
        console.error(error);
        this.actionLoading = false;
        this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
      }
    });
  }

  doActionForChecked(action: ServiceActions): void {
    switch (action) {
      case ServiceActions.confirm: {
        this.confirm();
        break;
      }
      case ServiceActions.send_to_structure: {
        this.sendToStructure();
        break;
      }
      case ServiceActions.send_to_customer_site: {
        this.sendToCustomerSite();
        break;
      }
      case ServiceActions.perform: {
        this.perform();
        break;
      }
    }
  }

  actionPerformed(event: { action: ServiceActions; loading: boolean; service: Service[]; error?: Error }): void {
    if (event.loading) {
      this.actionLoading = true;
      const loadingMessage = `${event.action === ServiceActions.perform ? 'Réalisation' : 'Envoi au site client'} en cours…`;
      this.currentLoadingMessageReference = this.message.loading(loadingMessage);
    } else {
      this.actionLoading = false;
      const id = this.currentLoadingMessageReference?.messageId;
      if (id) {
        this.confirmModal?.destroy();
        this.message.remove(id);
        this.currentLoadingMessageReference = undefined;
        if (event.error) {
          this.message.error(ApiErrorMessageUtil.getMessageFromError(event.error));
        } else {
          const errorMessage = `Une erreur est survenue lors de ${event.action === ServiceActions.perform ? 'la réalisation' : 'l\'envoi au site client'}. Réessayer plus tard.`;
          if (event.service.length === 0) {
            this.message.error(errorMessage);
          } else if (event.service.length === 1) {
            this.message.info(`1 prestation ${event.action === ServiceActions.perform ? 'réalisée' : 'envoyée au site client'} avec succès.`);
          } else {
            this.message.info(`${event.service.length} prestations ${event.action === ServiceActions.perform ? 'réalisées' : 'envoyées au site client'} avec succès.`);
          }

          this.checked.splice(0, this.checked.length);
          setTimeout(() => {
            // Delai de reload (au cas ou)
            this.listServices.loadData();
          }, 200);
        }
      }
    }
  }

  setPage(page: number): void {
    this.page = page;
    this.setFilters();
  }

  exportExcel(): void {
    this.exportForm = this.formBuilder.group({
      dates: this.formBuilder.control(this.form.get('dates')?.value, [Validators.required]),
      invoiced: this.formBuilder.control(null),
      sort: this.formBuilder.control('ASC'),
      page: this.formBuilder.control('current', [Validators.required]),
      exportType: this.formBuilder.control('services', [Validators.required]),
      filename: this.formBuilder.control('')
    });

    const datesSubscription = this.exportForm.get('dates')?.valueChanges.subscribe(dates => {
      this.onDateRangeChange(dates);
    });

    if (datesSubscription) {
      this.subscriptions.push(datesSubscription);
    }

    this.exportForm.get('invoiced')?.disable();
    this.exportForm.get('sort')?.disable();

    this.modal.create({
      nzTitle: 'Exporter en .xlsx',
      nzContent: this.exportFormContent,
      nzOkText: 'Exporter',
      nzCancelText: 'Annuler',
      nzData: {
        exportForm: this.exportForm
      },
      nzOkDisabled: !this.exportForm.valid,
      nzOnOk: () => {
        let filename: string = this.exportForm.get('filename')?.value ?? 'export';
        filename = filename.trim() === '' ? 'export' : filename.trim();
        filename = filename.toLowerCase().replaceAll(' ', '-');

        const dates: Date[] = this.exportForm.get('dates')?.value;
        const onlyCurrentPage = (this.exportForm.get('page')?.value ?? 'current') === 'current';
        const exportType = this.exportForm.get('exportType')?.value ?? 'services';

        const pagedRequest = new PagedRequest<ServicesFiltersRequest>({
          page: onlyCurrentPage ? (this.page ? +this.page : 1) : undefined,
          limit: onlyCurrentPage ? 10 : undefined,
          filters: {
            minDate: dates[0] ?? this.minDate,
            maxDate: dates[1] ?? this.maxDate,
            invoiced: this.exportForm.get('invoiced')?.value
          },
          sortType: this.exportForm.get('sort')?.value
        });

        this.servicesService.servicesExcel(pagedRequest, exportType).pipe(take(1)).subscribe({
          next: (url => {
            if (url.data) {
              Utils.download(url.data, filename);
            } else {
              console.error(url);
              this.message.error('Une erreur s\'est produite durant l\'export. Réessayez plus tard');
            }
          }),
          error: (error => {
            console.error(error);
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
          })
        });
      }
    });
  }

  private loadUser(): void {
    const userSubscription = this.userService.currentUser().subscribe({
      next: result => {
        this.isLoading = result.loading;
        this.currentUser = result.data;
      },
      error: error => {
        console.error(error);
        void this.router.navigate([this.routes.logout.path]);
        this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
        this.isLoading = false;
      }
    });

    const roleSubscription = this.userService.currentRole.subscribe(role => {
      this.currentRole = role;
      this.isStructure = role.type === UserRoleType.structure; // TODO faire mieux comme par exemple ajouter un rôle spécial
    });

    this.subscriptions.push(userSubscription, roleSubscription);
  }

  private setDates(): void {
    this.minDate = startOfToday();
    this.maxDate = addMonths(startOfToday(), 6);

    this.form = this.formBuilder.group({
      dates: this.formBuilder.control([this.minDate, this.maxDate], [Validators.required])
    });

    this.copyForm = this.formBuilder.group({
      date: this.formBuilder.control(null, [Validators.required]),
      time: this.formBuilder.control(null, [])
    });

    this.dateRanges = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Aujourd\'hui': [startOfToday(), endOfToday()],
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Cette semaine': [startOfWeek(startOfToday(), { weekStartsOn: 1 }), endOfWeek(startOfToday(), { weekStartsOn: 1 })],
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Ce mois': [startOfMonth(startOfToday()), endOfMonth(startOfToday())]
    };

    const datesSubscriptions = this.form.get('dates')?.valueChanges.subscribe(dates => {
      this.onDateRangeChange(dates);
    });

    if (datesSubscriptions) {
      this.subscriptions.push(datesSubscriptions);
    }
  }

  private setSearchIfExist(): void {
    let firstTime = true;
    this.subscriptions.push(
      this.route.queryParamMap.subscribe(parameters => {
        const minDate = parameters.get('minDate');
        const maxDate = parameters.get('maxDate');
        const page = parameters.get('page');

        if (minDate && maxDate) {
          this.minDate = new Date(+minDate);
          this.maxDate = new Date(+maxDate);
          this.form.get('dates')?.setValue([this.minDate, this.maxDate]);
        }


        if (page) {
          this.page = +page;
        }

        this.setFilters(!firstTime);
        firstTime = false;
      })
    );

  }

  private setFilters(updateUrl = true): void {
    this.filters = new PagedRequest<ServicesFiltersRequest>({
      page: this.page ? +this.page : 1,
      filters: {
        minDate: this.minDate,
        maxDate: this.maxDate
      }
    });

    if (updateUrl) {
      void this.router.navigate([], {queryParams: { page: this.page, minDate: +this.minDate, maxDate: +this.maxDate }, queryParamsHandling: 'merge' });
    }

    this.checked.splice(0, this.checked.length);
  }

  // Checked actions

  private confirm(): void {
    const confirmable = this.checked.filter(service => service.actions.includes(ServiceActions.confirm));
    let nzContent = `Êtes-vous sûr de vouloir confirmer <b>${confirmable.length > 1 ? `les ${confirmable.length} prestations sélectionnées` : 'la prestation sélectionnée'}</b> ?`;
    if (confirmable.length !== this.checked.length) {
      const notConfirmableLength = this.checked.length - confirmable.length;
      nzContent += notConfirmableLength === 1 ? '<br><br> (' + notConfirmableLength + ' autre prestation ne peut pas être confirmée à cause de son statut)' : '<br><br> (' + notConfirmableLength + ' autres prestations ne peuvent pas être confirmées à cause de leurs statuts)';
    }

    this.modal.confirm({
      nzTitle: 'Confirmation multiple',
      nzContent,
      nzOkText: 'Confirmer',
      nzOkType: 'primary',
      nzCancelText: 'Annuler',
      nzOnOk: () => {
        this.actionLoading = true;
        const messageReference = this.message.loading('Confirmation en cours…');
        this.servicesService.confirmMultiple(...confirmable.map(s => s.id)).subscribe({
          next: response => {
            this.message.remove(messageReference.messageId);
            if ((response.data?.length ?? 0) === 0) {
              this.message.error('Une erreur est survenue lors de la confirmation. Réessayer plus tard.');
            } else if ((response.data?.length ?? 0) === 1) {
              this.message.info(`1 prestation confirmée avec succès.`);
            } else {
              this.message.info(`${response.data?.length} prestations confirmées avec succès.`);
            }
            this.actionLoading = false;
            this.checked.splice(0, this.checked.length);
            setTimeout(() => {
              // Delai de reload (au cas ou)
              this.listServices.loadData();
            }, 200);
          },
          error: (error: ApolloError) => {
            this.message.remove(messageReference.messageId);
            console.error(error);
            this.actionLoading = false;
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
          }
        });
      }
    });
  }

  private sendToStructure(): void {
    const sendable = this.checked.filter(service => service.actions.includes(ServiceActions.send_to_structure));
    let nzContent = `Êtes-vous sûr de vouloir envoyer <b>${sendable.length > 1 ? `les ${sendable.length} prestations sélectionnées` : 'la prestation sélectionnée'}</b> à la structure ?`;
    if (sendable.length !== this.checked.length) {
      const notSendableLength = this.checked.length - sendable.length;
      nzContent += notSendableLength === 1 ? '<br><br> (' + notSendableLength + ' autre prestation ne peut pas être envoyée à cause de son statut)' : '<br><br> (' + notSendableLength + ' autres prestations ne peuvent pas être envoyées à cause de leurs statuts)';
    }

    this.modal.confirm({
      nzTitle: 'Envoi multiple à la structure',
      nzContent,
      nzOkText: 'Envoyer',
      nzOkType: 'primary',
      nzCancelText: 'Annuler',
      nzOnOk: () => {
        this.actionLoading = true;
        const messageReference = this.message.loading('Envoi à la structure en cours…');
        this.servicesService.sendToStructureMultiple(...sendable.map(s => s.id)).subscribe({
          next: response => {
            this.message.remove(messageReference.messageId);
            if ((response.data?.length ?? 0) === 0) {
              this.message.error('Une erreur est survenue lors de l\'envoi à la structure. Réessayer plus tard.');
            } else if ((response.data?.length ?? 0) === 1) {
              this.message.info(`1 prestation envoyée avec succès.`);
            } else {
              this.message.info(`${response.data?.length} prestations envoyées avec succès.`);
            }
            this.actionLoading = false;
            this.checked.splice(0, this.checked.length);
            setTimeout(() => {
              // Delai de reload (au cas ou)
              this.listServices.loadData();
            }, 200);
          },
          error: (error: ApolloError) => {
            this.message.remove(messageReference.messageId);
            console.error(error);
            this.actionLoading = false;
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
          }
        });
      }
    });
  }

  private sendToCustomerSite(): void {
    const sendable = this.checked.filter(service => service.actions.includes(ServiceActions.send_to_customer_site));
    let content = `Êtes-vous sûr de vouloir envoyer <b>${sendable.length > 1 ? `les ${sendable.length} prestations sélectionnées` : 'la prestation sélectionnée'}</b> ?`;
    if (sendable.length !== this.checked.length) {
      const notSendableLength = this.checked.length - sendable.length;
      content += notSendableLength === 1 ? '<br><br> (' + notSendableLength + ' autre prestation ne peut pas être envoyée à cause de son statut)' : '<br><br> (' + notSendableLength + ' autres prestations ne peuvent pas être envoyées à cause de leurs statuts)';
    }

    const emitter = new EventEmitter<ServiceActions>();

    this.confirmModal = this.modal.create({
      nzTitle: 'Envoi multiple au site client',
      nzContent: this.workflowFormContent,
      nzData: {
        services: sendable,
        content,
        emitter
      },
      nzFooter: [
        {
          label: 'Annuler',
          onClick: () => this.confirmModal?.destroy()
        },
        {
          label: 'Envoyer',
          type: 'primary',
          onClick: () => emitter.emit(ServiceActions.send_to_customer_site)
        }
      ]
    });
  }

  private perform(): void {
    const performable = this.checked.filter(service => service.actions.includes(ServiceActions.perform));
    let content = `Êtes-vous sûr de vouloir réaliser <b>${performable.length > 1 ? `les ${performable.length} prestations sélectionnées` : 'la prestation sélectionnée'}</b> ?`;
    if (performable.length !== this.checked.length) {
      const notPerformableLength = this.checked.length - performable.length;
      content += notPerformableLength === 1 ? '<br><br> (' + notPerformableLength + ' autre prestation ne peut pas être réalisée à cause de son statut)' : '<br><br> (' + notPerformableLength + ' autres prestations ne peuvent pas être réalisées à cause de leurs statuts)';
    }

    const emitter = new EventEmitter<ServiceActions>();

    this.confirmModal = this.modal.create({
      nzTitle: 'Réalisation multiple',
      nzContent: this.workflowFormContent,
      nzData: {
        services: performable,
        content,
        emitter
      },
      nzFooter: [
        {
          label: 'Annuler',
          onClick: () => this.confirmModal?.destroy()
        },
        {
          label: 'Réaliser',
          type: 'primary',
          onClick: () => emitter.emit(ServiceActions.perform)
        }
      ]
    });
  }

  private setTitle() {
    this.titleService.setTitle('Lavéo - Accueil');
  }
}
