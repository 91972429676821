import { Component, OnDestroy, OnInit } from '@angular/core';
import { PagedResponse } from '../../../../../shared/models/api/shared/paged/paged-response.interface';
import { VehicleModel } from '../../../../../shared/models/entities/vehicle-model';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NzTableQueryParams, NzTableSortOrder } from 'ng-zorro-antd/table';
import { VehicleModelsService } from '../../../../../shared/services/api/vehicles-model.service';
import { Subscription } from 'rxjs';
import { PagedRequest } from '../../../../../shared/models/api/shared/paged/paged-request';
import { debounceTime } from 'rxjs/operators';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Router } from '@angular/router';
import { ApiErrorMessageUtil } from '../../../../../shared/utils/api-error-message.util';

@Component({
  selector: 'laveo-vehicle-model-list',
  templateUrl: './vehicle-model-list.component.html',
  styleUrls: ['./vehicle-model-list.component.scss']
})
export class VehicleModelListComponent implements OnInit, OnDestroy {

  modelsIsLoading = true;
  modelsActionIsLoading = false;
  modelsData?: PagedResponse<VehicleModel>;
  modelsSearchForm: UntypedFormGroup;
  modelsCurrentPage = 1;
  modelsLimit = 10;
  modelsChecked: string[] = [];
  modelsError?: Error;

  private modelsSort: { key: string; value: NzTableSortOrder }[] = [{ key: 'brand', value: 'ascend' }];
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly router: Router,
    private readonly vehicleModelsService: VehicleModelsService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly modal: NzModalService,
    private readonly message: NzMessageService
  ) {}

  ngOnInit(): void {
    this.setModelsForm();
    this.loadModelsData();
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  loadModelsData(): void {
    let sortProperty: string | undefined;
    let sortType: 'ASC' | 'DESC' | undefined;

    const currentSort = this.modelsSort.find(s => s.value);
    if (currentSort) {
      sortProperty = currentSort.key;
      sortType = currentSort.value === 'ascend' ? 'ASC' : 'DESC';
    }

    const search: string = this.modelsSearchForm.get('search')?.value;

    const parameters = new PagedRequest({
      page: this.modelsCurrentPage,
      limit: this.modelsLimit,
      sortProperty,
      sortType,
      search: search?.toLowerCase()?.trim()
    });

    this.modelsIsLoading = true;

    const modelsSubscription = this.vehicleModelsService.allVehicleModels(parameters).subscribe({
      next: response => {
        this.modelsIsLoading = response.loading;
        this.modelsData = response.data;
        modelsSubscription?.unsubscribe();
      },
      error: error => {
        console.error(error);
        this.modelsError = error;
        this.modelsIsLoading = false;
        modelsSubscription?.unsubscribe();
      }
    });

    this.subscriptions.push(modelsSubscription);
  }

  setModelsPage(event: NzTableQueryParams): void {
    const indexSame = !event.pageIndex || this.modelsCurrentPage === event.pageIndex;
    const limitSame = this.modelsLimit === event.pageSize;
    let sortSame = true;
    for (const sortObject of event.sort) {
      const originalSort = this.modelsSort.find(sortElement => sortElement.key === sortObject.key);
      if (originalSort?.value !== sortObject.value) {
        sortSame = false;
        break;
      }
    }

    if (indexSame && limitSame && sortSame) {
      return;
    }

    this.modelsCurrentPage = event.pageIndex;
    this.modelsLimit = event.pageSize;
    this.modelsSort = event.sort;
    this.loadModelsData();
  }

  setModelsChecked(check: boolean, id: string): void {
    if (check && !this.modelsChecked.includes(id)) {
      this.modelsChecked.push(id);
    } else if (!check && this.modelsChecked.includes(id)) {
      this.modelsChecked = this.modelsChecked.filter(c => c !== id);
    }
  }

  addVehicleModel(): void {
    void this.router.navigate(['/', 'admin', 'params', 'vehicle-models', 'new']);
  }

  editVehicleModel(vehicleModel: VehicleModel): void {
    void this.router.navigate(['/', 'admin', 'params', 'vehicle-models', vehicleModel.id], { fragment: 'edit' });
  }

  deleteVehicleModel(vehicleModel: VehicleModel): void {
    this.modal.confirm({
      nzTitle: 'Désactivation',
      nzContent: 'Êtes-vous sûr de vouloir désactiver le modèle sélectionné ?',
      nzOkText: 'Désactiver',
      nzOkType: 'primary',
      nzCancelText: 'Annuler',
      nzOnOk: () => {
        this.vehicleModelsService.deleteVehicleModel(vehicleModel.id).subscribe({
          next: () => {
            this.loadModelsData();
            this.message.info('Le modèle à bien été désactivé');
          },
          error: error => {
            console.error(error);
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
          }
        });
      }
    });
  }

  deleteCheckedVehicleModel(): void {
    let nzContent = 'Êtes-vous sûr de vouloir désactiver les <b> ' + this.modelsChecked.length + ' modèles sélectionnés</b> ?';
    if (this.modelsChecked.length === 1) {
      nzContent = 'Êtes-vous sûr de vouloir désactiver le modèle sélectionné ?';
    }
    this.modal.confirm({
      nzTitle: 'Désactivation multiple',
      nzContent,
      nzOkText: 'Désactiver',
      nzOkType: 'primary',
      nzCancelText: 'Annuler',
      nzOnOk: () => {
        this.modelsActionIsLoading = true;
        this.vehicleModelsService.deleteVehicleModel(...this.modelsChecked).subscribe({
          next: () => {
            this.modelsActionIsLoading = false;
            this.loadModelsData();
            this.modelsChecked.splice(0, this.modelsChecked.length);
          },
          error: error => {
            console.error(error);
            this.modelsActionIsLoading = false;
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
          }
        });
      }
    });
  }

  private setModelsForm(): void {
    this.modelsSearchForm = this.formBuilder.group({
      search: this.formBuilder.control(null)
    });

    const searchSubscription = this.modelsSearchForm.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this.modelsCurrentPage = 1;
      this.loadModelsData();
    });
    this.subscriptions.push(searchSubscription);
  }

}
