<laveo-entity-wrapper
  [title]="title"
  [description]="description"
  [entityService]="userService"
  [getFunction]="userService.currentUser"
  [updateFunction]="userService.updateUser"
  [wrappedComponent]="this"
  [getFormFunction]="form">
  <laveo-account-detail *ngIf="isReady" detail [user]="user"></laveo-account-detail>
  <laveo-account-form *ngIf="isReady" form [user]="user"></laveo-account-form>
</laveo-entity-wrapper>
