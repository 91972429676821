import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { SharedModule } from 'src/app/shared/shared.module';
import { InputLicensePlateModule } from 'src/app/shared/views/inputs/input-license-plate/input-license-plate.module';
import { SelectVehicleAttributionModule } from 'src/app/shared/views/inputs/select-vehicle-attribution/select-vehicle-attribution.module';
import { SelectVehicleFrequencyModule } from 'src/app/shared/views/inputs/select-vehicle-frequency/select-vehicle-frequency.module';
import { SelectVehicleModelModule } from 'src/app/shared/views/inputs/select-vehicle-model/select-vehicle-model.module';
import { MainSharedModule } from '../../shared/main-shared.module';
import { AdminVehiclesComponent } from './admin-vehicles.component';
import { VehicleDetailComponent } from './vehicle/vehicle-detail/vehicle-detail.component';
import { VehicleFormComponent } from './vehicle/vehicle-form/vehicle-form.component';
import { VehicleComponent } from './vehicle/vehicle.component';
import { SelectActorModule } from '../../../../shared/views/inputs/select-actor/select-actor.module';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { SelectVehicleCategoryModule } from 'src/app/shared/views/inputs/select-vehicle-category/select-vehicle-category.module';
import { SelectVehicleBrandModule } from 'src/app/shared/views/inputs/select-vehicle-brand/select-vehicle-brand.module';
import { LicensePlateModule } from 'src/app/shared/views/license-plate/license-plate.module';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { AdminVehicleModalUpdateComponent } from './admin-vehicle-modal-update/admin-vehicle-modal-update.component';

@NgModule({
  declarations: [
    AdminVehiclesComponent,
    VehicleComponent,
    VehicleFormComponent,
    VehicleDetailComponent,
    AdminVehicleModalUpdateComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MainSharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NzCardModule,
    NzCheckboxModule,
    NzTableModule,
    NzButtonModule,
    NzIconModule,
    NzResultModule,
    NzEmptyModule,
    NzFormModule,
    NzGridModule,
    NzRadioModule,
    NzUploadModule,
    NzInputModule,
    NzDropDownModule,
    NzModalModule,
    InputLicensePlateModule,
    LicensePlateModule,
    SelectActorModule,
    SelectVehicleModelModule,
    SelectVehicleFrequencyModule,
    SelectVehicleAttributionModule,
    SelectVehicleCategoryModule,
    SelectVehicleBrandModule
  ],
  exports: [
    AdminVehiclesComponent
  ]
})
export class AdminVehiclesModule {}
