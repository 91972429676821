<div class="tab-content">
  <form nz-form [formGroup]="form" nzLayout="vertical">
    <div nz-row nzGutter="8" nzAlign="middle" class="mt-8">
      <div nz-col [nzSm]="24" [nzMd]="12" [nzXl]="8">
        <nz-form-item>
          <nz-form-label nzFor="year">Année</nz-form-label>
          <nz-form-control>
            <nz-select
              id="year"
              name="year"
              nzSize="large"
              nzPlaceHolder="Année"
              [nzShowArrow]="true"
              formControlName="Year"
              [style.minWidth]="'200px'">
              <nz-option *ngFor="let year of years" [nzLabel]="year" [nzValue]="year"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzFlex="1 1 auto"></div>
      <div nz-col>
        <button type="button" nz-button nzType="primary" nzSize="large" (click)="save()">
          <i nz-icon nzType="save"></i>
          Sauvegarder
        </button>
      </div>
    </div>
    <nz-table
      nzBordered
      nzSize="small"
      [nzData]="['']"
      [nzShowPagination]="false"
      [nzLoading]="isLoading"
      [nzScroll]="{ x: '20000px' }"
      *ngIf="!error; else errorTemplate">
      <thead>
        <tr>
          <th nzColumnKey="rse" nzLeft rowspan="3">RSE</th>
          <th *ngFor="let serviceType of serviceTypes" [nzColumnKey]="'serviceType-' + serviceType"
            [colSpan]="serviceFinishings.length * vehicleCategories.length" >
            Type de lavage : {{ serviceType | serviceTypeName }}
          </th>
          <th nzColumnKey="firstIntervention" rowspan="3">1èreIntervention</th>
          <th nzColumnKey="vehicleVeryDirty" rowspan="3">Véhicule très sale</th>
          <th nzColumnKey="serviceUpholsteryStainRemoval" rowspan="2" [colSpan]="serviceUpholsteryStainRemovals.length">Détachage sellerie</th>
          <th nzColumnKey="serviceCarpetStainRemoval" rowspan="2" [colSpan]="serviceCarpetStainRemovals.length">Détachage moquette</th>
          <th nzColumnKey="vehicleStateEnabled" rowspan="3">État des lieux</th>
          <th nzColumnKey="conveying" rowspan="3">Convoyage</th>
          <th nzColumnKey="sticking" rowspan="3">Stickage</th>
          <th nzColumnKey="desticking" rowspan="3">Destickage</th>
          <th nzColumnKey="contactZoneDisinfection" rowspan="3">Desinfection ZC</th>
          <th nzColumnKey="interiorDisinfection" rowspan="3">Desinfection H</th>
        </tr>
        <tr>
          <ng-container *ngFor="let serviceType of serviceTypes">
            <th [nzColumnKey]="'serviceFinishing-' + serviceFinishing" *ngFor="let serviceFinishing of serviceFinishings"
              [colSpan]="vehicleCategories.length">
              Finition : {{ serviceFinishing | serviceFinishingName }}
            </th>
          </ng-container>
        </tr>
        <tr>
          <ng-container *ngFor="let serviceType of serviceTypes">
            <ng-container *ngFor="let serviceFinishing of serviceFinishings">
              <th [nzColumnKey]="'vehicleCategory-' + vehicleCategory" *ngFor="let vehicleCategory of vehicleCategories">
                {{ vehicleCategory | vehicleCategoryName }}
              </th>
            </ng-container>
          </ng-container>
          <th [nzColumnKey]="'serviceUpholsteryStainRemoval-' + serviceUpholsteryStainRemoval"
            *ngFor="let serviceUpholsteryStainRemoval of serviceUpholsteryStainRemovals">
            {{ serviceUpholsteryStainRemoval | serviceUpholsteryStainRemovalName }}
          </th>
          <th [nzColumnKey]="'serviceCarpetStainRemoval-' + serviceCarpetStainRemoval"
            *ngFor="let serviceCarpetStainRemoval of serviceCarpetStainRemovals">
            {{ serviceCarpetStainRemoval | serviceCarpetStainRemovalName }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let rseType of rseTypes; let i = index; let length = count;" [formGroupName]="rseType.toString()">
          <td nzColumnKey="rse" nzLeft>
            {{ rseType | rseTypeName }}
          </td>
          <ng-container *ngFor="let serviceType of serviceTypes" [formGroupName]="serviceType.toString()">
            <ng-container *ngFor="let serviceFinishing of serviceFinishings" [formGroupName]="serviceFinishing.toString()">
              <td *ngFor="let vehicleCategory of vehicleCategories">
                <nz-input-number [nzMin]="0" [nzStep]="0.1" nzPlaceHolder="0" [formControlName]="vehicleCategory.toString()" [nzFormatter]="numberFormatter" [nzParser]="numberParser"></nz-input-number>
              </td>
            </ng-container>
          </ng-container>
          <td formGroupName="Options">
            <nz-input-number [nzMin]="0" [nzStep]="0.1" nzPlaceHolder="0" formControlName="FirstIntervention" [nzFormatter]="numberFormatter" [nzParser]="numberParser"></nz-input-number>
          </td>
          <td formGroupName="Options">
            <nz-input-number [nzMin]="0" [nzStep]="0.1" nzPlaceHolder="0" formControlName="VehicleVeryDirty" [nzFormatter]="numberFormatter" [nzParser]="numberParser"></nz-input-number>
          </td>
          <ng-container formGroupName="UpholsteryStainRemoval">
            <td *ngFor="let serviceUpholsteryStainRemoval of serviceUpholsteryStainRemovals">
              <nz-input-number [nzMin]="0" [nzStep]="0.1" nzPlaceHolder="0" [formControlName]="serviceUpholsteryStainRemoval.toString()" [nzFormatter]="numberFormatter" [nzParser]="numberParser"></nz-input-number>
            </td>
          </ng-container>
          <ng-container formGroupName="CarpetStainRemoval">
            <td *ngFor="let serviceCarpetStainRemoval of serviceCarpetStainRemovals">
              <nz-input-number [nzMin]="0" [nzStep]="0.1" nzPlaceHolder="0" [formControlName]="serviceCarpetStainRemoval.toString()" [nzFormatter]="numberFormatter" [nzParser]="numberParser"></nz-input-number>
            </td>
          </ng-container>
          <td formGroupName="Options">
            <nz-input-number [nzMin]="0" [nzStep]="0.1" nzPlaceHolder="0" formControlName="VehicleState" [nzFormatter]="numberFormatter" [nzParser]="numberParser"></nz-input-number>
          </td>
          <td formGroupName="Options">
            <nz-input-number [nzMin]="0" [nzStep]="0.1" nzPlaceHolder="0" formControlName="Conveying" [nzFormatter]="numberFormatter" [nzParser]="numberParser"></nz-input-number>
          </td>
          <td formGroupName="Options">
            <nz-input-number [nzMin]="0" [nzStep]="0.1" nzPlaceHolder="0" formControlName="Sticking" [nzFormatter]="numberFormatter" [nzParser]="numberParser"></nz-input-number>
          </td>
          <td formGroupName="Options">
            <nz-input-number [nzMin]="0" [nzStep]="0.1" nzPlaceHolder="0" formControlName="Desticking" [nzFormatter]="numberFormatter" [nzParser]="numberParser"></nz-input-number>
          </td>
          <td formGroupName="Options">
            <nz-input-number [nzMin]="0" [nzStep]="0.1" nzPlaceHolder="0" formControlName="ContactZoneDisinfection" [nzFormatter]="numberFormatter" [nzParser]="numberParser"></nz-input-number>
          </td>
          <td formGroupName="Options">
            <nz-input-number [nzMin]="0" [nzStep]="0.1" nzPlaceHolder="0" formControlName="InteriorDisinfection" [nzFormatter]="numberFormatter" [nzParser]="numberParser"></nz-input-number>
          </td>
        </tr>
      </tbody>
    </nz-table>
    <div nz-row nzGutter="8" class="mt-8">
      <div nz-col [nzSm]="24" [nzMd]="12" [nzXl]="6">
        <nz-form-item>
          <nz-form-label nzFor="consumption">Consommation d'eau par foyer (Litres par jour)</nz-form-label>
          <nz-form-control>
            <nz-input-number nzId="consumption" name="consumption" [nzMin]="0" [nzStep]="1" nzPlaceHolder="0" formControlName="WaterConsumptionPerHousehold" [nzFormatter]="numberFormatter" [nzParser]="numberParser"></nz-input-number>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSm]="24" [nzMd]="12" [nzXl]="6">
        <nz-form-item>
          <nz-form-label nzFor="etp">ETP (heures /an)</nz-form-label>
          <nz-form-control>
            <nz-input-number nzId="etp" name="etp" [nzMin]="0" [nzStep]="1" nzPlaceHolder="0" formControlName="Etp" [nzFormatter]="numberFormatter" [nzParser]="numberParser"></nz-input-number>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSm]="24" [nzMd]="12" [nzXl]="6">
        <nz-form-item>
          <nz-form-label nzFor="co2">Economie émission CO2 (kg /prestation)</nz-form-label>
          <nz-form-control>
            <nz-input-number nzId="co2" name="co2" [nzMin]="0" [nzStep]="0.001" nzPlaceHolder="0" formControlName="Co2EmissionsSavings" [nzFormatter]="numberFormatter" [nzParser]="numberParser"></nz-input-number>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSm]="24" [nzMd]="12" [nzXl]="6">
        <nz-form-item>
          <nz-form-label nzFor="solidarity">Volet Solidarité Internationale (euros /prestation)</nz-form-label>
          <nz-form-control>
            <nz-input-number nzId="solidarity" name="solidarity" [nzMin]="0" [nzStep]="0.1" nzPlaceHolder="0" formControlName="InternationalSolidarityComponent" [nzFormatter]="numberFormatter" [nzParser]="numberParser"></nz-input-number>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>
  <ng-template #errorTemplate>
    <nz-result nzTitle="Erreur de chargement" nzStatus="error"
      nzSubTitle="Une erreur est survenue lors du chargement des paramètres du blian RSE">
      <div nz-result-extra>
        <button nz-button nzType="primary" (click)="loadData()">
          Réessayer
          <i nz-icon nzType="reload"></i>
        </button>
      </div>
    </nz-result>
  </ng-template>
</div>
